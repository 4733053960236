export class SearchResult {
    //This class wasn't necessary yet (used it for sending current location anyways), but likely will be in the future.
    constructor(referenceID, name) {
        this.referenceID = referenceID;
        this.name = name;
        this.location = null; // L.latLng(lat, lon);
    }
}

export default SearchResult;
