// const PHOTO_URL = 'UserProfilePic.jpg';
const UNKNOWN_PHOTO_URL = 'images/noProfilePic.jpg';
const UNKNOWN_EMAIL = 'UnknownEmail@iHunter.com';
const UNKNOWN_NAME = 'Unknown Contact';
import ContactMarker from './ContactMarker';
import Observable from './Observable';

const EVENT = {
    UPDATE_CONTACT_IMAGE: 'update-contact-image'
}

export default class Contact extends Observable {
    constructor(uid, mapProvider) {
        super();
        this.uid = uid;
        
        this.username = UNKNOWN_NAME;
        this.email = UNKNOWN_EMAIL;
        this.accepted = true;
        this.online = false;       
        this.chatKey = ''
        this.photoUrl = UNKNOWN_PHOTO_URL; 
        this.onUpdateContactImage = this.createHandlerMethod(EVENT.UPDATE_CONTACT_IMAGE);
        this.locationMarker = new ContactMarker(this, mapProvider);   

    }

    // get PHOTO_URL() { return PHOTO_URL; }
    get UNKNOWN_PHOTO_URL() { return UNKNOWN_PHOTO_URL; }
    get UNKNOWN_EMAIL() { return UNKNOWN_EMAIL; }
    get UNKNOWN_NAME() { return UNKNOWN_NAME; }

    initWithJson(dataService, mapProvider, contactJson){
        if(contactJson != null) {
            this.uid = contactJson.uid;
            this.username = contactJson.username;
            this.email = contactJson.email;
            this.accepted = Object.prototype.hasOwnProperty.call(contactJson, "accepted") ? contactJson.accepted : false;
            this.online = Object.prototype.hasOwnProperty.call(contactJson, "online") ? contactJson.online : false;
            this.profilePicDateString = contactJson.profilePicDate;
            if(dataService.user.uid != this.uid) {
                this.chatKey = dataService.db.createChatKeyForContacts([this, dataService.userContact]);
            }

            this.locationMarker = new ContactMarker(this, mapProvider);      
            

            dataService.storage.getPhotoUrl(this.uid).then((url) => {
              this.photoUrl = url;
              this.locationMarker.setPin();
              this.emit(EVENT.UPDATE_CONTACT_IMAGE, this);
            }, () => {
                this.photoUrl = UNKNOWN_PHOTO_URL;
            });
        }
    }

    //only gets used with user as Contact, so no need for locationMarker mapProvider
    initWithData(dataService, email, username, photoUrl) {
        this.email = email ? email : UNKNOWN_EMAIL;
        this.username = username ? username : UNKNOWN_NAME;
        this.photoUrl = photoUrl ? photoUrl : UNKNOWN_PHOTO_URL; 
        this.locationMarker = new ContactMarker(this, null);    
        dataService.storage.getPhotoUrl(this.uid).then((url) => {
            this.photoUrl = url;
            this.locationMarker.setPin();
            this.emit(EVENT.UPDATE_CONTACT_IMAGE, this);
        }, () => {
            this.photoUrl = UNKNOWN_PHOTO_URL;
        });
    }

    isEqual(object) {
        var contact = object;
        return contact.uid == this.uid;
    }

    update(contactJson) {
      this.username = contactJson.username;
      this.accepted = Object.prototype.hasOwnProperty.call(contactJson, "accepted") ? contactJson.accepted : false;
      this.online = Object.prototype.hasOwnProperty.call(contactJson, "online") ? contactJson.online : false;
      this.locationMarker.updatePopup();
    }

    //  Deprecated: see update
    // updateWithContact(contact) {
    //     this.username = contact.username;
    //     this.accepted = contact.accepted;
    //     this.online = contact.online;
    //     this.locationMarker.updatePopup();
    // }

    // Moved to FirebaseDatabase
    // getPhotoUrl() {
    //     var self = this;
    //     if(!this.photoUrl || this.photoUrl == "") {
    //         var storageRef = firebase.storage().ref();
    //         storageRef.child('/users/'+this.uid+'/UserProfilePic.jpg').getDownloadURL().then(function(url) {
    //             self.photoUrl = url;
    //             self.locationMarker.setPin();
    //             return self.photoUrl;
    //         }).catch(function(error) {
    //             console.log("NO FILE EXISTS FOR: " + self.uid + ". This is normal - ignore this error!");
    //             self.photoUrl = 'images/noProfilePic.jpg';
    //             self.locationMarker.setPin();
    //             return self.photoUrl;
    //         });
    //     } 
    //     return this.photoUrl ? this.photoUrl : 'images/noProfilePic.jpg';
    // }

    isUnknownContact() {
        return false;
    }

    // isUser() {
    //     if(this.uid == gFirebaseHelper.user.uid) {
    //         return true;
    //     }
    //     return false;
    //}

    getMaskedEmail() {
        if(this.email != null) {
            var e = this.email.toLowerCase();
            if(e.includes("@")) {
                var emailComponents = e.split("@");
                var firstPart = emailComponents[0];
                if(firstPart.length > 3) {
                    for(var i = 3; i < e.length && e.charAt(i) != "@"; ++i) {
                        e = setCharAt(e, i, "*");
                    }
                }
            }
            return e;
        }
        return "";
    }

    removeMarker() {
       if(this.locationMarker) {
           this.locationMarker.removeMarkers();
       }
    }
    
}

function setCharAt(str,index,chr) {
    if(index > str.length-1) return str;
    return str.substr(0,index) + chr + str.substr(index+1);
}
