import { shouldShowAnimations } from './minimumrequirements';


const SIZE_CLOSED =  "0px";
const MENU_TYPE_CLOSED = 0;
const TRANSITION_SPEED = shouldShowAnimations() ? 250 : 50; //still have a short delay to give the previous bar time to hide.


export default class Sidebar {

    static get SIZE_CLOSED() { return SIZE_CLOSED; }
    static get MENU_TYPE_CLOSED() { return MENU_TYPE_CLOSED; }
    static get TRANSITION_SPEED() { return TRANSITION_SPEED; }

    constructor(sidebarDiv, sidebarContent, sidebarHeading, sidebarFooter) {
        this.bSidebarOpen = false;
        this.sidebarDiv = sidebarDiv;
        this.sidebarContent = sidebarContent;
        this.sidebarHeader = sidebarHeading;
        this.sidebarFooter = sidebarFooter;
    }

    open() {
    }

    close() {
    }

    removeViewsExcept(type) {
    }
               
    setHeader(header) {
        this.sidebarHeader.empty();
        this.sidebarHeader.html(header);
    }

    getHeader() {
        return this.sidebarHeader.html();
    }
    
    setContent(content) {
        this.sidebarContent.empty();
        this.sidebarContent.html(content);
    }
    
    setFooter(htmlFooter) {
        this.sidebarContent.toggleClass('footer-hidden', false); // in case it was hidden
        this.sidebarFooter.show();
        this.sidebarFooter.empty();
        this.sidebarFooter.html(htmlFooter);
    }

    hideFooter() {
        this.sidebarFooter.hide();
        this.sidebarContent.toggleClass('footer-hidden', true);
    }
    
    setActiveButton(type) {
    }


    openFromHtml(htmlString, view) {
        if(this.bSidebarOpen) {
            this.close();
            this.removeViewsExcept(null);
            setTimeout(() => {
                this.populateAndOpen(htmlString, view);
            }, Sidebar.TRANSITION_SPEED);
        } else {
            this.populateAndOpen(htmlString, view);
        }
    }
    
    populateAndOpen(htmlString, view) {
        this.sidebarContent.load(htmlString, () => {
            this.populateHeaderAndFooter(view);
            view.populatePage();
            this.open();
        }, this);
    }

    populateHeaderAndFooter(view) {
      if(!view.header) {
        this.setHeader('');
      }else {
        this.setHeader(view.header);
      }

      if(!view.footer) {
        this.hideFooter();
      }else {
        this.setFooter(view.footer);
      }
    }

    openFromHtmlWithType(type, htmlString, view) {
        if(this.shouldClose(type)) { //other will just toggle, not close
            this.close(); 
            this.removeViewsExcept(type);
        } else {
            if(type != Sidebar.MENU_TYPE_CLOSED) {
                this.close();
                this.removeViewsExcept(type); 
                this.sidebarViewType = type;

                setTimeout(() => {
                    this.openFromHtml(htmlString, view);
                }, Sidebar.TRANSITION_SPEED);

            } else {
                this.openFromHtml(htmlString, view);
            }
        }
    }

    shouldClose(type) { //override in subclass
        return false;
    }

    setSidebarSizeForType(type) {
    }
}
