import $ from 'jquery';
import _ from 'underscore';
import SunCalc from 'suncalc';
import Swiper, { Navigation } from 'swiper'; Swiper.use([Navigation]);

import { getCoordinateString } from './CoordinateConverter';
import View from './View';
import DateHelpers from './DateHelpers';
import Waypoint from './Waypoint';

export var gActiveUUID = ""

export class WaypointView extends View {
    constructor(waypointManager, uuid) {
        super();

        this.waypointManager = waypointManager;

        this.waypointsDict = this.waypointManager.getListByType(this.WaypointType);
        this.waypoint = this.waypointsDict[uuid];

        this.swiper = null;
        this.waypointManager.activeWaypoint = this.waypoint; //gActiveUUID = uuid;
        
        this.numImagesLoaded = 0;
    }

    get header() {
        return "Waypoint";
    }

    get footer() {
        return  '<div class="d-flex justify-content-center" style="width:100%;"><button id="drivingDirectionsButton" class="btn btn-primary" type="button" style="margin-right: 10px;margin-left: 10px;"><i class="fa fa-map-signs"></i>&nbsp;Driving Directions</button><button class="btn btn-primary" type="button" id="editWaypointButton" style="margin-right: 10px;margin-left: 10px;"><i class="fa fa-edit"></i>&nbsp;Edit</button></div>';
    }

    get WaypointType() {
        return Waypoint;
    }
    
    get waypointImages() {
        return this.waypoint.imageNamesArray;
    }

    setupClickListeners() {
        $("#editWaypointButton").on('click', () => {
            this.waypointManager.showEditWaypoint(this.waypoint);
            window.closeRightSidebar(); // Don't like this. Need to give view access to close parent sidebar (Erik)
        });
        $("#drivingDirectionsButton").on('click', () => {
            this.waypointManager.showNavigateToWaypoint(this.waypoint);
        });
    }

    populateSharedContent() {
        $('.sidebar #WaypointView_pinImage').attr("src", this.waypoint.pinImageUrl)
        $('.sidebar #WaypointView_waypointName').html(this.waypoint.name);
        $('.sidebar #WaypointView_waypointLocation').html("Location: " + getCoordinateString(this.waypoint.location.lat,this.waypoint.location.lng));
        $('.sidebar #WaypointView_waypointDescription').html(this.waypoint.desc);
        $('.sidebar #WaypointView_waypointCreationDate').html("Created: " + this.waypoint.creationDate.format('MMM D, YYYY') + " at " + this.waypoint.creationDate.format('h:mm A'));
        
        this.populateCarousel();
        $('#rightSidebarSpinner').hide();

    }

    //lets make each view have this function, and call it when the sidebar opens
    populatePage() {
        this.populateSharedContent();        
        
        if(this.waypoint.weatherImageUrl == null || this.waypoint.weatherImageUrl == "") {
            $('.sidebar #WaypointView_conditionsOnHeading').hide();
            $('.sidebar #WaypointView_originalConditionsImage').hide();
        } else {
            $('.sidebar #WaypointView_conditionsOnHeading').show();
            $('.sidebar #WaypointView_originalConditionsImage').show();
            $('.sidebar #WaypointView_conditionsOnHeading').html("Conditions on " + this.waypoint.creationDate.format('M/D/YY'));
            $('.sidebar #WaypointView_originalConditionsImage').attr("src", this.waypoint.weatherImageUrl);
            //ignoring moon phase for now
        }
       
        var times = SunCalc.getTimes(new Date(), this.waypoint.location.lat, this.waypoint.location.lng)
        $('.sidebar #WaypointView_sunrise').html("Sunrise Time: " + DateHelpers.getTimeAsString(times.sunrise));
        $('.sidebar #WaypointView_sunset').html("Sunset Time: " + DateHelpers.getTimeAsString(times.sunset));

        this.populateOtherWaypoints();


        this.setupClickListeners();
    }
    
    async populateCarousel() {
        var totalNumberOfImages = this.waypointImages.length;
        
        if(this.waypointImages && this.waypointImages.length > 0) {
            for(var i = 0 ; i < this.waypointImages.length ; i++) {
                var self = this;
                let urlNameDict = await this.waypointManager.getImageUrl(this.waypoint, this.waypointImages[i]);
                self.addImageToCarousel(urlNameDict[0], totalNumberOfImages);
            }
        }
    }

    addImageToCarousel(source, totalNumberOfImages) {
        $('<div class="swiper-slide"><div class="img-wrapper"><div class="alignment-helper"></div><div class="img-container"><img class="swiper-img" src="'+source+'"></div></div></div>').appendTo('#WaypointSwiper');
        if(++this.numImagesLoaded == totalNumberOfImages) {
            this.createCarousel();
        }
    }

    createCarousel() {
        $('#WaypointView_swipeContainer').show();
        this.swiper = new Swiper ('.swiper-container-side', {
                // Optional parameters
                slidesPerView: this.getNumberOfSlidesPerView(),
                direction: 'horizontal',
                spaceBetween: 0,
                navigation: { //nav arrows
                    nextEl: '.swiper-button-next-side',
                    prevEl: '.swiper-button-prev-side',
                },
            });
    }
   
    getNumberOfSlidesPerView() {
        return Math.max(1, Math.floor(($('#WaypointView_swipeContainer').width()) / 290)); 
    }
    
    
    populateOtherWaypoints() {
        for(var uuid in this.waypointsDict) {
            var otherWaypoint = this.waypointsDict[uuid];
            if(otherWaypoint.uuid == this.waypoint.uuid) {
                continue;
            }
            $(otherWaypoint.getHTMLForOtherWaypointRow(this.waypoint.location)).appendTo('.sidebar #WaypointView_otherWaypointsContainer');
        }
    }
}

export default WaypointView;
