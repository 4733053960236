import Observable from './Observable';
import DateHelpers from './DateHelpers';
import Chat from './Chat';
import Message from './Message';
import UnknownContact from './UnknownContact';

import { MESSAGE_TYPE_WAYPOINT, MESSAGE_TYPE_NOTIFICATION, MESSAGE_TYPE_CURRENT_LOCATION, MESSAGE_TYPE_TEXT } from './FirebaseAttributes';

const EVENT = {
    UPDATE_CHAT_MESSAGE: 'update-chat-message',
    UPDATE_CONTACT_IMAGE: 'update-contact-image',
    UPDATE_CONTACT: 'update-contact'
}


export default class ChatManager extends Observable {

  constructor(dataService, appView, contactManager, waypointManager) {
    super();
    
    this.service = dataService;
    this.appView = appView;
    this.contactManager = contactManager;
    this.waypointManager = waypointManager;

    this.chats = [];

    this.onUpdateChatMessage = this.createHandlerMethod(EVENT.UPDATE_CHAT_MESSAGE);
    this.onUpdateContactImage = this.createHandlerMethod(EVENT.UPDATE_CONTACT_IMAGE);
    this.onUpdateContact = this.createHandlerMethod(EVENT.UPDATE_CONTACT);

  }

//   acceptBroadcastedLocationFromContactUID(contactUID) {
//     this.contactManager.zoomToNextAddedBroadcastedLocation = true;

//     this.service.db.acceptBroadcastedLocationFromContactUID(contactUID);
//   }

  get user() { // Current user
    return this.service.user;
  }

  get chatView() {
      return this.appView.chatView;
  }

  initialize() {

    this.service.db.onUpdateChat((chatKey, uid, chatNode) => {
      this.updateChat(chatKey, uid, chatNode);
    });

    this.service.db.onDeleteChat((chatKey) => {
      this.removeChat(chatKey);
    });

    this.service.db.onUpdateChatMessage((chatKey, messageJson, lastViewed) => {

        var chat = this.chatForChatKey(chatKey);
        if(chat != null) {
          this.messageAdded(chat, messageJson);
          var lastViewedDate = DateHelpers.dateFromString(lastViewed);
          var messageDate = DateHelpers.dateFromString(messageJson.time);
          if(messageDate > lastViewedDate) {
            chat.addUnreadMessage();
          }
        }
    });

    this.contactManager.onUpdateContactImage((contact) => {
      this.chatForChatKey(contact.chatKey).refreshPicture();
    });

    this.contactManager.onUpdateContact((contact) => {
      if(contact != null) {
        var chat = this.chatForChatKey(contact.chatKey);
        if(chat != null) {
          chat.updateChatWithContact(contact);
          this.emit(EVENT.UPDATE_CONTACT, contact);
        }
      }
    });
    

    // TODO: Uncomment and fix this
    // this.service.db.onReadChatMessage((chatkey, messageJson, lastMessage) => {

    //     var message = new Message(messageJson);
    //     var chat = this.chats[chatkey];
        
    //     if(message.time > chat.lastCleared) { // This logic should be inside the Chat
    //         chat.messages.push(message);
    //     }

    //     if(lastMessage) {
    //         this.chatView.getMessagesForChat(chat, true);
    //     }
    // });

    this.service.db.onUpdateProfilePicture((uid, group) => {
        if(group) {
            this.chatForChatKey(uid).refreshPicture();
        }
      });

  }


  sendMessage(message, chatkey) {
    this.service.db.sendMessage(message, chatkey, MESSAGE_TYPE_TEXT, null, null);
  }

  acceptClipboardWaypoint(waypointUUID, contactUID) {
    this.service.db.acceptClipboardWaypoint(waypointUUID, contactUID);
  }

  copyFromClipboard(waypointUUID, contactUID) {
    this.service.db.copyFromClipboard(waypointUUID, contactUID);
  }

  setLastViewed(chat) {
    this.service.db.setLastViewed(chat);
  }

  leaveGroupChat(chat) {
    this.service.db.leaveGroupChat(chat);
  }

  deleteContactForChat(chat) {
    this.service.db.deleteContactForChat(chat);
  }

  clearChat(chat) {
    this.service.db.clearChat(chat);
  }
  
  waypointExists(waypointUUID) {
      return this.waypointManager.waypointExists(waypointUUID)
  }

  preloadMessages(chatkey) {
    const PRELOAD_MESSAGE_COUNT = 10;
    this.service.db.addMessagesToChat(PRELOAD_MESSAGE_COUNT, chatkey);
}

  getContactForUID(uid) {
    return this.contactManager.getContactForUID(uid);
  }

  acceptContact(uid) {
    this.service.db.confirmContactWithUID(uid);

    var contacts = new Array();
    contacts.push(this.service.userContact);
    contacts.push(new UnknownContact(uid));
    return this.service.db.createChatKeyForContacts(contacts); //this way it will get selected when the convo is created
  }

  denyContact(uid) {
    this.service.db.denyContactWithUID(uid);
  }

  getUserAsContact() { 
    return this.service.userContact;
  }

  getChatsArray() {
    return this.chats;
  }

  getActiveChat() {
      return this.chatForChatKey(this.chatView.activeChat);
  }
  
  createGroupChatForContacts(contacts) {
      this.service.db.createGroupChatForContacts(contacts);
  }

  addContactsToChat(contactsArray, chat) {
      this.service.db.addContactsToChat(contactsArray, chat);
  }

  removeOutgoingContactRequest(uid) {
    this.service.db.removeOutgoingContactRequest(uid);
  }

  chatForChatKey(chatKey) {
      var arrayLength = this.chats.length;
      for(var i = 0; i < arrayLength; i++) {
          var chat = this.chats[i];
          if(chat.chatkey === chatKey) {
              return chat;
          }
      }
      return null;
  }

  removeChat(chatKey) {
    this.removeChatWithKeyFromArray(chatKey, this.chats);
    this.chatView.activeChat = "";
    this.chatView.refreshChats();
    this.chatView.deselectAllChats();
  }


  removeChatWithKeyFromArray(chatKey, array) {
    var arrayLength = array.length;
    for(var i = 0; i < arrayLength; i++) {
        var chat = array[i];
        if(chat.chatkey === chatKey) {
            array.splice(i, 1); 
            return;
        }
    }
  }

  updateGroupChatName(chat, name) {
    this.chatView.updateGroupChatName(chat, name);
    this.service.db.setGroupChatName(name, chat.chatkey);
  }

  updateProfileImage(chatkey, pendingImage) {
    this.service.storage.pushProfileImageToStorage(pendingImage, chatkey, true);
  }

  deleteProfileImage(chatkey) {
    this.service.storage.deleteProfileImageInStorage(chatkey, true);
  }

  // Deprecated: see updateChat
  // updateChatFromSnapshot(snapshot, userId, chatKey) {
  //   var chatNode = snapshot.val();
  //   var isGroupChat = chatNode.isgroupchat;
    
  //   if(isGroupChat) {
  //       let chat = new Chat(this);
  //       chat.populateForGroupChat(userId, chatKey, chatNode);
  //       this.addChat(chat);
  //   }
  //   else {
  //       let chat = new Chat(this);
  //       chat.populateForSoloChat(userId, chatKey, chatNode);
  //       this.addChat(chat);
  //   }

  // }

  updateChat(chatKey, uid, chatNode) {

    if(this.chatForChatKey(chatKey, uid)) {
        return;
    }

    var isGroupChat = chatNode.isgroupchat;
    
    if(isGroupChat) {
        let chat = new Chat(this);
        chat.populateForGroupChat(uid, chatKey, chatNode);
        this.addChat(chat);
    }
    else {
        let chat = new Chat(this);
        chat.populateForSoloChat(uid, chatKey, chatNode);
        this.addChat(chat);
    }

  }

  addChat(newChat) {
      var chatsLength = this.chats.length;
      for(var i = 0; i < chatsLength; i++) {
          var existingChat = this.chats[i];
          if(existingChat.chatkey === newChat.chatkey) {
              existingChat.updateWithChat(newChat);
              this.sortChats();
              return;
          }
      }

      this.chats.push(newChat);
      this.sortChats();
  }

  sortChats() {
      this.chats.sort(function(a, b){
          var aDate = a.lastMessageDate;
          var bDate = b.lastMessageDate;
          if(aDate < bDate) {return 1;}
          if(aDate > bDate) {return -1;}
          return 0;
      });

      this.chatView.reorderChatDivs();
  }

  messageAdded(chat, messageJson) {

    var message = new Message(messageJson);

    if(message.time > chat.lastCleared) {
        if(message.type == MESSAGE_TYPE_NOTIFICATION) {
            chat.lastMessage = message.text.replace("\n", "");
        } else if(message.type == MESSAGE_TYPE_WAYPOINT) {
            if(chat.bIsGroupChat) {
                chat.lastMessage = this.contactManager.contactForUID(message.contactUID).username + ": shared a waypoint.";
            } else {
                chat.lastMessage = "Shared a waypoint.";
            }
        } else if(message.type == MESSAGE_TYPE_CURRENT_LOCATION) {
            if(chat.bIsGroupChat) {
                chat.lastMessage = this.contactManager.contactForUID(message.contactUID).username + ": shared their location.";
            } else {
                chat.lastMessage = "Shared location.";
            }
        } else {
            if(chat.bIsGroupChat) {
                chat.lastMessage = message.contactUID !== this.service.user.uid ? this.contactManager.contactForUID(message.contactUID).username + ": " + message.text : message.text;
            } else {
                chat.lastMessage = message.text;
            }
        }
        chat.lastMessageDate = message.time;
        chat.messages.push(message);

        if(this.chatView.activeChat === chat.chatkey) {
            this.chatView.selectChat(chat.chatkey); //this will rebuild the entire GUI, which isn't ideal - but testing for now
        }

        this.chatView.replaceInnerHTMLForChatRow(chat); //updates the last message text and date
        this.sortChats();
    }
}

}