const MIME_TYPE_JSON = 'application/json';
const MIME_TYPE_BINARY = 'application/octet-stream';

export default class DataAccessLayer {
   
    constructor(dataService) {
        this.service = dataService;
    }

    checkout(sku, title, subscription) {

        let msg, name;

        // NOTE: Logic and text copied from create-checkout-session.php
        if(subscription) {
            name = `${title} annual subscription`;
            msg = `The ${name} layers have been added to your iHunter account. It may take a minute or two for them to be activated in the iHunter Web app and your iOS and Android devices that are logged into the same iHunter account. As this is an annual subscription, your account will be charged each year, until you unsubscribe from within the Web app.`;
        }else {
            name = sku.replace('_', ' ').toUpperCase();
            msg = `${name} should now be activated in the iHunter Web app and your iOS and Android devices logged into the same iHunter account.`;
        }

        let args = {
            items: [
                {
                    sku,
                    count: 1
                }
            ],
            origin: 'webapp',
            promo: "",
            success: window.location.origin + window.location.pathname + 'success.php?PRODUCTNAME=' + name + '&MSG=' + msg,
            cancel: window.location.origin + window.location.pathname + 'cancelled.html'
        }
        return this.post(this.apiServer + '/billing-api/checkout', args);
    }

    portal(purchase) {
        let options = {};
        if(purchase) {
            options.purchase = purchase;
        }
        return this.post(this.apiServer + '/billing-api/portal', options);
    }


    get apiServer() {
        return this.service.config.functionURL;
    }

    // get tileServer() {
    //     return Environment.config.tileURL;
    // }

    // get webServer() {
    //     return Environment.config.webAppURL;
    // }

    async getAccessToken() {
        return await this.service.requestFirebaseToken();
    }

    async get(query, contentType, responseType) {
        let token = await this.getAccessToken();
        let options = {
            method: 'GET',
            headers: {
                'Authorization': 'Firebase ' + token,
                'Content-Type': contentType || MIME_TYPE_JSON,
                'Accept': responseType || MIME_TYPE_JSON
            }
        };
        return this.request(query, options);
    }

    async getJson(query, authorized) {
        let options = {
            method: 'GET',
            headers: {
                'Accept': MIME_TYPE_JSON
            }
        };
        if(authorized) {
            let token = await this.getAccessToken();
            options.headers['Authorization'] = 'Firebase ' + token;
        }
        return this.request(query, options);
    }

    async put(query, body, contentType, responseType) {
        let token = await this.getAccessToken();
        let options = {
            method: 'PUT',
            headers: {
                'Authorization': 'Firebase ' + token,
                'Content-Type': contentType || MIME_TYPE_JSON,
                'Accept': responseType || MIME_TYPE_JSON
            },
            body: JSON.stringify(body)
        };
        return this.request(query, options);
    }


    async patch(query, body, contentType, responseType) {
        let token = await this.getAccessToken();
        let options = {
            method: 'PATCH',
            headers: {
                'Authorization': 'Firebase ' + token,
                'Content-Type': contentType || MIME_TYPE_JSON,
                'Accept': responseType || MIME_TYPE_JSON
            },
            body: JSON.stringify(body)
        };
        return this.request(query, options);
    }

    async post(query, body, contentType, responseType) {
        let token = await this.getAccessToken();
        let options = {
            method: 'POST',
            headers: {
                'Authorization': 'Firebase ' + token,
                'Content-Type': contentType || MIME_TYPE_JSON,
                'Accept': responseType || MIME_TYPE_JSON
            },
            body: JSON.stringify(body)
        };
        return this.request(query, options);
    }

    async delete(query, contentType, responseType) {
        let token = await this.getAccessToken();
        let options = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Firebase ' + token,
                'Content-Type': contentType || MIME_TYPE_JSON,
                'Accept': responseType || MIME_TYPE_JSON
            }
        };
        return this.request(query, options);
    }

    async request(query, options) {

        if(!options) {
            options = {};
        }
        if(!options.headers) {
            options.headers = {};
        }

        return new Promise((resolve, reject) => {
            try {
                if(!fetch) {
                    return reject(new Error("No fetch"));
                }

                return fetch(query, options).then(async (response) => {
                    if(!response) {
                        return reject(new Error("Unexpected response"));
                        
                    }else if(!response.ok) {

                        let error = new Error(response.statusText); 
            
                        let type = response.headers.get('Content-Type');
                        if(type && type.startsWith(MIME_TYPE_JSON)) {
                            let json = await response.json();
                            error.stack = json.stack;
                            error.message = json.error; 
                        }else {
                            error.message = await response.text();
                        }
                        return reject(error);
                    }

                    if(response.body && response.status === 200) {
                        let type = options.headers['Accept'] || response.headers.get('Content-Type');
                        if(type && type.startsWith(MIME_TYPE_JSON)) {
                            return resolve(await response.json());
                        }else if(type && type.startsWith(MIME_TYPE_BINARY)) {
                            return resolve(await response.blob());
                        }else {
                            return resolve(await response.text());
                        }
                    }else {
                        return resolve();
                    }
                    
                }).catch((error) =>{
                    throw error;
                });
                
            }catch(error) {
                return reject(error);
            }
        });
    }
}
