import L from 'leaflet';

import { gSettings } from './Settings';
import { gWaypointImageCache } from './WaypointImageCache';

import { getHTMLForTitleAndSubtitleRowWithLeftAndRightImages } from './ViewHelper';

import DateHelpers from './DateHelpers';
import { getCoordinateString } from './CoordinateConverter';
import MapHelpers from './MapHelpers';
import { FB_TYPE_WAYPOINT } from './FirebaseAttributes';

export var WaypointPin = L.Icon.extend({
  options: {
      iconSize: [30, 31],
      iconAnchor: [15, 31],
      popupAnchor: [0, -33]//??
  }
});

export class Waypoint {
    constructor(dataService, mapProvider) {
        this.map = mapProvider;
        this.service = dataService;
        // this.uuid = json.UUID;
        // this.updateWaypoint(json);

        // this.creationUsername = Object.prototype.hasOwnProperty.call(json, "creationUsername") ? json.creationUsername : "";
                    
        // this.location = this.getLocation(json);
        // this.altitude = json.altitude;
        // this.accuracy = json.accuracy;
        // this.creationDate = DateHelpers.dateFromString(json.date);

        // this.moonphase = Object.prototype.hasOwnProperty.call(json, "moonphase") ? json.moonphase : -1;
    }

    initialize(json) {
        this.uuid = json.UUID;
        this.updateWaypoint(json);

        this.creationUsername = Object.prototype.hasOwnProperty.call(json, "creationUsername") ? json.creationUsername : "";
                    
        this.location = this.getLocation(json);
        this.altitude = json.altitude;
        this.accuracy = json.accuracy;
        this.creationDate = DateHelpers.dateFromString(json.date);

        this.moonphase = Object.prototype.hasOwnProperty.call(json, "moonphase") ? json.moonphase : -1;
    }


    async updateWaypoint(json) {
        this.hide();
        this.name = json.name;
        this.desc = json.desc;

        this.imagesString = json.image ? json.image : ""; //TODO - this is a list of images and needs to be parsed
        this.imageNamesArray = new Array();
        if(this.imagesString.length > 0) {
            this.imageNamesArray = this.imagesString.split(",")
        } 

        this.weatherImage = json.weather_image;
        if(this.weatherImage) {
            this.service.storage.getWeatherImageUrlForWaypoint(this.uuid, this.weatherImage).then((url) => {
                this.weatherImageUrl = url;
            }, (error) => {
                console.warn(`Unable to find URL for waypoint image: ${this.weatherImage}`);
            });
        }

        this.pinImage = json.pinResource == null ? "camp" : json.pinResource;
        this.pinImage = this.pinImage.replace(".png", "").replace("@2x", "");
        
        this.pinBackground = json.pinBackgroundResource == null ? getBackgroundResourceForPinImage(this.pinImage) : json.pinBackgroundResource;
        this.pinBackground = this.pinBackground.replace(".png", "").replace("@2x", "");

        this.updatedDate = json.dateUpdated;
        this.needsImageUpload = json.needsImageUpload;
        this.location = this.getLocation(json);
        this.marker = null;

        if(this.pinImageUrl == null) {
            this.pinImageUrl = "images/default_pin.png"; //fix me! This needs to update if we change the pinImage and/or pinBackground!
        }
        this.setPinImageUrl();
    }

    //let's make it return a promise
    setPinImageUrl() {
        var self = this;
        return new Promise(function(resolve, reject) {
            gWaypointImageCache.getWaypointImage(self.pinImage, self.pinBackground).then(function(pinUrl){
                self.pinImageUrl = pinUrl;
                self.pin = new WaypointPin({iconUrl:pinUrl});
                if(self.marker != null) {
                    self.marker.setIcon(self.pin); 
                }
                resolve();
            });
        });
    }

    //returns latLngBounds (leaflet)
    getBounds() {
        var topLeft = L.latLng(this.location.lat + 0.25, this.location.lng - 0.25);
        var botRight = L.latLng(this.location.lat - 0.25, this.location.lng + 0.25);
        return L.latLngBounds(topLeft, botRight);
    }

    getLocation(json) {
        var lat = json.latitude;
        var lon = json.longitude;
        return new L.LatLng(lat.replace(",", "."), lon.replace(",", "."))
    }

    getHTMLForOtherWaypointRow(otherWaypointLocation) {
        return getHTMLForTitleAndSubtitleRowWithLeftAndRightImages(this.uuid, 'openWaypointViewAndZoomToLocation(this.id)', this.pinImageUrl, this.name, this.getDistanceBetweenLocations(this.location, otherWaypointLocation));
    }

    getHTMLForWaypointRow() {
        if(this.map.currentLocation) {
            return getHTMLForTitleAndSubtitleRowWithLeftAndRightImages(this.uuid, 'openWaypointViewAndZoomToLocation(this.id)', this.pinImageUrl, this.name, this.getDistanceBetweenLocations(this.map.currentLocation, this.location));
        } else {
            return getHTMLForTitleAndSubtitleRowWithLeftAndRightImages(this.uuid, 'openWaypointViewAndZoomToLocation(this.id)', this.pinImageUrl, this.name, "(" + getCoordinateString(this.location.lat,this.location.lng) + ")");
        }
    }

    getHTMLForWaypointRowWithSwitch(bChecked, inputID) {
        var checked = bChecked ? 'checked' : '';
        var row = '';
        row += '<div class="ihunter-menu-switch-row">';// + this.name;

        row += '<div class="left-div"> <img class="pin-image" src="' + this.pinImageUrl + '" /> </div>';
            row += '<div class="middle-div">';
            row += '<div class="ihunter-menu-text large">'+ this.name + '</div>';
            row += '<div class="ihunter-menu-text small">' + getCoordinateString(this.location.lat,this.location.lng) + '</div>';
        row += '</div>';

        row += '<label class="ihunter-menu-switch float-right">'
            row += '<input id="'+ inputID + '" type="checkbox" style="' + checked + '">'
            row += '<span class="slider-switch round"></span>'
        row += '</label>';
        row += '</div>';
        return row;
    }

    getDistanceBetweenLocations(location1, location2) {
        return MapHelpers.getLabelTextForDistanceBetweenCoords(location1, location2) + " away";
    }

    setVisibility(forceRefresh) {
        if(gSettings.waypointShouldBeFilteredOut(this)) {
            this.hide();
        } else {
            this.show(forceRefresh);
        } 
    }

    show(forceRefresh) {
        var self = this;
        this.setPinImageUrl().then(() => {
            if(!self.marker) {
                self.marker = L.marker(self.location, {icon: self.pin});
            }
            
            if(!this.map.leafletMap.hasLayer(self.marker) || (forceRefresh !== undefined && forceRefresh === true) ) {            
                var popup = '';
                popup += '<div class="popup-top-bar"><div class="popup-top-bar-title">' + getCoordinateString(self.location.lat,self.location.lng) + '</div></div>';
                popup += '<table class="popup">';                  
                popup += '<tr onclick="openWaypointView(\''+ self.uuid + '\')">';
                popup += '<td class="otherRows">';
                popup += '<img class="left-img" src="'+self.pinImageUrl+'"/>';
                popup += '<div class="text-content"><b>' + self.name + '</b></div>';
                popup += '<img class="arrow-img" src="images/arrow_grey_right@2x.png"/></td></tr>';
                popup += '</table>';

                //add bottom buttons!
                popup += '<div class="popup-bottom-bar">';
                //delete waypoint button
                popup += '<button id="deleteWaypointButton" class="btn btn-dark text-center round-left" type="button" onclick="requestDeleteWaypoint(\''+ self.uuid + '\')"><i class="icon-ihunter" style="margin-top:2px; font-size:30px; background-image: url(&quot;images/trash_icon@2x.png&quot;); background-size:contain; height:36px;"></i></button>';
                //weather button
                popup += '<button class="btn btn-dark text-center round-right" type="button" onclick="openWeatherView('+ self.location.lat.toFixed(6) + ',' + self.location.lng.toFixed(6) +')"><i class="icon-ihunter" style="margin-top:2px; font-size: 30px; background-image: url(&quot;images/weather_icon@2x.png&quot;); background-size:contain; height:36px;"></i></button>';
                popup += '</div>';
                //end add bottom buttons.

                this.marker.addTo(this.map.leafletMap).bindPopup(popup);
            }
        });
    }

    hide() {
        if(this.marker && this.map.leafletMap.hasLayer(this.marker)) {
            this.map.leafletMap.removeLayer(this.marker);
        }
    }

    getFirebaseType() {
        return FB_TYPE_WAYPOINT;
    }

    
}

export function getBackgroundResourceForPinImage(pinImage) {
    if(pinImage === "camp" || pinImage === "ihunter_pin_boat_dock" || pinImage === "ihunter_pin_boat" || pinImage === "ihunter_pin_fire" ||
            pinImage === "ihunter_pin_stand" || pinImage === "ihunter_pin_treestand" || pinImage === "ihunter_pin_truck" || pinImage === "ihunter_pin_snare" || 
            pinImage === "ihunter_pin_trap") {

        return "ihunter_pin_background_blue";
    }
    else if(pinImage  === "hunting" || pinImage  === "ihunter_pin_archer" || pinImage  === "ihunter_pin_camera" || pinImage  === "ihunter_pin_sights" || 
            pinImage  === "ihunter_pin_binos" || pinImage  === "ihunter_pin_fire") {

        return "ihunter_pin_background_red";
    }
    else if(pinImage  === "ihunter_pin_blooddrop" || pinImage  === "ihunter_pin_checkmark" || pinImage  === "ihunter_pin_xmark" || pinImage  === "ihunter_pin_questionmark") {

        return "ihunter_pin_background_grey";
    }
    else if(pinImage === "ihunter_pin_deerscat" || pinImage  === "ihunter_pin_deertrack" || pinImage  === "ihunter_pin_dogtrack" ||
            pinImage === "ihunter_pin_elk_shed" || pinImage  === "ihunter_pin_moose_shed" || pinImage  === "ihunter_pin_whitetail_shed" ||
            pinImage === "ihunter_pin_mule_shed" || pinImage  === "ihunter_pin_rub" || pinImage  === "ihunter_pin_scrape") {

        return "ihunter_pin_background_yellow";
    }
    else if(pinImage  === "farmer" || pinImage  === "ihunter_pin_mountain" || pinImage  === "ihunter_pin_gate" || pinImage  === "ihunter_pin_private" || 
            pinImage  === "ihunter_pin_trees" || pinImage  === "ihunter_pin_waterhole" || pinImage  === "ihunter_pin_cattle" || 
            pinImage === "ihunter_pin_mushroom" || pinImage  === "ihunter_pin_bridge" || pinImage  === "ihunter_pin_culvert") {

        return "ihunter_pin_background_brown";
    }
    else if(pinImage.includes("ihunter_pin_drawing") || pinImage.includes("listitem_signpost")) {
        return "";
    }
    return "ihunter_pin_background_green";
}

export default Waypoint;
