import $ from 'jquery';

import Subscription from './Subscription';

export var gSubscriptionSettings = null; // Created in index.js:completeSetup()

export class SubscriptionSettings {
    constructor(settings) {
      this.settings = settings;
      this.subscription_G = null;
      this.SUBSCRIPTION_PRICE = null;
      this.SUBSCRIPTION_INTRO_PRICE = null;

      if(gSubscriptionSettings === null) {
        gSubscriptionSettings = this;
      }
    }

    setSubscription(json, path) {
        if(json && path) {
            this.subscription_G = new Subscription(json, path);
            $('#publicLandsButton').show(); 
        }
    }

    setSubscriptionPurchased(bPurchased) {
        this.SUBSCRIPTION_PURCHASED = bPurchased;
    }

    isSubscriptionPurchased() {
        var subscription = this.getSubscription();
        if(subscription != null) {
            return this.settings.isProductPurchasedWeb(subscription.sku_web) || 
            this.settings.isProductPurchasedIOS( subscription.sku_ios ) || 
            this.settings.isProductPurchasedAndroid( subscription.sku_android );
        } 
        
        return false;
    }

    getSubscription() {
        return this.subscription_G;
    }

    

    
}

export default SubscriptionSettings;
