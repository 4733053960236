import $ from 'jquery';

import View from './View';

export class AddRegionView extends View {
    constructor(regionManager) {
        super();
        this.regionManager = regionManager;


        $('#startupRegionCancelButton').click(() => {
            this.removeView();
        });
        $('#startupRegionSaveButton').click(() => {

            this.regionManager.addStartupRegion($('#regionNameInput').val());

            this.removeView();
        });
    }

    showToolbars() {
        $('#leftDiv').fadeOut(function() {
            $('#addStartupRegionDiv').fadeIn(function() {
                $('#regionNameInput').focus();
                $('#regionNameInput').select();
            });
        });
        $('#buttonCoveringHeaderDiv').fadeIn();
    }

    hideToolbars() {
        $('#showLabelsDiv').fadeOut();
        $('#addStartupRegionDiv').fadeOut(function() {
            $('#leftDiv').fadeIn();
        });
        $('#buttonCoveringHeaderDiv').fadeOut();
    }

    start() {
        this.showToolbars();
        this.regionManager.addTapRestrictions();
    }

    stop() {
        this.hideToolbars();
        this.regionManager.removeTapRestrictions();
    }

    removeView() {
        this.stop();
    }
}

export default AddRegionView;
