import L from 'leaflet';

import { gSettings, MapStartupType } from './Settings';
import { FB_STARTUP_TYPE, FB_DEFAULT_MAP_FAVORITE_ID } from './FirebaseAttributes';

export class MapRegion {
    constructor() {
        this.name = '';
        this.uuid = '';
        this.bounds = null;
        this.favoriteType = 0;
        //this.creationDate = moment();
    }

    initWithJson(properties) {
        // console.log(properties);
        var centerLat = properties["CenterLatitude"];
        var centerLon = properties["CenterLongitude"];
        var spanLat = properties["SpanLatitude"];
        var spanLon = properties["SpanLongitude"];
        // console.log(centerLat);
        var topLeft = L.latLng(parseFloat(centerLat) + parseFloat(spanLat/2), parseFloat(centerLon) - parseFloat(spanLon/2));
        var botRight = L.latLng(parseFloat(centerLat) - parseFloat(spanLat/2), parseFloat(centerLon) + parseFloat(spanLon/2));
        this.bounds = L.latLngBounds(topLeft, botRight);

        this.uuid = properties["UUID"];
        this.name = properties["name"];
        
        this.favoriteType = Object.prototype.hasOwnProperty.call(properties, "FavoriteType") ? properties["FavoriteType"] : 0;
        return this;
    }

    isValid() {
        return (this.name != null && this.uuid != null && this.bounds != null && this.bounds.isValid());
    }

    toJSON() {
        var json = {};
        json["CenterLatitude"] = this.bounds.getCenter().lat;
        json["CenterLongitude"] = this.bounds.getCenter().lng;
        json["SpanLatitude"] = Math.abs(this.bounds.getNorth() - this.bounds.getSouth());
        json["SpanLongitude"] = Math.abs(this.bounds.getWest() - this.bounds.getEast());
        json["UUID"] = this.uuid;
        json["name"] = this.name;
        json["FavoriteType"] = this.favoriteType;
        return json;
        //return JSON.stringify(json);
    }

    getRowID() {
        return this.uuid + "_rowID";
    }

    getRowHTML() {
        // var checked = gSettings.getPreference(FB_STARTUP_TYPE) == MapStartupType.STARTUP_MAP_REGION && gSettings.getPreference(FB_DEFAULT_MAP_FAVORITE_ID) == this.uuid ? 'checked' : '';
        var inputID = this.uuid;
        var row = '';
            
        row += '<div class="ihunter-menu-row flex less-top-bot-padding" id="'+this.getRowID()+'">';
        row += '<div class="middle-div">';
            row += '<div class="ihunter-menu-text">'+ this.name + '</div>';
            // row += '<div class="ihunter-menu-text small">' + this.displaySubtitle + '</div>';
        row += '</div>';
        

        row += '<button id="'+inputID+'_deleteButton" class="btn btn-danger btn-sm user-map-layer-btn margin-right" type="button" onclick="deleteStartupMapRegion(\''+this.uuid+'\')"><span class="fa fa-trash fa-lg" aria-hidden="true"></span></button>';
        
        row += '<img class="img-checkmark" src="images/checkmark.png"/>';
   
        row += '</div>';

        return row;
    }

    isSelectedStartupPreference() {
        return (gSettings.getPreference(FB_STARTUP_TYPE) == MapStartupType.STARTUP_MAP_REGION && gSettings.getPreference(FB_DEFAULT_MAP_FAVORITE_ID) == this.uuid);
    }
}

export default MapRegion;
