import L from 'leaflet';

import { getHTMLForTitleAndSubtitleRowWithLeftAndRightImages } from './ViewHelper';
import { getCoordinateString } from './CoordinateConverter';
import Waypoint from './Waypoint';
import DateHelpers from './DateHelpers';
import LatLngAnnotation from './LatLngAnnotation';
import TrackLocation from './TrackLocation';
import { FB_TYPE_TRACKEDWAYPOINT } from './FirebaseAttributes';

export class TrackedWaypoint extends Waypoint {
    constructor(dataService, mapProvider) {
        super(dataService, mapProvider);
    }


    initialize(json) {
        super.initialize(json);

        this.annotationXML = json.AnnotationXML;
        this.spanLat = json.SpanLatitude; 
        this.spanLon = json.SpanLongitude; 
        this.centerLat = json.CenterLatitude;
        this.centerLon = json.CenterLongitude; 
        this.bounds = this.getBounds(); //should overwrite by getAnnotations
    }


    //returns latLngBounds (leaflet)
    getBounds() {
        if(!this.bounds) {
            //Recaclulating bounds when getAnnotations is called. This may be accurate, but in DrawnWaypoints it wasn't so better safe than sorry.
            var topLeft = L.latLng(parseFloat(this.centerLat) + parseFloat(this.spanLat/2), parseFloat(this.centerLon) - parseFloat(this.spanLon/2));
            var botRight = L.latLng(parseFloat(this.centerLat) - parseFloat(this.spanLat/2), parseFloat(this.centerLon) + parseFloat(this.spanLon/2));
            return L.latLngBounds(topLeft, botRight);
        }
        return this.bounds;
    }

    getLocation(json) {
        var annotation = this.getAnnotation(json.AnnotationXML);
        if(annotation != null && annotation.locations.length > 0) {
            return new L.LatLng(annotation.locations[0].latitude, annotation.locations[0].longitude, annotation.locations[0].altitude);
        } else {
            return new L.LatLng(parseFloat(json.CenterLatitude), parseFloat(json.CenterLongitude));
        }
    }

    getAnnotation(xmlString) {
        if(this.annotation != null) {
            return this.annotation;
        }

        var parseXml;
        if (typeof window.DOMParser != "undefined") {
            parseXml = function(str) {
                return ( new window.DOMParser() ).parseFromString(str, "text/xml");
            };
        } else if (typeof window.ActiveXObject != "undefined" &&
            new window.ActiveXObject("Microsoft.XMLDOM")) {
            parseXml = function(str) {
                var xmlDoc = new window.ActiveXObject("Microsoft.XMLDOM");
                xmlDoc.async = "false";
                xmlDoc.loadXML(str);
                return xmlDoc;
            };
        } else {
            throw new Error("No XML parser found");
        }

        var xml = parseXml(xmlString);
        var annotationsXML = xml.getElementsByTagName("location");
        this.annotation = new LatLngAnnotation();

        for(var key in annotationsXML) {
            if(annotationsXML[key].nodeName == "location") {
                var nodes = annotationsXML[key];
                var attributes = nodes.attributes;
                
                var lat;
                var lon;
                var time;
                var course;
                var alt;
                var speed;
                var vertAccuracy; 
                var horizAccuracy;
                var pause = null;
                
                for (var attr in attributes) {
                    if(attributes[attr]) {
                        
                        if (attributes[attr].nodeName == "latitude") {
                            lat = attributes[attr].nodeValue;
                        } 
                        if (attributes[attr].nodeName == "longitude") {
                            lon = attributes[attr].nodeValue;
                        } 
                        if (attributes[attr].nodeName == "time") {
                            time = attributes[attr].nodeValue;
                        }
                        if (attributes[attr].nodeName == "course") {
                            course = attributes[attr].nodeValue;
                        } 
                        if (attributes[attr].nodeName == "altitude") {
                            alt = attributes[attr].nodeValue;
                        } 
                        if (attributes[attr].nodeName == "speed") {
                            speed = attributes[attr].nodeValue;
                        } 
                        if (attributes[attr].nodeName == "verticalaccuracy") {
                            vertAccuracy = attributes[attr].nodeValue;
                        } 
                        if (attributes[attr].nodeName == "horizontalaccuracy") {
                            horizAccuracy = attributes[attr].nodeValue;
                        }
                        if (attributes[attr].nodeName == "pause") {
                            pause = attributes[attr].nodeValue;
                        }  

                        //console.log(attributes[attr].nodeName);
                    }
                }

                var location = new TrackLocation();
                location.setLatitude(lat);
                location.setLongitude(lon);
                if(alt != null) {
                    location.setAltitude(parseFloat(alt));
                }
                if(horizAccuracy != null) {
                    location.setHorizontalAccuracy(parseFloat(horizAccuracy));
                }
                if(course != null) {
                    location.setCourse(parseFloat(course));
                }
                if(time != null) {
                    location.setTime(DateHelpers.timeIntervalFromString(time));
                }
                if(speed != null) {
                    location.setSpeed(parseFloat(speed));
                }
                if(pause != null) {
                    location.setPause(pause);
                    console.log("pause")
                }
                
                //console.log("test");
                //console.log(location);
                this.annotation.addLocation(location);
            }
        }

        this.annotation.color = this.getColorFromPinImage();
        this.annotation.thickness = 3;
        this.bounds = L.latLngBounds(this.annotation.getPoints()); 

        return this.annotation;
    }

    getColorFromPinImage() {
        if(this.pinImage.toUpperCase() === "LISTITEM_SIGNPOST_BLUE") {
            return "#0FB4F3";
        } else if(this.pinImage.toUpperCase() === "LISTITEM_SIGNPOST_GREEN") {
            return "#00A550";
        } else if(this.pinImage.toUpperCase() === "LISTITEM_SIGNPOST_BROWN") {
            return "#A2672C";
        }
        return "#F01F28";
    }

    getHTMLForOtherWaypointRow(otherWaypointLocation) {
        return getHTMLForTitleAndSubtitleRowWithLeftAndRightImages(this.uuid, 'openTrackedWaypointViewAndZoomToLocation(this.id)', this.pinImageUrl, this.name, this.getDistanceBetweenLocations(this.location, otherWaypointLocation));
    }

    getHTMLForWaypointRow() {
        if(this.map.currentLocation) {
            return getHTMLForTitleAndSubtitleRowWithLeftAndRightImages(this.uuid, 'openTrackedWaypointViewAndZoomToLocation(this.id)', this.pinImageUrl, this.name, this.getDistanceBetweenLocations(this.map.currentLocation, this.location));
        } else {
            return getHTMLForTitleAndSubtitleRowWithLeftAndRightImages(this.uuid, 'openTrackedWaypointViewAndZoomToLocation(this.id)', this.pinImageUrl, this.name, "(" + getCoordinateString(this.location.lat,this.location.lng) + ")");
        }
    }

    show() {
        var self = this;
        this.setPinImageUrl().then(() => {
            if(!self.marker) {
                self.marker = L.marker(self.location, {icon: self.pin});
            }

            if(!this.map.leafletMap.hasLayer(self.marker)) {
                var popup = '';
                popup += '<div class="popup-top-bar"><div class="popup-top-bar-title">' + getCoordinateString(self.location.lat,self.location.lng) + '</div></div>';
                popup += '<table class="popup">';                  
                popup += '<tr onclick="openTrackedWaypointView(\''+ self.uuid + '\')">';
                popup += '<td class="otherRows">';
                popup += '<img class="left-img" src="'+self.pinImageUrl+'"/>';
                popup += '<div class="text-content"><b>' + self.name + '</b></div>';
                popup += '<img class="arrow-img" src="images/arrow_grey_right@2x.png"/></td></tr>';
                popup += '</table>';

                //add bottom buttons!
                popup += '<div class="popup-bottom-bar">';
                //delete waypoint button
                popup += '<button id="deleteWaypointButton" class="btn btn-dark text-center round-left" type="button" onclick="requestDeleteTrackedWaypoint(\''+ self.uuid + '\')"><i class="icon-ihunter" style="margin-top:2px; font-size:30px; background-image: url(&quot;images/trash_icon@2x.png&quot;); background-size:contain; height:36px;"></i></button>';
                //weather button
                popup += '<button class="btn btn-dark text-center round-right" type="button" onclick="openWeatherView('+ self.location.lat.toFixed(6) + ',' + self.location.lng.toFixed(6) +')"><i class="icon-ihunter" style="margin-top:2px; font-size: 30px; background-image: url(&quot;images/weather_icon@2x.png&quot;); background-size:contain; height:36px;"></i></button>';
                popup += '</div>';
                //end add bottom buttons.

                self.marker.addTo(this.map.leafletMap).bindPopup(popup);
            }
            
            this.map.updatePolylinesForTrackedWaypoint(self);
        });
    }

    hide() {
        if(this.marker && this.map.leafletMap.hasLayer(this.marker)) {
            this.map.leafletMap.removeLayer(this.marker);
        }
        var polylines = this.map.polyLinesDict[this.uuid];
        if (polylines != null) {
            this.map.removePolylinesForTrackedWaypoint(this);
        }
    }


    getFirebaseType() {
        return FB_TYPE_TRACKEDWAYPOINT;
    }
}

export default TrackedWaypoint;
