import $ from 'jquery';
import _ from 'underscore';

import { gSettings, 
    SETTINGS_WAYPOINT_SORT_TYPE_NAME, SETTINGS_WAYPOINT_SORT_TYPE_DATE, SETTINGS_WAYPOINT_SORT_TYPE_LOCATION,
 } from './Settings';
import { FB_WAYPOINT_SORT_TYPE, FB_HIGHLIGHT_SELECTED_WMU } from './FirebaseAttributes';
import { getAjaxRequest } from './AjaxHelper';

import View from './View';
import BoundaryData from './BoundaryData';

const Unique_Identifier_Type = Object.freeze({"CODE":1, "NAME":2});
const HEADER_ID_SELECT_WAYPOINTS = 'selectWaypoints-searchHeader';
const CONTENT_ID_SELECT_WAYPOINTS = 'selectWaypoints-searchContent';

export { Unique_Identifier_Type };

export class ToolsMenuView extends View {
    constructor(mapProvider, waypointManager) {
        super();
        this.map = mapProvider;
        this.waypointManager = waypointManager;
        
        this.bWMUCreated = false;
        this.bLEHCreated = false;  
        this.activeWaypoints = null;
        this.inactiveWaypoints = null;
        this.populateWaypointArrays();

        this.waypointManager.onUpdateWaypoint(() => {
            this.updateWaypoints();
        });

        gSettings.manager.onUpdateWaypointFilter((key, val) => {
            this.updateWaypoints();
        });

        gSettings.manager.onUpdateWaypointSort((key, val) => {
            this.updateWaypoints();
        });

        gSettings.manager.onUpdateCoordFormat((key, val) => {
            this.updateWaypoints();
        });

    }

    get header() {
        return "Tools";
    }

    populatePage() {
        this.addSearchableMapLayers();      
        this.addSelectWaypoints();

    }

    addAdditionalTools() {
        var addWaypointHtml = '<div class="ihunter-menu-row subscription-bottom-row" id="addwaypointrow" onclick="onAddWaypointClicked()"><div class="ihunter-menu-text-black">Add Waypoint</div><img src="images/arrow_orange_right.png"/></div>';
        $('#toolsMenuCollapsableList').append(addWaypointHtml);

        var drawAndMeasureHtml = '<div class="ihunter-menu-row subscription-bottom-row" id="drawandmeasurerow" onclick="onDrawAndMeasureClicked()"><div class="ihunter-menu-text-black">Draw and Measure</div><img src="images/arrow_orange_right.png"/></div>';
        $('#toolsMenuCollapsableList').append(drawAndMeasureHtml);
    }

    updateWaypoints() {
        this.populateWaypointArrays();
        $('#'+CONTENT_ID_SELECT_WAYPOINTS).html('<div class="panel-body">' + this.getSearchableWaypointContent(HEADER_ID_SELECT_WAYPOINTS, CONTENT_ID_SELECT_WAYPOINTS) + '</div>');
        if(this.inactiveWaypoints.length > 0) {
            this.insertInactiveWaypointsContent();
        }
        this.addSortByOnClick(); //the sort by row gets recreated, so need to recreate the onclick listener
    }

    addSelectWaypoints() {
        this.createHeadingAndAppend(HEADER_ID_SELECT_WAYPOINTS, CONTENT_ID_SELECT_WAYPOINTS, 'Select Waypoint', '#toolsMenuCollapsableList');
        var content = this.getSearchableWaypointContent(HEADER_ID_SELECT_WAYPOINTS, CONTENT_ID_SELECT_WAYPOINTS);
        this.createContentAndInsertAfter(HEADER_ID_SELECT_WAYPOINTS, CONTENT_ID_SELECT_WAYPOINTS, content, '#'+HEADER_ID_SELECT_WAYPOINTS);

        if(this.inactiveWaypoints.length > 0) {
            this.insertInactiveWaypointsContent();
        }
        this.addSortByOnClick();
    }

    getSearchableWaypointContent(headerID, contentID) {
        var content = '<div class="collapsable-menu-content">';
        content += this.getFilterWaypointsHtml();
        content += this.getSortByHtml();

        content += '<div class="input-container">';
        content += '<i class="fa fa-search search-icon"></i>';
        content += '<input type="text" placeholder="Search..." id="'+headerID+'-searchInput" class="search-input" onkeyup="filterFunction(\''+headerID+'-searchInput\',\''+contentID+'\')">';
        content += '</div>';

        content += '<div id="activeWaypointsDiv">';
        content += this.getWaypointsContent(this.activeWaypoints);
        content += '</div>';

        content += '</div>';
        return content;
    }

    addSortByOnClick() {
        $("#toolsMenuSortSegmentedGroup").click(function (event) {
            console.log('toolsmenuSort');
            $("li", this)
            .removeClass("selected")
            .filter(event.target)
            .addClass("selected");
            
            var id = $("li", this).filter(event.target).attr('id');
            if(id == 'toolsMenuSortByName') {
                gSettings.updatePreference(FB_WAYPOINT_SORT_TYPE, SETTINGS_WAYPOINT_SORT_TYPE_NAME);
            } 
            else if(id == 'toolsMenuSortByDate') {
                gSettings.updatePreference(FB_WAYPOINT_SORT_TYPE, SETTINGS_WAYPOINT_SORT_TYPE_DATE);
            } 
            else {
                gSettings.updatePreference(FB_WAYPOINT_SORT_TYPE, SETTINGS_WAYPOINT_SORT_TYPE_LOCATION);
            }
        });
    }

    insertInactiveWaypointsContent() {
        var content = '<div class="collapsable-menu-content">';
        content += this.getWaypointsContent(this.inactiveWaypoints)
        content += '</div>';

        var filteredWaypointText = '  ('+this.inactiveWaypoints.length+' filtered waypoint' + (this.inactiveWaypoints.length > 9 ? 's)' : ')');

        this.createSubHeadingAndAppend('inactiveWaypoints-searchHeader', 'inactiveWaypoints-searchContent', '<span class="text" style="display:inline-block; margin-right:5px;">Inactive Waypoints</span><span class="text" style="display:inline-block; font-size:12px">'+filteredWaypointText+'</span>', '#activeWaypointsDiv');
        this.createContentAndInsertAfter('inactiveWaypoints-searchHeader', 'inactiveWaypoints-searchContent', content, '#inactiveWaypoints-searchHeader');
    }

    getFilterWaypointsHtml() {
        return '<div class="ihunter-menu-row filter-row" id="filterWaypointsRow" onclick="onFilterWaypointsClicked()"><div class="ihunter-menu-text">Filter Waypoints</div><img src="images/arrow_orange_right.png"/></div>';
    }

    getSortByHtml() {
        var html = '<div class="ihunter-menu-row-nohover filter-row" id="sortByRow" "><ul id="toolsMenuSortSegmentedGroup" class="buttonGroup full-width">';


        var sortType = gSettings.getPreference(FB_WAYPOINT_SORT_TYPE);
        if(sortType == SETTINGS_WAYPOINT_SORT_TYPE_NAME) {
            html += '<li id="toolsMenuSortByName" class="selected">Name</li><li id="toolsMenuSortByDate">Date</li><li id="toolsMenuSortByLocation">Location</li>';
        } 
        else if(sortType == SETTINGS_WAYPOINT_SORT_TYPE_DATE) {
            html += '<li id="toolsMenuSortByName">Name</li><li id="toolsMenuSortByDate" class="selected">Date</li><li id="toolsMenuSortByLocation">Location</li>';
        }
        else if(sortType == SETTINGS_WAYPOINT_SORT_TYPE_LOCATION) {
            html += '<li id="toolsMenuSortByName">Name</li><li id="toolsMenuSortByDate">Date</li><li id="toolsMenuSortByLocation" class="selected">Location</li>';
        }

        html += '</ul></div>';
        return html;
    }

    setInitialSortSelection(array) {
        var sortType = gSettings.getPreference(FB_WAYPOINT_SORT_TYPE);
        if(sortType == SETTINGS_WAYPOINT_SORT_TYPE_NAME) {
            this.waypointManager.sortWaypointsArrayByName(array);
            $("#toolsMenuSortSegmentedGroup").find("li").removeClass("selected");
            $("#toolsMenuSortByName").addClass("selected");
        } 
        else if(sortType == SETTINGS_WAYPOINT_SORT_TYPE_DATE) {
            this.waypointManager.sortWaypointsArrayByDate(array);
            $("#toolsMenuSortSegmentedGroup").find("li").removeClass("selected");
            $("#toolsMenuSortByDate").addClass("selected");
        } 
        else if(sortType == SETTINGS_WAYPOINT_SORT_TYPE_LOCATION) {
            this.waypointManager.sortWaypointsArrayByLocation(array);
            $("#toolsMenuSortSegmentedGroup").find("li").removeClass("selected");
            $("#toolsMenuSortByLocation").addClass("selected");
        }
    }

    populateWaypointArrays() {
        this.inactiveWaypoints = new Array();
        this.activeWaypoints = new Array();

        var allWaypoints = this.waypointManager.getAllWaypoints(); // moved to waypoits manager
        var length = allWaypoints.length;
        for(var i = 0; i < length; i++) {
            var wp = allWaypoints[i];
            if(gSettings.waypointShouldBeFilteredOut(wp)) {
                this.inactiveWaypoints.push(wp);
            } else {
                this.activeWaypoints.push(wp);
            }
        }
    }


    getWaypointsContent(array) {
        this.setInitialSortSelection(array);

        var content = '';
        var length = array.length;
        for(var i = 0; i < length; i++) {
            content+= array[i].getHTMLForWaypointRow();
        }
        return content;
    }
   

    addSearchableMapLayers() {
        var wmus = new Array();
        var lehs = new Array();
        var otherLayers = new Array();
        for(var i = 0; i < this.map.mapLayersArray.length; i++) {
            let mapLayer = this.map.mapLayersArray[i];
            if(mapLayer.layerRoot == 'wmu' && mapLayer.searchable) {
                wmus.push(mapLayer);
            } else if(mapLayer.layerRoot == 'leh' && mapLayer.searchable) {
                lehs.push(mapLayer);
            } else if(mapLayer.layerRoot != 'subscription' && mapLayer.searchable){
                otherLayers.push(mapLayer); //this isn't ideal, but I don't want the order of the menus changing
            }
        }
        if(wmus.length > 0) {
            this.addSearchableWMUorLEHLayers(wmus); 
        }
        if(lehs.length > 0) {
            this.addSearchableWMUorLEHLayers(lehs); 
        }
        for(var k = 0; k < otherLayers.length; k++) {
            let mapLayer = otherLayers[k];
            if(mapLayer.remoteSearchURL) {
                this.addRemoteSearchableMapLayer(mapLayer, '#toolsMenuCollapsableList');
            }
            else if(mapLayer.searchable) {
                this.addSearchableMapLayer(mapLayer, '#toolsMenuCollapsableList');
            } 
        }
    }

    //these are often split into multiple databases, so need to just show one
    addSearchableWMUorLEHLayers(mapLayersArray) {
        if(mapLayersArray != null) {
            var numMapLayers = mapLayersArray.length;
            if(numMapLayers > 0) {

                var headerID = mapLayersArray[0].layerRoot+'-searchHeader';
                var contentID = mapLayersArray[0].layerRoot+'-searchContent';
                var layerRoot = mapLayersArray[0].layerRoot.toUpperCase();
                var layerTitle = '';
                if(layerRoot === 'WMU') {
                    layerTitle = this.map.province.getProperty('STR_WMU');
                } else {
                    layerTitle = this.map.province.getProperty('STR_LEH');
                }

                var headerTitle = 'Select '+layerTitle;
                
                var appendToDiv = '#toolsMenuCollapsableList';
                var self = this;
                this.createHeadingAndAppend(headerID, contentID, headerTitle, appendToDiv);

                //for each map layer, getSearchableJsonData()...nest the
                var promises = [];
                for(var i = 0; i < numMapLayers; i++) {
                    var mapLayer = mapLayersArray[i];
                    var databasePath = mapLayer.layerRootPath + '/boundarydata.db'
                    promises.push(this.getSearchableJsonData(mapLayer.layerID, databasePath,Unique_Identifier_Type.CODE));
                }
                Promise.all(promises).then((returnVals) => {
                    if(returnVals != null) {
                        var length = returnVals.length;
                        var htmlContent = self.getMapLayerSearchBarHTML(headerID, contentID);

                        for(var i = 0; i < length; i++) {
                            var mapLayerID = returnVals[i].mapLayerID;
                            var mapLayer = this.map.getMapLayerForLayerID(mapLayerID);
                            var jsonData = returnVals[i].jsonData;
                            htmlContent += self.getMapLayerContentHTML(jsonData, mapLayer);
                        }
                        htmlContent += '</div>';
                        self.createContentAndInsertAfter(headerID, contentID, htmlContent, '#'+headerID);
                    }
                })

            }
        }
    }

    addRemoteSearchableMapLayer(mapLayer, appendToDiv) {
        var headerID = mapLayer.layerID+'-searchHeader';
        var contentID = mapLayer.layerID+'-searchedContent';
        var headerTitle = 'Select '+mapLayer.name;

        this.createHeadingAndAppend(headerID, contentID, headerTitle, appendToDiv);
        this.getRemoteSearchableMapLayerSearchBar(headerID, contentID, '#'+headerID, mapLayer);
    }

    getRemoteSearchableMapLayerSearchBar(headerID, contentID, insertAfter, mapLayer) {
        var searchResultsDiv = headerID+'-remoteSearchResults';
        var content = '<div class="collapsable-menu-content">';
        content += '<div class="input-container">';
        content += '<i class="fa fa-search search-icon"></i>';
        content += '<input type="text" placeholder="'+mapLayer.remoteSearchTerm+'" id="'+headerID+'-searchInput" class="search-input" autocomplete="off" onkeyup="delayedRemoteSearch(\''+mapLayer.remoteSearchURL+'\',\''+mapLayer.defaultColor+'\',this.value,\''+searchResultsDiv+'\','+false+')" >';
        content += '</div>';
        content += '<div id="'+searchResultsDiv+'">';
        content += '</div>';
        this.createContentAndInsertAfter(headerID, contentID, content, insertAfter);
    }

    addSearchableMapLayer(mapLayer, appendToDiv) {
        var databasePath = mapLayer.layerRootPath + '/boundarydata.db';
        var headerID = mapLayer.layerID+'-searchHeader';
        var contentID = mapLayer.layerID+'-searchContent';
        var headerTitle = 'Select '+mapLayer.name;

        this.createHeadingAndAppend(headerID, contentID, headerTitle, appendToDiv);

        var self = this;
        this.getSearchableJsonData(mapLayer.layerID, databasePath, Unique_Identifier_Type.NAME).then(function(returnVal) {
            var htmlContent = self.getMapLayerSearchBarHTML(headerID, contentID);
            htmlContent += self.getMapLayerContentHTML(returnVal.jsonData, mapLayer);
            htmlContent += '</div>';
            self.createContentAndInsertAfter(headerID, contentID, htmlContent, '#'+headerID);
        }, function(err) {
            console.log(err);
        });

    }

    getSearchableJsonData(mapLayerID, databasePath, identifyBy) {
        return new Promise((resolve, reject) => {
            var script = "getBoundariesFromDatabase.php";
            var ajaxRequest = getAjaxRequest(); 
            var self = this;
            if(ajaxRequest != null) {
                ajaxRequest.onreadystatechange = function () {
                    if (ajaxRequest.readyState == 4 && ajaxRequest.status == 200) {
                        var returnVal = {
                            mapLayerID:mapLayerID,
                            jsonData:JSON.parse(ajaxRequest.responseText)    
                        };
                        resolve(returnVal);
                    } else if(this.status != 200 && this.status != 0) {
                        reject(Error('Rejected in getSearchableJsonData'));
                    }
                }
                var queryString = "?&database="+databasePath + "&identifyby=" + identifyBy;
                ajaxRequest.open("GET", script + queryString, true);
                ajaxRequest.send(null);
            } else {
                reject(Error('Rejected in getSearchableJsonData'));
            }
        });
    }


    getMapLayerSearchBarHTML(headerID, contentID) {
        var content = '<div class="collapsable-menu-content">';
        content += '<div class="input-container">';
        content += '<i class="fa fa-search search-icon"></i>';
        content += '<input type="text" placeholder="Search..." id="'+headerID+'-searchInput" class="search-input" onkeyup="filterFunction(\''+headerID+'-searchInput\',\''+contentID+'\')">';
        content += '</div>';
        return content;
    }

    getMapLayerContentHTML(jsonData, mapLayer) {
        var content = '';
        if(jsonData != null) {
            var rowsHTML = "";
            Object.keys(jsonData).forEach(function(key) {
                var json = jsonData[key];
                if (!_.isEmpty(json)) {                           
                    rowsHTML += getBoundaryRowHTML(json, mapLayer);
                }
            });

            content += rowsHTML;
        }
        return content;
    }

    //these functions are all shared with SubscriptionMenuView - maybe move them to View?!
    createHeadingWithContentAndAppend(headerID, contentID, title, content, appendToDiv) {
        $(appendToDiv).append(this.createHeadingWithContent(headerID, contentID, title, content));
    }

    createHeadingWithContent(headerID, contentID, title, content) {
        return this.createHeading(headerID, contentID, title) + this.createContent(headerID, contentID, content); 
    }

    createHeading(headerID, contentID, title) {
        var html = '';
        html += '<div class="panel panel-default">';
        html +=   '<div class="panel-heading" role="tab" id="'+headerID+'">'
        html +=     '<h4 class="panel-title">'
        html +=       '<a role="button" data-toggle="collapse" data-parent="#accordion" href="#'+contentID+'" aria-expanded="true" aria-controls="'+contentID+'" class="collapsed">'+title+'</a>'; 
        html +=     '</h4>';
        html +=   '</div>';
        return html;
    }

    createSubHeading(headerID, contentID, title) {
        var html = '';
        html += '<div class="panel panel-default">';
        html +=   '<div class="panel-heading sub" role="tab" id="'+headerID+'">'
        html +=     '<h4 class="panel-title sub">'
        html +=       '<a role="button" data-toggle="collapse" data-parent="#accordion" href="#'+contentID+'" aria-expanded="true" aria-controls="'+contentID+'" class="collapsed">'+title+'</a>'; 
        html +=     '</h4>';
        html +=   '</div>';
        return html;
    }

    createContent(headerID, contentID, content) {
        var html = '';
        html +=   '<div id="'+contentID+'" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="'+headerID+'">';
        html +=     '<div class="panel-body">'+content+'</div>';
        html +=   '</div>';
        html += '</div>';
        return html;
    }

    
    //need to use these so that we can create the header before the PHP call
    createHeadingAndAppend(headerID, contentID, title, appendToDiv) {
        $(appendToDiv).append(this.createHeading(headerID, contentID, title));
    }

    createSubHeadingAndAppend(headerID, contentID, title, appendToDiv) {
        $(appendToDiv).append(this.createSubHeading(headerID, contentID, title));
    }

    //need to use these so that we can create the header before the PHP call
    createContentAndInsertAfter(headerID, contentID, content, insertAfterDiv) {
        $(this.createContent(headerID, contentID, content)).insertAfter(insertAfterDiv)
    }
}

export default ToolsMenuView;

function getBoundaryRowHTML(json, mapLayer) {
    var bd = new BoundaryData(json);
    var row = '';
    if(!gSettings.getPreference(FB_HIGHLIGHT_SELECTED_WMU) || (mapLayer != null && !mapLayer.highlightSelected)) {
        row += '<div class="ihunter-menu-boundary-row" onclick="zoomToBoundsString(\'' + bd.bounds + '\')">'; 
    } else {
        if(mapLayer.layerRoot == "leh") {
            row += '<div class="ihunter-menu-boundary-row" onclick="zoomToBoundsStringAndSelectLEH(\''+bd.bounds+'\',\''+mapLayer.layerID+'\',\''+bd.code+'\')">';
        } else {
            row += '<div class="ihunter-menu-boundary-row" onclick="zoomToBoundsStringAndSelectWithMapLayer(\'' + bd.bounds + '\',\''+mapLayer.layerID+'\',\''+ bd.code +'\')">';
        }
    }
    
    // row += '<div class="left-div"> <img class="ihunter-left-img" src="images/listitem_wmu@2x.png"/></div>';
    row += '<div class="left-div"> <img class="ihunter-left-img" src="images/wmu_icon_black.png"';
    if(mapLayer.defaultColor != null) {
        row += ' style="background-color: ' + mapLayer.defaultColor + '"';
    }
    
    row += '/></div>';
    row += '<div class="middle-div">';
    row += '<div class="ihunter-menu-text large" >' + bd.title + '</div>';
    row += '<div class="ihunter-menu-text small">' + bd.subtitle + '</div>';
    row += '</div>';
    row += '<div class="right-div"><i class="fa fa-chevron-right" style="color:#727272"></i></div>';
    row += '</div>';
    return row;
}


