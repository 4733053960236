import $ from 'jquery';
import _ from 'underscore';
import L from 'leaflet';
import 'leaflet.locatecontrol';
import '../libs/leaflet-tilelayer-colorizr';

import Observable from './Observable';
import Province from './Province'
import CountyMaps from './CountyMaps';
import Rect from './Rect';
import MapLayer from './MapLayer';
import MapRegion from './MapRegion';
import MapHelpers from './MapHelpers';

import { UserMapLayer, OverlayType } from './UserMapLayer';
import { gSettings, MapStartupType, TileServiceType } from './Settings';
import { gSubscriptionSettings } from './SubscriptionSettings';
import { LEHLayersPackage } from './LEHLayersPackage';
import { getAjaxRequest } from './AjaxHelper';
import { setCursorSpinner } from './UIHelper';
import { cleanupTitle, toTitleCase, pSBC } from './ViewHelper';
import { getCoordinateString } from './CoordinateConverter';
import { isProduction, IHUNTER_URL, SERVICE_URL } from './Environment';
import { FB_HIGHLIGHT_SELECTED_WMU, FB_COUNTY_MAP_OPACITY, FB_COUNTY_MAP_SHOW_BOUNDARY, FB_SELECTED_LEH_REGION, FB_CURRENT_BASEMAP_WEB } from './FirebaseAttributes';

const Unique_Identifier_Type = Object.freeze({"CODE":1, "NAME":2}); // from ToolsMenuView.js (TODO: find a better home)

var defaultIcon = new L.Icon({
    iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  
const EVENT = {
    INIT_LEAFLET:               'init-leaflet',
    UPDATE_MAP_LAYER:           'update-map-layer',
    UPDATE_SUBSCRIPTION_LAYER:  'update-subscription-layer',
    UPDATE_COUNTY_MAPS_LAYER:   'update-country-maps-layer',
    UPDATE_BOUNDARY:            'update-boundary',
    UPDATE_LEH_BOUNDARY:        'update-leh-boundary',
    UDPATE_OPTIONS:             'update-options',
}

// Need better name, but grouping map data related objects
export default class MapDataProvider extends Observable {

    constructor(dataService) {
        super();

        this.service = dataService;

        this.leafletMap = null;       // gMap.leafletMap
        this.baseMapsControl = null;  // gMap.baseMapsControl
        this.userMapLayersArray = [];
        this.mapLayersArray = [];
        this.lehLayersPackage = null;
        this.addLayersRetries = 0;

        this.highlightedBoundariesArray = [];
        this.searchResults = [];

        this.provinceCode = "";       // gProvinceCode
        this.province = {};           // gProvince
        this.provinces = [];
        this.countyMaps = null;       // gCountyMaps

        this.currentLocation = null;
        this.currentLocationAccuracy = null;

        this.googleSatMutant = null;
        this.googleRoadMutant = null;
        this.googleTerrainMutant = null;
        this.googleHybridMutant = null;
        this.appleRoadMutant = null;
        this.appleSatelliteMutant = null;
        this.appleHybridMutant = null;

        this.onInitLeaflet = this.createHandlerMethod(EVENT.INIT_LEAFLET);
        this.onUpdateMapLayer = this.createHandlerMethod(EVENT.UPDATE_MAP_LAYER);
        this.onUpdateSubscriptionLayer = this.createHandlerMethod(EVENT.UPDATE_SUBSCRIPTION_LAYER);
        this.onUpdateCountyMapsLayer = this.createHandlerMethod(EVENT.UPDATE_COUNTY_MAPS_LAYER);
        this.onUpdateBoundary = this.createHandlerMethod(EVENT.UPDATE_BOUNDARY);
        this.onUpdateLehBoundary = this.createHandlerMethod(EVENT.UPDATE_LEH_BOUNDARY);

        this.polyLinesDict = {}; // TODO: Seems out of place
        this.multiColoredPolylinesDict = {};
        this.measurementLabelsDict = {};

        this.legendBottomMargin = 0;// ughh TODO: find new home

        this.service.onSignOut(() => {
        this.clearData();
        });
    }

    async initialize(provinceCode){

        this.provinceCode = provinceCode;
        this.province = await this.createProvince();

        console.log(`${provinceCode.toUpperCase()} province map created`);

        console.log(`Loading map layers and resources`);

        this.countyMaps = new CountyMaps(this.province);

        this.leafletMap = this.createLeaflet();
        this.emit(EVENT.INIT_LEAFLET, this.leafletMap);

        await this.loadMapStartupPref(); // Blocks until prefs load 

        this.setupMutants();
        this.addControls();
        this.addBaseLayers();
        this.addBuiltInMapLayers();

        this.loadDefaultBasemap();

        this.setupLayerResources();
        this.addLocationControl();

        this.setupListeners();

        this.enableMapClicks();  
    }

    get leaflet() {
        return this.leafletMap; // ideally, we don't wan to expose leaflet
    }

    get user() {
        return this.service.user; // Feels dirty to expose. TODO: remove once we have map view + controller
    }

    closePopup() {
        this.leafletMap.closePopup();
    }

    zoomToLocation(latLng) { 
        this.leafletMap.setView([latLng.lat, latLng.lng], 14);
    }


    createLeaflet() {
        let zoom = this.province.getProperty("initial_zoom");
        let lat = this.province.getProperty("CENTER_LATITUDE");
        let long = this.province.getProperty("CENTER_LONGITUDE");
        return L.map('mapid', {closePopupOnClick: false, tap: false, zoomSnap: 0.5, zoomDelta: 0.5}).setView([lat, long], zoom == null ? 6 : zoom);
    }

    createProvince() {
        return new Promise((resolve, reject) => {
            try {
                var metadataRequest = getAjaxRequest();
                metadataRequest.onreadystatechange = () => {
                    if (metadataRequest.readyState == 4) {// DONE
                        if(metadataRequest.status == 200) {
                            var jsonObj = JSON.parse(metadataRequest.responseText);
                            if (Object.prototype.hasOwnProperty.call(jsonObj, 'provinces')) {
                                //set the global provinces Array (used to list provinces). For now we're not actually storing other province data
                                this.provinces = jsonObj.provinces;
                                this.provinces.forEach(provinceString => {
                                    var xmlhttp = getAjaxRequest();
                                    xmlhttp.onreadystatechange = () => {
                                        if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                                            if (this.provinceCode == provinceString) {
                                                resolve(new Province(provinceString, JSON.parse(xmlhttp.responseText)));
                                            }
                                        }
                                    };
                                    xmlhttp.open("GET", "res/provinces/" + provinceString + "/province.json", true);
                                    xmlhttp.send();
                                });
                            }
                        }else{
                            reject(new Error(`Unable to loading product data`));
                        }
                    }
                };
                metadataRequest.open("GET", "res/provinces/provinces.json", true);
                metadataRequest.send();

            }catch(error) {
                reject(new Error(`Unable to loading product data`));
            }
        });
    }

    clearData() {
        
        this.province = null;

        if(this.leafletMap != null) {
        this.leafletMap.eachLayer((layer) => {
            this.leafletMap.removeLayer(layer);
        });
        this.leafletMap.remove();
        this.leafletMap = null;
        }
    }

    async loadMapStartupPref() {

        let startupPref = gSettings.DefaultMapStartupSelection;
        
        // Get stored startup preference, if set
        try {
            startupPref = await this.service.db.getStartupPref();
            gSettings.DefaultMapStartupSelection = startupPref;
            

        }catch{
            // Safely ignore; no prefs set
        }

        try {
            if(startupPref === MapStartupType.STARTUP_ZOOM_TO_LOCATION) {
                this.leafletMap.locate({setView:'once', maxZoom: 10})
                .on('locationfound', (e) => { //should zoom
                    this.currentLocation = e.latlng;
                    this.currentLocationAccuracy = e.accuracy;
                })
                .on('locationerror', (e) => { //error zooming
                    console.log(e);
                });

            }else if(startupPref === MapStartupType.STARTUP_MAP_REGION) {

                //get the map region pref just so that when the map region loads it will zoom to it!
                let uuid = await this.service.db.getDefaultMapFavoriteID(this.provinceCode);
                let regionJson = await this.service.db.getMapRegionByUUID(uuid);
                let mapRegion = new MapRegion().initWithJson(regionJson); //gets the uuid for the first key in 
                if(mapRegion.isValid()) {
                    this.zoomToLatLngBounds(mapRegion.bounds);
                }
            }
            
        }catch(error) {
            // Safely ignore; no map region set
        }
    }

    zoomToLatLngBounds(bounds) {
        this.leafletMap.fitBounds(bounds);
    }

    zoomToLatLngBoundsRect(boundsRect) {
        this.leafletMap.fitBounds(this.latLngBoundsFromRect(boundsRect));
    }
    
    latLngBoundsFromRect(boundsRect) {
        var southWest = L.latLng(boundsRect.bottom, boundsRect.left);
        var northEast = L.latLng(boundsRect.top, boundsRect.right);
        return L.latLngBounds(southWest, northEast);  
    }

    zoomToBounds(boundsRect) { //Rect
        this.zoomToLatLngBounds(this.latLngBoundsFromRect(boundsRect));
    }

    zoomToBoundsString(boundsString) { //string
        var boundsRect = this.getBoundingRectFromString(boundsString);
        this.zoomToBounds(boundsRect);
    }
    
    zoomToBoundsStringAndSelectWithBoundary(boundsString, boundary, defaultColor) {
        this.removeHighlightedBoundaries();
        this.highlightBoundary(boundary, defaultColor);
        this.zoomToBoundsString(boundsString);
    }

    zoomToBoundsStringAndSelectWithMapLayer(boundsString, mapLayerID, code) {
        this.removeHighlightedBoundaries();
        var mapLayer = this.getMapLayerForLayerID(mapLayerID);
        if(mapLayer != null) {
            this.highlightBoundaryForMapLayer(mapLayer,code);
        }
        this.zoomToBoundsString(boundsString);
    }
    
    // TODO: Break dependency on FirebaseHelper and FB_SELECTED_LEH_REGION
    zoomToBoundsStringAndSelectLEH(boundsString, mapLayerID, code) {    
        this.removeHighlightedBoundaries();
        var mapLayer = this.getMapLayerForLayerID(mapLayerID);
        var layerIndex = parseInt(mapLayer.layerID.split('_').pop()) - 1;
        if(mapLayer != null) {
            if(layerIndex != null && layerIndex != -1) {
            gSettings.updatePreference(FB_SELECTED_LEH_REGION, layerIndex);
            } else {
            gSettings.updatePreference(FB_SELECTED_LEH_REGION, -1);
            }
            _.delay(() => {
                this.highlightBoundaryForMapLayer(mapLayer,code);
            },3000);
        }   
    
        this.zoomToBoundsString(boundsString);
    }

    purchaseHandler(sku, platform, purchased) {
        // update county data layer, then menu, then actual maps, if this is a map
        if(this.countyMaps.isCountyMap(sku)) {
            if(!purchased) {
                this.hideMapLayer(this.countyMaps.getPurchasedMap(sku));
            }
            this.countyMaps.updatePurchasedMaps();
            
            this.emit(EVENT.UPDATE_COUNTY_MAPS_LAYER, sku);

            this.updateCountyMapLayers();
        }

        // update subscription menu
        var subscription = gSubscriptionSettings.getSubscription();
        if(subscription !== null && subscription['sku_' + platform] == sku) {

            console.log(`Notified that the subscription is now active from an ${platform} purchase`);
            this.updateSubscriptionLayers();

            this.emit(EVENT.UPDATE_SUBSCRIPTION_LAYER);
        }   
    }

  setupListeners() {

        // Purchase
        this.service.db.onPurchase((sku, platform, purchased) => {
            this.purchaseHandler(sku, platform, purchased);
        });

        // UserMaps
        this.service.db.onCreateUserMap((snapshot) => {
            this.processUserMapLayer(snapshot);
        });
        this.service.db.onUpdateUserMap((snapshot) => {
            this.processExistingUserMapLayer(snapshot);
        });
        this.service.db.onDeleteUserMap((snapshot) => {
            this.userMapLayerRemoved(snapshot);
        });

        // Setting preferences
        gSettings.manager.onUpdateSelectedRegion((key, val) => {
            this.refreshLehLayer(val);
        });
        gSettings.manager.onUpdateColorPrefs((key, val) => {
            this.refreshColorPreference(key, val);
        });
        gSettings.manager.onUpdateLayerPrefs((key, val) => {
            this.refreshMapPreference(key, val);
        });
    
        gSettings.manager.onUpdateBoundaryPrefs((key, val) => {
            this.refreshBoundaryPreference(key, val);
        });

        window.zoomToBoundsStringAndSelectWithBoundary = (boundsString, boundary, defaultColor) => {
            this.zoomToBoundsStringAndSelectWithBoundary(boundsString, boundary, defaultColor);
        };

        window.zoomToBoundsStringAndSelectLEH = (boundsString, mapLayerID, code) => {
            this.zoomToBoundsStringAndSelectLEH(boundsString, mapLayerID, code);
        };

        window.zoomToBoundsStringAndSelectWithMapLayer = (boundsString, mapLayerID, code) => {
            this.zoomToBoundsStringAndSelectWithMapLayer(boundsString, mapLayerID, code);
        };

        window.zoomToBoundsString = (boundsString) => {
            this.zoomToBoundsString(boundsString);
        };

        window.onAddWaypointFromTempWaypoint = (markerKey, lat, lon) => {
            this.onAddWaypointFromTempWaypoint(markerKey, lat, lon);
        };
  }
    
    addBuiltInMapLayers() {
        if(this.provinceCode == "on") {
            var ontarioTrailsLayer = (new UserMapLayer(this)).initWithParams("https://tiles.ihunterapp.com/tiles/on/trails/{z}/{x}/{y}.png","Ontario Trails and Trailheads","",0,TileServiceType.XYZ,"Ontario_Trails_and_Trailheads_trails",16,1,true,OverlayType.OVERLAY,"Ontario Trails and Trailheads_trails");
            ontarioTrailsLayer.setEditable(false);
            ontarioTrailsLayer.setTileLayer();
            this.addUserMapLayer(ontarioTrailsLayer);
            if(ontarioTrailsLayer.bIsVisible) {
                this.showUserMapLayer(ontarioTrailsLayer);
            }

            var ontarioTopoLayer = (new UserMapLayer(this)).initWithParams("https://ws.lioservices.lrc.gov.on.ca/arcgis1061a/rest/services/LIO_Cartographic/LIO_Topographic/MapServer/tile/{z}/{y}/{x}","Topology", "Land Information Ontario",0,TileServiceType.XYZ,"lio_topo",19, 1, true, OverlayType.BASEMAP);
            ontarioTopoLayer.setEditable(false);
            ontarioTopoLayer.setTileLayer();
            this.addUserMapLayer(ontarioTopoLayer);
            // Add a base map layer (addProvinceSpecificBuiltInBaseMaps)
            this.addBaseMapToControl(ontarioTopoLayer);
        }

        var geogratisTopoLayer = (new UserMapLayer(this)).initWithParams("https://maps.geogratis.gc.ca/wms/canvec_en?service=WMS&request=GetMap&version=1.1.1&styles=&format=image/png&SRS=EPSG:4326&layers=hydro,elevation&transparent=true","GeoGratis Topo","" ,0,TileServiceType.WMS, null ,17,1,true,OverlayType.OVERLAY, "GeoGratis Topo");
        geogratisTopoLayer.setEditable(false);
        geogratisTopoLayer.setTileLayer();
        this.addUserMapLayer(geogratisTopoLayer);
        if(geogratisTopoLayer.bIsVisible) {
            this.showUserMapLayer(geogratisTopoLayer);
        }
    }

  addControls() {
    L.control.scale({ position: 'bottomleft' }).addTo(this.leafletMap);

    L.icon = function (options) {
        return new L.Icon(options);
    };
  }

  addLocationControl() {
        
    let options = {
        showPopup: false,
        flyTo: true,
        keepCurrentZoomLevel: true,
        locateOptions: {
            maxZoom: 10
        }

    };

    L.control.locate(options).addTo(this.leafletMap);
}

matchingBasemap(preference) {
    const baseMutants = [this.googleRoadMutant, this.googleSatMutant, this.googleTerrainMutant, this.googleHybridMutant, this.appleRoadMutant, this.appleSatelliteMutant, this.appleHybridMutant, this.googleRoadMutant]

    for(let i in baseMutants) {
        if(preference.provider === baseMutants[i].options.provider && preference.type === baseMutants[i].options.type) {
            return baseMutants[i];
        }
    }

    // Check userMapLayers for build-in basemaps
    for(let i in this.userMapLayersArray) {
        if(this.userMapLayersArray[i].isBaseMap()) {
            let uuid = this.userMapLayersArray[i].getUUID();
            if(preference.uuid === uuid) {
                return this.userMapLayersArray[i].leafletTileLayer;
            }
        }
    }

    // User entered basemaps will override the default, if no match is found
}

loadDefaultBasemap() {
    // Load prior basemap from peferences
    let preference = JSON.parse(gSettings.getPreference(FB_CURRENT_BASEMAP_WEB));
    if(preference) {
        if(preference.layerID) {
            console.log("user basemap")
        }else {
            let defaultBasemap = this.matchingBasemap(preference);
            if(defaultBasemap) {
                defaultBasemap.addTo(this.leaflet);
                return;
            }
        }
    }

    this.googleRoadMutant.addTo(this.leaflet); //setting this as the default for now
}

  //I think these are set with z-index 800, controls at 1000
  addBaseLayers() {
    this.leaflet.on('baselayerchange', (e) => {
        let options = e.layer.options;
        console.log(`Baselayer change: `, options);
        // console.debug(options);

        gSettings.updatePreference(FB_CURRENT_BASEMAP_WEB, JSON.stringify(options)); // Store as json string, not object

        var layerID = this.getLayerIDFromAttribution(e.layer.getAttribution());
        this.legendBottomMargin = layerID || options.provider === 'apple' ? 0 : 20; 

        for(var i = 0; i < this.userMapLayersArray.length; i++) {
            var userMapLayer = this.userMapLayersArray[i];
            if(userMapLayer.isBaseMap()) {
                if(userMapLayer.getUUID() == layerID) {
                    this.service.db.setVisibilityForUserMapLayer(userMapLayer, true);
                } else {
                    this.service.db.setVisibilityForUserMapLayer(userMapLayer, false);
                }
            }
        }

        this.adjustBottomLeftControls(); //attribution for google maps will include "&zwnj;" (an invisible character) 
    });

    var layersMap = {};
    layersMap['<span>Roads</span> <img src="images/googleicon.png" height="9" width="9"/>'] = this.googleRoadMutant;
    layersMap['<span>Satellite</span> <img src="images/googleicon.png" height="9" width="9"/>'] = this.googleSatMutant;
    layersMap['<span>Terrain</span> <img src="images/googleicon.png" height="9" width="9"/>'] = this.googleTerrainMutant;
    layersMap['<span>Hybrid</span> <img src="images/googleicon.png" height="9" width="9"/>'] = this.googleHybridMutant;
    layersMap['<span>Roads</span> <img src="images/appleicon.png" height="11" width="11"/>'] = this.appleRoadMutant;
    layersMap['<span>Satellite</span> <img src="images/appleicon.png" height="11" width="11"/>'] = this.appleSatelliteMutant;
    layersMap['<span>Hybrid</span> <img src="images/appleicon.png" height="11" width="11"/>'] = this.appleHybridMutant;

    this.baseMapsControl = L.control.layers(layersMap, {}, {
        collapsed: false,
        position: 'bottomleft'
    }).addTo(this.leafletMap);

    this.adjustBottomLeftControls();
  }
  
    //running this and processLayer with async / await so that the order is retained. It doesn't seem to affect
    //startup performance at all.
    async setupLayerResources() {
        for(const resource of this.province.getProperty("required_resources")) {
            await this.processLayer(resource, "res/provinces/" + this.provinceCode + "/", "root");
        }

        this.service.db.activateWebPurchases().then(() => {
            return this.service.db.activateIOSPurchases();  
        }).then(() => {
            return this.service.db.activateAndroidPurchases();
        }).then(() => {
            this.setupSubscriptionLayers();
            this.setupCountyMapLayers();
        }).catch(function(err) {
            console.log(err); 
        });
    }

    setupSubscriptionLayers() {
        var subscriptionVersion = this.province.getProperty("subscription_version");
        if (subscriptionVersion) {
            this.updateSubscriptionLayers();
        }
    }

    setupCountyMapLayers() {
        if(this.province.getProperty("HAS_COUNTY_MAPS_IAP")) {
            this.countyMaps.updatePurchasedMaps();

            var countiesURL;
            if(!isProduction()) {
                countiesURL = "https://tiles.ihunterapp.com/tiles/"+this.province.getProperty("SERVER_FOLDER").toLowerCase()+"/debug/counties.json";
            } else {
                countiesURL = "https://tiles.ihunterapp.com/tiles/"+this.province.getProperty("SERVER_FOLDER").toLowerCase()+"/counties.json";
            }
            
            //get the boundary data from the database to get the list of unavailable maps!
            var script = "getBoundariesFromDatabase.php";
            var ajaxRequest = getAjaxRequest(); 
            var mapLayer  = this.getMapLayerForLayerID("county");
            var databasePath = mapLayer.layerRootPath + '/boundarydata.db'

            if(ajaxRequest != null) {
                ajaxRequest.onreadystatechange = () => {
                    if (ajaxRequest.readyState == 4 && ajaxRequest.status == 200) {
                        var boundaryJsonData = JSON.parse(ajaxRequest.responseText);    
                        
                        this.getStringFromURL(countiesURL, (jsonContent) => {
                            if(jsonContent != null && jsonContent.length > 0) {
                                var countiesObj = JSON.parse(jsonContent);
                                if (Object.prototype.hasOwnProperty.call(countiesObj, 'counties')) {
                                    
                                    this.countyMaps.setupMaps(countiesObj["counties"], boundaryJsonData, Object.prototype.hasOwnProperty.call(countiesObj, 'inactive_counties') ? countiesObj["inactive_counties"] : null);
                                    this.updateCountyMapLayers();
                                    this.service.db.setupCountyMapsListeners((snapshot) => {
                                        this.processCountyMap(snapshot);
                                    });
                                }   
                            }
                        });

                    } 
                }
                var queryString = "?&database="+databasePath + "&identifyby=" + Unique_Identifier_Type.CODE;
                ajaxRequest.open("GET", SERVICE_URL + '/' + script + queryString, true);
                ajaxRequest.send(null);
            }
        }
   }

  async processLayer(layerName, path, root) {
    return new Promise((resolve) => {
        
        $.ajax({
            url: path + layerName + "/metadata.json",
            dataType: "json",
            async: true,
            success: async (jsonObj) => {
                if (Object.prototype.hasOwnProperty.call(jsonObj, 'layers')) {
                    var shouldProcessLayers = true;
                    if(layerName == "subscription") {
                        gSubscriptionSettings.setSubscription(jsonObj, path + layerName);
                        shouldProcessLayers = gSubscriptionSettings.isSubscriptionPurchased();
                    }
  
                    if(shouldProcessLayers) {
                        if(layerName == 'leh' && !this.lehLayersPackage) {
                            this.lehLayersPackage = new LEHLayersPackage(jsonObj.name);
                        }
                        var layers = jsonObj.layers;
                        for(const layer of layers) {
                            // console.log("Processing Layer : " + path + layerName + "/");
                            await this.processLayer(layer, path + layerName + "/", layerName);
                        }
                    }
                }
                else {
                    if(!this.hasMapLayerWithLayerID(layerName)) {
                        // console.log("--Processing Layer : " + path + layerName + "/");
                        var mapLayer = new MapLayer(jsonObj, layerName, path, root);
                        if(mapLayer.layerRoot != 'leh') {
                            this.service.db.getVisibilityPreferenceForMapLayer(mapLayer).then((visPref) => {
                                if(visPref) {
                                    this.showMapLayer(mapLayer);
                                }
                            }, (err) => {
                              this.showMapLayer(mapLayer); //will check the visibility default so we'll still call this regardless of vis pref being there
                            });
                        } 
                        else {
                            if(this.lehLayersPackage) {
                              this.lehLayersPackage.mapLayers.push(mapLayer);
                            }
                            // console.log(this.lehLayersPackage);
                            this.service.db.getLEHPreference().then((visPref) => {
                                if(visPref == mapLayer.getIndex()) {
                                  this.showMapLayer(mapLayer);
                                }
                            }, (err) => {
                                this.showMapLayer(mapLayer); //will check the visibility default so we'll still call this regardless of vis pref being there
                            });
                        }
  
                        this.mapLayersArray.push(mapLayer);

                        // TODO: fire event for new layer added
                        if(mapLayer.layerRoot === 'subscription'){// && gSubscriptionView) {
                            this.emit(EVENT.UPDATE_SUBSCRIPTION_LAYER);
                            //gSubscriptionView.updateUI();
                        }
                        resolve(mapLayer);
                    }
                }
                resolve(true);
            }
        });
    });     
  }

  processCountyMap(snapshot) {
    //snapshot will contain a node with the county map name and 2 children: opacity and showBoundary
    let mapName = snapshot.key;

    let countyMap = this.countyMaps.getPurchasedMap(mapName);
    if(countyMap != null) {
        let childJson = snapshot.val();
        if(Object.prototype.hasOwnProperty.call(childJson, FB_COUNTY_MAP_OPACITY)) {
            countyMap.opacity = childJson[FB_COUNTY_MAP_OPACITY];
            if(countyMap.leafletTileLayer != null) {
                countyMap.leafletTileLayer.setOpacity(countyMap.opacity);
            }
        }
        
        if(Object.prototype.hasOwnProperty.call(childJson, FB_COUNTY_MAP_SHOW_BOUNDARY)) {
            countyMap.showBoundary = childJson[FB_COUNTY_MAP_SHOW_BOUNDARY];
            let mapLayer = this.getMapLayerForLayerID('county');
            if(mapLayer.leafletLayer) {
                mapLayer.leafletLayer.redraw();
            }
        }
    }
    else {
        countyMap = this.countyMaps.getPurchasableMap(mapName);
        if(countyMap != null) {
            let childJson = snapshot.val();
            
            if(Object.prototype.hasOwnProperty.call(childJson, FB_COUNTY_MAP_SHOW_BOUNDARY)) {
                countyMap.showBoundary = childJson[FB_COUNTY_MAP_SHOW_BOUNDARY];
                let mapLayer = this.getMapLayerForLayerID('county');
                if(mapLayer.leafletLayer) {
                    mapLayer.leafletLayer.redraw();
                }
            }
        }
    }
  }

  showMapLayer(mapLayer) {

    var shouldShowLayer = mapLayer.shouldShow();
    // set up the vectors
    if(shouldShowLayer && (!mapLayer.leafletLayer || !this.leafletMap.hasLayer(mapLayer.leafletLayer)) && mapLayer.vectorsMinZoom != null) {
        if(mapLayer.url && !mapLayer.leafletLayer) {
            var url = mapLayer.url + "/boundarydata.geojson";
            $.ajax({
                url: url,
                type:'HEAD',
                error: () => {
                    //file doesn't exist
                    console.log("FILE DIDN'T EXIST: " + url);
                },
                success: () => {
                    //file exists
                    // console.log("FILE EXISTS: " + url);
                    var fillOpacity = mapLayer.fillOpacity;
                    $.ajax({
                        url: url,
                        dataType: "json",
                        success: (jsonObj) => {
                            if (jsonObj !== "" && jsonObj !== null) {
                                //this uses geojson-vt!!!
                                mapLayer.geoJson = jsonObj;
                                //checking again as sometimes it's possible to add a layer twice based on showMapLayer being called asynchronously.
                                if(!mapLayer.leafletLayer) {                                 
                                    mapLayer.leafletLayer = L.vectorGrid.slicer(jsonObj, {
                                        maxZoom: 24, // max zoom to preserve detail on can't be higher than 24
                                        minZoom: mapLayer.vectorsMinZoom,
                                        vectorTileLayerStyles: {
                                            sliced: (properties) => {
                                                //handle county maps boundary layers
                                                if(mapLayer.layerID == 'county') {
                                                    var countyMap = this.countyMaps.getCountyMapFromRoot(properties.Subtitle);
                                                    //may need to change this for unavailable maps!
                                                    if(countyMap && countyMap.showBoundary) {
                                                        return {
                                                            fillColor: mapLayer.getColor(),
                                                            fillOpacity: 0,
                                                            stroke: true,
                                                            fill: false,
                                                            color: mapLayer.getColor(),
                                                            weight: 2,
                                                        }
                                                    } else {
                                                        return {
                                                            fillColor: mapLayer.getColor(),
                                                            fillOpacity: 0,
                                                            stroke: false,
                                                            fill: false,
                                                            color: mapLayer.getColor(),
                                                            weight: 0,
                                                        }
                                                    }    
                                                } 
                                                else {
                                                    return {
                                                        fillColor: mapLayer.getColor(),
                                                        fillOpacity: fillOpacity,
                                                        stroke: true,
                                                        fill: fillOpacity > 0,
                                                        color: mapLayer.getColor(),
                                                        weight: mapLayer.getStrokeWidth(),
                                                    }
                                                }
                                            }
                                        }
                                    }).addTo(this.leafletMap).setZIndex(mapLayer.zIndex);

                                    if(shouldShowLayer && mapLayer.geoJson) {
                                      this.setLabelsForLayer(mapLayer);
                                    }
                                }
                            }
                        }
                    });

                }
            });
        } else {
            mapLayer.leafletLayer.addTo(this.leafletMap).setZIndex(mapLayer.zIndex);
            if(shouldShowLayer && mapLayer.geoJson) {
              this.setLabelsForLayer(mapLayer);
            }
        }
    }

    if(shouldShowLayer && (!mapLayer.leafletTileLayer || !this.leafletMap.hasLayer(mapLayer.leafletTileLayer))) {
        // add in the remote URL if it exists
        var rgb = this.hexToRGB(mapLayer.getColor());
        if(mapLayer.urlTemplate) {
            if(!mapLayer.leafletTileLayer) {
                var minLat = this.province.getProperty("BOUNDS_LATITUDE_MIN");
                var minLon = this.province.getProperty("BOUNDS_LONGITUDE_MIN");
                var maxLat = this.province.getProperty("BOUNDS_LATITUDE_MAX");
                var maxLon = this.province.getProperty("BOUNDS_LONGITUDE_MAX");

                let tileURL = navigator.browserSpecs.name == 'Safari' ? mapLayer.urlTemplate : SERVICE_URL + '/' + "getTile.php?path="+ mapLayer.urlTemplate;

                if(minLat && minLon && maxLat && maxLon) {
                    var southWest = L.latLng(minLat, minLon);
                    var northEast = L.latLng(maxLat, maxLon);
                    var bounds = L.latLngBounds(southWest, northEast);     
                    if(mapLayer.usesCustomColor()) {
                        mapLayer.leafletTileLayer = L.tileLayer.colorizr(tileURL, {colorize: function(pixel) {return rgb}, minZoom: mapLayer.remoteTileMinZoom, maxNativeZoom: mapLayer.remoteTileMaxZoom, maxZoom:24, bounds: bounds }).addTo(this.leafletMap).setZIndex(mapLayer.zIndex);
                    } else {       
                        mapLayer.leafletTileLayer = L.tileLayer(tileURL, {minZoom: mapLayer.remoteTileMinZoom, maxNativeZoom: mapLayer.remoteTileMaxZoom, maxZoom:24, bounds: bounds }).addTo(this.leafletMap).setZIndex(mapLayer.zIndex);
                    }
                } else {
                    if(mapLayer.usesCustomColor()) {
                        mapLayer.leafletTileLayer = L.tileLayer.colorizr(tileURL, {colorize: function(pixel) {return rgb}, minZoom: mapLayer.remoteTileMinZoom, maxNativeZoom: mapLayer.remoteTileMaxZoom, maxZoom:24 }).addTo(this.leafletMap).setZIndex(mapLayer.zIndex);
                    } else {
                        mapLayer.leafletTileLayer = L.tileLayer(tileURL, {minZoom: mapLayer.remoteTileMinZoom, maxNativeZoom: mapLayer.remoteTileMaxZoom, maxZoom:24 }).addTo(this.leafletMap).setZIndex(mapLayer.zIndex);
                    }
                }
            } else {
                mapLayer.leafletTileLayer.addTo(this.leafletMap).setZIndex(mapLayer.zIndex);
            }
        }
        // add in the MBTiles layers - alternatively, we can host these on server and request tiles as they are needed (faster)
        else if(mapLayer.localTileMinZoom > 0 && mapLayer.localTileMaxZoom > 0 && mapLayer.url) {
            if(!mapLayer.leafletTileLayer) {
                // if we are using tiles, we can just put them in the resources, and directly reference them
             
                let tileURL = SERVICE_URL + '/' + "getTile.php?path="+ mapLayer.layerRootPath+"/tiles/{z}/{x}/{y}.png";

                if(mapLayer.usesCustomColor()) {
                    // console.log(tileURL);
                    mapLayer.leafletTileLayer = L.tileLayer.colorizr(tileURL, {colorize: function(pixel) {return rgb}, minZoom: mapLayer.localTileMinZoom, maxZoom: mapLayer.localTileMaxZoom}).addTo(this.leafletMap).setZIndex(mapLayer.zIndex);
                } else {
                    // console.log(tileURL);
                    mapLayer.leafletTileLayer = L.tileLayer(tileURL, {minZoom: mapLayer.localTileMinZoom, maxZoom: mapLayer.localTileMaxZoom}).addTo(this.leafletMap).setZIndex(mapLayer.zIndex);
                }
                 
                // if we want to use mbtiles (a bit less performant, but simpler), we use the mbtiles file
                //var url = mapLayer.url + "/tiles.mbtile";
                //mapLayer.leafletTileLayer = L.tileLayer.mbTiles(url, { minZoom: mapLayer.localTileMinZoom, maxZoom: mapLayer.localTileMaxZoom }).addTo(this.leafletMap).setZIndex(302);    
            } else {
                mapLayer.leafletTileLayer.addTo(this.leafletMap).setZIndex(mapLayer.zIndex);
            }
        }
    }

  }

  setLabelsForLayer(mapLayer) {
    mapLayer.parcels = true;
    if(mapLayer.geoJson && (!mapLayer.leafletLabelLayers || !this.leafletMap.hasLayer(mapLayer.leafletLabelLayers[0]))) {
            //WMU labels
            if (mapLayer.showLabels) {
          
                //get the boundary data from the database to get the list of unavailable maps!
                var script = "getLabelCentroidFromDatabase.php";
                var ajaxRequest = getAjaxRequest(); 
                var databasePath = mapLayer.layerRootPath + '/boundarydata.db'

                if(ajaxRequest != null) {
                    ajaxRequest.onreadystatechange = () => {
                        if (ajaxRequest.readyState == 4) {
                            var boundaryJsonData = JSON.parse(ajaxRequest.responseText);    
                            for (let i = 0; i < boundaryJsonData.length; i++) {
                                var jsonObj = boundaryJsonData[i];

                                var code = jsonObj.Code;
                                var point = [];
                                point.push(jsonObj.CentroidLat, jsonObj.CentroidLon);
                                var labelLayerCoords = this.getBoundsForLabelCenter(point);

                                var tooltip = L.tooltip({
                                    permanent: true,
                                    direction: 'center',
                                    opacity: 0.99,
                                    className: 'class-tooltip '+ mapLayer.layerID
                                }).setContent(this.province.hasCWD(code) && gSettings.getPreference('ShowCWDZones') ? '<img class="pin-image" src="images/caution_med.png" style="height:10px; margin-bottom:2px"></div> '+code : code);
        
                                var layer = L.polygon(labelLayerCoords, {
                                    stroke: false,
                                    fill: false
                                }).bindTooltip(tooltip);
                                
                                this.leafletMap.addLayer(layer);
                                mapLayer.leafletLabelLayers.push(layer);

                            }
                        } 
                    }
                    ajaxRequest.open("GET", SERVICE_URL + '/' + script + "?&database="+databasePath, true);
                    ajaxRequest.send(null);
                }

                this.adjustLabelSizeBasedOnZoomLevel();
                this.mapLayersArray.sort(function (a, b) { return a.popupDisplayOrder - b.popupDisplayOrder });
            }

        } 
    }

    


    enableMapClicks() {
        //HANDLE POPUPS
        this.leafletMap.off('click');

        var clickCount = 0;
        this.leafletMap.on('click', (e) => {
        // if(!gDrawingView) {
                // we want to ignore clicks that are part of double clicks, so we can zoom in/out
                // without triggering popups
                clickCount += 1;
                if (clickCount <= 1) {
                    _.delay(() => {
                        if (clickCount <= 1) {
                            // either close or open the popup depending on whether its already shown 
                            if (this.leafletMap._popup != null && this.leafletMap._popup.isOpen()) {
                            this.leafletMap.closePopup();
                            }
                            else {
                            this.handleMapClick(e);
                            }
                        }
                        clickCount = 0;
                    }, 200);
                }
        // }
        });
    }

    disableMapClicks() {
        this.leafletMap.off('click');
    }

    handleMapClick(e) {
        setCursorSpinner(true);
        var lon = e.latlng.lng;
        var lat = e.latlng.lat;
        var popup = '';
        this.removeHighlightedBoundaries();

        //lets create the popup immediately with just the add waypoint and spinner
        popup += '<div class="popup-top-bar"><div class="popup-top-bar-title">' + getCoordinateString(lat,lon) + '</div></div>';
        popup += '<tr id="spinnerRow"><td class ="bottomRow" style="height:55px;">';
        popup += '<div class="spinner-wrap in-popup" id="popupSpinner"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div></td></tr>';
        //use this to insert rows. We used to use the addWaypointRow, but this is a filler
        popup += '<tr id="bottomOfPopup" style="display: none;"></tr>'
        popup = popup == '' ? '' : '<table class="popup">' + popup + '</table>';
        
        //add bottom buttons!
        popup += '<div class="popup-bottom-bar">';
        //add waypoint button
        popup += '<button id="addWaypointButton" class="btn btn-dark text-center round-left" type="button" onclick="onAddWaypoint(\''+ lat + '\', \'' + lon +'\')"><i class="icon-ihunter" style="margin-top:2px; font-size:30px; background-image: url(&quot;images/add_waypoint_button_icon@2x.png&quot;); background-size:contain; height:36px;"></i></button>';
        //weather button
        popup += '<button class="btn btn-dark text-center round-right" type="button" onclick="openWeatherView('+lat + ',' + lon +')"><i class="icon-ihunter" style="margin-top:2px; font-size: 30px; background-image: url(&quot;images/weather_icon@2x.png&quot;); background-size:contain; height:36px;"></i></button>';
        popup += '</div>';
        //end add bottom buttons.

        
        if (popup != '') {
            setTimeout(() => {
                this.leafletMap.openPopup(popup, e.latlng);
            }, 5);
        }

        setCursorSpinner(true);
        this.addLayersRetries = 0;
        this.addLayersToPupup(lat,lon);   
    }

    // Do we need this?
    isArray(what) {
        return Object.prototype.toString.call(what) === '[object Array]';
    }

    addLayersToPupup(lat,lon) {
        var self = this;
        if(!$('#bottomOfPopup').length) { //will try every .25s to addLayersToPopup. There's no way to tell when the popup html has been loaded (so that we can add content to it)
            self.addLayersRetries++;
            if(self.addLayersRetries <= 10) {
                _.delay(function() {self.addLayersToPupup(lat,lon)},100);
            }
        } 
        else {
            //adds the popup for all the layers. It does the query on the server side and returns a promise when complete
            //we will do the popup once all of the promises have been returned.
            var promises = [];
            this.mapLayersArray.forEach(mapLayer => {
                var bIsWMU = mapLayer.layerRoot == "wmu";
                var bIsLEH = mapLayer.layerRoot == "leh";
                var bIsAgLease = mapLayer.layerRoot == "subscription" && mapLayer.layerID == "agleases";
                var bIsCounty = mapLayer.layerID == "county";

                if(gSettings.getShowMapLayer(mapLayer) && (mapLayer.parcels || mapLayer.remoteDatabaseURL != null)) { //we're now just setting parcels to true/null for visiblity..

                    promises.push(this.getLayersForLatLng(mapLayer, lat, lon).then((arrayOfLayers) => {
                        // console.log(json);
                        if (!_.isEmpty(arrayOfLayers)) { //mapLayer was tapped, setup the html and highlight it
                            // console.log(arrayOfLayers);
                            for (var i = 0; i < arrayOfLayers.length; i++) {
                                var json = arrayOfLayers[i];
                                var code = json["Code"];

                                if(bIsCounty) {
                                    var countyMap = this.countyMaps.getCountyMapFromRoot(json["Subtitle"]);
                                    if(countyMap) {
                                        let title = countyMap.getPopupTitle();
                                        let subtitle = countyMap.getPopupSubtitle();                               


                                        let onClickText = 'onclick="onCountyMapPopupClick(\''+countyMap.mapName+'\')"'; 

                                        let tdClass = '<td class="otherRows">';
                                        let tableRow = '';                 
                                        tableRow += '<tr class="county-map-popup-row"' + onClickText + '>';
                                        tableRow += tdClass;
                                        
                                        let infoContent = '<img class="left-img" src="images/listitem_map_black_and_blue@2x.png" style="background-color:white;"/>';
                                        infoContent += '<div class="text-content"><b>' + title + '</b><br />' + subtitle + '</div>';
                                        if( onClickText.length > 0 ) {
                                            tableRow += infoContent + '<img class="arrow-img" src="images/arrow_grey_right@2x.png"/></td></tr>';
                                        }
                                        else {
                                            tableRow += infoContent + '</td></tr>';
                                        }
                                        $('#bottomOfPopup').before(tableRow);
                                    }
                                } else {
                                    let title = cleanupTitle(json["Title"]);
                                    
                                    let comment = json["Comment"];
                                    let subtitle = json["Subtitle"];      
                                    let extraInfo = json["extrainfo"];
                                    if(extraInfo != null && extraInfo.length != 0 && this.isString(extraInfo) && !this.isJson(extraInfo)) {
                                        //Sometimes extraInfo is a string, but we need to clean it up a bit before Json-ing it.
                                        extraInfo = extraInfo.replaceAll('|','"');
                                        extraInfo = JSON.parse(extraInfo);
                                    }
      
                                    let tableRow = '';                 
                                    
                                    let onClickText = "";
                                    if(bIsWMU) {
                                        onClickText = 'onclick="onWMU(\''+ code + '\', \'' + title +'\')"';
                                    }
                                    else if(bIsLEH) {
                                        onClickText = 'onclick="onLEH(\''+ code + '\', \'' + title +'\')"';
                                    }
                                    else if(bIsAgLease) {
                                        onClickText = 'onclick="onAgLease(\''+ code + '\', \'' + title +'\')"';
                                    }
                                    else {
                                        let additionalData = comment;
                                        let additionalData2 = "";
                                        if(additionalData.includes(" || ")) {
                                            let commentsArray = additionalData.split(" || ");
                                            if(commentsArray.length > 1) {
                                                additionalData = commentsArray[0];
                                                additionalData2 = commentsArray[1];
                                            }
                                        }
                                        additionalData = this.handleLocalFileComment(additionalData, mapLayer, subtitle);
                                        additionalData2 = this.handleLocalFileComment(additionalData2, mapLayer, subtitle);
   
                                        if((additionalData != null && additionalData.length != 0) || (additionalData2 != null && additionalData2.length != 0)) {
                                            onClickText = 'onclick="onBoundary(\''+ this.sanitizeForOutputToJS(additionalData) +'\', \''+ this.sanitizeForOutputToJS(additionalData2) +'\')"';
                                        } else if(extraInfo != null && extraInfo.length != 0) {
                                            onClickText = 'onclick="showExtraInfo(\''+ JSON.stringify(extraInfo).replace(/"/g,"&quot;") +'\')"';  
                                        }
                                        
                                    }
                                
                                    let tdClass = '<td class="otherRows">';
                                    tableRow += '<tr ' + onClickText + '>';
                                    tableRow += tdClass;
                                    let infoContent = '';
                                    infoContent += '<div style="position:relative">'
                                    infoContent += '<img class="left-img" src="images/wmu_icon_black.png" style="position:absolute;"/>';
                                    infoContent += '<div style="background-color: '+mapLayer.getColor()+';width:42px;height:42px; margin-top:1px; margin-left:1px"></div>'
                                    infoContent += '</div>'

                                    infoContent += '<div class="text-content"><b>' + title + '</b><br />' + toTitleCase(subtitle) + '</div>';
                                    if( onClickText.length > 0 ) {
                                        tableRow += infoContent + '<img class="arrow-img" src="images/arrow_grey_right@2x.png"/></td></tr>';
                                    }
                                    else {
                                        tableRow += infoContent + '</td></tr>';
                                    }

                                    if($('.county-map-popup-row').length) {
                                        $('.county-map-popup-row').first().before(tableRow);
                                    } else {
                                        $('#bottomOfPopup').before(tableRow);
                                    }
                                    self.removePopupSpinner(); //we're going to remove it as soon as one comes in since they come in back to back
                                }
                                
                                if(mapLayer.highlightSelected && gSettings.getPreference(FB_HIGHLIGHT_SELECTED_WMU)) {
                                    if(mapLayer.remoteDatabaseURL == null) {
                                        self.highlightBoundaryForMapLayer(mapLayer, code);
                                    }
                                    else {
                                        let boundary = json["Boundary"];
                                        self.highlightBoundary(boundary, mapLayer.defaultColor);
                                    }
                                }
                            }
                        }
                    }));
                }
                    
            });

            //all data is back from the server, so let's create the popup
            Promise.all(promises).then(function() {
                self.removePopupSpinner();
                var rowCount = $('.popup tr').length;
                // if rowCount is 2, then no layers are showing (there are the invisible spinner row and invisible "bottomOfPopup" rows)
                if(rowCount <= 2) {
                    var noLayersFoundRow = '<tr id="noLayersRow"><td class="otherRows"><div class="text-center" style="width:100%;">No Boundaries Found</div></td></tr>';
                    $('#bottomOfPopup').before(noLayersFoundRow);
                }
            });
        }
    }

    isString(val) {
        return (typeof val === 'string' || val instanceof String);
    }

    isJson(str) {
        try {
          JSON.parse(str);
        } catch (e) {
          return false;
        }  
        return true;
    }

    setBoundaryVisibilityForCountyMap(mapName, bIsVisible) {
        return this.service.db.setBoundaryVisibilityForCountyMap(mapName, bIsVisible);
    }

    sanitizeForOutputToJS(output) {
        if(output == null) return null;
        return output.replace(/\n/g, "<br />").replace(/'/g, "\\'");
    }

    removeHighlightedBoundaries() {
        this.highlightedBoundariesArray.forEach(highlightedBoundary => {
            this.leafletMap.removeLayer(highlightedBoundary);
        });
        this.highlightedBoundariesArray.length = 0; // removes all the items
    }

    handleLocalFileComment(comment, mapLayer, subtitle) {
        if (comment != null && comment.startsWith("html/") && comment.endsWith(".html")) {
            comment = mapLayer.layerRootPath + "/" + comment;
        }
        else if(comment == null || comment.length == 0) {
            comment = mapLayer.getTypeDescriptionFromType(subtitle);
        }
        return comment;
    }


    highlightBoundaryForMapLayer(mapLayer, code) {

        //Boundary Highlighting                        
        var highlight = new L.geoJson(mapLayer.geoJson, {
            filter: function (feature, mapLayer) {
                return feature.properties.Code == code
            },
            style: {
                dashArray: '10, 10', 
                dashOffset: '10',
                color: pSBC(-0.4, mapLayer.getColor()), //these should use the mapLayer colours and just make them thicker?
                fillColor: mapLayer.getColor(), //these should use the mapLayer colours and just make them thicker?
                fillOpacity: 0, //Remove this if we want a fillColor to show up as well...
                weight: 4,
            }
        });
        this.highlightedBoundariesArray.push(highlight);
        highlight.addTo(this.leafletMap);
    }

    highlightBoundary(boundary, defaultColor) {
        if(boundary && defaultColor) {
            var boundaryArray = boundary.split("\n");
            for(var j = 0; j < boundaryArray.length; j++) {
                var highlightedBoundary = L.polyline([], {interactive: false});
                var boundaryTemp = boundaryArray[j];
                var points = boundaryTemp.split(",");
                for(var i = 0; i < points.length; i+=2) {
                    highlightedBoundary.addLatLng({lat: points[i], lon: points[i+1]});
                }
                
                highlightedBoundary.setStyle({
                    dashArray: '10, 10', 
                    dashOffset: '10',
                    color: defaultColor,
                    weight: 5
                });

                this.highlightedBoundariesArray.push(highlightedBoundary);
                highlightedBoundary.addTo(this.leafletMap);
            }
        }
    }

    removePopupSpinner() {
        setCursorSpinner(false);
        $('#popupSpinner').hide();
        $('#spinnerRow').hide();
    }

    getLayersForLatLng(mapLayer, lat, lon) {
        return new Promise((resolve, reject) => {
            
            var ajaxRequest = getAjaxRequest(); 
            if(ajaxRequest != null) {
                ajaxRequest.onreadystatechange = () => {
                    if (ajaxRequest.readyState == 4) {
                        if(ajaxRequest.status === 200 && ajaxRequest.responseText !== "") {
                            if(ajaxRequest.responseText.includes("Unable to connect to PostgreSQL")){
                                resolve("Unable to connect to PostgreSQL");
                            } else {
                                try {  
                                    //json will have code, name, type, comment.
                                    var json = JSON.parse(ajaxRequest.responseText);

                                    // If the json is an array, pass it on. If a single object wrap it in an array
                                    if(this.isArray(json)) {
                                        resolve(json);
                                    }
                                    else {
                                        resolve([json]);
                                    }
                                }catch(error) {
                                    reject(error);
                                }
                            }
                        } else {
                            reject("Failed ajax response in getLayersForLatLng");
                        }
                    }
                }
        
                var script;
                if(mapLayer.remoteDatabaseURL == null ) {
                    script = "getLayerData.php?&path=" + mapLayer.layerRootPath + "&lat="+lat + "&lon=" + lon;
                }
                else {
                    script = mapLayer.remoteDatabaseURL.replace("{lat}", ""+lat).replace("{lon}", ""+lon);
                }
                ajaxRequest.open("GET", SERVICE_URL + '/' + script, true);
                ajaxRequest.send(null);
            } else {
                reject("Unable to create ajax request");
            }
        });
    }

    getBoundsForLabelCenter(point) {
        var lat = parseFloat(point[0]);
        var lon = parseFloat(point[1]);

        var maxLat = lat+0.2;
        var minLat = lat-0.2;
        var maxLon = lon+0.2;
        var minLon = lon-0.2;

        return [[maxLat,minLon], [maxLat,maxLon], [minLat,maxLon], [minLat,minLon]];
    }

    getBoundsForLabelLayer(coordinates) {
        //get the min and max lat and create the labels based on that. It's not perfect but works OK
        var minLat = 999999999999;
        var maxLat = -999999999999;
        var minLon = 999999999999;
        var maxLon = -999999999999;
        for(var k = 0; k < coordinates.length; k++) {
            var c = coordinates[k];
            minLat = c[0] < minLat ? c[0] : minLat;
            maxLat = c[0] > maxLat ?  c[0] : maxLat;
            minLon = c[1] < minLon ? c[1] : minLon;
            maxLon = c[1] > maxLon ? c[1] : maxLon;
        }
        return [[maxLat,minLon], [maxLat,maxLon], [minLat,maxLon], [minLat,minLon]];
    }

    adjustLabelSizeBasedOnZoomLevel() {
        $('.leaflet-tooltip').css('font-size', 9); //initial zoom level
        this.leafletMap.on('zoomend', () => {
            //if(gMap != null) {
                var zoomLevel = this.leafletMap.getZoom();
                var tooltip = $('.leaflet-tooltip');
                if (zoomLevel > 6) {
                    tooltip.css('font-size', 11);
                } else {
                    tooltip.css('font-size', 9);
                }

                
                // todo CHAD
                // adjustVisibleMeasurementLabelsForZoom(zoomLevel);
            //}
        });
    }


    getBoundingRectFromString(boundsString) {
        var coords = boundsString.split(",");
        //lets try a Rect for now, but may need to change this later
        //left, top, right, bottom
        //console.log(new Rect(parseFloat(coords[0]), parseFloat(coords[1]), parseFloat(coords[2]), parseFloat(coords[3])));
        return new Rect(parseFloat(coords[0]), parseFloat(coords[1]), parseFloat(coords[2]), parseFloat(coords[3])); 
    }

    //for use with tileLyaer.colorizr
    hexToRGB(strHex) {
        var bigint = parseInt(strHex.replace('#',''), 16);
        var red = (bigint >> 16) & 255;
        var green = (bigint >> 8) & 255;
        var blue = bigint & 255;
        return {r:red, g:green, b:blue};
    }
    

    getLayerIDFromAttribution(str) {
        var start = str.indexOf("id=**");
        if(start < 0) {
        return null;
        }
        var id = str.substring(start+5, str.indexOf("**",start+5))
        return id;
    }

    hasMapLayerWithLayerID(layerID) {
        if(this.mapLayersArray) {
            for(var i = 0; i < this.mapLayersArray.length; i++) {
                var mapLayer = this.mapLayersArray[i];
                if(mapLayer.layerID == layerID) {
                    return true;
                }
            }
        }

        return false;
    }

    getMapLayerForLayerID(layerID) {
        if(this.mapLayersArray) {
            for(var i = 0; i < this.mapLayersArray.length; i++) {
                var mapLayer = this.mapLayersArray[i];
                if(mapLayer.layerID == layerID) {
                    return mapLayer;
                }
            }
        }
        return null;
    }

    getMapLayerForVisibilityPreference(visPref) {
        if(this.mapLayersArray) {
            for(var i = 0; i < this.mapLayersArray.length; i++) {
                var mapLayer = this.mapLayersArray[i];
                if(mapLayer.visibilityPreference == visPref) {
                    return mapLayer;
                }
            }
        }
        return null;
    }

    getMapLayerForColorPreference(colorPref) {
        if(this.mapLayersArray) {
            for(var i = 0; i < this.mapLayersArray.length; i++) {
                var mapLayer = this.mapLayersArray[i];
                if(mapLayer.colorPreference == colorPref) {
                    return mapLayer;
                }
            }
        }
        return null;
    }

    getCountyMapDescription(mapName) {
        return new Promise((resolve, reject) => {
            this.getStringFromURL( this.countyMaps.getMetaDataURLForCountyMap(mapName), (jsonContent) => {
                var jsonObj = JSON.parse(jsonContent);
                if(Object.prototype.hasOwnProperty.call(jsonObj, 'description')) {
                    var description = jsonObj.description;
                    resolve(description);
                }
                else {
                    reject(null);
                }
            } );
        });
    }

    updateCountyMap(mapName) {
        var countyMap = this.countyMaps.getPurchasedMap(mapName);
        if(countyMap != null && (!countyMap.leafletTileLayer || !this.leafletMap.hasLayer(countyMap.leafletTileLayer))) {
            this.getStringFromURL(this.countyMaps.getMetaDataURLForCountyMap(countyMap.mapName), (jsonContent) => {
                var url = this.countyMaps.getTemplatedPathForCountyMap(countyMap.mapName);
                var type = 0;
                var tileService = TileServiceType.TMS;

                var minZoom = 4; //default
                var maxZoom = 15; //default

                var jsonObj = JSON.parse(jsonContent);
                if (Object.prototype.hasOwnProperty.call(jsonObj, 'maxzoom')) {
                    maxZoom = parseInt(jsonObj.maxzoom);
                } 
                if (Object.prototype.hasOwnProperty.call(jsonObj, 'minzoom')) {
                    minZoom = parseInt(jsonObj.minzoom);
                } 

                countyMap.setTileData(url, type, tileService, maxZoom, minZoom);
                this.showCountyMapLayer(countyMap);
            });
        }
        else {
            console.log("updateCountyMapLayers NOT adding map: "+mapName +"as it already has a leafletTileLayer");
        }
    }

    updateSubscriptionLayers() {
        this.removeSubscriptionLayers();
        this.processLayer("subscription", "res/provinces/" + this.provinceCode + "/", "root");
    }

    removeSubscriptionLayers() {
        var length = this.mapLayersArray ? this.mapLayersArray.length : 0;
        for(var i = 0; i < length; i++) {
            var mapLayer = this.mapLayersArray[i];
            if(mapLayer.layerRoot == "subscription") {
                this.hideMapLayer(mapLayer);
                this.mapLayersArray.splice(i,1);
                i--;
                length--;
            }
        }
    }

    updateCountyMapLayers() {

        for(var mapName in this.countyMaps.purchasedMaps) {
            this.updateCountyMap(mapName);
        }
    }

    showCountyMapLayer(countyMap) {
        if(countyMap.tileURL) {
            if(!countyMap.leafletTileLayer) {
                var minLat = countyMap.bounds.bottom;//gProvince.getProperty("BOUNDS_LATITUDE_MIN");
                var minLon = countyMap.bounds.left;// gProvince.getProperty("BOUNDS_LONGITUDE_MIN");
                var maxLat = countyMap.bounds.top;//gProvince.getProperty("BOUNDS_LATITUDE_MAX");
                var maxLon = countyMap.bounds.right;//gProvince.getProperty("BOUNDS_LONGITUDE_MAX");
                if(minLat && minLon && maxLat && maxLon) {
                    var southWest = L.latLng(minLat, minLon);
                    var northEast = L.latLng(maxLat, maxLon);
                    var bounds = L.latLngBounds(southWest, northEast);  
                    countyMap.leafletTileLayer = L.tileLayer(countyMap.tileURL, {minZoom: countyMap.minZoom, maxNativeZoom: countyMap.maxZoom, maxZoom:24, bounds: bounds }).addTo(this.leafletMap).setOpacity(countyMap.opacity).setZIndex(countyMap.zIndex);
                } else {
                    countyMap.leafletTileLayer = L.tileLayer(countyMap.tileURL, {minZoom: countyMap.minZoom, maxNativeZoom: countyMap.maxZoom, maxZoom:24 }).addTo(this.leafletMap).setOpacity(countyMap.opacity).setZIndex(countyMap.zIndex);
                }
            } else {
                countyMap.leafletTileLayer.addTo(this.leafletMap).setZIndex(countyMap.zIndex);
            }
        }
    }

    // //to hide, we should be able to use hideMapLayer()
    showUserMapLayer(userMapLayer) {
        if(userMapLayer.tileURL) {
            if(!userMapLayer.leafletTileLayer) { 
                userMapLayer.setTileLayer();
            } 
            userMapLayer.leafletTileLayer.addTo(this.leafletMap);
        }
    }

    hideUserMapLayer(userMapLayer) {
        this.leafletMap.removeLayer(userMapLayer.leafletTileLayer);
    }

    addUserMapLayer(userMapLayer) {
        if(this.getUserMapLayer(userMapLayer)) {
            this.removeUserMapLayer(userMapLayer);
        }
        this.userMapLayersArray.push(userMapLayer);
    }

    removeUserMapLayer(userMapLayer) {
        var length = this.userMapLayersArray.length;
        for(var i = 0; i < length; i++) {
            var existingLayer = this.userMapLayersArray[i];
            if(existingLayer.equals(userMapLayer)) {
                this.hideUserMapLayer(existingLayer);
                this.userMapLayersArray.splice(i,1);
                break;
            }
        }
    }

    getUserMapLayer(userMapLayer) {
        var length = this.userMapLayersArray.length;
        for(var i = 0; i < length; i++) {
            var existingLayer = this.userMapLayersArray[i];
            if(existingLayer.equals(userMapLayer)) {
                return existingLayer;
            }
        }
        return null;
    }

    getUserMapLayerForUUID(uuid) {
        var length = this.userMapLayersArray.length;
        for(var i = 0; i < length; i++) {
            var existingLayer = this.userMapLayersArray[i];
            if(existingLayer.getUUID() === uuid) {
                return existingLayer;
            }
        }
        return null;
    }

    getUserMapLayerForCacheFolder(cache) {
        var length = this.userMapLayersArray.length;
        for(var i = 0; i < length; i++) {
            var existingLayer = this.userMapLayersArray[i];
            if(existingLayer.cacheFolder === cache) {
                return existingLayer;
            }
        }
        return null;
    }

    hideMapLayer(mapLayer) {
        if(mapLayer) {
            if(mapLayer.leafletLayer) {
                this.leafletMap.removeLayer(mapLayer.leafletLayer);
            }
            if(mapLayer.leafletTileLayer) {
                this.leafletMap.removeLayer(mapLayer.leafletTileLayer);
            }
            if(mapLayer.leafletLabelLayers) {
                for(var i = 0; i < mapLayer.leafletLabelLayers.length; i++) {
                    this.leafletMap.removeLayer(mapLayer.leafletLabelLayers[i]);
                }
            }
            mapLayer.parcels = null;
        }
    }

    clearBaseMap() {
        this.googleSatMutant.remove();
        this.googleRoadMutant.remove();
        this.googleTerrainMutant.remove();
        this.googleHybridMutant.remove();
        this.appleRoadMutant.remove();
        this.appleSatelliteMutant.remove();
        this.appleHybridMutant.remove();
    }

    processUserMapLayer(snapshot) {
        var existingUserMapLayer = this.getUserMapLayerForUUID(snapshot.key);
        console.log(snapshot.val())

        if(existingUserMapLayer != null) {
            //already exists (likely a built in map layer)
            if(Object.prototype.hasOwnProperty.call(snapshot.val(), "opacity")) { 
                existingUserMapLayer.setOpacity(snapshot.val()["opacity"]);
            }
            if(Object.prototype.hasOwnProperty.call(snapshot.val(), "visible")) {
                existingUserMapLayer.setVisibility(snapshot.val()["visible"]);
            }
        } else {
            if(Object.prototype.hasOwnProperty.call(snapshot.val(), "json")) {
                var userMapLayer = (new UserMapLayer(this)).initWithSnapshot(snapshot);
                userMapLayer.setTileLayer();

                this.addUserMapLayer(userMapLayer);

                if(userMapLayer.isBaseMap()) {
                    this.addBaseMapToControl(userMapLayer);
                }

                if(userMapLayer.bIsVisible) {
                    this.showUserMapLayer(userMapLayer);
                    if(userMapLayer.isBaseMap()) {
                        //remove google layers if any of them were active
                        this.clearBaseMap();
                    }
                }

                this.emit(EVENT.UPDATE_MAP_LAYER);
            }
        }
    }

    processExistingUserMapLayer(snapshot) {
        if(!Object.prototype.hasOwnProperty.call(snapshot.val(), "json")) { 
            //if there's no json, it's a built-in layer and we just want to modify opacity and visibility
            var existingUserMapLayer = this.getUserMapLayerForUUID(snapshot.key);
            if(existingUserMapLayer != null) {
                if(Object.prototype.hasOwnProperty.call(snapshot.val(), "opacity")) { 
                    existingUserMapLayer.setOpacity(snapshot.val()["opacity"]);
                }
                if(Object.prototype.hasOwnProperty.call(snapshot.val(), "visible")) {
                    existingUserMapLayer.setVisibility(snapshot.val()["visible"]);
                }
            }
        } else {
            var userMapLayer = (new UserMapLayer(this)).initWithSnapshot(snapshot);
            userMapLayer.setTileLayer();

            var existingMapLayer = this.getUserMapLayer(userMapLayer);

            if(existingMapLayer) {
                if(existingMapLayer.bIsVisible != userMapLayer.bIsVisible) {
                    existingMapLayer.bIsVisible = userMapLayer.bIsVisible;
                    if(existingMapLayer.bIsVisible) {
                        this.showUserMapLayer(existingMapLayer);
                    } else {
                        this.hideUserMapLayer(existingMapLayer);
                    }
                }
                //DO SOMETHING WITH OPACITY
                if(existingMapLayer.opacity != userMapLayer.opacity) {
                    existingMapLayer.opacity = userMapLayer.opacity;
                    existingMapLayer.leafletTileLayer.setOpacity(existingMapLayer.opacity);
                }
            }
        }
    }

    userMapLayerRemoved(snapshot) {
        //snapshot.key should be "displayName" + "cacheFolder" 
        var userMapLayer = (new UserMapLayer(this)).initWithSnapshot(snapshot);
        this.removeUserMapLayer(userMapLayer);
        
        this.emit(EVENT.UPDATE_MAP_LAYER);
    }

    addBaseMapToControl(userMapLayer) {
        // console.log(userMapLayer);
        this.baseMapsControl.addBaseLayer(userMapLayer.leafletTileLayer, userMapLayer.getShortenedTitle());
        this.adjustBottomLeftControls();
    }

    removeBaseMapFromControl(userMapLayer) {
        this.baseMapsControl.removeLayer(userMapLayer.leafletTileLayer);
        if(this.leafletMap.hasLayer(userMapLayer.leafletTileLayer)) {
        this.googleSatMutant.addTo(this.leafletMap);
        }
        this.adjustBottomLeftControls();
    }

    adjustBottomLeftControls() {
        //Because the width of this control varies by browser, need to set the left div width after we set this control...    
        $("#leftDivContainer").width(this.baseMapsControl.getContainer().offsetWidth);
        //20 = margin-bottom, but for some reason I can't use .css("margin-bottom")
        let bottom = this.baseMapsControl.getContainer().offsetTop + $(".leaflet-bottom.leaflet-left").first().height() + this.legendBottomMargin + 10;
        $("#leftDivContainer").css("bottom", bottom + "px"); 
    }

    setupMutants() {

        const ATTRIBUTION_HTML =  '<a href="https://www.ihunterapp.com" title="iHunter Website" target="_blank">iHunter&zwnj;</a>';
        const Z_INDEX = 199;

        //global so that we can set the map to this if no other map gets selected (when deleting another basemap)
        this.googleSatMutant = L.gridLayer.googleMutant({
            provider: 'google',
            maxZoom: 24,
            type: 'satellite',
            attribution: ATTRIBUTION_HTML
        }).setZIndex(Z_INDEX);

        this.googleRoadMutant = L.gridLayer.googleMutant({
            provider: 'google',
            maxZoom: 24,
            type: 'roadmap',
            attribution: ATTRIBUTION_HTML
        }).setZIndex(Z_INDEX);

        this.googleTerrainMutant = L.gridLayer.googleMutant({
            provider: 'google',
            maxZoom: 24,
            type: 'terrain',
            attribution: ATTRIBUTION_HTML
        }).setZIndex(Z_INDEX);

        this.googleHybridMutant = L.gridLayer.googleMutant({
            provider: 'google',
            maxZoom: 24,
            type: 'hybrid',
            attribution: ATTRIBUTION_HTML
        }).setZIndex(Z_INDEX);

        // MapKit requests must be authorized; periodically exchange iHunter token for MapKit token
        let authorization = async (done) => {
            // We don't control the timing of this authorization call; so we need to be resiliant to 
            // race conditions between our initial token request
            console.log("Refreshing MapKit access token");
            
            let token = null;
            let count = 0;
            while(!token && count <= 3) {
                try {
                    count++;
                    token = await this.requestMapKitToken();

                }catch(error) {
                    console.log(`MapKit token request failed; attempt #${count}`);
                    
                }
            }
            
            done(token);
        }

        this.appleRoadMutant = L.mapkitMutant({
            provider: 'apple',
            // valid values for 'type' are 'default', 'satellite' and 'hybrid'
            type: 'default',
            pane: 'tilePane',
            authorizationCallback: authorization,
            attribution: ATTRIBUTION_HTML,
            language: 'en'
        });

        this.appleSatelliteMutant = L.mapkitMutant({
            provider: 'apple',
            // valid values for 'type' are 'default', 'satellite' and 'hybrid'
            type: 'satellite',
            pane: 'tilePane',
            authorizationCallback: authorization,
            attribution: ATTRIBUTION_HTML,
            language: 'en'
        });

        this.appleHybridMutant = L.mapkitMutant({
            provider: 'apple',
            // valid values for 'type' are 'default', 'satellite' and 'hybrid'
            type: 'hybrid',
            pane: 'tilePane',
            authorizationCallback: authorization,
            attribution: ATTRIBUTION_HTML,
            language: 'en'
        });
    }

    requestMapKitToken() {
        return new Promise(async (resolve, reject) => {            
            var ajaxRequest = getAjaxRequest();
            if(ajaxRequest != null) {
                // Create a function that will receive data sent from the server
                ajaxRequest.onreadystatechange = () => {
                    if (ajaxRequest.readyState == 4) {
                        if(ajaxRequest.status == 200) {
                            let json = JSON.parse(ajaxRequest.responseText);
                            if(json.token_type === 'MapKit') {
                                return resolve(json.access_token);
                            }
                        }
                        return reject("");
                    }
                }
                let firebaseToken = await this.user.getIdToken();
                let query = this.service.config.functionURL + '/token';
                ajaxRequest.open("GET", query, true);
                ajaxRequest.setRequestHeader('authorization', 'Firebase ' + firebaseToken);
                ajaxRequest.setRequestHeader('type', 'MapKit');
                ajaxRequest.send(null);
            }else {
                return reject("Ajax request error");
            }   
        });
    }

    addPolylinesForTrackedWaypoint(waypoint) {
        if (!this.polyLinesDict[waypoint.uuid]) {
            var polylines = new Array();
            var a = waypoint.getAnnotation(waypoint.annotationXML);

            //adding the lines
            if (a != null) {
                var segments = a.getTrackSegments();
                for(var i=0; i<segments.length; i++) {
                    var segment = segments[i];
                    var points = segment.points;
                    var polyline = new L.Polyline(points, {
                        color: segment.pause?'gray':a.color,
                        weight: a.thickness,
                        dashArray: '3 5',
                        opacity: 1,
                        smoothFactor: 1,
                        interactive: false
                    });
                    polyline.addTo(this.leafletMap);
                    polylines.push(polyline);
                }
            }
            this.polyLinesDict[waypoint.uuid] = polylines;
        }
    }

    removePolylinesForTrackedWaypoint(waypoint) {
        var polylines = this.polyLinesDict[waypoint.uuid];
        if (polylines != null) {
            for (var i = 0; i < polylines.length; i++) {
                this.leafletMap.removeLayer(polylines[i]);
            }
            delete this.polyLinesDict[waypoint.uuid];
        }
    }

    updatePolylinesForTrackedWaypoint(waypoint) {
        this.removePolylinesForTrackedWaypoint(waypoint);
        this.addPolylinesForTrackedWaypoint(waypoint);
    }
    addPolylinesForDrawnWaypoint(waypoint) { //drawnWaypoint    

        if (this.polyLinesDict[waypoint.uuid] == null) {
            var polylines = new Array();
            var annotations = waypoint.getAnnotations(waypoint.annotationXML);
            var measurementLabels = [];
            //adding the lines
            for (var i = 0; i < annotations.length; i++) {
                var a = annotations[i];
                a.waypoint = waypoint;
                a.polyline.addTo(this.leafletMap);
                polylines.push(a.polyline);
                measurementLabels = measurementLabels.concat(a.updateMeasurementLabels());
            }

            this.polyLinesDict[waypoint.uuid] = polylines;
            this.measurementLabelsDict[waypoint.uuid] = measurementLabels;


            //console.log("create polyLinesDict: " + waypoint.name);
        }
    }

    //this is almost exact same as function above. Combine?
    addMultiColoredPolylinesForDrawnWaypoint(waypoint) { //drawnWaypoint
        if (this.multiColoredPolylinesDict[waypoint.uuid] == null) {
            var polylines = new Array();
            var annotations = waypoint.getAnnotations(waypoint.annotationXML);
            var measurementLabels = [];
            //adding the lines
            for (var i = 0; i < annotations.length; i++) {
                var a = annotations[i];
                a.waypoint = waypoint;
                if((a.isLine() || a.isFreehand()) && a.elevations != null && a.elevations.length > 0 && a.elevationPoints != null && a.elevationPoints.length > 0) {
                    a.setMultiColorPolyline();
                    a.multiColorPolyline.addTo(this.leafletMap);
                    polylines.push(a.multiColorPolyline);
                } else {
                    a.polyline.addTo(this.leafletMap);
                    polylines.push(a.polyline);
                }
                measurementLabels = measurementLabels.concat(a.updateMeasurementLabels());
            }

            this.multiColoredPolylinesDict[waypoint.uuid] = polylines;
            this.measurementLabelsDict[waypoint.uuid] = measurementLabels;

            //console.log(this.measurementLabelsDict[waypoint.uuid]);
        }
        
    }

    removePolylinesForDrawnWaypoint(waypoint) { //drawnWaypoint  

        let polylines = this.polyLinesDict[waypoint.uuid];
        if (polylines) {
            for (let i = 0; i < polylines.length; i++) {
                this.leafletMap.removeLayer(polylines[i]);
            }
            //console.log("delete polyLinesDict: " + waypoint.name);
            delete this.polyLinesDict[waypoint.uuid];
        }

        let labels = this.measurementLabelsDict[waypoint.uuid];
        if (labels) {
            for (let i = 0; i < labels.length; i++) {
                this.leafletMap.removeLayer(labels[i]);
            }
            delete this.measurementLabelsDict[waypoint.uuid];
        }

        let multiColoredPolylines = this.multiColoredPolylinesDict[waypoint.uuid];
        if(multiColoredPolylines) {
            for(let i = 0; i < multiColoredPolylines.length; i++) {
                this.leafletMap.removeLayer(multiColoredPolylines[i]);
            }
            delete this.multiColoredPolylinesDict[waypoint.uuid];
        }

    }

    updatePolylinesForDrawnWaypoint(waypoint) { //drawnWaypoint    
        this.removePolylinesForDrawnWaypoint(waypoint);

        waypoint.getAnnotations(waypoint.annotationXML); // I think we rely on side-effects from getAnnotations



        // if(waypoint.showElevation && waypoint.annotations != null && waypoint.annotations[0].elevations != null) {
        //     // console.log('showing Multi Colored Poly');
        //     this.addMultiColoredPolylinesForDrawnWaypoint(waypoint);
        // } else {
            // console.log('showing Normal Colored Poly')
            this.addPolylinesForDrawnWaypoint(waypoint);
        //}
    }

    createMeasurementMarkerBetweenCoords(mp1, mp2, rotation) {
        var lblText = MapHelpers.getLabelTextForDistanceBetweenCoords(mp1, mp2);
        var mpMid = new L.LatLng((mp1.lat + mp2.lat) / 2, (mp1.lng + mp2.lng) / 2);
        var minZoomLevel = MapHelpers.getMinimumZoomLevelToCoverDistance(mpMid, mp1.distanceTo(mp2));
        return this.addDrawnWaypointMeasurementMarker(lblText, mpMid, minZoomLevel, rotation);
    }

    addDrawnWaypointMeasurementMarker(markerText, midPoint, minZoomLevel, rotation) {
        //console.log("minZoom = " + minZoomLevel);
        var marker;// = new L.marker(midPoint, {opacity : 0});
        if (rotation == 90) {
            let label = new L.divIcon({ html: '<div class="measurement-label rotated"><div class="measurement-label-content">' + markerText + '</div></div>' });
            marker = new L.marker(midPoint, { icon: label, interactive: false });
        } else {
            let label = new L.divIcon({ html: '<div class="measurement-label"><div class="measurement-label-content">' + markerText + '</div></div>' });
            marker = new L.marker(midPoint, { icon: label, interactive: false });
        }
        marker.addTo(this.leafletMap);

        return marker;
    }


    refreshMapPreference(pref, val) {
        let mapLayer = this.getMapLayerForVisibilityPreference(pref);
        if(mapLayer) {
            if(val) {
                this.showMapLayer(mapLayer);
            } else {
                this.hideMapLayer(mapLayer);
            }

            if(mapLayer.layerRoot == 'subscription') {
                this.emit(EVENT.UPDATE_SUBSCRIPTION_LAYER, true);
            }else 
            if(mapLayer.layerRoot == 'leh') {
                this.emit(EVENT.UPDATE_LEH_BOUNDARY);
            }else {
                this.emit(EVENT.UPDATE_BOUNDARY);
            }
        }
    }

    refreshBoundaryPreference(pref, val) {
        let mapLayer = this.getMapLayerForVisibilityPreference(pref);
        if(mapLayer) {
            if(val) {
                this.showMapLayer(mapLayer);
            } else {
                this.hideMapLayer(mapLayer);
            }
        }
    }

    refreshLehLayer(pref) {

        for(let i = 0; i < this.mapLayersArray.length; i++) {
            let mapLayer = this.mapLayersArray[i];
            if(mapLayer.layerRoot == 'leh') {
                if(mapLayer.getIndex() == pref) {
                    this.showMapLayer(mapLayer);
                } else {
                    this.hideMapLayer(mapLayer);
                }
            }
        }
    }

    refreshColorPreference(key, val) {
        if(this.lehLayersPackage && val.includes(this.lehLayersPackage.getColorPreference())) {
            if(this.lehLayersPackage.mapLayers) {
                for(let i = 0; i < this.lehLayersPackage.mapLayers.length; i++) {
                    let mapLayer = this.lehLayersPackage.mapLayers[i];
                    this.hideMapLayer(mapLayer);
                    mapLayer.leafletTileLayer = null;
                    mapLayer.leafletLayer = null;
                    this.showMapLayer(mapLayer);
                }
            }
            this.emit(EVENT.UPDATE_LEH_BOUNDARY);        

        } else if(val.includes(gSettings.colorPreferenceForCounties())) {
            let numCountyMaps = this.countyMaps.allCountyMapsArray.length;
            for(let i = 0; i < numCountyMaps; i++) {
                let countyMap = this.countyMaps.allCountyMapsArray[i];
                if(countyMap.leafletBoundsLayer) {
                    //remove it so it gets recreated
                    if(this.leafletMap.hasLayer(countyMap.leafletBoundsLayer)) {
                        this.leafletMap.removeLayer(countyMap.leafletBoundsLayer);
                    }
                    countyMap.leafletBoundsLayer = null; 
                }
            }
        } else {
            let mapLayers = this.mapLayersArray;
            if(mapLayers) {
                let mapLayer = this.getMapLayerForColorPreference(key);
                if(mapLayer) {
                    this.hideMapLayer(mapLayer);
                    mapLayer.leafletTileLayer = null;
                    mapLayer.leafletLayer = null;
                    this.showMapLayer(mapLayer);
                    //update the views if they're open
                    
                    if(mapLayer.layerRoot == 'subscription') {
                        this.emit(EVENT.UPDATE_SUBSCRIPTION_LAYER);

                    } else {
                        this.emit(EVENT.UPDATE_BOUNDARY);
                    }
                }
            }
        }
    }

    boundarySwitchClicked(checkbox, layerID) {
        for(var i = 0; i < this.mapLayersArray.length; i++) {
            if(layerID == this.mapLayersArray[i].layerID) {
                var mapLayer = this.mapLayersArray[i];
                gSettings.manager.updatePreference(mapLayer.visibilityPreference, checkbox.checked);

            }
        }    
    }



    addMarkerForSearchResult(searchResult) {
        //no custom icon, just use the default map marker
        this.removeAllSearchResultMarkers(); //we'll remove all temporary search markers when adding a new one.
        var markerKey = searchResult.name;
        var snippet = this.getLocationSnippetForLocation(searchResult.location);

        var html = '<div class="temp-waypoint-popup">';
            html += '<div class="info-div">';
                html += '<div class="ihunter-menu-text small" style="padding-left: 5px;">' + markerKey + '</div>';
                html += '<div class="ihunter-menu-text small" style="padding-left: 5px;">' + snippet + '</div>';
            html += '</div>';
            html += '<div class="button-div" style="margin-top:5px; margin-bottom:5px; margin-left:5px; margin-right: 20px;">';
                html += '<button class="btn btn-primary" type="button" id="saveTempWaypointButton" onclick="onAddWaypointFromTempWaypoint(\''+ markerKey + '\', \''+ searchResult.location.lat + '\', \'' + searchResult.location.lng +'\')"' + '>Save</button>';
            html += '</div>';
        html += '</div>';


        //var marker = L.marker(searchResult.location, {icon: defaultIcon}).addTo(this.leafletMap).bindPopup(html);
        var marker = L.marker(searchResult.location).addTo(this.leafletMap).bindPopup(html);

        this.searchResults[markerKey] = marker;
    }

    removeAllSearchResultMarkers() {
        for(var key in this.searchResults) {
            if(Object.prototype.hasOwnProperty.call(this.searchResults, key)) {
                var marker = this.searchResults[key];
                this.leafletMap.removeLayer(marker);
                delete this.searchResults[key];
            }
        }
    }

    removeMarkerForMarkerKey(markerKey) {//searchResult.name == markerKey
        var marker = this.searchResults[markerKey];
        if(marker != null) {
            this.leafletMap.removeLayer(marker);
            delete this.searchResults[markerKey];
        }
    }

    onAddWaypointFromTempWaypoint(markerKey, lat, lon) {
        this.removeMarkerForMarkerKey(markerKey);
        window.onAddWaypoint(lat, lon, markerKey);
    }

    getLocationSnippetForLocation(latLng) {
        var currentLocation = this.currentLocation;
        if(currentLocation != null && currentLocation.lng != 0 && currentLocation.lat != 0) {
            var distanceInMeters = latLng.distanceTo(currentLocation);


            if(gSettings.useMetric()) {
                if(distanceInMeters < 1000) {
                    return distanceInMeters.toFixed(2) + "m away";
                } else {
                    return (distanceInMeters/1000).toFixed(2) + "km away"
                }
            } else {
                var distanceInMiles = distanceInMeters * 0.000621371192;
                if(distanceInMiles < 1) {
                    return (distanceInMiles * 1760).toFixed(2) + "yd away"; 
                } else {
                    return distanceInMiles.toFixed(2) + "mi away";
                }
            }
        } else {
            return getCoordinateString(latLng.lat, latLng.lng);
        }
    }

       
    getStringFromURL(url, onReadyFunction) {
        var ar = getAjaxRequest();  // The variable that makes Ajax possible!
        if(ar != null) {
            // Create a function that will receive data sent from the server
            ar.onreadystatechange = function () {
                if (ar.readyState == 4) {
                    onReadyFunction(ar.responseText);                             
                }
            }
      
            var phpFunction = IHUNTER_URL+"/getStringFromURL.php?path="+ url;
            ar.open("GET", phpFunction, true);
            ar.send(null);
        }
    }
}

