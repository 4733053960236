// IHUNTER_URL - location.href without parameters
function removeLastSlash(oldURL) {
    var newURL = oldURL;
    if(newURL.lastIndexOf("/") == newURL.length - 1) {
        newURL = newURL.substring(0, newURL.length - 1);
    }
    return newURL;
}

function removeAllParams(oldURL) {
    var index = 0;
    var newURL = oldURL;
    index = oldURL.indexOf('?');
    if(index == -1){
        index = oldURL.indexOf('#');
    }
    if(index != -1){
        newURL = oldURL.substring(0, index);
    }
    return newURL;
}

var url = window.location.href;
if(url.indexOf("?") !== -1) {
    url = removeAllParams(url);
    window.location.href = url; //refreshes without anything after the url
}

// NOTE: see FirebaseService.js for firebase enviroment definitions

export const IHUNTER_URL = removeLastSlash(url);
export function isProduction() {
  return (IHUNTER_URL.includes("web.ihunterapp.com") || IHUNTER_URL.includes("beta.ihunterapp.com"));
}

export function isLocal() {
    return (IHUNTER_URL.includes("localhost"));
}
// Used in development to request resources from the dev-server instead of localhost
// - good to test without local DB
// - violates CORS as-is
export const USE_DEV_SERVER_WHEN_LOCAL = true;
export const SERVICE_URL = (!USE_DEV_SERVER_WHEN_LOCAL || !isLocal()) ? IHUNTER_URL : "https://dev.ihunterapp.com";

export function openURLInNewTab(url) {
  var win = window.open(url, '_blank');
  if(win != null) {
      win.focus();
  }
  
}
