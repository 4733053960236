
import { getAjaxRequest } from './AjaxHelper';

export default class AppManifest {
    constructor() {
        this.manifest = null;

        this.load();
    }

    get regulations() {
        return this.regulationService;
    }

    get version() {
        return this.manifest.version;
    }


    load() {
        return new Promise((resolve, reject) => {
            var ajaxRequest = getAjaxRequest();
            if(ajaxRequest != null) {
                ajaxRequest.onreadystatechange = () => {
                    if(ajaxRequest.readyState === 4) {
                        if(ajaxRequest.status === 200) {
                            if(ajaxRequest.responseText){
                                this.manifest = JSON.parse(ajaxRequest.responseText);
                                resolve(this.manifest);
                            }                     
                        }

                        return reject("Manifest request failed");
                    }
                }
                ajaxRequest.open("GET", 'manifest.json', true);
                ajaxRequest.send(null);
            }else {

                return reject("Ajax request error");
            } 
        });
    }
}