export class Subscription {
    constructor(json, path) {
        this.path = path;
        this.name = json.name;
        this.sku_ios = json.sku;
        this.sku_android = json.sku_android;
        if(Object.prototype.hasOwnProperty.call(json, "sku_web")) {
            this.sku_web = json.sku_web;
        }
        
        this.headerDescription = json.header_description;
        this.moreInfo = json.more_info;
        this.moreInfoURL = json.more_info_url;
        this.features = json.features;
        //parse array of features
        this.disclaimer = Object.prototype.hasOwnProperty.call(json, 'disclaimer') ? json.disclaimer : '';
        this.populateUserGuide(Object.prototype.hasOwnProperty.call(json, 'userguide') ? json.userguide : null);
        this.clearCacheForOldVersions();
        this.storeCurrentCacheVersions();
    }

    populateUserGuide(array) {
        this.userGuideImageNames = new Array();
        this.userGuideText = new Array();
        if(array != null) {
            for (var i = 0; i < array.length; i++) {
                var jsonObject = array[i];
                this.userGuideImageNames.push(jsonObject.image);
                this.userGuideText.push(jsonObject.text);
            }
        }
    }

    getUserGuideCount() {
        return this.userGuideText.length;
    }

    getUserGuideImagePath(index) {
        return this.path+'/'+this.userGuideImageNames[index];
    }

    getUserGuideText(index) {
        return this.userGuideText[index];
    }

    getSearchableLayers() {

    }

    // isPurchased() { // is this used?
    //     /*if(!gSubscriptionSettings.SUBSCRIPTION_PURCHASED) {
    //         //gSubscriptionSettings.SUBSCRIPTION_PURCHASED = gSettings.isProductIdentifierPurchased();
    //         gSubscriptionSettings.SUBSCRIPTION_PURCHASED = true;
    //     }*/
    //     return gSubscriptionSettings.SUBSCRIPTION_PURCHASED;
    // }

    clearCacheForOldVersions() {

    }

    storeCurrentCacheVersions() {
        
    }
  
}

export default Subscription;
