import { Annotation, DRAW_TYPE, NO_ROTATION }  from './Annotation';


export class LineAnnotation extends Annotation {
    constructor(drawingView) {
        super(drawingView);
        this.type = DRAW_TYPE.LINE;
    }

    addPoint(pt) { 
        if(this.getPoints().length < 2) {
            this.polyline.addLatLng(pt); //not using yet, but may switch to this
        }
        else {
            while(this.getPoints().length > 1) {
                this.getPoints().pop();
                this.setPoints(this.getPoints());
            }
            super.addPoint(pt);

            this.updateMeasurementLabels();
        }
    }

    addLabels() {
        var measurementLabels = new Array();
        var points = this.getPoints();
        if (points.length == 2 && this.shouldShowDistLabels()) {
            this.measurementMarker = this.map.createMeasurementMarkerBetweenCoords(this.getPoints()[0], this.getPoints()[1], NO_ROTATION);
            measurementLabels.push(this.measurementMarker);
        }
        return measurementLabels;
    }

    getMinimumPoints() {
        return 2;
    }

    isRectangle() {
		return false;
	}

	isCircle() {
		return false;
	}

	isLine() {
		return true;
	}

	isFreehand() {
        return false;
	}
}

export default LineAnnotation;
