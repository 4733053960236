import { Annotation, ERASER_WIDTH_PIXELS, DRAW_TYPE, NO_ROTATION } from './Annotation';

import MapHelpers from './MapHelpers';
import L from 'leaflet';

export class CircleAnnotation extends Annotation {
    constructor(drawingView) {
        super(drawingView);
        this.type = DRAW_TYPE.CIRCLE;
        this.firstPoint = null;
        this.secondPoint = null;
        this.areaLabel = null;
    }

    addPoint(pt) { 
        if(this.firstPoint == null) {
            this.firstPoint = pt;
        } else {
            this.secondPoint = pt;
        }

        //this is just for drawing.
        if(this.firstPoint != null && this.secondPoint != null) {
            this.polyline.setLatLngs([]); //clears all points
            var midPoint = L.latLng((this.firstPoint.lat+this.secondPoint.lat)/2,(this.firstPoint.lng+this.secondPoint.lng)/2);
            var topPoint = L.latLng(Math.max(this.firstPoint.lat,this.secondPoint.lat),midPoint.lng);
            var rightPoint = L.latLng(midPoint.lat,Math.max(this.firstPoint.lng,this.secondPoint.lng));
            for(var angle = 0; angle <= 360; angle+=5) { //instead of drawing 360 points, lets draw a point at every 5th degree for performance reasons
                var lat = midPoint.lat + Math.abs(topPoint.lat - midPoint.lat) * Math.cos(Math.PI / 180 * angle);
                var lon = midPoint.lng + Math.abs(rightPoint.lng - midPoint.lng) * Math.sin(Math.PI / 180 * angle);
                super.addPoint([lat,lon]); //NW point
            }
        }
    }

    addLabels() {
        var measurementLabels = new Array();
        if(this.firstPoint != null && this.secondPoint != null && this.shouldShowAreaLabels()) {
            var lblText = MapHelpers.getLabelTextForAreaOfCircle(this.firstPoint, this.secondPoint); //area label
            var mpMid = L.latLng((this.firstPoint.lat + this.secondPoint.lat) / 2, (this.firstPoint.lng + this.secondPoint.lng) / 2);
            var mpRight = L.latLng(mpMid.lat,Math.max(this.firstPoint.lng,this.secondPoint.lng));
            var minZoomLevel = MapHelpers.getMinimumZoomLevelToCoverDistance(mpMid, mpMid.distanceTo(mpRight));
            this.areaLabel = this.map.addDrawnWaypointMeasurementMarker(lblText, mpMid, minZoomLevel, NO_ROTATION);
            measurementLabels.push(this.areaLabel);
        }
        return measurementLabels;
    }

    shouldShowAreaLabels() {
        if(this.waypoint) {
            return this.waypoint.showAreaLabel;
        }

        if(this.drawingView && !this.drawingView.waypoint) {
            return this.drawingView.bShowArea;
        }
        
        return false;
    }

    shouldShowElevation() {
        return false;
    }

    removeLabels() {
        if(this.areaLabel) {
            this.map.leafletMap.removeLayer(this.areaLabel);
        }
    }

    eraseAtPoint(erasePoint) {
        var points = this.getPoints();
        
        for(var i = 0; i < points.length; i++) {
            var existingPoint = points[i];

            while( i < points.length && this.distanceInPixels(this.map.leafletMap.latLngToLayerPoint(erasePoint),this.map.leafletMap.latLngToLayerPoint(existingPoint)) < ERASER_WIDTH_PIXELS/2) {

                this.setPoints([]);
                return;
            } 
        }
    }

    getMinimumPoints() {
        return 2;
    }

    isRectangle() {
		return false;
	}

	isCircle() {
		return true;
	}

	isLine() {
		return false;
	}

	isFreehand() {
    return false;
	}

    getXML() {
        if(this.firstPoint != null && this.secondPoint != null) {
            var ptString =  this.firstPoint.lng + ',' + this.firstPoint.lat + ',' + this.secondPoint.lng + ',' + this.secondPoint.lat;

            return '<annotation color="' + this.getColor() + '" opacity="1.0" thickness="' + this.getThickness() + '" type="' + this.type + '" points="' + ptString + '" elevations="' + this.elevationsString + '" elevationPoints="' + this.elevationPointsString + '" />';
        }
    }
}

export default CircleAnnotation;
