import L from 'leaflet';
import moment from 'moment';

import Observable from './Observable';

import UnknownContact from './UnknownContact';
import Contact from './Contact';
import { FB_LOCATION_ACCURACY } from './FirebaseAttributes';

const EVENT = {
  UPDATE_CONTACT_REQUEST: 'update-contact-request',
  UPDATE_CONTACT_IMAGE: 'update-contact-image',
  UPDATE_CONTACT: 'update-contact'
};

// Encapsulate contacts and contact requests
export default class ContactManager extends Observable {

  constructor(dataService, mapProvider) {
    super();
    
    this.service = dataService;
    this.map = mapProvider;

    this.contacts = [];
    this.incomingRequests = [];
    this.outgoingRequests = [];

    this.zoomToNextAddedBroadcastedLocation = false;

    this.onUpdateContactRequest = this.createHandlerMethod(EVENT.UPDATE_CONTACT_REQUEST);
    this.onUpdateContactImage = this.createHandlerMethod(EVENT.UPDATE_CONTACT_IMAGE);
    this.onUpdateContact = this.createHandlerMethod(EVENT.UPDATE_CONTACT);

  }

  initialize() {
    this.setupListeners();
  }

  setupListeners() {
    this.service.db.onCreateContact((contactJson) => {
        var bNewContact = true;
        var uid = contactJson.uid;
        var arrayLength = this.contacts.length;
  
        for (var i = 0; i < arrayLength; i++) {
          var contact = this.contacts[i];
          if(contact.uid === uid && !contact.isUnknownContact()) {
            this.updateContact(contact);
            bNewContact = false;
            break;
          }
        }
        if(bNewContact) {
          this.addContact(contactJson);
        }
      });
  
      this.service.db.onUpdateContact((contactJson) => {
        this.updateContact(contactJson);  
      });

      this.service.db.onUpdateContactLocation((uid, locationJson) => {
        this.updateContactLocation(uid, locationJson);  
      });
  
      this.service.db.onDeleteContact((contactJson) => {
        this.removeContact(contactJson);  
      });
  
      this.service.db.onCreateIncomingContactRequest((contactJson) => {
        this.addContactRequest(contactJson);  
      });
  
      this.service.db.onDeleteIncomingContactRequest((contactJson) => {
        this.removeContactRequest(contactJson);  
      });
  
      this.service.db.onCreateOutgoingContactRequest((contactJson) => {
        this.addOutgoingRequest(contactJson);  
      });
  
      this.service.db.onDeleteOutgoingContactRequest((contactJson) => {
        this.removeOutgoingRequest(contactJson);  
      });

      this.service.db.onSetContactStatus((uid, bIsOnline) => {
        this.setContactStatus(uid, bIsOnline);
      });

      this.service.db.onUpdateContactFrequency((snapshot, uid) => {
        this.updateContactFrequency(snapshot, uid);
      });

      this.service.db.onRemoveContactMarker((uid) => {
        this.removeContactMarker(uid);
      });
  }

  get user() {
      return this.service.user;
  }

  getIncomingContactRequestsArray() {
    return this.incomingRequests;
  }

  getOutgoingContactRequestsArray() {
      return this.outgoingRequests;
  }
 
  getContactsArray() {
    return this.contacts;
  }

  acceptBroadcastedLocationFromContactUID(contactUID) {
    this.zoomToNextAddedBroadcastedLocation = true;

    this.service.db.acceptBroadcastedLocationFromContactUID(contactUID);
  }

  createContact(jsonContact) {
      let contact = new Contact(jsonContact.uid, this.map);
      contact.initWithJson(this.service, this.map, jsonContact);
      contact.onUpdateContactImage((contact) => {
        this.emit(EVENT.UPDATE_CONTACT_IMAGE, contact);
      });
      return contact;
    }

    requestContact(contact) {

        for(var i = 0; i < this.incomingRequests.length; i++) {
            var contactRequest = this.incomingRequests[i];
            if(contactRequest.uid === contact.uid) {
                this.service.db.confirmContactWithUID(contact.uid);
                return;
            }
        }

        this.service.db.requestContact(contact);
    }

  // get incomingRequests() {
  //   return this.incomingRequests;
  // }

  // get outgoing() {
  //   return this.outgoingRequests;
  // }

  getContactForUID(uid) {
    var contactsLength = this.contacts.length;
    if(uid === this.service.userContact.uid) {
        return this.service.userContact;
    }
    for(var i = 0; i < contactsLength; i++) {
        var contact = this.contacts[i];
        if(contact.uid === uid) {
            return contact;
        }
    }
    return new UnknownContact(uid);
  }

  addContactRequest(contactJson) {
      var contact = new Contact(contactJson.uid, this.map);
      contact.initWithJson(this.service, this.mapProvider, contactJson);
      this.incomingRequests.push(contact);

      this.emit(EVENT.UPDATE_CONTACT_REQUEST);
      // if(gChatView) {
      //     gChatView.refreshChats();
      // }
  }

  removeContactRequest(contactJson) {
      var uid = contactJson.uid;
      this.removeContactWithUidFromArray(uid, this.incomingRequests);
      this.emit(EVENT.UPDATE_CONTACT_REQUEST);
      // if(gChatView) {
      //     gChatView.refreshChats();
      // }
  }

  addOutgoingRequest(contactJson) {
    if(contactJson !== null) {
      var contact = new Contact(contactJson.uid, this.map);
      contact.initWithJson(this.service, this.map, contactJson);
      this.outgoingRequests.push(contact);
      this.emit(EVENT.UPDATE_CONTACT_REQUEST);
      // if(gChatView) {
      //     gChatView.refreshChats();
      // }
    }
  }

  removeOutgoingRequest(contactJson) {
      var uid = contactJson.uid;
      this.removeContactWithUidFromArray(uid, this.outgoingRequests);
      this.emit(EVENT.UPDATE_CONTACT_REQUEST);
      // if(gChatView) {
      //     gChatView.refreshChats();
      // }
  }

  contactForUID(uid) {
      var arrayLength = this.contacts.length;
      for(var i = 0; i < arrayLength; i++) {
          var contact = this.contacts[i];
          if(contact.uid == uid) {
              return contact;
          }
      }
      return this.unknownContactWithUID(uid);
  }

  contactWithUidExists(uid, arrayOfContacts) {
      var arrayLength = arrayOfContacts.length;
      for(var i = 0; i < arrayLength; i++) {
          var contact = arrayOfContacts[i];
          if(contact.uid == uid) {
              return true;
          }
      }
      return false;
  }

  unknownContactWithUID(uid) {
      var unknownContact = new UnknownContact(uid);
      //this.contacts.push(unknownContact); //THIS SEEMS WRONG - LOOK INTO IT ON IOS AS WELL
      return unknownContact;
  }

  addContact(contactJson) {
      var contact = new Contact(contactJson.uid, this.map);
      contact.initWithJson(this.service, this.map, contactJson);
      contact.onUpdateContactImage((contact) => {
        this.emit(EVENT.UPDATE_CONTACT_IMAGE, contact);
      });
      if(this.contactWithUidExists(contact.uid, this.contacts) && this.contactForUID(contact.uid).isUnknownContact()) {
          this.removeContactWithUidFromArray(contact.uid, this.outgoingRequests);
      }

      this.contacts.push(contact);

      //update chats in case the contact didn't exist in group chat yet
      //if(this.chat.chatForChatKey(contact.chatKey)) {
        this.service.db.updateChatWithKey(contact.chatKey, this.service.user.uid);
      //}
      this.emit(EVENT.UPDATE_CONTACT, contact);
  }

  updateContact(contactJson) {
      console.log("UPDATING CONTACT");
      var uid = contactJson.uid;
      var arrayLength = this.contacts.length;
      for(var i = 0; i < arrayLength; i++) {
          var contact = this.contacts[i];
          if(contact.uid == uid) {
              contact.update(contactJson);
//              if(this.chat.chatForChatKey(contact.chatKey)) {
                  this.service.db.updateChatWithKey(contact.chatKey, this.service.user.uid);
  //            }
              return;
          }
      }
  }

  removeContact(contactJson) {
      var uid = contactJson.uid;
      this.removeContactWithUidFromArray(uid, this.contacts);
  }

  removeContactWithUidFromArray(uid, array) {
      var arrayLength = array.length;
      for(var i = 0; i < arrayLength; i++) {
          var contact = array[i];
          if(contact.uid === uid) {
              array.splice(i, 1); 
              return;
          }
      }
  }

  updateContactLocation(uid, locationJson) {
    var lat = locationJson["latitude"];
    var lon = locationJson["longitude"];
    var contact = this.getContactForUID(uid);
    var location = new L.LatLng(lat,lon);

    if(this.zoomToNextAddedBroadcastedLocation) {
        this.zoomToNextAddedBroadcastedLocation = false;
        this.map.zoomToLocation(location);
    }

    var time = moment.utc(locationJson["time"]).local();
    var accuracy = parseInt(locationJson[FB_LOCATION_ACCURACY]);

    if(contact.locationMarker.paused !== locationJson["paused"]) {
        contact.locationMarker.paused = locationJson["paused"];
        contact.locationMarker.setPin();
    }

    contact.locationMarker.setMarker(location, time, accuracy);
  }

  setContactStatus(uid, bIsOnline) {
    var contact = this.getContactForUID(uid);
    contact.online = bIsOnline;
    contact.locationMarker.setPin();
    contact.locationMarker.updatePopup();
  }

  updateContactFrequency(snapshot, uid) {
    var contact = this.getContactForUID(uid);
    if(contact) {
        contact.locationMarker.broadcastFrequency = parseInt(snapshot.val());
        contact.locationMarker.updatePopup(); 
    }
  }

  removeContactMarker(uid) {
    var contact = this.getContactForUID(uid);
    contact.removeMarker();
  }

}