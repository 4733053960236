import $ from 'jquery';

import CreateGroupView from './CreateGroupView';

export class AddContactsToGroupView extends CreateGroupView {
    constructor(modalView, chatManager, chat) {
        super(modalView, chatManager);

        this.chat = chat;

        this.populateContacts();
        this.appendContactsToHTML();

        this.selectedContacts = null;
    }


    saveAndClose() {
        // this.selectedContacts.push(gFirebaseHelper.getUserAsContact());
        this.chatManager.addContactsToChat(this.selectedContacts, this.chat);
    }

    checkSaveEnabled() {
        if(this.selectedContacts && this.selectedContacts.length >= 1) {
            $('#modalPrimaryButton').removeAttr("disabled"); //enable save button.
        } else {
            $('#modalPrimaryButton').attr("disabled", true); //disable save button.
        }
    }
}
export default AddContactsToGroupView;
