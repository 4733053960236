import $ from 'jquery';

import View from './View';

export default class HuntLogView extends View {
    constructor(dataService) {
        super();
        this.service = dataService;

        this.frame = null;
    }

    get header() {
        return "ABHuntLog";
    }

    //lets make each view have this function, and call it when the sidebar opens
    populatePage() {

        let el = document.getElementById('huntlog');
        el.setAttribute('src', '/huntlog/?v=' + Date.now())
        this.frame = this.getIframeWindow(el);
        if(!this.frame) {
            console.error("Failed to load HuntLog dashboard");
            return;
        }
        
        // Wait for iFrame to load, then refresh
        this.frame.addEventListener('load', (event) => {
            this.initDashboard();
            this.registerButtonHandlers();
            this.refreshDashboard();
        });
    }

    registerButtonHandlers() {
        $("#refreshButton").on('click', () => {
            this.refreshDashboard();
        });
    }

    async initDashboard() {
        try {
            if(!this.frame) {
                throw new Error("No HuntLog frame window found");
            }

            if(!this.frame.init(this.service.config.projectId)) {
                throw new Error("Init failure");
            }

        }catch (error) {
            console.error("Unable to initiate dashboard: " + error.message)
        }
    }

    async refreshDashboard() {
        try {
            if(!this.frame) {
                throw new Error("No HuntLog frame window found");
            }

            let token = await this.service.requestFirebaseToken();
            console.log("Firebase token granted");

            if(!this.frame.update(token)) {
                throw new Error("Authorization extension failed");
            }

        }catch (error) {
            console.error("Unable to refresh dashboard: " + error.message)
        }
    }

    getIframeWindow(iframe_object) {
        var doc;
      
        if (iframe_object.contentWindow) {
          return iframe_object.contentWindow;
        }
      
        if (iframe_object.window) {
          return iframe_object.window;
        } 
      
        if (!doc && iframe_object.contentDocument) {
          doc = iframe_object.contentDocument;
        } 
      
        if (!doc && iframe_object.document) {
          doc = iframe_object.document;
        }
      
        if (doc && doc.defaultView) {
         return doc.defaultView;
        }
      
        if (doc && doc.parentWindow) {
          return doc.parentWindow;
        }
      
        return undefined;
    }
}
