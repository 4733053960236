import $ from 'jquery';

var ajaxReqs = 0;
var ajaxQueue = [];
var ajaxActive = 0;
var ajaxMaxConc = 2;

export default function addAjax(obj) {
    ajaxReqs++;
    var oldSuccess = obj.success;
    var oldError = obj.error;
    var callback = function() {
        ajaxReqs--;
        if (ajaxActive === ajaxMaxConc && ajaxQueue>0) {
            $.ajax(ajaxQueue.shift());
        } else {
            ajaxActive--;
        }
    }
    obj.success = function(resp, xhr, status) {
        callback();
        if (oldSuccess) oldSuccess(resp, xhr, status);
    };
    obj.error = function(xhr, status, error) {
        callback();
        if (oldError) oldError(xhr, status, error);
    };
    if (ajaxActive === ajaxMaxConc && ajaxQueue>0) {
        ajaxQueue.push(obj);
    } else {
        ajaxActive++;
        $.ajax(obj);
    }
}
