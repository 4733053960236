import $ from 'jquery';
import _ from 'underscore';
import bootbox from 'bootbox';

import View from './View';

import { openModal } from './ModalHelper';
import { gSettings } from './Settings';

import { openModalURL } from './ModalHelper';
import { isProduction, openURLInNewTab } from './Environment';
import { FB_SHOW_BOUNDARY_ON_ZOOM } from './FirebaseAttributes';

import { createSwitchRowHTML } from './SettingsMenuView';


export class CountyMapsMenuView extends View {
    constructor(mapProvider, regulationManager) {
        super();

        this.map = mapProvider;
        this.map.onUpdateCountyMapsLayer((sku) => {
            this.updateCountyMap(sku);
        });

        this.regulationManager = regulationManager;

    }

    get header() {
        return "Landowner Maps";
    }

    populatePage() {
        this.addMapLayers();

        this.setupListeners();
    }
    
    setupListeners() {


        window.onPurchaseMap = (mapName) => {
            this.onPurchaseMap(mapName);
        };
        
        window.onShowBoundarySwitchClicked = (checkbox, mapName) => {
            this.map.setBoundaryVisibilityForCountyMap(mapName, checkbox.checked);
        };
        
        window.onLegendClicked = (mapName) => {
            openModalURL("Legend", this.map.countyMaps.getLegendURLForCountyMap(mapName));
        };
        
        window.onCopyrightClicked = (mapName) => {
            openModalURL("Copyright", this.map.countyMaps.getCopyrightURLForCountyMap(mapName));
        };
        
        window.onDisclaimerClicked = (mapName) => {
            openModal("Disclaimer", "All landowner maps are approximate, and should not be used as precise GPS property boundaries, but general guidelines. Landowner names were accurate as of the year listed, but may have changed since that date. Neither iHunter, nor the copyright holder that created the map, accept any responsibility for inaccuracies or incorrect information. Use and rely on the map at your own risk.");
        };

        window.onZoomToBoundsClicked = (mapName) => {
            var county = this.map.countyMaps.getCountyMapFromMapName(mapName);
            if(county != null) {
                if(gSettings.getPreference(FB_SHOW_BOUNDARY_ON_ZOOM)) {
                    $('#'+this.getIDPrefixForCountyMap(county)+'_showBoundary').prop('checked', true);
                    this.map.setBoundaryVisibilityForCountyMap(mapName, true);
                }
                this.map.zoomToBounds(county.bounds);
        
                this.map.removeHighlightedBoundaries();
                this.map.highlightBoundaryForMapLayer(this.map.getMapLayerForLayerID("county"), county.code);
        
                return;
            }
        };
        
        window.onEmailCounty = (email, countyName) => {

            if(this.map.provinceCode.toLowerCase() == "mb") {
                window.open('mailto:'+email+'?subject='+countyName+' Landowner Map Request');
            }
            else {
                var msgCountyName = countyName.includes(" of ") ? "the " + countyName : countyName;
            
                bootbox.alert("You are about to email "+msgCountyName+". Please remember to be professional and respectful to encourage the integration of their landowner map into iHunter.<br><br>Our intent is not to spam "+msgCountyName+", but contact from interested users may persuade them to agree to come on board.", 
                function(){ 
                    // var link = "mailto:"+email;
                    // + "&subject=" + escape(countyName+" Landowner Map Request");
                    // window.location.href = link;
                    window.open('mailto:'+email+'?subject='+countyName+' Landowner Map Request');
                });
            }
        };

        window.filterFunctionCounty = (inputID, contentID) => {
            this.filterFunctionCounty(inputID, contentID);
        };
    }

    addMapLayers() {   
        console.log("clearing the map list");
        $('#countyMapsMenuCollapsableList').empty();

        for(let mapName in this.map.countyMaps.purchasedMaps) {
            let county = this.map.countyMaps.getPurchasedMap(mapName);
            if(county != null) {
                this.addPurchasedMap(county);
            }
        }

        for(let mapName in this.map.countyMaps.purchasableMaps) {
            let county = this.map.countyMaps.getPurchasableMap(mapName);
            if(county != null) {
                this.addPurchasableMap(county);
            }
        }

        if(this.map.countyMaps.unavailableMaps != null && Object.keys(this.map.countyMaps.unavailableMaps).length > 0) {
            console.log("adding unavailable heading");
            this.createUnavailableMapsSection("unavailable-maps-heading", "Unavailable "+ this.map.province.getProperty("STR_COUNTIES"), '#countyMapsMenuCollapsableList');
        }
    }



    updateCountyMap(mapName) {
        this.addMapLayers();
    }

    getPurchasedContent(county) {
        var content = '<div class="collapsable-menu-content">';

        var updatableMap = this.map.countyMaps.getUpdatableMapForPurchasedMap(county.mapName);
        if(updatableMap != null) {
            content += this.getUpdateRow(county.mapName, updatableMap);
        }

        content += this.getMapOpacityRow(county);
        content += this.getShowBoundaryRow(county);
        content += this.getZoomToRow(county);
        content += this.getLegendRow(county);
        content += this.getCopyrightRow(county);
        content += this.getDisclaimerRow(county);
        content += '</div>';

        return content;
    }

    getUpdateRow(mapName, county) {
        return '<div class="ihunter-menu-row" onclick="onPurchaseMap(\''+ county.mapName +'\')" id="'+this.getIDPrefixForCountyMap(county)+'_updaterow"><div class="ihunter-menu-text" id="'+this.getIDPrefixForCountyMap(county)+'_updaterowtext">Update to ' + county.getMenuTitle()+'</div><img src="images/arrow_orange_right.png"/></div>';
    }

    addPurchasedMap(county) {
        var content = this.getPurchasedContent(county);

        //for now just going to fill it with a header and some empty content
        var headingTitle = '<span>'+county.getMenuTitle()+'<br/>' + '<span style="font-size:10px">'+county.getMenuSubtitle()+'</span>';
        this.createHeadingWithContentAndAppend(county, headingTitle, content, '#countyMapsMenuCollapsableList', true);

        $('#'+this.getIDPrefixForCountyMap(county)+'_input').slider({});
        $('#'+this.getIDPrefixForCountyMap(county)+'_input').on('slideStop', (event) => {
            var targetName = event.target.id;
            var mapName = targetName.substring(0,targetName.length - 6);
            this.map.service.database.setOpacityForCountyMap(mapName, event.value);
        });

        var outerThis = this;
        $('#'+this.getIDPrefixForCountyMap(county)+'-purchasedContent').on('show.bs.collapse', function (e) {
            if($(this).is(e.target)){
                outerThis.updateUpdatePrice(county.mapName);
            }
        })
    }

    updateUpdatePrice(mapName) {
        if(mapName.length > 0) {
            var updatableMap = this.map.countyMaps.getUpdatableMapForPurchasedMap(mapName);
            var county = this.map.countyMaps.getCountyMapFromMapName(mapName);
            if(updatableMap != null) {

                const sku = updatableMap.mapName;
                this.map.service.database.getProductPrice(sku).then((price) => {
                    var costText = ` ($${price})`; 
                    $('#'+this.getIDPrefixForCountyMap(county)+'_updaterowtext').text('Update to ' + updatableMap.getMenuTitle() + costText);
                }, (error) => {
                    console.error(`Failed to get product price: '${sku}'`,error);
                });
                

                // this.regulationManager.getPrice(updatableMap.mapName).then((jsonContent) => {
                //     var jsonObj = JSON.parse(jsonContent);
                //     if(Object.prototype.hasOwnProperty.call(jsonObj, "unit_amount")) {
                //         var cost = parseFloat(jsonObj.unit_amount) / 100;
                //         var costText = " ($"+cost.toFixed(2)+")"; 
                //         $('#'+this.getIDPrefixForCountyMap(county)+'_updaterowtext').text('Update to ' + updatableMap.getMenuTitle() + costText);
                //     }
                // }, function(err) {
                //     console.log(err);
                // });
            }
        }
    }

    scrollToAndOpenMap(countyMapName) {
        var purchasableHeader, purchasedHeader;

        $('#'+countyMapName+'-purchasableContent').collapse('show');
        purchasableHeader =  $('#'+countyMapName+'-purchasableHeader');
        if(purchasableHeader != null && purchasableHeader.length > 0) {
            _.delay(function () {
                purchasableHeader[0].scrollIntoView({behavior: "smooth"}); //must call on the DOM object, not jquery
            },500);
        }
        
        //handles if it's a purchased map
        $('#'+countyMapName+'-purchasedContent').collapse('show');
        purchasedHeader =  $('#'+countyMapName+'-purchasedHeader');
        if(purchasedHeader != null && purchasedHeader.length > 0) {
            _.delay(function () {
                purchasedHeader[0].scrollIntoView({behavior: "smooth"}); //must call on the DOM object, not jquery
            },500);
        }

        //handles unavailable maps
        purchasedHeader =  $('#'+countyMapName+'-unavilable-map');
        if(purchasedHeader != null && purchasedHeader.length > 0) {
            $('#unavailable-maps-heading').collapse('show');
            _.delay(function () {
                purchasedHeader[0].scrollIntoView({behavior: "smooth"}); //must call on the DOM object, not jquery
            },500);
        }
    }

    getMapOpacityRow(county) {
        return '<div class="ihunter-menu-row-nohover" id="'+this.getIDPrefixForCountyMap(county)+'_opacityrow"><div class="ihunter-menu-text" style="display:block;">Map Opacity:</div><input style="display: block; width:90%" id="'+this.getIDPrefixForCountyMap(county)+'_input" data-slider-id="'+this.getIDPrefixForCountyMap(county)+'_slider" type="text" data-slider-min="0" data-slider-max="1" data-slider-step="0.01" data-slider-value="'+county.opacity+'" data-slider-tooltip="hide"/></div>';
    }

    getShowBoundaryRow(county) {
        var onShowBoundaryClicked = 'onShowBoundarySwitchClicked(this,\''+county.mapName+'\')';
        return createSwitchRowHTML("Show Map Boundary:", county.showBoundary, this.getIDPrefixForCountyMap(county) + '_showBoundary', onShowBoundaryClicked);
    }

    getZoomToRow(county) {
        return '<div class="ihunter-menu-row" onclick="onZoomToBoundsClicked(\''+ county.mapName +'\')" id="'+this.getIDPrefixForCountyMap(county)+'_zoomtorow"><div class="ihunter-menu-text">Zoom to map bounds</div><img src="images/arrow_orange_right.png"/></div>';
    }

    getLegendRow(county) {
        return '<div class="ihunter-menu-row" onclick="onLegendClicked(\''+ county.mapName +'\')" id="'+this.getIDPrefixForCountyMap(county)+'_legendrow"><div class="ihunter-menu-text">Legend</div><img src="images/arrow_orange_right.png"/></div>';
    }

    getCopyrightRow(county) {
        return '<div class="ihunter-menu-row" onclick="onCopyrightClicked(\''+ county.mapName +'\')" id="'+this.getIDPrefixForCountyMap(county)+'_copyrightrow"><div class="ihunter-menu-text">Copyright</div><img src="images/arrow_orange_right.png"/></div>';
    }

    getDisclaimerRow(county) {
        return '<div class="ihunter-menu-row" onclick="onDisclaimerClicked(\''+ county.mapName +'\')" id="'+this.getIDPrefixForCountyMap(county)+'_disclaimerrow"><div class="ihunter-menu-text">Disclaimer</div><img src="images/arrow_orange_right.png"/></div>';
    }

    addPurchasableMap(county) {

        var content = '<div class="collapsable-menu-content">';
        content += this.getShowBoundaryRow(county);
        content += this.getZoomToRow(county);
        content += this.getPurchaseRow(county);
      
        content += '</div>';

        var headingTitle = '<span>'+county.getMenuTitle()+'<br/>' + '<span style="font-size:10px">'+county.getMenuSubtitle()+'</span>';
        this.createHeadingWithContentAndAppend(county, headingTitle, content, '#countyMapsMenuCollapsableList', false);
    
        var outerThis = this;
        $('#'+this.getIDPrefixForCountyMap(county)+'-purchasableContent').on('show.bs.collapse', function (e) {
            if($(this).is(e.target)){
                outerThis.updateBuyButton(county);
                outerThis.updateDescription(county);
            }
            console.log("open");
          })
    }

    updateDescription(county) {
        var self = this;
        if($('#'+self.getIDPrefixForCountyMap(county)+'_description').text().length == 0) {
            this.map.getCountyMapDescription(county.mapName).then(function(desc) {
                $('#'+self.getIDPrefixForCountyMap(county)+'_description').text(desc);
            }, function(err) {
                console.log(err);
            });
        }   
        //$('#'+mapName+'_description').text("Updated description text");
    }

    updateBuyButton(county) {
        var self = this;
        if(county != null) {


            const sku = county.mapName;
            this.map.service.database.getProductPrice(sku).then((price) => {
                var costText = `Purchase $${price}${self.getSaleBadge()}`;
                $('#'+self.getIDPrefixForCountyMap(county)+'_buyButton').html(costText);
            }, (error) => {
                console.error(`Failed to get product price: '${sku}'`,error);
            });
            

            // this.regulationManager.getPrice(county.mapName).then((jsonContent) => {
            //     var jsonObj = JSON.parse(jsonContent);
            //     if(Object.prototype.hasOwnProperty.call(jsonObj, "unit_amount")) {
            //         var cost = parseFloat(jsonObj.unit_amount) / 100;
            //         var costText = "Purchase $"+cost.toFixed(2)+self.getSaleBadge();
            //         $('#'+self.getIDPrefixForCountyMap(county)+'_buyButton').html(costText);
            //     }
            // }, function(err) {
            //     console.log(err);
            // });
        }
        else {
            $('#'+""+'_buyButton').text("Purchase");
        }
    }

    getPurchaseRow(county) {
        return '<div class="ihunter-menu-row-mappurchase" id="'+this.getIDPrefixForCountyMap(county)+'_purchaserow"><img src="images/landownership.png" style="width: 100px;"/><div class="ihunter-menu-text-mappurchase"><b>'+county.getMenuTitle()+'</b><br/>Land Ownership Map<br/><span id="'+this.getIDPrefixForCountyMap(county)+'_description"></span></div>'+this.getPurchaseButton(county)+'</div>';
    }

    getPurchaseButton(county) {    
        var html = '';// "<div>";
        html += '<button id="'+this.getIDPrefixForCountyMap(county)+'_buyButton" class="btn btn-primary" style="width:300px; margin-top: 20px" type="button" onclick="onPurchaseMap(\''+ county.mapName +'\')">';
        html += 'Purchase' + this.getSaleBadge();
        html += '</button>';
        return html;
    }

    getSaleBadge() {
        return '';// <span class="badge badge-danger ml-2">$2 off!</span>';
    }

    getOptionsRowForMapLayer(mapLayer) {
        var onclick = 'boundarySwitchClicked(this,\'' + mapLayer.layerID + '\')';
        //this will create the color style for the switch
        $("<style type='text/css'> input#"+mapLayer.layerID+"-switch:checked + .slider-switch { background-color:" + mapLayer.getColor() + "; } </style>").appendTo("head");
        return createSwitchRowHTML('Show '+mapLayer.name+':', mapLayer.shouldShow(), mapLayer.layerID + '-switch', onclick);
    }

    createHeadingWithContentAndAppend(countyMap, title, content, appendToDiv, bPurchased) {
        $(appendToDiv).append(this.createHeadingWithContent(countyMap, title, content, bPurchased));
    }

    getIDPrefixForCountyMap(countyMap) {
        if(countyMap != null) {
            return countyMap.mapName;//+"_"+countyMap.code
        }
        return "";
    }

    createHeadingWithContent(countyMap, title, content, bPurchased) {
        var headerID = this.getIDPrefixForCountyMap(countyMap)+(bPurchased ? '-purchasedHeader' : '-purchasableHeader');
        var contentID = this.getIDPrefixForCountyMap(countyMap)+(bPurchased ? '-purchasedContent' : '-purchasableContent');
        return this.createHeading(countyMap, headerID, contentID, title, bPurchased) + this.createContent(headerID, contentID, content, bPurchased); 
    }

    createHeading(countyMap, headerID, contentID, title, bPurchased) {

        var iconName = bPurchased ? "listicon_map_on_grey" : "listicon_map_off_grey";

        var updatableMap = this.map.countyMaps.getUpdatableMapForPurchasedMap(countyMap.mapName);

        var html = '';
        html += '<div class="county-row panel panel-default" style="position:relative;">';

        if(updatableMap != null) {
            // title = "<span>"+title+"</span> <span style='color:red; font-size:10px'>(Update Available)</span>";
            html += '<div class="ihunter-menu-new-badge">New</div>'
        }

        html +=   '<div class="county-searchable panel-heading-county" role="tab" id="'+headerID+'">'
        html +=     '<div style="position: absolute; top: 50%; transform: translateY(-50%);">'
        html +=     '<img src="images/'+iconName+'@2x.png" style="float: left; width: 30px; margin: 8px;"/>'
        html +=     '</div>';

        html +=     '<h4 class="panel-title" style="margin-left:26px;">';
        html +=       '<a id="'+headerID+'" class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#'+contentID+'" aria-expanded="true" aria-controls="'+contentID+'">'+title+'</a>'; 
        html +=     '</h4>';
        html +=   '</div>';
        return html;
    }

    createContent(headerID, contentID, content, bPurchased) {

        var html = '';
        html +=   '<div id="'+contentID+'" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="'+headerID+'">';
        html +=     '<div class="panel-body">'+content+'</div>';
        html +=   '</div>';
        html += '</div>';
        return html;
    }





    createUnavailableMapsSection(headerID, title, appendToDiv) {
        var content = this.getUnavailableMapsContent();

        $(appendToDiv).append(this.createUnavailableMapsHeadingWithContent(headerID, title, content));
    }
    
    createUnavailableMapsHeadingWithContent(headerID, title, content) {
        var contentID = 'unavailable-maps-content';
        return this.createUnavailableHeading(headerID, contentID, title) + this.createUnavailableContent(headerID, contentID, content);
    }
    
    createUnavailableHeading(headerID, contentID, title) {
    
        var iconName = "listicon_info_off_grey";
    
        var html = '';
        html += '<div class="panel panel-default">';
        html +=   '<div class="panel-heading" role="tab" id="'+headerID+'">'
        html +=     '<img src="images/'+iconName+'@2x.png" style="float: left; width: 30px; margin: 8px;"/>'
        html +=     '<h4 class="panel-title">'
        html +=       '<a id="'+headerID+'" class="" role="button" data-toggle="collapse" data-parent="#accordion" href="#'+contentID+'" aria-expanded="true" aria-controls="'+contentID+'">'+title+'</a>'; 
        html +=     '</h4>';
        html +=   '</div>';
        return html;
    }
    
    createUnavailableContent(headerID, contentID, content) {
    
        var html = '';
        html +=   '<div id="'+contentID+'" class="panel-collapse collapse in show" role="tabpanel" aria-labelledby="'+headerID+'">';
        html +=     '<div class="panel-body">'+content+'</div>';
        html +=   '</div>';
        html += '</div>';
        return html;
    }

    getUnavailableMapsContent() {
        var content = '<div class="collapsable-menu-content">';

        for (var m in this.map.countyMaps.unavailableMaps) {
            content += this.getUnavailableMapRow(this.map.countyMaps.unavailableMaps[m]);
        }
        content += '</div>';

        return content;
    }

    getUnavailableMapRow(countyMap) {
        var emailAddress = (this.map.provinceCode.toLowerCase() == "mb") ? "info@ihunterapp.com" : countyMap.email;


        var row = '<div id="'+this.getIDPrefixForCountyMap(countyMap)+'-unavilable-map'+'" class="county-row ihunter-menu-unavailable-map-row">';         
        row += '<div class="left-div">';
        row += '<div class="county-searchable ihunter-menu-text large" >' + countyMap.getMenuTitle() + '</div>';
        row += '<div class="county-searchable ihunter-menu-text small">' + countyMap.getMenuSubtitle() + '</div>';
        row += '</div>';
        row += '<div class="right-div">';
        row += '<div class="button-div" onclick="onZoomToBoundsClicked(\''+ countyMap.mapName +'\')">';
        row += '<img class="ihunter-left-img" src="images/zoom_icon@2x.png"/>'
        row += '<div>Zoom</div>'
        row += '</div>';
        row += '<div class="button-div" onclick="onEmailCounty(\''+ emailAddress +'\',\''+ countyMap.fullName +'\')">';
        row += '<img class="ihunter-left-img" src="images/email_icon@2x.png" style="padding-right:5px"/>'
        row += '<div>Email</div>'
        row += '</div>';
        row += '</div>';
        row += '</div>';
        return row;
    }

    async requestMapCheckout(sku) {
        // try {
        return await this.map.service.dal.checkout(sku);
        // }catch(error) {
        //     throw error;
        // }
    }

    async onPurchaseMap(mapName) {
        console.log("onPurchaseMap "+mapName);

        try {
            let response = await this.requestMapCheckout(mapName);
            openURLInNewTab(response.url);

        }catch(error) {
            console.error("Error:", error);
            alert("There was an error with the purchase of: "+mapName+".\n\nPlease try again or contact info@ihunterapp.com if this error continues.");
        }

        // var urlParams = new URLSearchParams({
        //     SKU:mapName,
        //     EMAIL:this.map.user.email,
        //     USER:this.map.user.uid,
        //     PROV:this.map.provinceCode,
        //     IS_SUB:'FALSE'
        // });
        // urlParams.append('TESTING', isProduction() ? 'FALSE' : 'TRUE');
        // console.log(urlParams.toString());
        // fetch("create-checkout-session.php?" + urlParams, 
        //     { method: "POST",}
        // )
        // .then(function (response) {
        //     // console.log(response.json());
        //     return response.json();
        // })
        // .then(function (session) {
        //     var testString = !isProduction() ? "&TESTING=TRUE" : "";
    
        //     if(session.id != undefined) {
        //         //we need to use a php script to redirect to, otherwise redirectToCheckout redirects our page.
        //         openURLInNewTab("redirect-to-checkout.php?sid='"+session.id+"'"+testString);
        //     } else {
        //         alert("There was an issue finding the product: "+mapName+".\n\nPlease try again or contact info@ihunterapp.com if this error continues.");
        //     }
        // })
        // .catch(function (error) {
        //     console.error("Error:", error);
        //     alert("There was an error with the purchase of: "+mapName+".\n\nPlease try again or contact info@ihunterapp.com if this error continues.");
        // });
    }
   

    
    filterFunctionCounty(inputID, contentID) {
        var input = document.getElementById(inputID);
        var filter = input.value.toUpperCase();
        var div = document.getElementById(contentID);
        var row = div.getElementsByClassName("county-row");
    
        for (var i = 0; i < row.length; i++) {
            var objects = row[i].getElementsByClassName("county-searchable");
            for(var k = 0; k < objects.length; k++) {
                var txtValue = objects[k].textContent || objects[k].innerText;// || objects[k].id ;
                if(txtValue.toUpperCase().indexOf(filter) > -1) {
                    row[i].style.display = "";
                    break;
                }
                else {
                    row[i].style.display = "none";
                }
            }
        }
    }


}


export default CountyMapsMenuView;
