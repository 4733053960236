import { gSettings } from './Settings';
import { FB_HUNTLOG_OPT_IN } from './FirebaseAttributes';

export class Province {

    constructor(provinceCode, provinceDictionary) {
        this.provinceCode = provinceCode;
        this.provinceDictionary = provinceDictionary;
    }

    getProperty(key) {
        return this.provinceDictionary[key];
    }

    hasCWDZones() {
        return this.getProperty("HAS_CWD_ZONES");
    }

    // hasLEHRegions() {
    //     return gLEHLayersPackage ? true : false;
    // }

    hasCWD(zone) {
        if(this.hasCWDZones()) {
            return this.isCWDZone(zone) || this.isCWDMuleZone(zone);
        }
        return false;
    }

    allowsBlackBearBaiting(wmu) {
        var zones = this.getProperty("BLACK_BEAR_BAITING_LIST");
        return this.isZoneInList(wmu, zones);
    }

    allowsBlackBearBaitingPartialZone(wmu) {
        var zones = this.getProperty("BLACK_BEAR_BAITING_LIST_PARTIAL_ZONE");
        return this.isZoneInList(wmu, zones);
    }

    hasSupplementalBlackBear(wmu) {
        var zones = this.getProperty("BLACK_BEAR_SUPPLEMENTAL_LIST");
        return this.isZoneInList(wmu, zones);
    }

    hasSupplementalAntlerless(wmu) {
        var firstOnlyZones = this.getProperty("SUPPLEMENTAL_ANTLERLESS_LIST_FIRST_ONLY");
        var firstOrSecondZones = this.getProperty("SUPPLEMENTAL_ANTLERLESS_LIST_FIRST_OR_SECOND");
        return this.isZoneInList(wmu, firstOnlyZones) || this.isZoneInList(wmu, firstOrSecondZones);
    }

    isSupplementalFirstOnly(wmu) {
        var zones = this.getProperty("SUPPLEMENTAL_ANTLERLESS_LIST_FIRST_ONLY");
        return this.isZoneInList(wmu, zones);
    }

    isCWDZone(wmu) {
        var zones = this.getProperty("CWD_ZONE_LIST");
        return this.isZoneInList(wmu, zones);
    }

    isCWDMuleZone(wmu) {
        var zones = this.getProperty("CWD_MULE_ZONE_LIST");
        return this.isZoneInList(wmu, zones);
    }

    isCWDVoluntaryZone(wmu) {
        var zones = this.getProperty("CWD_VOLUNTARY_ZONE_LIST");
        return this.isZoneInList(wmu, zones);
    }

    isBiologicalSampleRequestedForZone(wmu) {
        var zones = this.getProperty("BIOLOGICAL_SAMPLE_REQUESTED_FOR_ZONE_LIST");
        return this.isZoneInList(wmu, zones);
    }

    isAltNoticeZone(wmu) {
        var zones = this.getProperty("ALT_NOTIFICATION_ZONE_LIST");
        return this.isZoneInList(wmu, zones);
    }

    isZoneInList(wmu, listOfZones) {
        if(listOfZones != null) {
            for(var zone of listOfZones) {
                if(zone.includes("-")) {
                    var zones = zone.split("-");
                    var startRange = zones[0];
                    var endRange = zones[1]; 
                    if(startRange <= wmu && wmu <= endRange) {
                        return true;
                    }
                }

                if(wmu === zone) {
                    return true;
                }
            }
        }
        return false;
    }

    getBlackBearBaitingTitle() {
        return this.getProperty("BLACK_BEAR_CELL_TITLE");
    }

    getBlackBearBaitingSubtitle() {
        return this.getProperty("BLACK_BEAR_CELL_SUBTITLE").replace("%@", this.getProperty("STR_WMU"));
    }

    getPartialBlackBearBaitingSubtitle() {
        return this.getProperty("BLACK_BEAR_CELL_SUBTITLE_PARTIAL").replace("%@", this.getProperty("STR_WMU"));
    }

    getBlackBearSupplementalTitle() {
        return this.getProperty("BLACK_BEAR_SUPPLEMENTAL_CELL_TITLE");
    }

    getBlackBearSupplementalSubtitle() {
        return this.getProperty("BLACK_BEAR_SUPPLEMENTAL_CELL_SUBTITLE").replace("%@", this.getProperty("STR_WMU"));
    }

    getCWDTitle() {
        return this.getProperty("STR_CWD_CELL_TITLE");
    }

    getCWDSubtitle() {
        return this.getProperty("STR_CWD_CELL_SUBTITLE");
    }

    getCWDMuleSubtitle() {
        return this.getProperty("STR_CWD_CELL_SUBTITLE_MULE");
    }

    getBioSampleTitle() {
        return this.getProperty("STR_BRAIN_WORM_CELL_TITLE");
    }

    getBioSampleSubtitle() {
        return this.getProperty("STR_BRAIN_WORM_CELL_SUBTITLE");
    }


    getAltNoticeTitle() {
        return this.getProperty("ALT_NOTIFICATION_CELL_TITLE");
    }

    getAltNoticeSubtitle() {
        return this.getProperty("ALT_NOTIFICATION_CELL_SUBTITLE");
    }

    getSupplementalTitle() {
        return this.getProperty("STR_SUPPLEMENTAL_CELL_TITLE");
    }

    getSupplementalSubtitle() {
        return this.getProperty("STR_SUPPLEMENTAL_CELL_SUBTITLE").replace("%@", this.getProperty("STR_WMU"));
    }

    getFirstOnlySupplementalSubtitle() {
        return this.getProperty("STR_SUPPLEMENTAL_CELL_SUBTITLE_FIRST_ONLY").replace("%@", this.getProperty("STR_WMU"));
    }
}

export default Province;

export function getProvinceSelectionContent(provinces) {
  var content = '<div id="province-selection">';

  for (var i = 0; i < provinces.length; i++) {
      var provinceCode = provinces[i];
      if (gSettings.provinceCode == provinceCode) {
          //do nothing - this is the active province
      } else {
          content += '<div class="province" onclick="updateActiveProvince(\'' + provinceCode + '\')">iHunter <span>' + provinceCode.toUpperCase() + '</span></div>'
      }
  }
  content += '</div>'

  content += '<div id="faq-tutorials-container-bottom">'
  if(gSettings.getPreference(FB_HUNTLOG_OPT_IN)) {
    content += '<hr class="left-hr">'
    content += '<div class="province" onclick="onHuntLog()">ABHuntLog Dashboard</div>'
  }
  content += '<hr class="left-hr">'
  content += '<div class="province" onclick="showAppInfo()">App Info</div>'
  content += '<div class="province" onclick="onFAQs()">FAQs</div>'
  content += '<div class="province" onclick="onTutorials()">Tutorials</div>'
  content += '<div class="province" onclick="onEmailUs()">Email Us</div>'
  content += '<div class="province" onclick="onManageAccount()">Manage Account</div>'
  content += '<div class="province" onclick="logoutOfFirebase()">Logout</div>'
  content += '</div>'

  return content;
}
