import L from 'leaflet';

import MapHelpers from './MapHelpers';
import { getAjaxRequest } from './AjaxHelper';

export const ERASER_WIDTH_PIXELS = 40;
export const SAMPLING_FACTOR_SMALL = 50;
export const SAMPLING_FACTOR_MEDIUM = 100;
export const SAMPLING_FACTOR_LARGE = 200;
export const SAMPLING_FACTOR_XLARGE = 1000;
export const MAX_NUMBER_POINTS = 400.0000;

const DRAW_TYPE_UNKNOWN = -1; //use this for legacy waypoints that don't have a type included in the annotationXML
const DRAW_TYPE_FREEHAND = 0;
const DRAW_TYPE_LINE = 1;
const DRAW_TYPE_RECT = 2;
const DRAW_TYPE_CIRCLE = 3;

export const DRAW_TYPE = {
  UNKNOWN: DRAW_TYPE_UNKNOWN,
  FREEHAND: DRAW_TYPE_FREEHAND,
  LINE: DRAW_TYPE_LINE,
  RECT: DRAW_TYPE_RECT,
  CIRCLE: DRAW_TYPE_CIRCLE,
};
export const NO_ROTATION = 0;

export class Annotation {

    constructor(drawingView) {
        this.drawingView = drawingView;

        this.polyline = L.polyline([], {interactive: false});
        // this.multiColorPolyline = L.multiOptionsPolyline([], {interactive: false});
        this.setThickness(5);
        this.measurementMarker = null;
        this.type = DRAW_TYPE_FREEHAND;
        this.waypoint = null;
        this.elevationsString = "";
        this.elevations = null;
        this.elevationPointsString = "";
        this.elevationPoints = null;

        this.lastPoint = null;
    }

    get map() {

        if(!this.drawingView || !this.drawingView.map) {
            console.log(this);
        }
        return this.drawingView.map;
    }

    addPointRaw(pt) {
        this.addPoint(pt);
    }

    addPoint(pt) { 
        
        // Prevent superfluous duplicate points for freehand annotations
        if(this.isFreehand() && 
            this.lastPoint && (this.lastPoint.lat === pt.lat && this.lastPoint.lng === pt.lng)) {
            return;
        }

        this.polyline.addLatLng(pt);
        this.lastPoint = pt;
    }

    setColor(color) {
        this.polyline.setStyle({
            color: color
        });
        this.color = color;
    }

    getColor() {
        return this.color;   
    }

    setThickness(thickness) {
        this.polyline.setStyle({
            weight: thickness
        });
        this.thickness = thickness;
    }

    getThickness() {
        return this.thickness;
    }

    getPoints() {
        if(this.polyline == null) {
            return null;
        }
        return this.polyline.getLatLngs();
    }

    setPoints(points) {
        this.polyline.setLatLngs(points);
    }

    setMultiColorPolyline(featureGroup) {
        if(this.elevationPoints != null && this.elevationPoints.length > 0) {
            var i = 0;
            var points = new Array();
            if(this.multiColorPolyline != null) {
                if(featureGroup != null && featureGroup.hasLayer(this.multiColorPolyline)) {
                    console.log("Removing previous multiColorPolyline");
                    featureGroup.removeLayer(this.multiColorPolyline);
                }
                this.multiColorPolyline = null
            }
            
            while(i < this.elevationPoints.length) {
                var point = this.elevationPoints[i];
                points.push(new L.LatLng(point.lat, point.lng, this.elevations[i]))
                i++;
            }
            
            //points should now have elevaion info! We also could not store 2 separate arrays on the web app since lat lng handles elevations!
            this.multiColorPolyline = L.multiOptionsPolyline(points, {
                multiOptions: {
                    optionIdxFn: function (latLng, prevLatLng) {
                        var i = 0;
                        var grade;
                        var gradeThresholds = [1.5, 3, 4.5, 6, 7.5, 9, 10.5, 12, 13.5, 15, 16.5, 18, 19.5, 21, 22.5];
                        var distance = latLng.distanceTo(prevLatLng);
                        grade = Math.abs(((prevLatLng.alt - latLng.alt) / distance) * 100);
            
                        for (i = 0; i < gradeThresholds.length; i++) {
                            if (grade <= gradeThresholds[i]) {
                                return i;
                            }
                        }
                        return gradeThresholds.length;//return 15 (red) if wasn't found
                    },
                    options: [
                        {color: '#00EE00'}, {color: '#55EE00'}, {color: '#77EE00'}, {color: '#BAEE00'},
                        {color: '#FFEE00'}, {color: '#FFDD00'}, {color: '#FFCC00'}, {color: '#FFBB00'},
                        {color: '#FFAA00'}, {color: '#FF9900'}, {color: '#FF8800'}, {color: '#FF7700'},
                        {color: '#FF6600'}, {color: '#FF4400'}, {color: '#FF2200'}, {color: '#FF0000'},
                    ]
                },
                weight: this.getThickness(),
                lineCap: 'round', // could use 'square' too
                opacity: 1,
                smoothFactor: 1}); //could also use 1.0

            if(featureGroup && !featureGroup.hasLayer(this.multiColorPolyline)) {
                console.log("Adding multiColorPolyline to featureGroup");
                featureGroup.addLayer(this.multiColorPolyline);
            }
        }
    }

    eraseAtPoint(erasePoint, featureGroup, annotations, annotationIndex) {
        var points = this.getPoints();
        for(var i = 0; i < points.length; i++) {
            var existingPoint = points[i];
            var bRemovedPoints = false;

            //erase all points within the eraser
            while( i < points.length && this.distanceInPixels(this.map.leaflet.latLngToLayerPoint(erasePoint),this.map.leaflet.latLngToLayerPoint(existingPoint)) <= ERASER_WIDTH_PIXELS/2) {

                bRemovedPoints = true;
                points.splice(i, 1);
                if(i < points.length) {
                    existingPoint = points[i];
                }
            }
            
            //the line was potentially split, so we need to create another annotation
            if(bRemovedPoints) {
                var a = new Annotation(this.drawingView);
                a.setColor(this.color);
                a.setThickness(this.thickness);
                while(i < points.length) {
                    a.addPoint(points[i]);
                    points.splice(i, 1);
                }
                
                this.updateMeasurementLabels();
                this.setPoints(points);

                let newAnnotations = [];
                newAnnotations.push(a);
                return newAnnotations;
            }            
        } 
    }

    distanceInPixels(p1, p2) {
        var dx = p2.x - p1.x;
        var dy = p2.y - p1.y;
        return Math.sqrt(dx * dx + dy * dy);
    }

    updateMeasurementLabels() {
        this.removeLabels();
        return this.addLabels();
    }

    //lots of these functions are shared with iHunterMap...look into reuse.
    addLabels() {
        var measurementLabels = new Array();
        if(this.shouldShowDistLabels()) {
            var points = this.getPoints();
            if(points.length > 1) {
                var distAlongPath = MapHelpers.distAlongPath(points);
                var lblText = MapHelpers.getLabelTextForDistAlongPath(distAlongPath);
                var mpMid = MapHelpers.getMidCoordAlongPath(points, distAlongPath);
                var minZoomLevel = MapHelpers.getMinimumZoomLevelToCoverDistance(mpMid, distAlongPath);
                this.measurementMarker = this.map.addDrawnWaypointMeasurementMarker(lblText, mpMid, minZoomLevel, NO_ROTATION);
                measurementLabels.push(this.measurementMarker);
            }
        }
        return measurementLabels;
    }

    shouldShowDistLabels() {

        if(this.waypoint) {
            return this.waypoint.showDistLabel;
        }

        if(this.drawingView && !this.drawingView.waypoint) {
            return this.drawingView.bShowDist;
        }
        
        return false;
    }

    shouldShowAreaLabels() {
        return false;
    }

    shouldShowElevation() {

        if(this.waypoint) {
            return this.waypoint.showElevation;
        }

        if(this.drawingView && !this.drawingView.waypoint) {
            return this.drawingView.bShowElevation;
        }
        
        // if((this.drawingView && !this.drawingView.waypoint && this.drawingView.bShowElevation) || (this.waypoint && this.waypoint.showElevation)) {
        //     return true;
        // }
        return false;
    }

    removeLabels() {
        if(this.measurementMarker) {
            this.map.leafletMap.removeLayer(this.measurementMarker);
        }
    }

    remove() {
        this.removeLabels();
        this.polyline = null;
    }

    getXML() {
        var points = this.getPoints();
        if(points.length > 0) {
            var ptString = '';
            for(var i = 0; i < points.length; i++) {
                var pt = points[i];
                if(i == 0) {
                    ptString += pt.lng + ',' + pt.lat;
                } else {
                    ptString += ',' + pt.lng + ',' + pt.lat;
                }
            }

            return '<annotation color="' + this.getColor() + '" opacity="1.0" thickness="' + this.getThickness() + '" type="' + this.type + '" points="' + ptString + '" elevations="' + this.elevationsString + '" elevationPoints="' + this.elevationPointsString + '" />';
        }
    }

    resetElevations(featureGroup) {
        this.elevationsString = "";
        this.elevations = null;
        this.elevationPointsString = "";
        this.elevationPoints = null;
        if(this.multiColorPolyline != null) {
            if(featureGroup != null) {
                featureGroup.removeLayer(this.multiColorPolyline);
            } 
            this.multiColorPolyline.remove();
            this.multiColorPolyline = null;
        }
    }

    queryElevations(featureGroup) {
        var self = this;
        return new Promise(function(resolve, reject) {
            if(self != null && self.getPoints() != null && self.getPoints().length > 1) {
                self.resetElevations();
                self.elevationPoints = self.getElevationPoints(self.getPoints());
                var numberOfPoints = self.elevationPoints.length;
                var numberOfAPICalls = Math.ceil(numberOfPoints / MAX_NUMBER_POINTS);
                var results = Array(numberOfAPICalls);
            
                for(var k = 0; k < numberOfAPICalls; k++) {
                    var locations = "";
                    var index;
                    for(var i = 0; i < numberOfPoints && i < MAX_NUMBER_POINTS; i++) {
                        index = k*MAX_NUMBER_POINTS + i;
                        if(index >= numberOfPoints) {
                            break;
                        }
                        locations += (locations.length == 0) ? "" : "|";
                        var pt = self.elevationPoints[index];
                        locations += pt.lat.toFixed(5) + "," + pt.lng.toFixed(5);
                    }
                    results[k] = self.getElevationsFromAPI(locations);
                }
        
                Promise.all(results).then(function(returnVals) { 
                    if(returnVals != null) {
                        self.populateElevations(returnVals, featureGroup);
                        if(self.waypoint != null) {
                            resolve(self);
                        }  
                        resolve(null);
                    }
                });
            } else {
                resolve(null);
            }
        });
    }

    getElevationsFromAPI(locations) {
        var script = "getElevationInformation.php";
    
        return new Promise(function(resolve, reject) {
            var ajaxRequest = getAjaxRequest();  // The variable that makes Ajax possible!
            if(ajaxRequest != null) {
                ajaxRequest.onreadystatechange = function () {
                    if (ajaxRequest.readyState == 4) {
                        if(ajaxRequest.status === 200) {
                            if(ajaxRequest.responseText.includes("\"status\":\"OK\"")){
                                resolve(ajaxRequest.responseText);
                            } 
                            else {
                                resolve(null);
                                console.log("Issue with getElevationsFromAPI");
                            }
                        } else {
                            reject(null);
                            console.log("failed ajax response in getElevationsFromAPI");
                        }
                    }
                }
    
                ajaxRequest.open("GET", script + "?locations=" + locations, true);
                ajaxRequest.send(null);
            }
        });
    }

    populateElevations(results, featureGroup) {
        var elevationsArray = new Array();
        var elevationPointsArray = new Array();
        if(results != null) {
            for(var k = 0; k < results.length; k++) {
                var elevationsJson = results[k];
                if (elevationsJson != null && elevationsJson.length > 0) {
                    try {
                        var jObject = JSON.parse(elevationsJson);
                        var jArray = jObject.results;
                        for (var i = 0; i < jArray.length; i++) {
                            try {
                                var elevationPoint = jArray[i];
                                var elevation = elevationPoint.elevation;
                                elevationsArray.push(elevation);
                                var lat = elevationPoint.location.lat;
                                var lon = elevationPoint.location.lng;
                                elevationPointsArray.push(L.latLng(lat, lon));
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            }
            this.elevations = elevationsArray;
            this.elevationsString = this.elevations.toString();
            this.elevationPoints = elevationPointsArray;
            this.elevationPointsString = this.elevationPointsToString(this.elevationPoints);
            this.setMultiColorPolyline(featureGroup);
            
            // if (this.existingWaypoint != null && this.view != null) {
            //     //I think we need this to update the annotationXML if we switch on elevations and then cancel.
            //     String xml = DrawnWaypoint.getXMLForAnnotations(((DrawingViewLayout) view).annotations); //this.annotation will now contain elevation info too
            //     WMUSettingsFactory.getSettings(null).updatedAnnotationForDrawnWaypointWithUUID(this.existingWaypoint.uuid, xml);
            // } else if (this.existingWaypoint != null) {
            //     console.log("updated annotation for waypoint");
            //     String xml = DrawnWaypoint.getXMLForAnnotations(this.existingWaypoint.getAnnotations()); //this.annotation will now contain elevation info too
            //     WMUSettingsFactory.getSettings(null).updatedAnnotationForDrawnWaypointWithUUID(this.existingWaypoint.uuid, xml);
            // }
        }
    }

    getElevationPoints(originalPoints) {
        var elevationPoints = new Array();
        if(originalPoints != null) {
            var numOriginalPoints = originalPoints.length;
            if(numOriginalPoints > 1) {
                var pathLength = MapHelpers.distAlongPath(originalPoints);
                for(var i = 0; i < (numOriginalPoints - 1); i++) {
                    var pt1 = originalPoints[i];
                    var pt2 = originalPoints[i+1];
                    this.compareAndAddPoints(elevationPoints, pathLength, pt1, pt2);
                }
                elevationPoints.push(originalPoints[numOriginalPoints-1]);
            }
        }
        return elevationPoints;
    }

    compareAndAddPoints(elevationPoints, pathLengthInMeters, pt1, pt2) {
        elevationPoints.push(pt1);
        var distanceBetween = pt1.distanceTo(pt2);
        var samplingFactor = this.getSamplingFactorForPathLength(pathLengthInMeters);
    
        if(distanceBetween > samplingFactor) {
            var newPoint;// = L.latLng
            if(distanceBetween <= (samplingFactor*2)) {
                newPoint = MapHelpers.getCoordAtDistance(distanceBetween/2, pt1, pt2);
            } else {
                newPoint = MapHelpers.getCoordAtDistance(samplingFactor, pt1, pt2);
            }
            this.compareAndAddPoints(elevationPoints, pathLengthInMeters, newPoint, pt2);
        }
    }

    getSamplingFactorForPathLength(pathLengthInMeters) {
        if(pathLengthInMeters > 50000) {
            return SAMPLING_FACTOR_XLARGE;
        } else if(pathLengthInMeters > 10000) {
            return SAMPLING_FACTOR_LARGE;
        } else if(pathLengthInMeters > 5000) {
            return SAMPLING_FACTOR_MEDIUM;
        }
        return SAMPLING_FACTOR_SMALL;
    }

    elevationPointsToString(pointsArray) {
        var ptString = "";
        for(var i = 0; i < pointsArray.length; i++) {
            var pt = pointsArray[i];
            if(i == 0) {
                ptString = ptString + pt.lat + "," + pt.lng;
            } else {
                ptString = ptString + "," + pt.lat + "," + pt.lng;
            }
        }
        return ptString;
    }

    getMinimumPoints() {
        return 1;
    }

    isRectangle() {

		return false;
	}

	isCircle() {

		return false;
	}

	isLine() {

		return false;
	}

	isFreehand() {

        return true;
	}
    
}

export default Annotation;
