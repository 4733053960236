import $ from 'jquery';
import _ from 'underscore';
import Chart from 'chart.js';

import { gSettings } from './Settings';

import WaypointView from './WaypointView';
import TrackedWaypoint from './TrackedWaypoint';

const GPS_TRACKING_FILTER_DISTANCE = 45.0;

export class TrackedWaypointView extends WaypointView {
    constructor(waypointManager, uuid) {
        super(waypointManager, uuid);
    }

    get header() {
        return "Tracked Waypoint";
    }

    get footer() {
        return '<div class="d-flex justify-content-center" style="width:100%;"><button class="btn btn-primary" type="button" id="drivingDirectionsButton" style="margin-right: 10px;margin-left: 10px;"><i class="fa fa-map-signs"></i>&nbsp;Driving Directions</button><button class="btn btn-primary" type="button" id="editTrackedWaypointButton"style="margin-right: 10px;margin-left: 10px;"><i class="fa fa-edit"></i>&nbsp;Edit</button></div>';
    }

    get WaypointType() {
        return TrackedWaypoint;
    }

    //lets make each view have this function, and call it when the sidebar opens
    populatePage() {
        this.populateSharedContent(); 
        
        //Statistics and Graphs
        this.updateStatistics();

        $("#editTrackedWaypointButton").on('click', () => {
            this.waypointManager.showEditTrackedWaypoint(this.waypoint);
        });

        $("#drivingDirectionsButton").on('click', () => {
            this.waypointManager.showNavigateToWaypoint(this.waypoint);
        });
    }
    
    updateStatistics() {
        var latLonAnnotation = this.waypoint.getAnnotation(this.waypoint.annotationXML); //

        var startTime = latLonAnnotation.locations[0].time/1000;
        var endTime = latLonAnnotation.locations[latLonAnnotation.locations.length - 1].time/1000;
     
        var speedDataPoints = [];
        var altitudeDataPoints = [];
        var xLabels = [];

        var simplifyValue = Math.max(Math.round(latLonAnnotation.locations.length/100), 1);
        for(var i = simplifyValue; i < latLonAnnotation.locations.length - (simplifyValue-1); i+=simplifyValue) {
            var oldLocation = latLonAnnotation.locations[i-simplifyValue];
            var currentLocation = latLonAnnotation.locations[i];
            let dist = (currentLocation.getLocation()).distanceTo(oldLocation.getLocation());
                        
            xLabels.push(Math.round(currentLocation.time / 1000 - startTime));
            speedDataPoints.push( (currentLocation.speed + oldLocation.speed)/2 );
            altitudeDataPoints.push( currentLocation.altitude );
        }
        
        this.createGraph(speedDataPoints, xLabels, endTime, "#chartContainerVelocity", 'Velocity (m/s)', 'Time (s)');
        this.createGraph(altitudeDataPoints, xLabels, endTime, "#chartContainerAltitude", 'Altitude (m)', 'Time (s)');

        //Lets get the actual distance - the simplified distance is off because it doesn't take all of the points.
        var count = 0;
        var distanceInMeters = 0;
        var altitudeTotal = 0;
		for (let i = 1; i < latLonAnnotation.locations.length; i++) {
			var oldLoc = latLonAnnotation.locations[i-1];
			var curLoc = latLonAnnotation.locations[i];
			if(curLoc.horizontalAccuracy < GPS_TRACKING_FILTER_DISTANCE) {
				count++;
        let dist = (curLoc.getLocation()).distanceTo(oldLoc.getLocation());
                distanceInMeters += Math.round(Math.abs(dist) * 100.0) / 100.0;
                altitudeTotal += curLoc.altitude;
			}
		}

        var durationInSeconds = endTime-startTime;
        var mps = distanceInMeters / durationInSeconds;
        if(gSettings.useMetric()) {
            $('.sidebar #TrackedWaypointView_distance').html(this.roundTwoDecimals(distanceInMeters) + ' m');
            $('.sidebar #TrackedWaypointView_speed').html(this.roundTwoDecimals(mps * 3.6) + ' k/h (' + this.roundTwoDecimals(mps) + ' m/s)');
            $('.sidebar #TrackedWaypointView_altitude').html(this.roundTwoDecimals(altitudeTotal/count) + ' m (average)');
        } else {
            $('.sidebar #TrackedWaypointView_distance').html(this.roundTwoDecimals(distanceInMeters * 1.09361) + ' yd');
            $('.sidebar #TrackedWaypointView_speed').html(this.roundTwoDecimals(mps * 3.6 * 0.621371) + ' mph (' + this.roundTwoDecimals(mps * 1.09361) + ' yd/s)');
            $('.sidebar #TrackedWaypointView_altitude').html(this.roundTwoDecimals((altitudeTotal * 1.09361)/count) + ' yd (average)');
        }
        if(durationInSeconds > 60) {
            $('.sidebar #TrackedWaypointView_duration').html(Math.round(durationInSeconds/60) + ':' + this.roundTwoDecimals(durationInSeconds % 60) + ' min' );
        } else {
            $('.sidebar #TrackedWaypointView_duration').html(this.roundTwoDecimals(durationInSeconds) + ' s');
        }
    }

    roundTwoDecimals(n) {
        return Math.round(n * 100) / 100;
    }

    createGraph(dataPoints, xLabels, endTime, chartID, yLabel, xLabel) {
        var canvas = $(chartID).get(0);
        var ctx = canvas.getContext('2d');
        var fillPattern = ctx.createLinearGradient(0, 0, 0, 200);
        fillPattern.addColorStop(0, '#d92121');
        fillPattern.addColorStop(1, '#ffffff');

        var speedChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: xLabels,
                datasets: [{
                    borderColor: '#d92121',
                    backgroundColor: fillPattern,
                    pointRadius: 0,
                    data: dataPoints
                }],
            },            
            options : {
                title: {
                    display:false
                    // text: 'Velocity vs. Time'
                },
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                            display:true,
                            scaleLabel: {
                                display: true,
                                labelString: yLabel
                            },
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 10,
                                autoSkip: true
                            }
                    }],
                    xAxes: [{
                            display:true,
                            scaleLabel: {
                                display: true,
                                labelString: xLabel
                            },
                            ticks: {
                                beginAtZero: true,
                                autoSkip: true,
                                maxTicksLimit: 10,
                                max: endTime
                            }
                        
                    }]
                },
            }
        });
    }
}

export default TrackedWaypointView;
