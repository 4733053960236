import $ from 'jquery';
import firebase from 'firebase';

export class Chat {
    constructor(chatManager) {
      this.chatManager = chatManager;
        this.name = "";
        this.chatkey = "";
        this.activeParticipants = new Array();
        this.participants = new Array();

        this.photoUrl = "";
        this.lastMessage = "";
        this.lastMessageDate = null;
        this.lastCleared = null;
        this.chatPicDateString = "";
        this.bIsGroupChat = false;
        this.messages = new Array();
        this.numNewMessages = 0;
        // this.getChatPictureUrl();
    }

    populateForGroupChat(userId, chatKey, chatNode) {
        this.chatkey = chatKey;
        this.bIsGroupChat = true;
        this.name = chatNode.chatname;
        if(Object.prototype.hasOwnProperty.call(chatNode, "profilePicDate")) {
            this.profilePicDate = chatNode.profilePicDate;
        }
        this.participants = this.getParticipantsFromParticipantsJson(chatNode.participants,false);
        this.activeParticipants = this.getParticipantsFromParticipantsJson(chatNode.participants,true);
        var clearedDates = chatNode.lastcleared;
        var clearedDate = clearedDates[userId];
        this.lastCleared = clearedDate;
        this.lastMessageDate = this.lastcleared;
        this.getChatPictureUrl();
    }

    populateForSoloChat(userId, chatKey, chatNode) {
        var participants = chatNode.participants;
        for(var participantUid in participants) {
            if(participantUid !== userId) {
                var contact = this.chatManager.getContactForUID(participantUid);
                if(contact) {
                    this.chatkey = chatKey;
                    this.bIsGroupChat = false;
                    this.name = contact.username;
                    this.participants = this.getParticipantsFromParticipantsJson(chatNode.participants,false);
                    this.activeParticipants = this.getParticipantsFromParticipantsJson(chatNode.participants,true);
                    var clearedDates = chatNode.lastcleared;
                    var clearedDate = clearedDates[userId];
                    this.lastCleared = clearedDate;
                    this.lastMessageDate = this.lastcleared;
                    // this.getChatPictureUrl()
                    break;
                }
            }
        }
    }

    //this gets used when someone first accepts your contact request. If the menu is open, the chat shows up as "Unknown Contact" otherwise.
    updateChatWithContact(contact) {
        if(contact) {
            this.bIsGroupChat = false;
            this.name = contact.username;
            this.refreshPicture();
        }
    }

    getParticipantsFromParticipantsJson(participants, bActiveParticipantsOnly) {
        var chatContacts = new Array();
       
        for(var participantUid in participants) {
            if(Object.prototype.hasOwnProperty.call(participants, participantUid)) {
                if(!bActiveParticipantsOnly || participants[participantUid]) {
                    if(participantUid === this.chatManager.user.uid) {
                        chatContacts.push(this.chatManager.service.userContact);    
                    }
                    else {
                        chatContacts.push(this.chatManager.getContactForUID(participantUid));
                    }
                }
            }
        }
        return chatContacts;
    }

    updateWithChat(chat) {
        this.participants = chat.participants;
        this.activeParticipants = chat.activeParticipants;
        this.name = chat.name;
        // this.photoUrl = chat.photoUrl;
        // if(chat.chatPicDateString != this.chatPicDateString) {
        //     selthisf.chatPicDateString = chat.chatPicDateString;
        //     this.setChatPicture();
        // }
        this.lastCleared = chat.lastCleared;

        //if we update a chat, we should also attempt to update the rows that are displaying it...
        if(this.chatManager.chatView) {
          this.chatManager.chatView.replaceInnerHTMLForChatRow(this);
        }
    }

    getChatPictureUrl() {
        var self = this;
        if(!this.photoUrl || this.photoUrl === "") {
            if(this.bIsGroupChat) {
                var storageRef = firebase.storage().ref();
                storageRef.child('/users/'+this.chatkey+'/UserProfilePic.jpg').getDownloadURL().then(function(url) {
                    self.photoUrl = url;
                    return self.photoUrl;
                }).catch(function(error) {
                    self.photoUrl = 'images/noProfilePic.jpg';
                    return self.photoUrl;
                });

            } else {
                this.participants.forEach((contact) => {
                    if(contact.uid !== this.chatManager.user.uid) {
                        self.photoUrl = contact.photoUrl;
                        return self.photoUrl;
                    }
                });
            }
        }
        return this.photoUrl ? this.photoUrl : 'images/noProfilePic.jpg';
    }

    refreshPicture() {
        this.photoUrl = null;
        this.getChatPictureUrl();
    }

    sortMessages() {
        this.messages.sort(function(a, b){
            var aDate = a.time;
            var bDate = b.time;
            return aDate - bDate;
        });
    }

    isGroupChat() {
        return this.bIsGroupChat;
    }

    containsContactUID(uid) {
        var arrayLength = this.activeParticipants.length;
        for(var i = 0; i < arrayLength; i++) {
            var participant = this.activeParticipants[i];
            if(uid === participant.uid) {
                return true;
            }
        }
        return false;
    }

    clearUnreadMessages() {
        this.numNewMessages = 0;
        this.updateChatNotifications();
    }

    addUnreadMessage() {
        this.numNewMessages += 1;
        this.updateChatNotifications();
    }

    updateChatNotifications() {
        var arrayLength = this.chatManager.chats.length;
        var numUnread = 0;
        for(var i = 0; i < arrayLength; i++) {
            var chat = this.chatManager.chats[i];
            numUnread += chat.numNewMessages;
        }
        
        var notification = '<div id="chatButtonNotification" class="notificationCircle">'+numUnread+'</div>';
        if($('#chatButtonNotification').length) {
            if(numUnread > 0) {
                $('#chatButtonNotification').html(numUnread);
            } else {
                $('#chatButtonNotification').remove();
            }
        } else {
            if(numUnread > 0) {
                $('#chatButton').prepend(notification);
            }
        }
        }
        }



export default Chat;
