import $ from 'jquery';
import _ from 'underscore';

import WaypointView from './WaypointView';
import DrawnWaypoint from './DrawnWaypoint';

export class DrawnWaypointView extends WaypointView {
    constructor(waypointManager, uuid) {
        super(waypointManager, uuid);

        window.distSwitchClicked = (cb) => {
            this.distSwitchClicked(cb);
        };

        window.areaSwitchClicked = (cb) => {
            this.areaSwitchClicked(cb);
        };

        window.elevationSwitchClicked = (cb) => {
            this.elevationSwitchClicked(cb);
        };
    }

    get header() {
        return "Drawn Waypoint";
    }

    get footer() {
        return '<div class="d-flex justify-content-center" style="width:100%;"><button class="btn btn-primary" type="button" id="drivingDirectionsButton" style="margin-right: 10px;margin-left: 10px;"><i class="fa fa-map-signs"></i>&nbsp;Driving Directions</button><button class="btn btn-primary" type="button" id="editDrawnWaypointButton" style="margin-right: 10px;margin-left: 10px;"><i class="fa fa-edit"></i>&nbsp;Edit</button></div>';
    }

    get WaypointType() {
        return DrawnWaypoint;
    }

    //lets make each view have this function, and call it when the sidebar opens
    populatePage() {
        this.populateSharedContent(); 
        //populate the measurement labels
        this.setMeasurementLabels();


        $("#editDrawnWaypointButton").on('click', () => {
            window.onDrawAndMeasureClicked(this.waypoint);
        });

        $("#drivingDirectionsButton").on('click', () => {
            this.waypointManager.showNavigateToWaypoint(this.waypoint);
        });
    }
    
    setMeasurementLabels() {
        $('#distSwitch').prop('checked', this.waypoint.showDistLabel);
        $('#areaSwitch').prop('checked', this.waypoint.showAreaLabel);       
        $('#elevationSwitch').prop('checked', this.waypoint.showElevation);     
    }

    distSwitchClicked(cb) {
        this.waypointManager.setShowDistForWaypoint(this.waypointManager.active, cb.checked);
    }

    areaSwitchClicked(cb) {
        this.waypointManager.setShowAreaForWaypoint(this.waypointManager.active, cb.checked);
    }

    elevationSwitchClicked(cb) {
        this.waypointManager.setShowElevationsForWaypoint(this.waypointManager.active, cb.checked);
    }

}

export default DrawnWaypointView;
