import $ from 'jquery';
import L from 'leaflet';

import MapRegion from './MapRegion';
import Observable from './Observable';

import { gSettings } from './Settings';

export default class RegionManager extends Observable {

    constructor(dataService, mapProvider) {
        super();
        this.map = mapProvider;
        this.service = dataService;
        
        this.markerPane = null;
        this.noClickPane = null;
    }

    initialize() {
        gSettings.manager.onAddMapStartupRegion((region) => {
            if(!region) {
                return;
            }
            let mapRegion = new MapRegion().initWithJson(region); //gets the uuid for the first key in 
            if(mapRegion.isValid()) {
                var bFoundMatch = false;
                for(var k = 0; k < gSettings.startupMapRegions.length; k++) {
                    var existingRegion = gSettings.startupMapRegions[k];
                    if(existingRegion.uuid === mapRegion.uuid) {
                        bFoundMatch = true;
                        break;
                    }
                }
                if(!bFoundMatch) {
                    gSettings.addMapStartupRegion(mapRegion);
                }
            }

        });

        gSettings.manager.onRemoveMapStartupRegion((region) => {
            gSettings.removeMapStartupRegionWithUUID(region);
        });
    }

    addStartupRegion(name) {

        var mapRegion = new MapRegion();
        mapRegion.name = name.trim();
        mapRegion.bounds = this.map.leafletMap.getBounds();

        this.service.db.setStartupMapRegion(mapRegion);
    }

    addTapRestrictions() {
        this.markerPane = this.map.leafletMap.getPane('markerPane');
        this.noClickPane = L.DomUtil.create('div', 'no-click-pane');
        this.markerPane.insertBefore(this.noClickPane, this.markerPane.children[0]);
        $('.leaflet-control-container').toggleClass('noselect', true); //makes it so the legend and +/- signs aren't selectable/highlightable
    }

    removeTapRestrictions() {
        this.markerPane.removeChild(this.noClickPane);
        $('.leaflet-control-container').toggleClass('noselect', false); //makes it so the legend and +/- signs are back to normal
    }
}
