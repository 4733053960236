export class LEHLayersPackage {
    constructor(name) {
        this.name = name;
        this.mapLayers = new Array(); //this will give us easy access to these layers. They will also be in the mapLayers array in the iHunterMap
    }

    getColor() {
        if(this.mapLayers && this.mapLayers.length > 0) {
            var firstMapLayer = this.mapLayers[0];
            return firstMapLayer.getColor();
        }
        return "#E650CD"
    }

    getColorPreference() {
        if(this.mapLayers && this.mapLayers.length > 0) {
            var firstMapLayer = this.mapLayers[0];
            return firstMapLayer.colorPreference;
        }
        return "unknown_colorpreference"
    }

    getDefaultColor() {
        if(this.mapLayers && this.mapLayers.length > 0) {
            var firstMapLayer = this.mapLayers[0];
            return firstMapLayer.defaultColor;
        }
        return "#E650CD"
    }
}
export default LEHLayersPackage;
