import L from 'leaflet';

import Waypoint from './Waypoint';

import { Annotation, DRAW_TYPE } from './Annotation';
import LineAnnotation from './LineAnnotation';
import CircleAnnotation from './CircleAnnotation';
import RectAnnotation from './RectAnnotation';

import { getCoordinateString } from './CoordinateConverter';
import { getHTMLForTitleAndSubtitleRowWithLeftAndRightImages } from './ViewHelper';
import { FB_TYPE_DRAWNWAYPOINT } from './FirebaseAttributes';

export class DrawnWaypoint extends Waypoint {
    constructor(dataService, mapProvider, drawingView) {
        super(dataService, mapProvider); //this will also call updateWaypoint
        
        this.drawingView = drawingView;
        // this.annotationXML = json.AnnotationXML;
        // this.spanLat = json.SpanLatitude; 
        // this.spanLon = json.SpanLongitude; 
        // this.centerLat = json.CenterLatitude;
        // this.centerLon = json.CenterLongitude; 
        // this.bounds = this.getBounds(); //should overwrite by getAnnotations
    }

    initialize(json) {
        super.initialize(json);

        this.annotationXML = json.AnnotationXML;
        this.spanLat = json.SpanLatitude; 
        this.spanLon = json.SpanLongitude; 
        this.centerLat = json.CenterLatitude;
        this.centerLon = json.CenterLongitude; 
        this.bounds = this.getBounds(); //should overwrite by getAnnotations
    }

    updateWaypoint(json) {
        this.hide();
        this.annotations = null;        
        super.updateWaypoint(json);
        this.location = this.getLocation(json);
        this.showDistLabel = json.drawnwaypointShowDist;
        this.showAreaLabel = json.drawnwaypointShowArea;
        this.showElevation = json.drawnwaypointShowElevation;

        if(json.AnnotationXML != this.annotationXML) {
            this.annotationXML = json.AnnotationXML;
            this.annotations = null;
        }
        if(this.drawingView && this.drawingView.waypoint && this.drawingView.waypoint.uuid == this.uuid) {
            this.drawingView.updateExistingWaypointInfo(this);
        }
    }


    //returns latLngBounds (leaflet)
    getBounds() {
        if(this.bounds == null) {
            //this isn't actually that accurate since centerLat and centerLon are actually the starting point (or so it seems). Recaclulating bounds when getAnnotations is called.
            var topLeft = L.latLng(parseFloat(this.centerLat) + parseFloat(this.spanLat/2), parseFloat(this.centerLon) - parseFloat(this.spanLon/2));
            var botRight = L.latLng(parseFloat(this.centerLat) - parseFloat(this.spanLat/2), parseFloat(this.centerLon) + parseFloat(this.spanLon/2));
            return L.latLngBounds(topLeft, botRight);
        }
        return this.bounds;
    }

    getLocation(json) {
        var annotations = this.getAnnotations(json.AnnotationXML);
        if(annotations.length > 0 && annotations[0].getPoints().length > 0) {
            var pt = annotations[0].getPoints()[0];
            return new L.LatLng(pt.lat, pt.lng);
        } else {
            return new L.LatLng(json.latitude,json.longitude);
        }
    }


    getAnnotations(xmlString) {
        if(this.annotations != null && this.annotations.length > 0) {
            return this.annotations;
        }

        var annotations = getAnnotationsFromXML(this.drawingView, xmlString);
        
        var len = annotations.length;
        var accurateBounds;
        for(var i = 0; i < len; i++) {
            var b = L.latLngBounds(annotations[i].getPoints());
            accurateBounds = accurateBounds ? accurateBounds.extend(b) : b;
        }

        this.annotations = annotations;
        this.bounds = accurateBounds;
        return this.annotations;
    }

    getHTMLForOtherWaypointRow(otherWaypointLocation) {
        return getHTMLForTitleAndSubtitleRowWithLeftAndRightImages(this.uuid, 'openDrawnWaypointViewAndZoomToLocation(this.id)', this.pinImageUrl, this.name, this.getDistanceBetweenLocations(this.location, otherWaypointLocation));
    }

    getHTMLForWaypointRow() {
        if(this.map.currentLocation) {
            return getHTMLForTitleAndSubtitleRowWithLeftAndRightImages(this.uuid, 'openDrawnWaypointViewAndZoomToLocation(this.id)', this.pinImageUrl, this.name, this.getDistanceBetweenLocations(this.map.currentLocation, this.location));
        } else {
            return getHTMLForTitleAndSubtitleRowWithLeftAndRightImages(this.uuid, 'openDrawnWaypointViewAndZoomToLocation(this.id)', this.pinImageUrl, this.name, "(" + getCoordinateString(this.location.lat, this.location.lng) + ")");
        }
    }

    show() {
        var self = this;
        this.setPinImageUrl().then(() => {
            if(!self.marker) {
                self.marker = L.marker(self.location, {icon: self.pin});
            }

            if(!this.map.leafletMap.hasLayer(self.marker)) {

                var popup = '';
                popup += '<div class="popup-top-bar"><div class="popup-top-bar-title">' + getCoordinateString(self.location.lat,self.location.lng) + '</div></div>';
                popup += '<table class="popup">';                  
                popup += '<tr onclick="openDrawnWaypointView(\''+ self.uuid + '\')">';
                popup += '<td class="otherRows">';
                popup += '<img class="left-img" src="'+self.pinImageUrl+'"/>';
                popup += '<div class="text-content"><b>' + self.name + '</b></div>';
                popup += '<img class="arrow-img" src="images/arrow_grey_right@2x.png"/></td></tr>';
                popup += '</table>';

                //add bottom buttons!
                popup += '<div class="popup-bottom-bar">';
                //delete waypoint button
                popup += '<button id="deleteWaypointButton" class="btn btn-dark text-center round-left" type="button" onclick="requestDeleteDrawnWaypoint(\''+ self.uuid + '\')"><i class="icon-ihunter" style="margin-top:2px; font-size:30px; background-image: url(&quot;images/trash_icon@2x.png&quot;); background-size:contain; height:36px;"></i></button>';
                //weather button
                popup += '<button class="btn btn-dark text-center round-right" type="button" onclick="openWeatherView('+ self.location.lat.toFixed(6) + ',' + self.location.lng.toFixed(6) +')"><i class="icon-ihunter" style="margin-top:2px; font-size: 30px; background-image: url(&quot;images/weather_icon@2x.png&quot;); background-size:contain; height:36px;"></i></button>';
                popup += '</div>';
                //end add bottom buttons.
                
                self.marker.addTo(this.map.leafletMap).bindPopup(popup);
            }

            this.map.updatePolylinesForDrawnWaypoint(self);
        });
    }

    hide() {
        this.map.removePolylinesForDrawnWaypoint(this);

        if(this.marker && this.map.leafletMap.hasLayer(this.marker)) {
            this.map.leafletMap.removeLayer(this.marker);
            this.marker = null;
        }
    }


    getFirebaseType() {
        return FB_TYPE_DRAWNWAYPOINT;
    }
    
}

export function getAnnotationsFromXML(drawingView, xmlString) {
    var annotations = new Array();
    var parseXml;

    if (typeof window.DOMParser != "undefined") {
        parseXml = function(str) {
            return ( new window.DOMParser() ).parseFromString(str, "text/xml");
        };
    } else if (typeof window.ActiveXObject != "undefined" &&
        new window.ActiveXObject("Microsoft.XMLDOM")) {
        parseXml = function(str) {
            var xmlDoc = new window.ActiveXObject("Microsoft.XMLDOM");
            xmlDoc.async = "false";
            xmlDoc.loadXML(str);
            return xmlDoc;
        };
    } else {
        throw new Error("No XML parser found");
    }

    var xml = parseXml(xmlString);
    var annotationsXML = xml.getElementsByTagName("annotation");
    
    for(var key in annotationsXML) {

        if(annotationsXML[key].nodeName == "annotation") {
            var nodes = annotationsXML[key];
            var attributes = nodes.attributes;
            var points = null;
            var pointsArray = null;
            var color = null;
            var thickness = null;
            var type = DRAW_TYPE.UNKNOWN;
            var elevationsString = null;
            var elevationPointsString = null;
            
            for (var attr in attributes) {
                if(attributes[attr]) {
                    if (attributes[attr].nodeName == "points") {
                        points = attributes[attr].nodeValue;
                        pointsArray = points.split(",");
                    } 
                    else if (attributes[attr].nodeName == "color") {
                        color = attributes[attr].nodeValue;
                    } 
                    else if (attributes[attr].nodeName == "thickness") {
                        thickness = attributes[attr].nodeValue;
                    }
                    else if (attributes[attr].nodeName == "type") {
                        type = attributes[attr].nodeValue;
                    } 
                    else if(attributes[attr].nodeName == "elevations") {
                        elevationsString = unSanitizeString(attributes[attr].nodeValue);
                    } 
                    else if(attributes[attr].nodeName == "elevationPoints") {
                        elevationPointsString = unSanitizeString(attributes[attr].nodeValue);
                    }
                }
            }
    
            var annotation;
            if(type == DRAW_TYPE.CIRCLE) annotation = new CircleAnnotation(drawingView);
            else if(type == DRAW_TYPE.RECT || (pointsArray.length == 10 && pointsArray[0] == pointsArray[8] && pointsArray[1] == pointsArray[9])) annotation = new RectAnnotation(drawingView);
            else if(type == DRAW_TYPE.LINE || pointsArray.length == 4) annotation = new LineAnnotation(drawingView);
            else annotation = new Annotation(drawingView);
            
            if(color != null) {
                annotation.setColor(color);
            }
            if(thickness != null) {
                annotation.setThickness(thickness);
            }

            if(elevationsString != null && elevationsString.length > 0) {
                annotation.elevationsString = elevationsString;
                annotation.elevations = elevationsArrayFromString(elevationsString);
            }
            if(elevationPointsString != null && elevationPointsString.length > 0) {
                annotation.elevationPointsString = elevationPointsString;
                annotation.elevationPoints = elevationPointsArrayFromString(elevationPointsString);
            }
            
            if(points != null) {
                for(var i = 0; i < pointsArray.length - 1; i += 2) {
                    var lon = pointsArray[i];
                    var lat = pointsArray[i+1];
                    if(annotation instanceof RectAnnotation) { //rect stored as 5 points, but really should have just stored 2 points. Only need the 2 points to create a rect annotation
                        annotation.addPointRaw(new L.LatLng(lat, lon));
                    } else {
                        annotation.addPoint(new L.LatLng(lat, lon));
                    }
                }
            }
            
            annotation.waypoint = this;
            annotations.push(annotation);
        }
    }
    return annotations;
}

export default DrawnWaypoint;


function unSanitizeString(content) {
    if(content != null) {
        return content.replace("&quot;", "\"").replace("&apos;", "\'").replace("&lt;", "<").replace("&gt;", ">").replace("&amp;", "&");
    }
    return content;
}

function elevationsArrayFromString(elevationsString) {
    var elevations = elevationsString.split(",");
    var elevationsAsDoublesArray = new Array();

    for(var i = 0; i < elevations.length; i++) {
        elevationsAsDoublesArray.push(parseFloat(elevations[i]));
    }
    return elevationsAsDoublesArray;
}

function elevationPointsArrayFromString(elevationPointsString) {
    var points = elevationPointsString.split(",");
    var pointsArray = new Array();
    for(var i = 0; i < points.length-1; i+=2) {
        var lat = parseFloat(points[i]);
        var lon = parseFloat(points[i+1]);
        
        pointsArray.push(new L.LatLng(lat, lon));
    }
    // console.log(pointsArray);
    return pointsArray;
}

export function getAnnotationXMLForAnnotations(annotations) {
    if(annotations == null || annotations.length == 0) {
        return null;
    }
    
    var xmlString = '<?xml version="1.0"?><annotations>';
    for(var i = 0; i < annotations.length; i++) {
        var a = annotations[i];
        var annotationXML = a.getXML();
        if(annotationXML != null) {
            xmlString += '\n' + annotationXML + '\n';
        }
    }
    xmlString += '</annotations>';
    return xmlString;
}
