import $ from 'jquery';

import { gSettings } from './Settings';
import { gSubscriptionSettings } from './SubscriptionSettings';

import View from './View';

export class ChangeBoundaryColorsView extends View {
    constructor(modalView, mapProvider) {
        super();
        this.modal = modalView;

        this.map = mapProvider;

        this.populatePage();

        window.defaultColorClicked = (colorPickerID, strColor) => {
            this.defaultColorClicked(colorPickerID, strColor);
        };

        window.resetAllDefaultColors = () => {
            this.resetAllDefaultColors();
        }
    }

    populatePage() {
        this.bLehLayersExist = this.map.lehLayersPackage ? true : false;
        this.bCountyMapsExist = this.map.province.getProperty("HAS_COUNTY_MAPS_IAP");

        $('#boundariesListDiv').html('');
        $('#subscriptionBoundariesListDiv').html('');
        
        this.populateBoundaries(false);

        if(gSubscriptionSettings && gSubscriptionSettings.isSubscriptionPurchased()) {
            this.populateBoundaries(true);
            $('#subscriptionBoundariesHeading').html(gSubscriptionSettings.getSubscription().name + ' Boundaries');
        } else {
            $('#subscriptionBoundariesHeading').hide();
        }

        this.populateExtraBoundaries();
    }

    populateExtraBoundaries() {
        var self = this;
        if(this.bLehLayersExist) {
            let id = 'lehColorRow';
            let btnId = id+"_defaultButton";
            $('#boundariesListDiv').append(this.getHTMLForBoundaryColorRow(id, this.map.lehLayersPackage.name, this.map.lehLayersPackage.getColor()));
            $('#'+id).colorpicker({
                format: 'hex',
                template: '<div class="colorpicker">' +
                        '<div class="colorpicker-saturation"><i class="colorpicker-guide"></i></div>' +
                        '<div class="colorpicker-hue"><i class="colorpicker-guide"></i></div>' +
                        '<div class="colorpicker-bar" style="display:flex; border:0px solid #CCCCCC; box-shadow:0 0 0 0px #CCCCCC">' +
                        '<button id="'+btnId+'" onclick="defaultColorClicked(\''+id+'\', \''+this.map.lehLayersPackage.getDefaultColor()+'\')" class="btn btn-dark" style="flex: 1 1 auto;">Default</button>' +
                        '<div style="width:38px; height:38px; vertical-align:middle; border: 1px solid #CCCCCC; flex 0 1 auto; margin-left:5px; background: '+this.map.lehLayersPackage.getDefaultColor()+'"' +
                        '</div>' +
                        '</div>'
            });
            //this will set the color preference in firebase if the color changes.
            $('#'+id).colorpicker().on('colorpickerHide', (ev) =>{
                    var colorPickerColor = $(this).colorpicker('getValue');
                    if(colorPickerColor != self.map.lehLayersPackage.getColor()) {
                        gSettings.updatePreference(self.map.lehLayersPackage.getColorPreference(), colorPickerColor);
                    }
                }
            );
        }

        if(this.bCountyMapsExist) {
            let id = 'countyColorRow';
            let btnId = id+"_defaultButton";
            $('#boundariesListDiv').append(this.getHTMLForBoundaryColorRow(id, 'Landowner Maps', gSettings.getColorForCounties()));
            $('#'+id).colorpicker({
                format: 'hex',
                template: '<div class="colorpicker">' +
                        '<div class="colorpicker-saturation"><i class="colorpicker-guide"></i></div>' +
                        '<div class="colorpicker-hue"><i class="colorpicker-guide"></i></div>' +
                        '<div class="colorpicker-bar" style="display:flex; border:0px solid #CCCCCC; box-shadow:0 0 0 0px #CCCCCC">' +
                        '<button id="'+btnId+'" onclick="defaultColorClicked(\''+id+'\', \''+gSettings.defaultColorForCounties()+'\')" class="btn btn-dark" style="flex: 1 1 auto;">Default</button>' +
                        '<div style="width:38px; height:38px; vertical-align:middle; border: 1px solid #CCCCCC; flex 0 1 auto; margin-left:5px; background: '+gSettings.defaultColorForCounties()+'"' +
                        '</div>' +
                        '</div>'
            });
            //this will set the color preference in firebase if the color changes.
            $('#'+id).colorpicker().on('colorpickerHide',
                function(ev) {
                    var colorPickerColor = $(this).colorpicker('getValue');
                    if(colorPickerColor != gSettings.getColorForCounties()) {
                        gSettings.updatePreference(gSettings.colorPreferenceForCounties(), colorPickerColor);
                    }
                }
            );
        }
    }

    populateBoundaries(bIsSubscription) {
        var self = this;
        var listDiv = bIsSubscription ? "#subscriptionBoundariesListDiv" : "#boundariesListDiv";
        for(var i = 0; i < this.map.mapLayersArray.length; i++) {
            var mapLayer = this.map.mapLayersArray[i];
            var bShouldBeAdded = bIsSubscription ? mapLayer.layerRoot == 'subscription' : mapLayer.layerRoot != 'subscription';
            if(bShouldBeAdded && mapLayer.layerRoot != 'leh') { 
                var id = mapLayer.layerID+"_colorPicker";
                var btnId = id+"_defaultButton";
                $(listDiv).append(this.getHTMLForBoundaryColorRow(id, mapLayer.pluralName, mapLayer.getColor()));
                $('#'+id).colorpicker({
                    format: 'hex',
                    template: '<div class="colorpicker">' +
                            '<div class="colorpicker-saturation"><i class="colorpicker-guide"></i></div>' +
                            '<div class="colorpicker-hue"><i class="colorpicker-guide"></i></div>' +
                            '<div class="colorpicker-bar" style="display:flex; border:0px solid #CCCCCC; box-shadow:0 0 0 0px #CCCCCC">' +
                            '<button id="'+btnId+'" onclick="defaultColorClicked(\''+id+'\', \''+mapLayer.defaultColor+'\')" class="btn btn-dark" style="flex: 1 1 auto;">Default</button>' +
                            '<div style="width:38px; height:38px; vertical-align:middle; border: 1px solid #CCCCCC; flex 0 1 auto; margin-left:5px; background: '+mapLayer.defaultColor+'"' +
                            '</div>' +
                            '</div>'
                });

                //this will set the color preference in firebase if the color changes.
                $('#'+id).colorpicker().on('colorpickerHide',
                    function(ev) {
                        var mapLayerID = this.id.substring(0,this.id.length - "_colorPicker".length);
                        for(var i = 0; i < self.map.mapLayersArray.length; i++) {
                            var mapLayer = self.map.mapLayersArray[i];
                            if(mapLayer.layerID == mapLayerID) {
                                var colorPickerColor = $(this).colorpicker('getValue');
                                if(colorPickerColor != mapLayer.getColor()) {
                                    gSettings.settingsManager.updatePreference(mapLayer.colorPreference, colorPickerColor);
                                }
                                break;
                            }
                        }
                    }
                );
            }
        }
    }


    getHTMLForBoundaryColorRow(id, title, currentColor) {
        var row = '';
        row += '<div class="ihunter-menu-switch-row">';
        row += '<div class="middle-div">';
            row += '<div class="ihunter-menu-text large">'+ title + '</div>';
        row += '</div>';

        row += '<label class="ihunter-menu-switch-boundary-color float-right">';
            row += '<div id="'+id+'" class="input-group colorpicker-element">'
            row += '<input type="text" value="' + currentColor + '" class="form-control"/>'
            row += '<span class="input-group-append">'
            row += '<span class="input-group-text colorpicker-input-addon"><i style="background: rgb(221, 15, 32);"></i></span>'
            row += '</span>'
            row += '</div>';
        row += '</label>';
        row += '</div>';
        return row;
    }

    getHTMLForOptionsRowWithSwitch(bChecked, inputID, title) {
        var checked = bChecked ? 'checked' : '';
        var row = '';
        row += '<div class="ihunter-menu-switch-row">';
        row += '<div class="middle-div">';
            row += '<div class="ihunter-menu-text large">'+ title + '</div>';
            // row += abstract ? '<div class="ihunter-menu-text small">' + abstract + '</div>' : '';
        row += '</div>';

        row += '<label class="ihunter-menu-switch float-right">';
            row += '<input id="'+ inputID + '" type="checkbox" ' + checked + '>';
            row += '<span class="slider-switch round"></span>';
        row += '</label>';
        row += '</div>';
        return row;
    }

    defaultColorClicked(colorPickerID, strColor) {
        $('#'+colorPickerID).colorpicker('setValue', strColor);
    }
    
    resetAllDefaultColors() {
        var arrayOfPreferences = new Array();
        for(let i = 0; i < this.map.mapLayersArray.length; i++) { //leh, wmu, subscription, and other layers
            var mapLayer = this.map.mapLayersArray[i];
            if(!arrayOfPreferences.includes(mapLayer.colorPreference)) {
                arrayOfPreferences.push(mapLayer.colorPreference);
            }
        }
    
        if(this.map.province.getProperty("HAS_COUNTY_MAPS_IAP")) {
            arrayOfPreferences.push(gSettings.colorPreferenceForCounties());
        }
    
        this.restoreDefaultColorsForPreferences(arrayOfPreferences);
    
        this.populatePage();
    }
    
    restoreDefaultColorsForPreferences(arrayOfPreferences) {
      var arrayLength = arrayOfPreferences.length;
      for(var i = 0; i < arrayLength; i++) {
          var colorPreference = arrayOfPreferences[i];
          gSettings.setPreference(colorPreference, null);
          gSettings.updatePreference(colorPreference, null);
      }
    }
}

export default ChangeBoundaryColorsView;
