import L from 'leaflet';

import MapHelpers from './MapHelpers';

export class ContactMarker {
    constructor(contact, mapProvider) {
        this.contact = contact;
        this.map = mapProvider;
        this.marker = null;
        this.accuracyMarker = null;
        this.timeOfLastLocation = null;
        this.paused = false;
        this.broadcastFrequency = -1;        
    }

    setPin() {
        // console.log("setPin");
        this.pin = new ContactLocationPin({html: this.getBroadcastPinImage(this.contact.photoUrl, false)});
        if(this.marker) {
            this.marker.setIcon(this.pin);
        }
    }
    
    getBroadcastPinImage(url, bInChat) {
        // console.log(this);
        var html = "<div class='custom-pin'>"
        if(!this.contact.online && !bInChat) {
            html += "<div class='top-pin offline'></div>";
        }
        else if(this.paused && !bInChat) {
            html += "<div class='top-pin paused'></div>";
        } 
        else {
            html += "<div class='top-pin'></div>";
        }
        html += "<div class='contact-pic' style='background-image: url(\""+url+"\")'></div>";
        html += "</div>"
        // console.log(html);
        return html;
    }

    broadcastFrequencyToString() {
        return (this.broadcastFrequency <= 60) ? this.broadcastFrequency+"s" : this.broadcastFrequency/60+"m";
    }

     //DONE
     updatePopup() {
        if(this.marker) {
            this.marker.setPopupContent(this.getHtmlForMarker(this.contact, this.marker.getLatLng(), this.timeOfLastLocation));
        }
    }

    //DONE
    getHtmlForMarker(contact, location, time) {
        var distBetween = this.map.currentLocation ? MapHelpers.getLabelTextForDistanceBetweenCoords(location, this.map.currentLocation) + " away" : null;
        var status = contact.online ? (this.paused ? " (paused)"  : "") : " (offline)";

        var html = '<div class="temp-waypoint-popup">';
        html += '<div class="info-div">';
            html += '<div class="ihunter-menu-text small" style="padding-left: 5px;"><b>' + contact.username + status + '</b></div>';
            if(distBetween) {
                html += '<div class="ihunter-menu-text small" style="padding-left: 5px;">' + distBetween + " ("+time.format("h:mm a") + " - freq:" + this.broadcastFrequencyToString() +")" + '</div>';
            } else {
                html += '<div class="ihunter-menu-text small" style="padding-left: 5px;">' + time.format("h:mm a") + " - freq:" + this.broadcastFrequencyToString() + '</div>';
            }
        html += '</div>';
            html += '<div class="button-div" style="margin:5px;">';
                html += '<button class="btn btn-primary" type="button" id="saveTempWaypointButton" onclick="gFirebaseHelper.stopViewingOfLocationOfContact(\''+contact.uid+'\')"' + '>Stop</button>';
            html += '</div>';
        html += '</div>';
        return html;
    }

    //DONE
    removeMarkers() {
        if(this.marker) {
            this.marker.closePopup();
            this.map.leafletMap.removeLayer(this.marker);
            this.marker = null;
        }

        if(this.accuracyMarker) {
          this.map.leafletMap.removeLayer(this.accuracyMarker);
            this.accuracyMarker = null;
        }
    }

    //DONE
    setAccuracyMarker(location, accuracy) {
        var radius = accuracy;
        if(this.accuracyMarker) {
            this.accuracyMarker.setRadius(radius);
            this.accuracyMarker.setLatLng(location);
        } else {
            this.accuracyMarker = L.circle(location, radius).addTo(this.map.leafletMap);
        }
    }

    setMarker(location, time, accuracy) {
        if(!this.marker) {
            this.marker = L.marker(location, {icon: this.pin}).addTo(this.map.leafletMap).bindPopup(this.getHtmlForMarker(this.contact,location,time));
            this.timeOfLastLocation = time;
            this.setAccuracyMarker(location,accuracy);
        } else {
            this.marker.setLatLng(location);
            this.timeOfLastLocation = time;
            this.marker.closePopup();
            this.marker.setPopupContent(this.getHtmlForMarker(this.contact, location, time));
            this.setAccuracyMarker(location,accuracy);
        }
    }
    
}

var ContactLocationPin = L.DivIcon.extend({
  options: {
      className: 'my-div-icon',
      iconSize: [36,51],
      iconAnchor: [18,51],
      popupAnchor: [0, -51],
  }
})

export default ContactMarker;
