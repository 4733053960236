import L from 'leaflet';

import { gSettings } from './Settings';

export class MapHelpers {
    
    static getLabelTextForDistanceBetweenCoords(mp1, mp2) {
        var distance  = mp1.distanceTo(mp2)
        return MapHelpers.getDisplayTextForDistance(distance);
    }

    static getLabelTextForAreaOfCircle(point1, point2) {
        var midPoint = L.latLng((point1.lat + point2.lat)/2, (point1.lng + point2.lng)/2);
        var topPoint = L.latLng(Math.max(point1.lat,point2.lat), midPoint.lng);
        var rightPoint = L.latLng(midPoint.lat, Math.max(point1.lng,point2.lng));
        var vertRadiusInKilometers = topPoint.distanceTo(midPoint)/1000;
        var horizRadiusInKilometers = rightPoint.distanceTo(midPoint)/1000;
        var areaSqKm = vertRadiusInKilometers * horizRadiusInKilometers * Math.PI;

        var areaAcres;

        if(gSettings.useMetric()) {
            var areaHectares = areaSqKm * 100; //square km to hectares
            areaAcres = areaHectares * 2.4710538146717; //hecates to acres
            if(areaAcres < 10) {
                return areaHectares.toFixed(2) + " ha" + "<br>(" + areaAcres.toFixed(2) + " ac)"; 
            } else {
                return areaHectares.toFixed(0) + " ha" + "<br>(" + areaAcres.toFixed(0) + " ac)";
            }
        }
        else {
            areaAcres = areaSqKm * 247.10538146717;
            if(areaAcres < 10) {
                return areaAcres.toFixed(2) + " ac"; 
            } else {
                return areaAcres.toFixed(0) + " ac";
            }
        }
    }

    static getLabelTextForAreaOfRect(mpNW, mpSE, mpNE) {
       
        var distance1Km = mpNW.distanceTo(mpNE)/1000;
        var distance2Km = mpNE.distanceTo(mpSE)/1000;
        var areaSqKm = distance1Km * distance2Km; // in sq km
        var areaAcres;
        
        if(gSettings.useMetric()) {
            var areaHectares = areaSqKm * 100; //square km to hectares
            areaAcres = areaHectares * 2.4710538146717; //hecates to acres
            if(areaAcres < 10) {
                return areaHectares.toFixed(2) + " ha" + "<br>(" + areaAcres.toFixed(2) + " ac)"; 
            } else {
                return areaHectares.toFixed(0) + " ha" + "<br>(" + areaAcres.toFixed(0) + " ac)";
            }
        }
        else {
            areaAcres = areaSqKm * 247.10538146717;
            if(areaAcres < 10) {
                return areaAcres.toFixed(2) + " ac"; 
            } else {
                return areaAcres.toFixed(0) + " ac";
            }
        }
    }

    static getDisplayTextForDistance(distanceInMeters) {
        
        if(gSettings.useMetric()) {
            if(distanceInMeters < 1000) {
                return distanceInMeters.toFixed(2) + " m";
            } else {
                return (distanceInMeters/1000).toFixed(2) + " km"
            }
        } else {
            var distanceInMiles = distanceInMeters * 0.000621371192;
            if(distanceInMiles < 1) {
                return (distanceInMiles * 1760).toFixed(2) + " yd"; 
            } else {
                return distanceInMiles.toFixed(2) + " mi";
            }
        }
    }

    static getMinimumZoomLevelToCoverDistance(latLng, distanceInMeters) {
        distanceInMeters = distanceInMeters * 2.5;
        const EQUATOR_LENGTH = 6378140;
        var mapWidth = window.innerWidth; //assuming the whole width of the window for now...
        var latitudinalAdjustment = Math.cos(Math.PI * latLng.lat / 180.0);
        var arg = EQUATOR_LENGTH * mapWidth * latitudinalAdjustment / (distanceInMeters * 256.0);
        return Math.round(Math.log(arg) / Math.log(2.0));
    }

    static distAlongPath(points) {
        var distanceAlongPathInMeters = 0;
        for(var i = 0; i < points.length - 1; i++) {
            distanceAlongPathInMeters += points[i].distanceTo(points[i+1]);
        }
        return distanceAlongPathInMeters;
    }

    static getLabelTextForDistAlongPath(distanceInMeters) {
        return MapHelpers.getDisplayTextForDistance(distanceInMeters);
    }

    static getMidCoordAlongPath(points, pathLengthMeters) {
        if(points && points[0]) {
            var currentPathLength = 0;
            var mapPointStart = points[0];
            var mapPointEnd;
            for(var i = 1; i < points.length; i++) {
                mapPointEnd = points[i];
                mapPointStart = points[i-1];
                currentPathLength += mapPointStart.distanceTo(mapPointEnd);
                if(currentPathLength >= pathLengthMeters / 2) {
                    //var distToMid = mapPointStart.distanceTo(mapPointEnd) - (currentPathLength - pathLengthMeters / 2);
                    var latLngBounds = new L.latLngBounds(mapPointStart, mapPointEnd);
                    return latLngBounds.getCenter();
                }
            }
            return mapPointStart;
        }
        return null;
    }

    static getCoordAtDistance(distanceInMeters, pt1, pt2) { //L.latlng
        var distanceKM = distanceInMeters / 1000;
        var EradiusKM = 6378.140;

        var bearing = MapHelpers.getBearingBetweenCoords(pt1, pt2);

        var destLat = Math.asin(Math.sin(MapHelpers.toRadians(pt1.lat)) * Math.cos(distanceKM/EradiusKM) +
                Math.cos(MapHelpers.toRadians(pt1.lat)) * Math.sin(distanceKM/EradiusKM) * Math.cos(MapHelpers.toRadians(bearing)));

        var destLon = MapHelpers.toRadians(pt1.lng) + Math.atan2(Math.sin(MapHelpers.toRadians(bearing)) *
                        Math.sin(distanceKM/EradiusKM) * Math.cos(MapHelpers.toRadians(pt1.lat)),
                        Math.cos(distanceKM/EradiusKM) - Math.sin(MapHelpers.toRadians(pt1.lat)) * Math.sin(destLat));

        return L.latLng(MapHelpers.toDegrees(destLat), MapHelpers.toDegrees(destLon));
    }

    static getBearingBetweenCoords(pt1, pt2) {
        var rad = Math.PI / 180;
        var lat1 = pt1.lat * rad;
        var lat2 = pt2.lat * rad;
        var lon1 = pt1.lng * rad;
        var lon2 = pt2.lng * rad;
        var y = Math.sin(lon2 - lon1) * Math.cos(lat2);
        var x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);
        var bearing = ((Math.atan2(y, x) * 180 / Math.PI) + 360) % 360;
        return bearing >= 180 ? bearing-360 : bearing;
    }

    static toRadians(degrees) {
        return degrees * Math.PI/180;
    }

    static toDegrees(radians) {
        return radians * 180/Math.PI;
    }
}

export default MapHelpers;
