import L from 'leaflet';

export class LatLngAnnotation {
    constructor() {
        this.locations = new Array();
        this.thickness = 3;
        this.color;
    }

    addLocation(loc) { //loc = TrackLocation
        this.locations.push(loc);
    }

    getPoints() {
        var arrayOfPoints = new Array();
        for(var i = 0; i < this.locations.length; i++) {
            var trackLocation = this.locations[i];
            arrayOfPoints.push(new L.LatLng(trackLocation.latitude, trackLocation.longitude, trackLocation.altitude));
        }
        return arrayOfPoints;
    }

    getTrackSegments() {
        var arrayOfSegments = new Array();
        var arrayOfPoints = new Array();
        var paused = false;
        for(var i = 0; i < this.locations.length; i++) {
            //paused = false;
            var trackLocation = this.locations[i];
            arrayOfPoints.push(new L.LatLng(trackLocation.latitude, trackLocation.longitude, trackLocation.altitude));
            
            if(trackLocation.pause !== paused) {
                // Push old segment; start new segment
                arrayOfSegments.push({
                    points: arrayOfPoints, 
                    pause: paused
                });
                arrayOfPoints = new Array();
                arrayOfPoints.push(new L.LatLng(trackLocation.latitude, trackLocation.longitude, trackLocation.altitude));
                paused = trackLocation.pause;
            }
        }
        arrayOfSegments.push({
            points: arrayOfPoints, 
            pause: paused
        });
        return arrayOfSegments;
    }
}

export default LatLngAnnotation;
