import _ from 'underscore';

import Observable from './Observable';

// State of the application
const STATE = {
  INIT: 'app-init',             // Initial state
  BOOT: 'app-booting',          // Loading DOM
  //READY: 'app-ready',           // DOM ready - similar to $.ready  
  STARTING: 'app-starting',     // Application startup - start()
  CONNECTED: 'app-connected',   // Connected to firebase
  RUNNING: 'app-running',           // Map setup
  STALLED: 'app-stalled',
};

// Events that occur, but don't persist like states
const EVENT = {
  STATE_CHANGE: 'app-state-change',
  READY: 'app-ready',
  LOAD: 'app-load',
}

// State changes are externally triggered, but follow perscribed flow
// Init -> Boot -> Start-> Connect -> 
export default class AppState extends Observable {

  constructor(name) {
    super();

    this.currentState = STATE.INIT;
    this.lastState = STATE.INIT;
    this.state = STATE.BOOT; // Trigger set property

    this.onStartup = this.createHandlerMethod(STATE.STARTING);
    this.onConnected = this.createHandlerMethod(STATE.CONNECTED);
    this.onRun = this.createHandlerMethod(STATE.RUNNING);

    this.onReady = this.createHandlerMethod(EVENT.READY);
    this.onLoad = this.createHandlerMethod(EVENT.LOAD);
    this.onChange = this.createHandlerMethod(EVENT.STATE_CHANGE);

    this.registerListeners();
  }

  registerListeners() {

    // When DOM is ready (but not all resources are loaded)
    document.addEventListener('DOMContentLoaded', () => {
      this.emit(EVENT.READY);
    });

    // When all resources are loaded
    window.addEventListener('load', () => {
      this.emit(EVENT.LOAD);
    });
  }

  get state() {
    return this.currentState;
  }

  set state(newState) {

    if(newState === this.currentState) {
      return; // nothing to change
    }

    if(this.currentState === STATE.STALLED) {
      throw Error('App startup stalled; state locked');
    }

    this.lastState = this.currentState;
    this.currentState = newState;

    //console.log(`State ${this.currentState} (from ${this.lastState})`);

    this.emit(EVENT.STATE_CHANGE, this.currentState, this.lastState);
    this.emit(this.currentState);
  }

  start() {
    if(this.state !== STATE.BOOT) {
      throw Error('Boot state required before starting');
    }

    this.state = STATE.STARTING;
  }

  connect() {
    if(this.state !== STATE.STARTING) {
      throw Error('Starting state required before connecting');
    }

    this.state = STATE.CONNECTED;
  }

  running() {
    if(this.state !== STATE.CONNECTED) {
      throw Error('Connected state required before running');
    }

    this.state = STATE.RUNNING;
  }

  stall() {
    this.state = STATE.STALLED;
  }

}
