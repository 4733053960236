import bootbox from 'bootbox';
import toastr from 'toastr';
import Stripe from 'Stripe';

import AppState from './AppState';
import AppView from './AppView';
import FirebaseService from './FirebaseService';
import RegulationService from './RegulationService';
import MapDataProvider from './MapDataProvider';
import SettingsManager from './SettingsManager';
import AccountManager from './AccountManager';
import ContactManager from './ContactManager';
import ChatManager from './ChatManager';
import WaypointManager from './WaypointManager';
import RegionManager from './RegionManager';
import SearchManager from './Search';
import AppManifest from './AppManifest';

import { isProduction } from './Environment';
import { gSettings } from './Settings';


// Main orchestration object to coordinate events and states between other components
class App {                         // view model
    constructor() {
        this.state = new AppState();    // model
        this.view = new AppView(this);  // view

        this.service = new FirebaseService();       
        this.map = new MapDataProvider(this.service);

        this.accountManager = new AccountManager(this.service);
        this.settingsManager = new SettingsManager(this.service, this.map);
        this.waypointManager = new WaypointManager(this.service, this.map);
        this.regionManager = new RegionManager(this.service, this.map);
        this.contactManager = new ContactManager(this.service, this.map);
        this.chatManager = new ChatManager(this.service, this.view, this.contactManager, this.waypointManager);
        this.search = new SearchManager(this.map);


        this.manifest = new AppManifest().load().then((app)=> {
            console.log("App manifest loaded");
            console.log(`Build: v${app.version} - ${app.date} @ ${app.time}`);
            console.log("Flags: ", app.flags);
        })

        this.regulationService = null;

        this.state.onReady(() => {
            if(this.view.confirmPlatformSupport()){
                this.start();

            }else {
                this.stall();
            }
        });

        this.state.onLoad(() => { 
            //this.view.showBody();
        });

        this.state.onStartup(() => {
            this.starting();
        });

        this.state.onRun(() => {
            this.running();
        });
    }

    get regulations() {
        return this.regulationService;
    }

    get version() {
        return this.manifest.version;
    }

    starting() {
        this.service.initialize();

        this.service.onSignIn((user) => {
            console.log(`User login successful: ${user.uid}`);
            this.service.requestActiveProvince();
        });
        
        this.service.db.onActiveProvince((provinceCode) => {
            console.log(`Active province: ${provinceCode.toUpperCase()}`);
            if(this.initialize(provinceCode)) {
                this.state.running();
            }      
        });

        this.service.db.onNoActiveProvince(() => { 
            console.log(`No active province`);
            this.showProvinceSelection(); 
        });

        this.initStripe();

        this.state.connect();
    }


    running() {

        this.contactManager.initialize();
        this.chatManager.initialize();
        this.waypointManager.initialize(this.view.drawingView);
        this.regionManager.initialize();

        this.service.db.setupListeners();

        // setTimeout(() => {
        //     this.confirmUserDataSync();
        // }, 5000);
        
    }

    get provinceCode() {
        return (!this.map || !this.map.province) ? '' : this.map.province.provinceCode;
    }

    async initialize(provinceCode) {

        if(!this.confirmProvinceSupport(provinceCode)) {
            this.service.db.setActiveProvince(''); // Forget unsupported provinces
            return false;
        }

        try {
            this.settingsManager.initialize(provinceCode);

            await this.map.initialize(provinceCode);
            this.regulationService = new RegulationService(this.map.province);

   

            this.view.setupAnimations();
            this.view.setProvinceIcon(provinceCode);     
            this.view.showMainUIComponents(this.map.province);

            this.view.hideFirebaseLogin();

        }catch(error) {

            this.view.hideFirebaseLoading();

            console.error(`Error initializing iHunter ${provinceCode.toUpperCase()}: ${error.message}`);
            bootbox.alert("Unable to load iHunter "+provinceCode.toUpperCase()+".\n\nPlease retry or email info@ihunterapp.com for support.");
            this.showProvinceSelection();
            return false;
        }
        return true;
    }

    showProvinceSelection(){
        this.view.showProvinceSelection();
    }

    setActiveProvince(provinceCode){
        this.service.setActiveProvince(provinceCode);
    }

    start() {

        this.state.start();
    }

    stall() {

        this.state.stall();
    }

    initStripe() {
        if(isProduction()) {
            this.stripe = Stripe('pk_live_4FInggyMQSLZuWP12UiIFdRo00Py3ixNkK');
        }
        else {
            this.stripe = Stripe('pk_test_TMd4Zij8XJO3Exjrs3g0BEuS00tgatpQcW');
        }
    }

    confirmProvinceSupport(provinceCode) {
        if(provinceCode == "co" || provinceCode == "id" || provinceCode == "mt" || provinceCode == "or" || provinceCode == "pa" || provinceCode == "wa") {
            bootbox.alert("The iHunter team has decided to focus on the Canadian iHunter apps. As of now, iHunter "+provinceCode.toUpperCase()+" will no longer receive updates and will not be available to purchase.\n\nPlease email info@ihunterapp.com if you have any questions.");
            return false;
        }
        return true;
    }

    confirmUserDataSync() {
        if(gSettings.provinceCode == "ab" || gSettings.provinceCode == "bc" || gSettings.provinceCode == "sk" || gSettings.provinceCode == "on") {
            if(gSettings.purchasesIOS.length + gSettings.purchasesAndroid.length == 0) {

                var options = {
                    "closeButton": true,
                    "debug": false,
                    "newestOnTop": false,
                    "progressBar": false,
                    "positionClass": "toast-bottom-center",
                    "preventDuplicates": true,
                    "showDuration": "1000",
                    "hideDuration": "1000",
                    "timeOut": "10000",
                    "extendedTimeOut": "13000",
                    "showEasing": "swing",
                    "hideEasing": "linear",
                    "showMethod": "fadeIn",
                    "hideMethod": "fadeOut",
                    "onclick": () => {
                        this.view.onEmailUs();
                    }
                }

                toastr.info("Are you not seeing your waypoints and/or in-app purchases from the iOS or Android iHunter app?<br><br>Try these steps:<br>&#8226 Please make sure that your mobile app is using the latest version.<br>&#8226 Please make sure that you have used the same login for both the iHunter web app and the iHunter mobile app.<br><br>If those steps didn't work, click here to contact iHunter.", '', options).css({
                width: "75%",
                "max-width": "800px"
                });
            
            }
        }
    }


    // confirmPlatformSupport() {
    //   var android = isAndroid();
    //   var ios = isIOS();
    
    //   if(!(android || ios)) {
    //     if(browserCanRunWebapp()) {
    //       return true;
    //     }
    //     else {
    //       console.log("On old or unsupported browser");
    //       hideFirebaseLoading();
    //       showSupportedBrowsers();
    //     }
    //   }
    //   else {
    //     console.log("on android or ios");
    //     hideFirebaseLoading();
    //     if(android) {
    //       showGooglePlayStoreLink();
    //     } else if(ios) {
    //       showIOSAppStoreLink();
    //     }
    //   }
    //   return false;
    // }


}

// Main app to container
const ihunter = new App();
window.ihunter = ihunter;
export default ihunter;
