import moment from 'moment';

import DateHelpers from './DateHelpers';

export class BlogMessage {
    constructor(json) {
        this.title = json.title;
        this.subtitle = json.subtitle;
        this.detail = json.detail;
        this.link = json.link;
        if(this.link && !this.link.toLowerCase().startsWith('http')) {
            // [HUNT-945] We use a sponsor:// or county:// schema on mobile for in-app redirects;
            // ignore on web (for now)
            this.link = ''; 
        }
        // console.log(json.timestamp);
        this.timestamp = moment(json.timestamp * 1000);
        this.index = json.index;
    }

    getHTML() {
        // console.log(this.link);
        var onRowClick = this.link.length > 0 ? "openURLInNewTab('"+this.link+"')" : "";
        // console.log(onRowClick);
        var htmlContent = '<div class="ihunter-menu-boundary-row" onClick="' + onRowClick + '">';
        htmlContent += '<div class="middle-div-blogmessage">';
        if(this.title.length > 0) {
            htmlContent += '<div class="ihunter-menu-text large">'+ this.title + '</div>';
        }
        if(this.subtitle.length > 0) {
            htmlContent += '<div class="ihunter-menu-text medium" style="padding-top: 8px;">' + this.subtitle.replace(/(?:\r\n|\r|\n)/g, '<br>') + '</div>';
        }
        htmlContent += '<div class="ihunter-menu-text small align-center" style="padding-top: 8px;">' + DateHelpers.stringFromDate(this.timestamp) + '</div>';
        
        htmlContent += '</div>';


        var visibility = this.link.length <= 0 ? "visibility: hidden" : "";
        htmlContent += '<div class="right-div"><i class="fa fa-chevron-right" style="color:#727272;'+visibility+'"></i></div>';

        htmlContent += '</div>';
        return htmlContent;
    }

}

export default BlogMessage;
