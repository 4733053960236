import $ from 'jquery';
import toastr from 'toastr';

import View from './View';

import { getBrowserInfo } from './minimumrequirements';
import { isProduction } from './Environment';
import { gSettings } from './Settings';
import { getPurchasesString } from './PurchaseHelper';

export class EmailUsView extends View {
    constructor(modalView, dataProvider) {
        super();
        this.modal = modalView;
        this.user = dataProvider.userContact;
        this.disableSaveButton();
        this.populatePage();
    }
    
    populatePage() {
        var self = this;
        
        //do this for each input box
        $("#userEmailInput").keyup(function() {
            self.checkSaveButton();
        });
        $("#messageInput").keyup(function() {
            self.checkSaveButton();
        });

        if(this.user) {
            if(this.user.username && this.user.username != "Unknown Name") {
                $('#userNameInput').val(this.user.username);
            }
            if(this.user.email && this.user.email != "UnknownEmail@iHunter.com") {
                $('#userEmailInput').val(this.user.email);
                this.checkSaveButton();
            }
        } 

        this.setButtons();
    }

    toast(text,title) {
        var options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-bottom-center",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "5000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
    
        if(title) {
            toastr.info(text,title, options);
        } else {
            toastr.info(text, '', options)
        }
    }

    setButtons() {
        var self = this;
        this.modal.find('#modalPrimaryButton').off(); //removes previous onclick
        this.modal.find('#modalPrimaryButton').on('click', 
            function(event) {
                self.sendAndClose();
            }
        );
    }

    checkSaveButton() {
        //we should be letting the user know WHY the save button isn't enabled...
        //red text next to the title saying why?
        if($("#userEmailInput").val().trim().length > 0 && $("#userEmailInput").val().trim().includes("@") && $("#messageInput").val().trim().length > 0) { 
            this.enableSaveButton();
        } else {
            this.disableSaveButton();
        }
    }

    enableSaveButton() {
        this.modal.find('#modalPrimaryButton').removeAttr("disabled");
    }

    disableSaveButton() {
        this.modal.find('#modalPrimaryButton').attr("disabled", true);
    }

    sendAndClose() {        
        var self = this;
        $('#emailUsForm').submit(() => {
            var formdata = $('#emailUsForm').serialize();
            formdata += '&uid='+(self.user.uid ? self.user.uid : "Not logged in");
            formdata += '&province='+(gSettings.provinceCode ? gSettings.provinceCode : "Not logged in");
            formdata += '&browser='+getBrowserInfo();
            formdata += '&production='+isProduction();
            formdata += '&webpurch='+ (gSettings ? getPurchasesString(gSettings.purchasesWeb) : "N/A");
            formdata += '&iospurch='+ (gSettings ? getPurchasesString(gSettings.purchasesIOS) : "N/A");
            formdata += '&andpurch='+ (gSettings ? getPurchasesString(gSettings.purchasesAndroid) : "N/A");

            $.ajax({
                type: "POST",
                url: "sendEmail.php",
                data: formdata,
                success: (r) => {
                    console.log(r);
                    this.toast("Thank you for the email. We will reply as soon as possible."); 
                },
                error: (r) => {
                    console.log(r);
                    this.toast("There was an issue sending your email. Please email info@ihunterapp.com."); 
                }
            });
            return false;
        });

        $('#submit').click();
    }
}

export default EmailUsView;
