import $ from 'jquery';

import EditWaypointView from './EditWaypointView';

import { getTrackedWaypointPins } from './WaypointFilter';

export class EditTrackedWaypointView extends EditWaypointView {
    constructor(waypointManager, modalView, waypoint) {
        super(waypointManager, modalView, waypoint);
        this.deleteWaypointTitle = "Are you sure that you want to delete this track?";
        $('#pin-picker').height(55);
        $('#pin-background-picker').hide();
    }


    selectCurrentPinImage() {
        var pinImages = $("#pin-picker .img-frame");
        for(var i = 0; i < pinImages.length; i++) {
            var pinImage = pinImages[i];
            if($(pinImage).attr("id") == this.waypoint.pinImage) {
                this.selectPinImageFromPinPicker(pinImage);
                break;
            }
        }
    }

    setupPinPicker() {
        var arrayOfPinNames = getTrackedWaypointPins();
        for(var i = 0; i < arrayOfPinNames.length; i++) {
            var pinName = arrayOfPinNames[i];
            $("#pin-picker .centered-div").append(this.getSourcelessDivForPin(pinName, false)); 
            this.setPinImage(pinName, "");
        }

        //add class .selected which will highlight it and remove class .selected from all others
        var self = this;
        $("#pin-picker .img-frame").click( function() {
            self.selectPinImageFromPinPicker($(this));
            return false;
        });
    }

    saveAndClose(name, desc, pinImage, pinBackground) {

        let oldImages = this.bExistingImagesChanged ? this.imagesToDelete : null;
        this.waypointManager.updateTrackedWaypoint(this.waypoint, name, desc,  pinImage, this.existingImages, this.pendingImages, oldImages);
    }

    deleteWaypoint() {
        this.waypointManager.deleteTrackedWaypoint(this.waypoint);
    }
}

export default EditTrackedWaypointView;
