import $ from 'jquery';
import bootbox from 'bootbox';

import View from './View';

import { gSettings, MapStartupType } from './Settings';
import { openCustomModal } from './ModalHelper';

import { 
    SETTINGS_WAYPOINT_SORT_TYPE_NAME, SETTINGS_WAYPOINT_SORT_TYPE_DATE, 
    SETTINGS_WAYPOINT_SORT_TYPE_LOCATION, 
} from './Settings';

import { 
    COORDINATE_FORMAT, FB_STARTUP_TYPE, FB_DEFAULT_MAP_FAVORITE_ID,
    FB_HIGHLIGHT_SELECTED_WMU, FB_SELECTED_LEH_REGION, FB_WAYPOINT_SORT_TYPE, 
    FB_SHOW_CWD_ZONES, FB_SHOW_CWD_DROPOFFS, FB_IS_METRIC, FB_SHOW_BOUNDARY_ON_ZOOM, 
} from './FirebaseAttributes';



//export var gSettingsView = null;  // Created in populatePages()

export class SettingsMenuView extends View {
    constructor(settingsManager) {
        super();
        
        this.settingsManager = settingsManager;

        this.settingsManager.onUpdateInfoSettings((key, val) => {
            this.updateAdditionalInfo();
        });

        this.settingsManager.onUpdateSeasonSettings((key, val) => {
            $('#'+key).prop('checked',val);
        });

        this.settingsManager.onAddMapStartupRegion((regions) => {
            this.updateMapStartupPrefs();
        });

        this.settingsManager.onRemoveMapStartupRegion((regions) => {
            this.updateMapStartupPrefs();
        });

        this.settingsManager.map.onUpdateBoundary((key, val) => {
            this.updateBoundaryPrefs();
        });

        this.settingsManager.map.onUpdateLehBoundary(() => {
            this.updateLEHBoundaryPrefs();
        });

        this.settingsManager.map.onUpdateMapLayer(() => {
            this.updateMapLayers();
        });

        window.preferenceSwitchClicked = (checkbox, preference) => {
            this.preferenceSwitchClicked(checkbox, preference);
        };

        window.lehRowClicked = (row, layerID) => {
            this.lehRowClicked(row, layerID);
        }

        window.deleteStartupMapRegion = (uuid) => {
            this.deleteStartupMapRegion(uuid);
        };
        window.deleteUserMapLayer = (uuid) => {
            this.deleteUserMapLayer(uuid);
        };
        window.editUserMapLayer = (uuid) => {
            this.editUserMapLayer(uuid);
        };
        window.changeVisibilityOfUserMapLayer = (uuid) => {
            this.changeVisibilityOfUserMapLayer(uuid);
        };
    }

    get header() {
        return "Settings";
    }

    //lets make each view have this function, and call it when the sidebar opens
    populatePage() {
        this.addMapStartupPrefs();     
        this.addBoundaryPrefs();
        this.addLEHBoundaryPrefs();
        this.addWaypointPrefs();
        this.addCWDInfo();
        this.addAdditionalInfo();
        this.addUserMapLayerFunctions();
        this.addUserMapLayers();
        $('#rightSidebarSpinner').hide();
    }

    // resetPage() {
    //     $('#settingsUserMapLayersDiv').html('');
    //     $('#boundariesDiv').html('');
    //     $('#lehBoundariesDiv').html('');
    //     $('#CWDSettingsDiv').html('');
    //     $('#AdditionalOptionsDiv').html('');
    //     this.populatePage();
    // }
    
    addMapStartupPrefs() {
        var self = this;

        $('#showEntireProvince').off();
        $('#showEntireProvince').on('click', (event) => { 
            this.settingsManager.updatePreference(FB_STARTUP_TYPE, MapStartupType.STARTUP_ENTIRE_PROVICE);
            self.toggleStartupPrefs(MapStartupType.STARTUP_ENTIRE_PROVICE);
         });

        $('#zoomToCurrentLocation').off();
        $('#zoomToCurrentLocation').on('click', (event) => { 
            this.settingsManager.updatePreference(FB_STARTUP_TYPE, MapStartupType.STARTUP_ZOOM_TO_LOCATION);
            self.toggleStartupPrefs(MapStartupType.STARTUP_ZOOM_TO_LOCATION);
        });

        for(var i = 0; i < gSettings.startupMapRegions.length; i++) {
            var mapRegion = gSettings.startupMapRegions[i];
            $('#userMapStartupRegions').append(mapRegion.getRowHTML());
            
            $('#' + mapRegion.getRowID()).off();

            $('#' + mapRegion.getRowID()).on('click', function() { 
                var id = $(this).attr('id');
                var uuid = id.substring(0, id.length - 6); //6 = "_rowID".length
                gSettings.manager.updatePreference(FB_STARTUP_TYPE, MapStartupType.STARTUP_MAP_REGION);
                gSettings.manager.updatePreference(FB_DEFAULT_MAP_FAVORITE_ID, uuid);
                self.toggleStartupPrefs(MapStartupType.STARTUP_MAP_REGION, uuid);
            });
        }
        this.toggleStartupPrefs(gSettings.getPreference(FB_STARTUP_TYPE));
    }

    toggleStartupPrefs(pref, uuid) {
        $('#showEntireProvince').toggleClass('selected', pref === MapStartupType.STARTUP_ENTIRE_PROVICE);
        $('#zoomToCurrentLocation').toggleClass('selected', pref === MapStartupType.STARTUP_ZOOM_TO_LOCATION);
        
        var defaultUUID = uuid ? uuid : gSettings.getPreference(FB_DEFAULT_MAP_FAVORITE_ID);
        for(var i = 0; i < gSettings.startupMapRegions.length; i++) {
            var mapRegion = gSettings.startupMapRegions[i];
            $('#' + mapRegion.getRowID()).toggleClass('selected', (pref === MapStartupType.STARTUP_MAP_REGION && defaultUUID === mapRegion.uuid));
        }
        //there will need to be something dynamic for the regions
        //$('#orderWaypointsByLocation').toggleClass('selected', sortVal == SETTINGS_WAYPOINT_SORT_TYPE_LOCATION);
    }

    updateMapStartupPrefs() {
        $('#userMapStartupRegions').html('');
        this.addMapStartupPrefs();
    }

    updateBoundaryPrefs() {
        $('#boundariesDiv').html('');
        this.addBoundaryPrefs();
    }

    updateMapLayers() {
        $("#settingsUserBaseMapsDiv").html('');
        $("#settingsUserOverlaysDiv").html('');
        this.addUserMapLayers();
    }

    updateLEHBoundaryPrefs() {
        $('#lehBoundariesDiv').html('');
        this.addLEHBoundaryPrefs();
    }

    addBoundaryPrefs() {
        for(var i = 0; i < this.settingsManager.map.mapLayersArray.length; i++) {
            var mapLayer = this.settingsManager.map.mapLayersArray[i];
            //console.log(mapLayer);
            if(mapLayer.layerRoot != 'subscription' && mapLayer.layerRoot != 'leh') {
                this.addMapLayerToHtml(mapLayer);
            }
        } 
        var onclick = 'preferenceSwitchClicked(this,\''+FB_HIGHLIGHT_SELECTED_WMU+'\')';
        createSwitchAndAppend('Highlight Selected '+ this.settingsManager.map.province.getProperty('STR_WMU_PLURAL')+':', gSettings.getPreference(FB_HIGHLIGHT_SELECTED_WMU), FB_HIGHLIGHT_SELECTED_WMU+'-switch', onclick, '#boundariesDiv');


        $('#boundariesDiv').append('<div class="ihunter-menu-row" id="modifyBoundaryColors" onclick="onModifyBoundaryColors()"><div class="ihunter-menu-text">Modify Boundary Colors</div><img src="images/arrow_orange_right.png"/></div>');
    }

    addLEHBoundaryPrefs() {
        if(this.settingsManager.map.lehLayersPackage) {
            $('#lehBoundariesHeading').html(this.settingsManager.map.lehLayersPackage.name).show();
            
            this.addNoLEHRow();

            for(var i = 0; i < this.settingsManager.map.mapLayersArray.length; i++) {
                var mapLayer = this.settingsManager.map.mapLayersArray[i];
                //console.log(mapLayer);
                if(mapLayer.layerRoot == 'leh') {
                    this.addLEHLayerToHtml(mapLayer);
                }
            } 
        } else {
            $('#lehBoundariesHeading').hide();
        }
    }

    addMapLayerToHtml(mapLayer) {
        var onclick = 'boundarySwitchClicked(this,\'' + mapLayer.layerID + '\')';
        if(mapLayer.layerDescription != null && mapLayer.layerDescription.length > 0) {
            this.createWhatIsAndAppend(mapLayer, onclick, '#boundariesDiv');
        } else {
            createSwitchAndAppend('Show '+mapLayer.pluralName+':', mapLayer.shouldShow(), mapLayer.layerID + '-switch', onclick, '#boundariesDiv');
            //this will create the color style for the switch
            // $("<style type='text/css'> input#"+mapLayer.layerID+"-switch:checked + .slider-switch { background-color:" + mapLayer.getColor() + "; } </style>").appendTo("head");
        }
        $("<style type='text/css'> input#"+mapLayer.layerID+"-switch:checked + .slider-switch { background-color:" + mapLayer.getColor() + "; } </style>").appendTo("head");
        
        
    }

    createWhatIsAndAppend(mapLayer, onclick, appendToDiv) {
        var text = 'Show ' + mapLayer.pluralName + ':';
        var bChecked = mapLayer.shouldShow();
        var inputID = mapLayer.layerID + '-switch';
        var row = this.createWhatIsSwitchRowHTML(mapLayer, text, bChecked, inputID, onclick);
        $(appendToDiv).append(row);
        $('#'+inputID+'-whatis').click(function() {
            openCustomModal("", mapLayer.layerDescription, "OK", null, 50, 80, false);
        });
    }

    createWhatIsSwitchRowHTML(mapLayer, text, bChecked, inputID, onclick) {
        var checked = bChecked ? 'checked' : '';
        var whatIs = mapLayer.pluralName.substring(mapLayer.pluralName.length - 1) === 's' ? 'What are ' : 'What is ';

        var row = '';
        row += '<div class="ihunter-menu-switch-with-whatis-row">';
        row += '<div class="ihunter-menu-switch-with-whatis-row-inside">';
        row += '<div class="middle-div">' + text + '</div>';
        row += '<label class="ihunter-menu-switch">'
        row += '<input id="'+ inputID + '" type="checkbox" onclick="' + onclick + '" ' + checked + '>'
        row += '<span class="slider-switch round"></span>'
        row += '</label>';
        row += '</div>';
        
        row += '<div id="'+inputID+'-whatis" class="ihunter-menu-switch-with-whatis-row-inside" style="color:blue">';// onclick="openCustomModal(\'\', \''+mapLayer.layerDescription+'\', \'OK\', null, 50, 80, false)">';
        row += whatIs + mapLayer.pluralName + '?';
        row += '</div>';

        row += '</div>';
        return row;
    }

    addNoLEHRow() {
        var onclick = 'lehRowClicked(this,\'' + "-1" + '\')';
        var selectedClass = '';
        if(gSettings.getPreference(FB_SELECTED_LEH_REGION) == null || gSettings.getPreference(FB_SELECTED_LEH_REGION) == -1) {
            selectedClass = 'selected';
        } 

        var row = '<div class="ihunter-menu-row '+selectedClass+'" id="settings-noLEH-check" onclick="'+onclick+'"><div class="ihunter-menu-text">Don\'t Show '+this.settingsManager.map.lehLayersPackage.name+':</div><img class="img-checkmark" src="images/checkmark_transparent.png" style="background:black; background-blend-mode:screen;"/></div>';

        $('#lehBoundariesDiv').append(row);
    }

    addLEHLayerToHtml(mapLayer) {
        var onclick = 'lehRowClicked(this,\'' + mapLayer.layerID + '\')';
        var selectedClass = '';
        if(gSettings.getPreference(FB_SELECTED_LEH_REGION) == mapLayer.getIndex()) {
            selectedClass = 'selected';
        } 

        var row = '<div class="ihunter-menu-row '+selectedClass+'" id="settings-'+mapLayer.layerID+'-check" onclick="'+onclick+'"><div class="ihunter-menu-text">Show '+mapLayer.pluralName+':</div><img class="img-checkmark" src="images/checkmark_transparent.png" style="background:'+mapLayer.getColor()+'; background-blend-mode:screen;"/></div>';

        $('#lehBoundariesDiv').append(row);
    }


    addWaypointPrefs() {
        this.toggleWaypointSorting(gSettings.getPreference(FB_WAYPOINT_SORT_TYPE));
        var self = this;
        $('#orderWaypointsByName').on('click', (event) => { 
            this.settingsManager.updatePreference(FB_WAYPOINT_SORT_TYPE, SETTINGS_WAYPOINT_SORT_TYPE_NAME);
            self.toggleWaypointSorting(SETTINGS_WAYPOINT_SORT_TYPE_NAME);
         });
        $('#orderWaypointsByDate').on('click', (event) => { 
            this.settingsManager.updatePreference(FB_WAYPOINT_SORT_TYPE, SETTINGS_WAYPOINT_SORT_TYPE_DATE);
            self.toggleWaypointSorting(SETTINGS_WAYPOINT_SORT_TYPE_DATE);
        });
        $('#orderWaypointsByLocation').on('click', (event) => { 
            this.settingsManager.updatePreference(FB_WAYPOINT_SORT_TYPE, SETTINGS_WAYPOINT_SORT_TYPE_LOCATION);
            self.toggleWaypointSorting(SETTINGS_WAYPOINT_SORT_TYPE_LOCATION);
        });
    }

    toggleWaypointSorting(sortVal) {
        $('#orderWaypointsByName').toggleClass('selected', sortVal == SETTINGS_WAYPOINT_SORT_TYPE_NAME);
        $('#orderWaypointsByDate').toggleClass('selected', sortVal == SETTINGS_WAYPOINT_SORT_TYPE_DATE);
        $('#orderWaypointsByLocation').toggleClass('selected', sortVal == SETTINGS_WAYPOINT_SORT_TYPE_LOCATION);
    }

    addCWDInfo() {
        if(this.settingsManager.map.province.hasCWDZones()) {
            $('#CwdHeading').show();
            var onCwdZoneclick = 'preferenceSwitchClicked(this,\''+FB_SHOW_CWD_ZONES+'\')';
            var onCwdDropoffclick = 'preferenceSwitchClicked(this,\''+FB_SHOW_CWD_DROPOFFS+'\')';
            createSwitchAndAppend('Highlight CWD Zones:', gSettings.getPreference(FB_SHOW_CWD_ZONES), 'showCwdZones',onCwdZoneclick ,'#CWDSettingsDiv');
            createSwitchAndAppend('Show CWD Dropoffs:', gSettings.getPreference(FB_SHOW_CWD_DROPOFFS), 'showCwdDropoffs',onCwdDropoffclick ,'#CWDSettingsDiv');
        } else {
            $('#CwdHeading').hide();
        }
    }

    updateAdditionalInfo() {
        $('#AdditionalOptionsDiv').html('');
        this.addAdditionalInfo();
    }

    addAdditionalInfo() {
        // var onMetricClicked = 'preferenceSwitchClicked(this,\''+FB_IS_METRIC+'\')';
        // createSwitchAndAppend('Use Metric Units:', gSettings.useMetric(), 'useMetricUnits',onMetricClicked ,'#AdditionalOptionsDiv');
        $('#AdditionalOptionsDiv').append(createMetricOrImperialSegmentedGroupHTML(gSettings.useMetric() ? 0 : 1));
        $("#MetricOrImperialSegmentedGroup").click((event) => {
            
            $("li", event.currentTarget)
            .removeClass("selected")
            .filter(event.target)
            .addClass("selected");
            var id = $("li", event.currentTarget).filter(event.target).attr('id');
            if(id == 'seg_control_imperial') {
                this.settingsManager.updatePreference(FB_IS_METRIC, false);
            } 
            else { //metric
                this.settingsManager.updatePreference(FB_IS_METRIC, true);
            }
        });

        
        //add LatLng/DMS/utm/mgrs stuff here
        $('#AdditionalOptionsDiv').append(createCoordinateSegmentedGroupHTML(gSettings.getCoordinateFormat()));
        $("#CoordinateUnitsSegmentedGroup").click((event) => {
            $("li", event.currentTarget)
            .removeClass("selected")
            .filter(event.target)
            .addClass("selected");
            var id = $("li", event.currentTarget).filter(event.target).attr('id');
            if(id == 'seg_control_dms') {
                this.settingsManager.updatePreference(COORDINATE_FORMAT, 1);
            } 
            else if(id == 'seg_control_mgrs') {
                this.settingsManager.updatePreference(COORDINATE_FORMAT, 2);
            } 
            else if(id == 'seg_control_utm') {
                this.settingsManager.updatePreference(COORDINATE_FORMAT, 3);
            } 
            else { //latlng
                this.settingsManager.updatePreference(COORDINATE_FORMAT, 0);
            }
        });


        if(this.settingsManager.map.province.getProperty("HAS_COUNTY_MAPS_IAP")) {
            var onShowBoundaryOnZoomClicked = 'preferenceSwitchClicked(this,\''+FB_SHOW_BOUNDARY_ON_ZOOM+'\')';
            createSwitchAndAppend('Show Map Boundary On Zoom:', gSettings.getPreference(FB_SHOW_BOUNDARY_ON_ZOOM), 'showBoundaryOnZoom',onShowBoundaryOnZoomClicked ,'#AdditionalOptionsDiv');
        }
    }

    //used for all switches that control a single preference
    preferenceSwitchClicked(checkbox, preference) {
        this.settingsManager.updatePreference(preference, checkbox.checked);

        if(preference === FB_SHOW_CWD_ZONES) {
            var message = "The CWD markers will " + (checkbox.checked ? " " : "not") + "be displayed on the map the next time you start the iHunter Web App."
            openCustomModal("CWD Markers", message, 'OK', null, 50, 80, false);
        }
    }

    addUserMapLayerFunctions() {
        $('#addMapLayer').on('click', (event) => { 
            window.onAddUserMapLayer(); // Exposed by AppView: don't love this...
        });
        // $('#zoomToCurrentLocation').on('click', function(event) { 
        //     this.settingsManager.updatePreference(FB_STARTUP_TYPE, MapStartupType.STARTUP_ZOOM_TO_LOCATION);
        //     self.toggleStartupPrefs(MapStartupType.STARTUP_ZOOM_TO_LOCATION);
        // });
    }

    addUserMapLayers() {
        $("#basemapsID").hide(); 
        $("#overlaysID").hide(); 
        for(var i = 0; i < this.settingsManager.map.userMapLayersArray.length; i++) {
            var userMapLayer = this.settingsManager.map.userMapLayersArray[i];
            this.addUserMapLayerToHtml(userMapLayer);
        } 

        // var onclick = 'preferenceSwitchClicked(this,\''+FB_HIGHLIGHT_SELECTED_WMU+'\')';
        // createSwitchAndAppend('Highlight Selected '+this.settingsManager.map.province.getProperty('STR_WMU_PLURAL')+':', gSettings.getPreference(FB_HIGHLIGHT_SELECTED_WMU), FB_HIGHLIGHT_SELECTED_WMU+'-switch', onclick, '#boundariesDiv');
    }

    addUserMapLayerToHtml(userMapLayer) {
        var row = userMapLayer.getRowHTML(); //using the cachefolder as it won't have any spaces in it
        
        if(userMapLayer.isBaseMap()) {
            $("#basemapsID").show(); 
            $("#settingsUserBaseMapsDiv").append(row);
        } 
        else {
            $("#overlaysID").show(); 
            $("#settingsUserOverlaysDiv").append(row);
 
            $('#'+userMapLayer.cacheFolder+'_input').slider({});
            $('#'+userMapLayer.cacheFolder+'_input').on('slideStop', (event) => {
                var targetName = event.target.id;
                var cacheFolder = targetName.substring(0,targetName.length - 6);
                var userMapLayer = this.settingsManager.map.getUserMapLayerForCacheFolder(cacheFolder);
                this.settingsManager.setOpacityForUserMapLayer(userMapLayer, event.value);
            });
        }
    
    }

    // getHTMLForMapSubHeader(title) {
    //     var id = (title == 'Base Maps') ? 'basemaps_id' : 'overlays_id';
    //     var htmlContent = '<div id="'+id+'" class="contacts-header-row">'; //for now we'll just use this..
    //     htmlContent += '<div>' + title + '</div>';
    //     htmlContent += '</div>';
    //     return htmlContent;
    // }

    deleteUserMapLayer(uuid) {
        bootbox.confirm({
            message: "Are you sure that you want to delete this map layer?",
            buttons: {
                confirm: {
                    label: 'Delete',
                    className: 'btn-danger'
                },
                cancel: {
                    label: 'Cancel',
                    className: 'btn-secondary'
                }
            },
            callback: (result) => {
                if(result) {
                    this.settingsManager.deleteUserMapLayer(uuid);
                } 
            }
        });
    }
    
    deleteStartupMapRegion(uuid) {
        bootbox.confirm({
            message: "Are you sure that you want to delete this startup region?",
            buttons: {
                confirm: {
                    label: 'Delete',
                    className: 'btn-danger'
                },
                cancel: {
                    label: 'Cancel',
                    className: 'btn-secondary'
                }
            },
            callback: (result) => {
                if(result) {
                    if(gSettings.getPreference(FB_STARTUP_TYPE) == MapStartupType.STARTUP_MAP_REGION && gSettings.getPreference(FB_DEFAULT_MAP_FAVORITE_ID) == uuid) {
                        gSettings.setPreference(FB_STARTUP_TYPE, MapStartupType.STARTUP_ENTIRE_PROVICE);
                        this.settingsManager.updatePreference(FB_STARTUP_TYPE,MapStartupType.STARTUP_ENTIRE_PROVICE);
                    }
                    this.settingsManager.deleteStartupMapRegion(uuid);
                } 
            }
        });
    }
    
    editUserMapLayer(uuid) {
        var userMapLayer = this.settingsManager.map.getUserMapLayerForUUID(uuid);
        if(userMapLayer) {
            window.onEditUserMapLayer(userMapLayer);
        }
    }
    
    changeVisibilityOfUserMapLayer(uuid) {
        for(var i = 0; i < this.settingsManager.map.userMapLayersArray.length; i++) {
            var userMapLayer = this.settingsManager.map.userMapLayersArray[i];
            if(userMapLayer.getUUID() == uuid) {
                this.settingsManager.setVisibilityForUserMapLayer(userMapLayer, !userMapLayer.bIsVisible);
                return;
            }
        }
        
    }

    lehRowClicked(row, layerID) {
        var mapLayer = null;
        for(var i = 0; i < this.settingsManager.map.mapLayersArray.length; i++) {
            if(layerID == this.settingsManager.map.mapLayersArray[i].layerID) {
                mapLayer = this.settingsManager.map.mapLayersArray[i];
            }
        }
    
        if(mapLayer != null) {
            this.settingsManager.updatePreference(FB_SELECTED_LEH_REGION, mapLayer.getIndex());
        } else {
            this.settingsManager.updatePreference(FB_SELECTED_LEH_REGION, -1);
        }
        removeSelectedLEHInSettingsMenu();
        $(row).toggleClass('selected',true);
    }
    

}

export default SettingsMenuView;

//move these, but for now I've made 2 copies of them...see subscriptionMenuView
function createSwitchAndAppend(text, bChecked, inputID, onclick, appendToDiv) {
    var row = createSwitchRowHTML(text, bChecked, inputID, onclick);
    $(appendToDiv).append(row);
}

function createMetricOrImperialSegmentedGroupHTML(selectedIndex) {
    var html = '';
    html += '<ul id="MetricOrImperialSegmentedGroup" class="buttonGroup">';
    html += '<li id="seg_control_metric"';
    html += (selectedIndex == 0 ? 'class="selected"' : '') + '>Metric</li>';
    html += '<li id="seg_control_imperial"';
    html += (selectedIndex == 1 ? 'class="selected"' : '') + '>Imperial</li>';
    html += '</ul>'
    return html;
}

function createCoordinateSegmentedGroupHTML(selectedIndex) {
    var html = '';
    html += '<ul id="CoordinateUnitsSegmentedGroup" class="buttonGroup">';
    html += '<li id="seg_control_latlng"';
    html += (selectedIndex == 0 ? 'class="selected"' : '') + '>Lat/Lon</li>';
    html += '<li id="seg_control_dms"';
    html += (selectedIndex == 1 ? 'class="selected"' : '') + '>D/M/S</li>';
    html += '<li id="seg_control_mgrs"';
    html += (selectedIndex == 2 ? 'class="selected"' : '') + '>MGRS</li>';
    html += '<li id="seg_control_utm"';
    html += (selectedIndex == 3 ? 'class="selected"' : '') + '>UTM</li>';
    html += '</ul>'
    return html;
}

export function createSwitchRowHTML(text, bChecked, inputID, onclick) {
    var checked = bChecked ? 'checked' : '';
    var row = '';
    row += '<div class="ihunter-menu-switch-row">';
    row += '<div class="middle-div">' + text + '</div>';
    row += '<label class="ihunter-menu-switch">'
    row += '<input id="'+ inputID + '" type="checkbox" onclick="' + onclick + '" ' + checked + '>'
    row += '<span class="slider-switch round"></span>'
    row += '</label>';
    row += '</div>';
    return row;
}




//remove all selected, then set selected for selected row
function removeSelectedLEHInSettingsMenu() {
    var lehRows = $('#lehBoundariesDiv');
    $(lehRows).children().each((i, element) => {                    
        $(element).toggleClass('selected', false);
    })
}


