import $ from 'jquery';
import _ from 'underscore';

import { openURLInNewTab } from './Environment';
import { getAjaxRequest } from './AjaxHelper';
import { gSettings } from './Settings';

export function openTextInModalWithMoreInfo(text, moreInfo) {
  if(moreInfo != null && moreInfo.length > 0) {
      openCustomModal("", text, 'Close', 'More Info', 70, 90, false);
      $('#modalCancelButton').off(); //removes previous onclick
      $('#modalCancelButton').click(function() {
          //more info
          _.delay(function () {
              $('#modalCancelButton').off(); //removes previous onclick
              //gMap.getLayerInfo(moreInfo, null); // TODO: Test and restore this capability
          },500);
          
      });
  } else {
      openTextInModal(text);
  }
}

export function openTextInModal(text) {
  openModal("", text);
}

export function openHTMLFileInModal(title, filePath) {
  openHTMLFileInCustomModal(title, filePath, 'Close', null, '70', '90', false);
}

function updateRelativeLinks(bodyText, filePath) {
  
  var dirPath = filePath.substring(0, filePath.lastIndexOf("/"));

  var re = new RegExp("<link href=\"Resources", 'g');
  var newBodyText = bodyText.replace(re, "<link href=\""+dirPath+"/Resources");
  var re2 = new RegExp("<img src=\"Resources", 'g');
  newBodyText = newBodyText.replace(re2, "<img src=\""+dirPath+"/Resources");

  return newBodyText;
}

export function openHTMLFileInCustomModal(title, filePath, primaryButtonText, secondaryButtonText, widthPercentage, heightPercentage, bIsSecondaryModal) {
  return new Promise(function(resolve, reject) {
      var xmlhttp = new XMLHttpRequest();
      xmlhttp.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
              resolve(openCustomModal(title, updateRelativeLinks(this.responseText, filePath), primaryButtonText, secondaryButtonText, widthPercentage, heightPercentage, bIsSecondaryModal));
          } else if(this.status != 200 && this.status != 0) {
              reject(Error('Rejected in openHTMLFileInCustomModal'));
          }
      };
      xmlhttp.open("GET", filePath, true);
      xmlhttp.send();
  })
}


export function openModalURL(title, url) {
  var iframe;
  var script = "checkIframeCapability.php";
  var query = "?url="+url;
  var ajaxRequest = getAjaxRequest();
  if(ajaxRequest != null) {
      ajaxRequest.onreadystatechange = function () {
          if (ajaxRequest.readyState == 4) {
              if(ajaxRequest.responseText == "true" || url.startsWith("http://")) {
                  openURLInNewTab(url);
              } else {
                  if(url.includes('.png') || url.includes('.jpg')) {
                      iframe = '<div class="iframe-container" style="overflow:auto" ><img src="' + url + '"></iframe></div>'
                  } else {
                      iframe = '<div class="iframe-container" ><iframe id="modalIframe" src="' + url + '"></iframe></div>'
                  }
                  openModal(title, iframe);
              }
          }
      }
      ajaxRequest.open("GET", script + query, true);
      ajaxRequest.send(null);
  } 
}
window.openModalURL = openModalURL;

export function openModal(title, body) {
  openCustomModal(title, body, 'Close', null, 70, 90, false);
}

export function openCustomModal(title, body, primaryButtonText, secondaryButtonText, widthPercentage, heightPercentage, bIsSecondaryModal) {
  var modalWindow = bIsSecondaryModal ? $('#secondaryModal') : $('#fullScreenModal');

  modalWindow.find('.modal-dialog').css('width', widthPercentage+'%');
  // modalWindow.find('.modal-content').css('height', heightPercentage+'%');
  modalWindow.find('.modal-content').css('max-height', heightPercentage+'%');
  modalWindow.find('.modal-content').css('height', 'auto');
  modalWindow.find('.modal-title').html(title);

  modalWindow.find('.modal-body').html(body);

  if(primaryButtonText) {
      modalWindow.find('#modalPrimaryButton').html(primaryButtonText);
      modalWindow.find('#modalPrimaryButton').show();
      $('#modalPrimaryButton').removeAttr("disabled"); //enable save button.
  } else {
      modalWindow.find('#modalPrimaryButton').hide();
  }

  if(secondaryButtonText) {
      modalWindow.find('#modalCancelButton').html(secondaryButtonText);
      modalWindow.find('#modalCancelButton').show();
  } else {
      modalWindow.find('#modalCancelButton').hide();
  }

  modalWindow.modal('handleUpdate');
  modalWindow.modal('show');
  return modalWindow;    
}



export function showImageWithName(imageName, title) {
    var imageURL = "res/provinces/"+gSettings.provinceCode+"/pdfs/"+imageName;
    showImage(imageURL, title);
}

export function showImage(imageURL, title) {
    
    var iframe = '<div class="iframe-container" style="overflow:auto" ><img src="' + imageURL + '"></iframe></div>'
    openModal(title,iframe);
}

export function showPDFWithName(pdfName, title) {
    var pdfURL = "res/provinces/"+gSettings.provinceCode+"/pdfs/"+pdfName;
    showPDF(pdfURL, title);
}

export function showPDF(pdfURL, title) {
    var iframe = '<div class="iframe-container" ><iframe src="' + pdfURL + '"></iframe></div>'
    openModal(title,iframe);
}