import moment from 'moment';

import DateHelpers from './DateHelpers';

import { 
  FB_WAYPOINT_FILTER_OUT_ICONS, FB_WAYPOINT_FILTER_OUT_BACKGROUNDS, FB_WAYPOINT_FILTER_NUMDAYS, 
  FB_WAYPOINT_FILTER_STARTDATE, FB_WAYPOINT_FILTER_ENDDATE, 
} from './FirebaseAttributes';

export default class WaypointFilter {
    constructor() {
        this.startDate = null;
        this.endDate = null;
        this.numberOfDays = 0;
        this.inactivePinImages = new Array();
        this.inactiveBackgroundImages = new Array();
    }

    pinImageArrayToString(array) {
        var string = "";
        for(var i = 0; i < array.length; i++) {
            var img = array[i];
            string += i == 0 ? img : "," + img;
        }
        return string;
    }

    stringToPinImageArray(s) {
        if(s != null && s.length > 0) {
            return s.split(",");
        } else {
            return new Array();
        }
    }

    setFilterFromJson(json) {
        // console.log(json);
        var inactivePinImagesString = Object.prototype.hasOwnProperty.call(json, FB_WAYPOINT_FILTER_OUT_ICONS) ? json[FB_WAYPOINT_FILTER_OUT_ICONS] : "";
        this.inactivePinImages = this.stringToPinImageArray(inactivePinImagesString);
        var inactiveBackgroundImagesString = Object.prototype.hasOwnProperty.call(json, FB_WAYPOINT_FILTER_OUT_BACKGROUNDS) ? json[FB_WAYPOINT_FILTER_OUT_BACKGROUNDS] : "";
        this.inactiveBackgroundImages = this.stringToPinImageArray(inactiveBackgroundImagesString)

        this.numberOfDays = Object.prototype.hasOwnProperty.call(json, FB_WAYPOINT_FILTER_NUMDAYS) ? json[FB_WAYPOINT_FILTER_NUMDAYS] : 0;
        if(this.numberOfDays > 0) {
            this.endDate = moment();
            this.startDate = moment().subtract(this.numberOfDays, "days");
        } else {
            this.startDate = Object.prototype.hasOwnProperty.call(json, FB_WAYPOINT_FILTER_STARTDATE) ? DateHelpers.dateFromString(json[FB_WAYPOINT_FILTER_STARTDATE]) : null;
            this.endDate = Object.prototype.hasOwnProperty.call(json, FB_WAYPOINT_FILTER_ENDDATE) ? DateHelpers.dateFromString(json[FB_WAYPOINT_FILTER_ENDDATE]) : null;
        }
    }

    getJsonForFirebase() {
        var json = {};
        if(this.numberOfDays == 0 && this.startDate && this.endDate) { 
            json[FB_WAYPOINT_FILTER_STARTDATE] = DateHelpers.stringFromDate(this.startDate);
            json[FB_WAYPOINT_FILTER_ENDDATE] = DateHelpers.stringFromDate(this.endDate);
        }
        json[FB_WAYPOINT_FILTER_NUMDAYS] = this.numberOfDays;
        json[FB_WAYPOINT_FILTER_OUT_ICONS] = this.pinImageArrayToString(this.inactivePinImages);
        json[FB_WAYPOINT_FILTER_OUT_BACKGROUNDS] = this.pinImageArrayToString(this.inactiveBackgroundImages);
        return json;//JSON.stringify(json);
    }

    isEqualTo(o) {
        if(this.startDate != o.startDate || this.endDate != o.endDate || this.numberOfDays != o.numberOfDays) {
            return false;
        }

        return this.arraysAreEqual(this.inactivePinImages, o.inactivePinImages) && this.arraysAreEqual(this.inactiveBackgroundImages, o.inactiveBackgroundImages);
    }

    arraysAreEqual(original, other) {
        var length = original.length;
        var otherLength = other.length;
        if(length != otherLength) {
            return false;
        }
        
        for(var i; i < length; i++) {
            if(original[i] != other[i]) {
                return false;
            }   
        }
        return true;
    }
}

function getDrawnWaypointPins() {
    var pins = new Array;
    pins.push("ihunter_pin_drawing_blue");
    pins.push("ihunter_pin_drawing_red");
    pins.push("ihunter_pin_drawing_brown");
    pins.push("ihunter_pin_drawing_green");
    return pins;
}

function getTrackedWaypointPins() {
    var pins = new Array;
    pins.push("listitem_signpost_blue");
    pins.push("listitem_signpost_red");
    pins.push("listitem_signpost_brown");
    pins.push("listitem_signpost_green");
    return pins;
}

function getWaypointPins() {
    var pins = new Array;
    pins.push("camp");
    pins.push("ihunter_pin_stand");
    pins.push("ihunter_pin_truck");
    pins.push("ihunter_pin_treestand");
    pins.push("deer");
    pins.push("ihunter_pin_moose");
    pins.push("ihunter_pin_whitetail");
    pins.push("ihunter_pin_muledeer");
    pins.push("ihunter_pin_doe");
    pins.push("ihunter_pin_antelope");
    pins.push("ihunter_pin_bhs");
    pins.push("ihunter_pin_mountaingoat");
    pins.push("ihunter_pin_bear");
    pins.push("ihunter_pin_bird");
    pins.push("ihunter_pin_coyote");
    pins.push("ihunter_pin_cat");
    pins.push("ihunter_pin_turkey");
    pins.push("ihunter_pin_grouse");
    pins.push("ihunter_pin_pheasant");
    pins.push("ihunter_pin_skunk");
    pins.push("ihunter_pin_goose");
    pins.push("ihunter_pin_duck");
    pins.push("ihunter_pin_fish");
    pins.push("ihunter_pin_rabbit");

    pins.push("ihunter_pin_blooddrop");
    pins.push("ihunter_pin_dogtrack");
    pins.push("ihunter_pin_deertrack");
    pins.push("ihunter_pin_deerscat");
    pins.push("ihunter_pin_rub");
    pins.push("ihunter_pin_scrape");
    pins.push("ihunter_pin_waterhole");
    pins.push("ihunter_pin_elk_shed");
    pins.push("ihunter_pin_moose_shed");
    pins.push("ihunter_pin_whitetail_shed");
    pins.push("ihunter_pin_mule_shed");
    pins.push("hunting");
    pins.push("ihunter_pin_archer");
    pins.push("ihunter_pin_camera");
    pins.push("ihunter_pin_binos");
    pins.push("ihunter_pin_fire");
    pins.push("ihunter_pin_sights");
    pins.push("ihunter_pin_trap");
    pins.push("ihunter_pin_snare");
    pins.push("ihunter_pin_cattle");
    pins.push("ihunter_pin_gate");
    pins.push("farmer");
    pins.push("ihunter_pin_mountain");
    pins.push("ihunter_pin_trees");
    pins.push("ihunter_pin_mushroom");
    pins.push("ihunter_pin_bridge");
    pins.push("ihunter_pin_culvert");
    pins.push("ihunter_pin_boat");
    pins.push("ihunter_pin_boat_dock");
    pins.push("ihunter_pin_private");
    pins.push("ihunter_pin_checkmark");
    pins.push("ihunter_pin_xmark");
    pins.push("ihunter_pin_questionmark");

    return pins;
}

function getWaypointBackgrounds() {
    var pins = new Array;
    pins.push("ihunter_pin_background_green");
    pins.push("ihunter_pin_background_red");
    pins.push("ihunter_pin_background_blue");
    pins.push("ihunter_pin_background_yellow");
    pins.push("ihunter_pin_background_grey");
    pins.push("ihunter_pin_background_brown");
    return pins;
}

export { getDrawnWaypointPins, getTrackedWaypointPins, getWaypointPins, getWaypointBackgrounds };
