import L from 'leaflet';

import MapHelpers from './MapHelpers';
import LineAnnotation from './LineAnnotation';

import { Annotation, DRAW_TYPE, ERASER_WIDTH_PIXELS, NO_ROTATION } from './Annotation';

export class RectAnnotation extends Annotation {
    constructor(drawingView) {
        super(drawingView);
        this.startPoint = null;
        this.distanceLabel1 = null;
        this.distanceLabel2 = null;
        this.areaLabel = null;
        this.type = DRAW_TYPE.RECT;
    }

    addPointRaw(pt) {
        super.addPoint(pt);
    }

    addPoint(pt) { 
        if(this.startPoint == null) {
            this.startPoint = pt;
        }
        this.polyline.setLatLngs([]); //clears all points

        var sp = this.startPoint;
        super.addPoint([Math.max(sp.lat, pt.lat), Math.min(sp.lng, pt.lng)]) //NW point
        super.addPoint([Math.max(sp.lat, pt.lat), Math.max(sp.lng, pt.lng)]) //NE point
        super.addPoint([Math.min(sp.lat, pt.lat), Math.max(sp.lng, pt.lng)]) //SE point
        super.addPoint([Math.min(sp.lat, pt.lat), Math.min(sp.lng, pt.lng)]) //SW point
        super.addPoint([Math.max(sp.lat, pt.lat), Math.min(sp.lng, pt.lng)]) //NW point again

        // COde to handle rotation of the map when adding a rect (but we can't currently rotate our map)
        // This code *almost* works, but something is not quite correct. 
     /*   // convert to screen coords to figure out the actual 4 corner points based on the 
        
        var pointStart = gMap.leafletMap.latLngToLayerPoint(this.startPoint);
        var pointEnd = gMap.leafletMap.latLngToLayerPoint(pt);

        super.addPoint(gMap.leafletMap.layerPointToLatLng([Math.max(pointStart.y, pointEnd.y), Math.min(pointStart.x, pointEnd.x)])); //NW point
        super.addPoint(gMap.leafletMap.layerPointToLatLng([Math.max(pointStart.y, pointEnd.y), Math.max(pointStart.x, pointEnd.x)])); //NE point
        super.addPoint(gMap.leafletMap.layerPointToLatLng([Math.min(pointStart.y, pointEnd.y), Math.max(pointStart.x, pointEnd.x)])); //SE point
        super.addPoint(gMap.leafletMap.layerPointToLatLng([Math.min(pointStart.y, pointEnd.y), Math.min(pointStart.x, pointEnd.x)])); //SW point
        super.addPoint(gMap.leafletMap.layerPointToLatLng([Math.max(pointStart.y, pointEnd.y), Math.min(pointStart.x, pointEnd.x)])); //NW point again
    */
    }

    eraseAtPoint(erasePoint) {
        var points = this.getPoints(); 
        for(var i = 0; i < points.length; i++) {
            var existingPoint = points[i];

            // Check for interset with a corner (ignore last point, given 5-point rects)
            while( i < points.length - 1 && this.distanceInPixels(this.map.leafletMap.latLngToLayerPoint(erasePoint),this.map.leafletMap.latLngToLayerPoint(existingPoint)) < ERASER_WIDTH_PIXELS/2) {
               
                // Copy remaining rect edges to new line annotations
                let newAnnotations = [];
                for(var k = 0; k < points.length -1; k++) {
                
                    if(i === k || i === k+1) {
                        continue; // Don't copy edges connected to the erased corner
                    }

                    var p1 = points[k];
                    var p2 = points[k+1];

                    var a = new LineAnnotation(this.drawingView);
                    a.setColor(this.color);
                    a.setThickness(this.thickness);
                    a.addPoint(p1);
                    a.addPoint(p2);
                    newAnnotations.push(a);
                }

                // Empty the rect annotation after deconstruction
                this.setPoints([]);
                
                return newAnnotations;
            }
        }
    }

    addLabels() {
        var measurementLabels = new Array();
        var points = this.getPoints();
        if (points.length == 5 && points[0].equals(points[4], 0.0)) {
            // consider this a rect, as it's unlikely the above conditions
            // would be true without it being a rect.
            var mpNW = points[0];
            var mpNE = points[1];
            var mpSE = points[2];
            var mpSW = points[3];
            if (this.shouldShowDistLabels()) {
                this.distanceLabel1 = this.map.createMeasurementMarkerBetweenCoords(mpNW, mpNE, NO_ROTATION); //top line label
                this.distanceLabel2 = this.map.createMeasurementMarkerBetweenCoords(mpNE, mpSE, NO_ROTATION); //right line label
                measurementLabels.push(this.distanceLabel1);
                measurementLabels.push(this.distanceLabel2);
            }
            if (this.shouldShowAreaLabels()) {
                var lblText = MapHelpers.getLabelTextForAreaOfRect(mpNW, mpSE, mpNE); //area label
                var mpMid = new L.LatLng((mpSW.lat + mpNE.lat) / 2, (mpSW.lng + mpNE.lng) / 2);
                var minZoomLevel = MapHelpers.getMinimumZoomLevelToCoverDistance(mpMid, mpSW.distanceTo(mpSE));
                this.areaLabel = this.map.addDrawnWaypointMeasurementMarker(lblText, mpMid, minZoomLevel, NO_ROTATION);
                measurementLabels.push(this.areaLabel);
            }
        }
        return measurementLabels;
    }

    shouldShowAreaLabels() {
        if(this.waypoint) {
            return this.waypoint.showAreaLabel;
        }

        if(this.drawingView && !this.drawingView.waypoint) {
            return this.drawingView.bShowArea;
        }
        
        return false;
        //return (this.drawingView && !this.drawingView.waypoint && this.drawingView.bShowArea) || (this.waypoint != null && this.waypoint.showAreaLabel);
    }

    shouldShowElevation() {
        return false;
    }

    removeLabels() {
        if(this.distanceLabel1) {
            this.map.leafletMap.removeLayer(this.distanceLabel1);
        }
        if(this.distanceLabel2) {
            this.map.leafletMap.removeLayer(this.distanceLabel2);
        }
        if(this.areaLabel) {
            this.map.leafletMap.removeLayer(this.areaLabel);
        }
    }

    remove() {
        this.removeLabels();
        this.polyline = null;
    }

    getMinimumPoints() {
        return 4;
    }

    isRectangle() {
		return true;
	}

	isCircle() {
		return false;
	}

	isLine() {
		return false;
	}

	isFreehand() {
    return false;
	}

}

export default RectAnnotation;
