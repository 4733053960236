export default class Message {
    constructor(decryptedJSON) {
        this.contactUID = decryptedJSON.uid;
        this.type = decryptedJSON.type;
        this.time = decryptedJSON.time;
        this.text = decryptedJSON.msg;
        this.messageUID = decryptedJSON.messageUID;
        this.additionalInfo = decryptedJSON.additionalinfo;
        this.pinResource = decryptedJSON.pinresource; 
        this.pinBackgroundResource = decryptedJSON.pinbackgroundresource == null ? "" : decryptedJSON.pinbackgroundresource; 
    }
}


