import _ from 'underscore';
import { gSettings } from './Settings';
import { getAjaxRequest } from './AjaxHelper';
import { isProduction } from './Environment';

export default class RegulationService {

    constructor(province) {
        this.province = province;
    }

    get provinceCode() {
        return this.province.provinceCode;
    }

    getWMUInfo(wmu, title, inModal) {
        return new Promise((resolve, reject) => {
            var script = inModal ? "getWMUInfoModal.php" : "getWMUInfo.php";
            var ajaxRequest = getAjaxRequest();  // The variable that makes Ajax possible!

            // Create a function that will receive data sent from the server
            ajaxRequest.onreadystatechange = function () {
                if (ajaxRequest.readyState == 4) {
                    resolve(ajaxRequest.responseText);
                }
            }
    
            var queryString = "?wmu=" + wmu + "&province=" + this.provinceCode + "&database=regulations.db" + "&title=" + title + 
                "&archery=" + gSettings.SeasonVisibility_Arch + "&muzzleloader=" + gSettings.SeasonVisibility_Muzz + "&trapping=" + gSettings.SeasonVisibility_Trap + "&youth=" + gSettings.SeasonVisibility_Youth + "&draws=" + gSettings.SeasonVisibility_Draw + "&closed=" + gSettings.SeasonVisibility_Closed;
            queryString += this.addHeadingsToWMUTable(wmu);
    
            ajaxRequest.open("GET", encodeURI(script + queryString), true);
            ajaxRequest.send(null);
        });
    }

    getLEHInfo(leh, title, inModal) {
        return new Promise((resolve, reject) => {
            //need to customize for PA, NS, ID, MT, CO, WA, ON, OR(uses leh if wmu code = 1???
            var script = inModal ? "getWMUInfoModal.php" : "getWMUInfo.php";
            var ajaxRequest = getAjaxRequest();  // The variable that makes Ajax possible!
    
            if(ajaxRequest != null) {
                // Create a function that will receive data sent from the server
                ajaxRequest.onreadystatechange = function () {
                    if (ajaxRequest.readyState == 4) {
                        resolve(ajaxRequest.responseText);
                    }
                }
    
                var queryString = "?wmu=" + leh + "&province=" + this.provinceCode + "&database=regulations.db" + "&title=" + title + "&is_leh=true";
    
                ajaxRequest.open("GET", script + queryString, true);
                ajaxRequest.send(null);
            }
        });
    }

    getAgLeaseInfo(mapLayer, disposition, title, inModal ) {

        return new Promise((resolve, reject) => {
            var script = inModal ? "getAgLeaseInfoModal.php" : "getAgLeaseInfo.php";
            var ajaxRequest = getAjaxRequest();
    
            // Create a function that will receive data sent from the server
            ajaxRequest.onreadystatechange = function () {
                if (ajaxRequest.readyState == 4) {
                    resolve(ajaxRequest.responseText);
                }
            }
        
            var queryString = "?disposition=" + disposition + "&province=" + this.provinceCode + "&database=subscription/agleases/boundarydata.db" + "&title=" + title + "&date=" + mapLayer.date;
            ajaxRequest.open("GET", script + queryString, true);
            ajaxRequest.send(null);
        });
    }


    // getSKU( sku ) {
    //     return new Promise(function(resolve, reject) {
    //         var script = "getSku.php"
         
    //         var ajaxRequest = getAjaxRequest();
    //         if(ajaxRequest != null) {
    //             // Create a function that will receive data sent from the server
    //             ajaxRequest.onreadystatechange = function () {
    //                 if (ajaxRequest.readyState == 4) {
    //                     if(ajaxRequest.status == 200) {
    //                         // console.log(ajaxRequest.responseText);
    //                         resolve(ajaxRequest.responseText);
    //                     }
    //                     else {
    //                         reject("");
    //                     }
    //                 }
    //             }
    
    //             //var queryString = "?wmu=" + wmu + "&province=" + this.provinceCode  + "&database=regulations_" + gProvince.getProperty("regulations_version") + ".db";
    //             var testString = !isProduction() ? "&TESTING=TRUE" : "&TESTING=FALSE";
    //             var queryString = "?sku=" + sku + testString;
    //             ajaxRequest.open("GET", script + queryString, true);
    //             ajaxRequest.send(null);
    //         }
    //     });
    //   }
    
    //    getPrice(priceId) {
    //     return new Promise(function(resolve, reject) {
    //         var script = "getPrice.php"
            
    //         var ajaxRequest = getAjaxRequest();
    //         if(ajaxRequest != null) {
    //             // Create a function that will receive data sent from the server
    //             ajaxRequest.onreadystatechange = function () {
    //                 if (ajaxRequest.readyState == 4) {
    //                     if(ajaxRequest.status == 200) {
    //                         resolve(ajaxRequest.responseText);
    //                     }
    //                     else {
    //                         reject("");
    //                     }
    //                 }
    //             }
    
    //             var testString = !isProduction() ? "&TESTING=TRUE" : "&TESTING=FALSE";
    //             var queryString = "?priceid=" + priceId + testString;
    //             ajaxRequest.open("GET", script + queryString, true);
    //             ajaxRequest.send(null);
    //         }
    //     });
    //   }
    
    //   getSubscriptionPlan( plan ) {
    //     return new Promise(function(resolve, reject) {
    //         var script = "getPlan.php"
            
    //         var ajaxRequest = getAjaxRequest();
    //         if(ajaxRequest != null) {
    //             // Create a function that will receive data sent from the server
    //             ajaxRequest.onreadystatechange = function () {
    //                 if (ajaxRequest.readyState == 4) {
    //                     if(ajaxRequest.status == 200) {
    //                         resolve(ajaxRequest.responseText);
    //                     }
    //                     else {
    //                         reject("");
    //                     }
    //                 }
    //             }
    
    //             var testString = !isProduction() ? "&TESTING=TRUE" : "";
    //             var queryString = "?plan=" + plan + testString;
    //             ajaxRequest.open("GET", script + queryString, true);
    //             ajaxRequest.send(null);
    //         }
    //     });
    //   } 

    addHeadingsToWMUTable(wmu) {
        var headings = "";
        if(this.province.allowsBlackBearBaiting(wmu)) {
            headings += "&bearbaiting=" + true;
            headings += "&bearbaiting_title=" + this.province.getBlackBearBaitingTitle();
            headings += "&bearbaiting_subtitle=" + this.province.getBlackBearBaitingSubtitle();
        }
        if(this.province.allowsBlackBearBaitingPartialZone(wmu)) {
            headings += "&partialbearbaiting=" + true;
            headings += "&bearbaiting_title=" + this.province.getBlackBearBaitingTitle();
            headings += "&bearbaiting_subtitle=" + this.province.getPartialBlackBearBaitingSubtitle();
        }
        if(this.province.hasSupplementalBlackBear(wmu)) {
            headings += "&supbear=" + true;
            headings += "&supbear_title=" + this.province.getBlackBearSupplementalTitle();
            headings += "&supbear_subtitle=" + this.province.getBlackBearSupplementalSubtitle();
        }
        if(this.province.hasSupplementalAntlerless(wmu)) {
            headings += "&supantlerless=" + true;
            headings += "&supantlerless_title=" + this.province.getSupplementalTitle();
            headings += "&supantlerless_subtitle=" + this.province.getSupplementalSubtitle();
        }
        if(this.province.isSupplementalFirstOnly(wmu)) {
            headings += "&supfirstonly=" + true;
            headings += "&supfirstonly_title=" + this.province.getSupplementalTitle();
            headings += "&supfirstonly_subtitle=" + this.province.getFirstOnlySupplementalSubtitle();
        }
        if(this.province.isCWDZone(wmu)) {
            headings += "&cwd=" + true;
            headings += "&cwd_title=" + this.province.getCWDTitle();
            headings += "&cwd_subtitle=" + this.province.getCWDSubtitle();
        }
        if(this.province.isCWDMuleZone(wmu)) {
            headings += "&cwdmule=" + true;
            headings += "&cwdmule_title=" + this.province.getCWDTitle();
            headings += "&cwdmule_subtitle=" + this.province.getCWDMuleSubtitle();
        }
        if(this.province.isBiologicalSampleRequestedForZone(wmu)) {
            headings += "&biosample=" + true;
            headings += "&biosample_title=" + this.province.getBioSampleTitle();
            headings += "&biosample_subtitle=" + this.province.getBioSampleSubtitle();
        }
        if(this.province.isAltNoticeZone(wmu)) {
            headings += "&altnotice=" + true;
            headings += "&altnotice_title=" + this.province.getAltNoticeTitle();
            headings += "&altnotice_subtitle=" + this.province.getAltNoticeSubtitle();
        }
        return headings;
    }


    getBlogMessages() {

        return new Promise((resolve, reject) => {
            var script = "getBlogMessages.php";
            var url = "?url=https://www.ihunterapp.com/messages/getMessages.php?province=" + this.provinceCode.toLowerCase();
            var ajaxRequest = getAjaxRequest();

            // Create a function that will receive data sent from the server
            ajaxRequest.onreadystatechange = function () {
                if (ajaxRequest.readyState == 4) {
                    resolve(ajaxRequest.responseText);
                }
            }
        
            ajaxRequest.open("GET", script + url, true);
            ajaxRequest.send(null);
        });
    }


    
    getCapabilities(getCapabilitiesURL) {
        return new Promise(function(resolve, reject) {
            var script = "getCapabilities.php"
            
            var ajaxRequest = getAjaxRequest();
            if(ajaxRequest != null) {
                // Create a function that will receive data sent from the server
                ajaxRequest.onreadystatechange = function () {
                    if (ajaxRequest.readyState == 4) {
                        if(ajaxRequest.status == 200) {
                            resolve(JSON.parse(ajaxRequest.responseText));
                        }
                        else {
                            reject("");
                        }
                    }
                }
    
                var queryString = "?" + getCapabilitiesURL;
                ajaxRequest.open("GET", script + queryString, true);
                ajaxRequest.send(null);
            }
        });
      }

}
