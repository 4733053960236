import $ from 'jquery';
import _ from 'underscore';
import bootbox from 'bootbox';

import AddWaypointView from './AddWaypointView';


export class EditWaypointView extends AddWaypointView {
    constructor(waypointManager, modalView, waypoint) {
        super(waypointManager, modalView, waypoint.location.lat.toString(), waypoint.location.lng.toString(), waypoint);
        // this.waypoint = waypoint;
        this.selectCurrentPinImage();
        this.deleteWaypointTitle = "Are you sure that you want to delete this waypoint?";
        $("#addWaypointTitle").val(this.waypoint.name);
        $("#addWaypointDescription").val(this.waypoint.desc);
        //if we edit existingImages at all (by removing an image), we need to set bExistingImagesChanged to true
        //when we save, if bExistingImagesChanged = true - we need to find out which ones are gone, then delete

        $('#AddWaypointSwiper').html('');

        this.existingImages = this.waypoint.imageNamesArray.slice(0);
        this.imagesToDelete = new Array();
        var self = this;

        this.populateCarousel();
        
        var deleteButton = this.modal.find('#modalDeleteButton');
        if(deleteButton) {
            deleteButton.show();
            this.modal.find('#modalDeleteButton').off(); //removes previous onclick
            this.modal.find('#modalDeleteButton').on('click', 
                function(event) {
                    bootbox.dialog({
                        message: self.deleteWaypointTitle,
                        onEscape: false,
                        closeButton: false,
                        buttons: {
                            delete: {
                                label: 'Delete',
                                className: 'btn-danger',
                                callback: function(){
                                    self.deleteWaypoint();
                                    $("#fullScreenModal .close").click();    
                                }
                            },
                            confirm: {
                                label: 'Keep',
                                className: 'btn-primary',
                                callback: function(){
                                          
                                }
                            }
                            
                        }
                    });
                }
            );
        }

        _.delay(function () {
            $('#'+self.selectedPinName())[0].scrollIntoView({behavior: "smooth"});
        },700);
    }

    async populateCarousel() {
        if(this.existingImages && this.existingImages.length > 0) {
            for(var i = 0 ; i< this.existingImages.length ; i++) {
                let urlNameDict = await this.waypointManager.getImageUrl(this.waypoint, this.existingImages[i]);
                this.addImageToCarousel(urlNameDict[0], urlNameDict[1], this.existingImages.length, true);
            }
        }
    }

    getInitialBackgroundImage() {
        return this.waypoint.pinBackground;
    }

    deleteWaypoint() {
        this.waypointManager.deleteWaypoint(this.waypoint);
        console.log('delete waypoint');
    }
    

    selectCurrentPinImage() {
        var pinBackgrounds = $("#pin-background-picker .img-frame");
        for(let i = 0; i < pinBackgrounds.length; i++) {
            var pinBackground = pinBackgrounds[i];
            if($(pinBackground).attr("id") == this.waypoint.pinBackground) {
                this.selectBackgroundImageFromPinPicker(pinBackground);
                break;
            }
        }

        var pinImages = $("#pin-picker .img-frame");
        for(let i = 0; i < pinImages.length; i++) {
            var pinImage = pinImages[i];
            if($(pinImage).attr("id") == this.waypoint.pinImage) {
                this.selectPinImageFromPinPicker(pinImage);
                break;
            }
        }
    }

    removeExistingImageWithName(filename) {
        console.log("remove existing image");
        for(var i = 0; i < this.existingImages.length; i++) {
            var imageName = this.existingImages[i];
            if(imageName == filename) {
                this.imagesToDelete.push(imageName);
                this.existingImages.splice(i,1);
                this.bExistingImagesChanged = true;
                $('#'+filename.substring(0,filename.length-4)).remove();
                this.swiper.update();
                break;
            }
        }
    }

    saveAndClose(name, desc, pinImage, pinBackground) {
        let oldImages = this.bExistingImagesChanged ? this.imagesToDelete : null;
        this.waypointManager.updateWaypoint(this.waypoint, name, desc,  pinImage, pinBackground, this.existingImages, this.pendingImages, oldImages);
    }
}

export default EditWaypointView;
