import addAjax from './AjaxQueue';
import { getAjaxRequest } from './AjaxHelper';
import _ from 'underscore';

export function decryptJSON(json, propertiesToDecrypt, postDecryptFunction, retryCount) {
    if(retryCount == null) {
        retryCount = 0;
    }

    var jsonToDecrypt = {};
    for (let prop in propertiesToDecrypt) {
        var value = propertiesToDecrypt[prop];
        if(Object.prototype.hasOwnProperty.call(json, value)) {
            jsonToDecrypt[value] = json[value];
        }
    }

    addAjax({
        'url': 'decryptJSON.php',
        'method': 'POST', // default 'GET'
        'data': JSON.stringify(jsonToDecrypt),
        'success': function (returnedJSON) {  
        for (let prop in propertiesToDecrypt) {
            var value = propertiesToDecrypt[prop];
            if(Object.prototype.hasOwnProperty.call(json, value) && Object.prototype.hasOwnProperty.call(returnedJSON, value)) {
                json[value] = returnedJSON[value];
            }
        }
        if(retryCount > 0){console.log("SUCCESS AFTER RETRY #" + retryCount);}
            postDecryptFunction(json);
        },
        'error': function () { 
            if(retryCount > 5) {
                console.log("Retried 5 times and still failed to decrypt"); 
            } else {
                _.delay(function () {
                        decryptJSON(json, propertiesToDecrypt, postDecryptFunction, retryCount++);
                }, 10000);
            }
        },
    });
}
export default decryptJSON;

export function encryptJSON(json, propertiesToEncrypt, postEncryptFunction) {
  var script = "encryptJSON.php";
  var ajaxRequest = getAjaxRequest();  // The variable that makes Ajax possible!
  var jsonToEncrypt = {};

  //console.log("encryptFirebaseJSON before request");
  if(ajaxRequest != null) {

      for(let prop in propertiesToEncrypt) {
          var value = propertiesToEncrypt[prop];
          if(Object.prototype.hasOwnProperty.call(json, value)) {
              jsonToEncrypt[value] = json[value];
          }
      }

      // Create a function that will receive data sent from the server
      ajaxRequest.onreadystatechange = function () {
          //console.log("encryptFirebaseJSON on ready state");
          if (ajaxRequest.readyState == 4) {
              var returnedJSON = JSON.parse(ajaxRequest.responseText);

              for (let prop in propertiesToEncrypt) {
                  var value = propertiesToEncrypt[prop];
                  if(Object.prototype.hasOwnProperty.call(json, value) && Object.prototype.hasOwnProperty.call(returnedJSON, value)) {
                      json[value] = returnedJSON[value];
                  }
              }
              postEncryptFunction(json);
          }
      }

      ajaxRequest.open("POST", script, true);
      ajaxRequest.setRequestHeader("Content-type", "application/json")
      ajaxRequest.send(JSON.stringify(jsonToEncrypt));
  }         
}

//apparently math.random isn't random enough and collisions can occur, but with crypto it's pretty good. There is
//still the slightest chance collisions can occur using this function, but the better generator seems to be down, 
//and I'm not sure we should rely on something that might go down at any time
//https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
export function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))
}

//needed for free text that might get used in part of a firebase path/key
export function encodeAsFirebaseKey(key) {
    return key.replace("%","%25").replace(".","%2E").replace("#","%23").replace("$","%24").replace("/","%2F").replace("[","%5B").replace("]","%5D");
}

//needed for free text that might get used in part of a firebase path/key
//we actually probably don't ever need to decode...
export function decodeFromFirebaseKey(key) {
    return key.replace("%25","%").replace("%2E",".").replace("%23","#").replace("%24","$").replace("%2F","/").replace("%5B","[").replace("%5D","]");
}

