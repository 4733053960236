import $ from 'jquery';
import _ from 'underscore';

import { gSubscriptionSettings } from './SubscriptionSettings';

import { isAndroid, isIOS } from './minimumrequirements';

const FADE_OUT_SPLASH = 2000;
const FADE_IN_SPLASH = 500;


export function showMainUIComponents(province) {

  showFirebaseLoading("Loading map data...");

  _.delay(function () {

      // show splash page before hiding the other features
      $("#splash_screen").fadeOut(FADE_OUT_SPLASH, function () {
          console.log("faded out splash screen");
      });


      document.getElementById('headerDiv').style.visibility = "visible";
      document.getElementById('mapid').style.visibility = "visible";
      document.getElementById('leftDivContainer').style.visibility = "visible";
      document.getElementById('bottomDivContainer').style.visibility = "visible";
      document.getElementById('leftSidebar').style.visibility = "visible";
      document.getElementById('rightSidebar').style.visibility = "visible";

      
      if(gSubscriptionSettings.getSubscription()) {
          $('#publicLandsButtonText').text(gSubscriptionSettings.getSubscription().name);
          $('#publicLandsButton').show();    
      } else {
          $('#publicLandsButton').hide();    
      }

      if(province.getProperty("HAS_COUNTY_MAPS_IAP")) {
          $('#countyMapsButton').show();  
      } else {
          $('#countyMapsButton').hide(); 
      }

      document.getElementById('firebase-login-box').style.visibility = "hidden";

      hideFirebaseLoading();

  }, FADE_OUT_SPLASH);
}

export function hideMainUIComponents() {
  $("#splash_screen").fadeIn(FADE_IN_SPLASH, function () {
      console.log("faded in splash screen");
      document.getElementById('headerDiv').style.visibility = "hidden";
      document.getElementById('mapid').style.visibility = "hidden";
      document.getElementById('leftDivContainer').style.visibility = "hidden";
      document.getElementById('bottomDivContainer').style.visibility = "hidden";
      document.getElementById('leftSidebar').style.visibility = "hidden";
      document.getElementById('rightSidebar').style.visibility = "hidden";
  });
}

export function showFirebaseLogin() {
  if(!(isAndroid() || isIOS())) {
      $("#firebase-login-box").fadeIn(FADE_OUT_SPLASH, function () {
          // document.getElementById('firebase-login-box').style.visibility = "visible";
      });
  }
}

export function hideProvinceSelection() {
  $("#province-selection-box").fadeOut(FADE_OUT_SPLASH, function () {

  });
}

export function showProvinceSelection() {
  hideFirebaseLogin();
  
  //hide the iHunter logo if the window is too small.
  if($("#province-selection-box").height() < 620) {
      $("#province-selection-box-ihunter-logo").height($("#province-selection-box").height()- 415 - 20);
  }
  $(window).resize(function() {
      if($("#province-selection-box").is(":visible")) {
          if($("#province-selection-box").height() < 620) {
              $("#province-selection-box-ihunter-logo").height($("#province-selection-box").height() - 415 - 20);
          } else {
              $("#province-selection-box-ihunter-logo").height(187.5)
          }
      }
  });

  _.delay(function () {
      $("#province-selection-box").fadeIn(FADE_OUT_SPLASH, function () {
      });
  }, FADE_OUT_SPLASH);
}

export function showFirebaseLoading(loadingtext) {
  document.getElementById('loadingview').style.visibility = "visible";
  document.getElementById('loadingtext').textContent = loadingtext;
}

export function hideFirebaseLoading() {
  document.getElementById('loadingview').style.visibility = "hidden";
  document.getElementById('loadingtext').textContent = "";
}

export function hideFirebaseLogin() {
  _.delay(function () {
      $("#firebase-login-box").fadeOut(FADE_OUT_SPLASH, function () {
          // document.getElementById('firebase-login-box').style.visibility = "hidden";
      });
  }, FADE_OUT_SPLASH);
}

export function showIOSAppStoreLink() {
  centerElementHorizontally(document.getElementById('firebase-login-box'));
  document.getElementById('ios-link').style.visibility = "visible";
}

export function showGooglePlayStoreLink() {
  centerElementHorizontally(document.getElementById('firebase-login-box'));
  document.getElementById('android-link').style.visibility = "visible";
}

export function showSupportedBrowsers() {
  document.getElementById('browser-support').style.visibility = "visible";
}

export function showSeatsExceeded() {
  hideFirebaseLoading();
  document.getElementById('seat-limit-exceeded').style.visibility = "visible";
}

export function hideSeatsExceeded() {
  document.getElementById('seat-limit-exceeded').style.visibility = "hidden";
}

export function centerElementHorizontally(elem) {
  elem.style.left = '50%';
  elem.style.right = 'auto';
  elem.style.transform = 'translate(-50%, -50%)';
  elem.style["-webkit-transform"] = "translate(-50%, -50%)";
  elem.style["-ms-transform"] = "translate(-50%, -50%)";
  elem.style["transform"] = "translate(-50%, -50%)";
}

// export function setProvinceIcon(code) {
//   $('.icon-ihunter').css('background-image', 'url(images/ihunter_logo_' + code + '.png)');
//   $('.icon-ihunter').css('background-size', 'cover');
//   $('.icon-ihunter').css('height', '65px');
//   $('.icon-ihunter').css('width', '70px');
// }

export function setCursorSpinner(bWait) {
    if(bWait) {
        $('body').addClass('waiting');
    } else {
        $('body').removeClass('waiting');
    }
}
