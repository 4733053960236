import $ from 'jquery';
import L from 'leaflet';
import bootbox from 'bootbox';

import { openCustomModal } from './ModalHelper';

import View from './View';
import UserMapLayer from './UserMapLayer';
import { TileServiceType } from './Settings';
import { OverlayType } from './UserMapLayer';

export class AddUserMapLayerView extends View {
    constructor(modalView, mapProvider, dataService) {
        super();
        this.modal = modalView;
        this.service = dataService;
        this.map = mapProvider;
        this.selectedServiceType = TileServiceType.XYZ;
        this.selectedOverlayType = OverlayType.OVERLAY;
        this.disableSaveButton();
        this.populatePage();
        this.wizardAttempted = false;
        this.transparentIfPossibleChecked = false;
        this.checkSaveButton();
    }

    populatePage() {
        var self = this;
        
        //do this for each input box
        $("#titleInput").keyup(function() {
            self.checkSaveButton();
        });
        $("#urlInput").keyup(function() {
            self.checkSaveButton();
        }); 

        this.setButtons();
        this.updateOptionsSection();
    }

    setButtons() {
        var self = this;
        this.modal.find('#modalPrimaryButton').off(); //removes previous onclick
        this.modal.find('#modalPrimaryButton').on('click', 
            function(event) {
                self.saveAndClose();
            }
        );
        this.modal.find('#modalPrimaryButton').attr("data-dismiss",""); //we don't necessarily want to closee the modal when we click the primary button. Remove this, and add it back when closed.

        //set the button group button functions        
        $('#serviceTypeButtonGroup button').click(function() {
            $(this).addClass('selected').siblings().removeClass('selected');

            if($(this).html() == 'XYZ') {
                $('#serviceTypeDescriptionDiv').html('An XYZ map system requires an x, y, and z value to specify the tile to be loaded from the server. The URL you enter below must contain {x}, {y}, and {z} which will be substituted for the actual values at run time.');
                self.selectedServiceType = TileServiceType.XYZ;
            } 
            else if($(this).html() == 'WMS') {
                $('#serviceTypeDescriptionDiv').html('Provide a URL containing a complete WMS GetMap request or the host URL with the "version" parameter and iHunter will provide a wizard that allows you to select the layers to create a WMS GetMap request.');
                self.selectedServiceType = TileServiceType.WMS;                
            } 
            else if($(this).html() == 'TMS') {
                $('#serviceTypeDescriptionDiv').html('A TMS map system requires an x, y, and z value to specify the tile to be loaded from the server. The URL you enter below must contain {x}, {y}, and {z} which will be substituted for the actual values at run time. The URL is similar to XYZ but has a flipped y-coordinate.');
                self.selectedServiceType = TileServiceType.TMS;
            } 
            else {
                $('#serviceTypeDescriptionDiv').html('Map layers are typically either XYZ, TMS, or WMS. If you have a map source, but are unsure of its type, feel free to contact us and we can try to help.');
                self.selectedServiceType = TileServiceType.None;
            }

            self.updateOptionsSection();
            self.checkSaveButton();
        });

        $('#mapTypeButtonGroup button').click(function() {
            $(this).addClass('selected').siblings().removeClass('selected');

            if($(this).html() == 'Base Map') {
                $('#mapTypeDescriptionDiv').html('A base map that can be selected instead of the 4 default Google base maps. This map will be selectable from the bottom left corner of the web app.');
                self.selectedOverlayType = OverlayType.BASEMAP;
            } 
            else {
                $('#mapTypeDescriptionDiv').html('A map overlay that sits above the base map. The opacity of this map layer can be adjusted to see the base map underneath.');
                self.selectedOverlayType = OverlayType.OVERLAY;
            } 
        });
    }

    updateOptionsSection() {
        var optionsHtml = '<div class="ihunter-menu-heading">Options</div>';
        optionsHtml += this.getHTMLForRowWithNumericEntry(this.originalMapLayer ? this.originalMapLayer.maxZoom : 17, 10, 21, 'maxZoomInput', 'Max Zoom Level (use 17 if unsure)');

        if(this.selectedServiceType == TileServiceType.WMS) {
            optionsHtml += this.getHTMLForOptionsRowWithSwitch(false,'transparency-wms-option', 'Transparent background (if possible)');
        }

        $("#serviceTypeOptions").html(optionsHtml);//add options
        var self = this;
        $('#transparency-wms-option').click(function() {
            var checked = this.checked;
            self.transparentIfPossibleChecked = checked;
        });
        this.addClickFunctionsForMaxZoom(10, 21);
    }

    getHTMLForRowWithNumericEntry(defaultNumber,min,max,inputID,text) {
        var row = '<div id="maxZoomDiv" class="max-zoom ihunter-menu-switch-row">';
            row += '<div class="middle-div">';
                row += '<div class="ihunter-menu-text large">'+ text + '</div>';
            row += '</div>';
            row += '<div class="float-right">';
                row += '<button type="button" id="maxZoomSubtract" class="max-zoom-sub">-</button>';
                row += '<input type="number" id="'+inputID+'" value="'+defaultNumber+'" min="'+min+'" max="'+max+'" class="input-text-qty" readonly="readonly"/>';
                row += '<button type="button" id="maxZoomAdd" class="max-zoom-add">+</button>';
            row += '</div>';
        row += '</div>';
        return row;
    }

    addClickFunctionsForMaxZoom(min,max) {
        $('.max-zoom-add').off();
        $('.max-zoom-sub').off();
        $('.max-zoom-add').click(function () {
            if ($(this).prev().val() < max) {
                $(this).prev().val(+$(this).prev().val() + 1);
            }
        });
        $('.max-zoom-sub').click(function () {
            if ($(this).next().val() > min) {
                if ($(this).next().val() > 1) $(this).next().val(+$(this).next().val() - 1);
            }
        });
    }

    getHTMLForOptionsRowWithSwitch(bChecked, inputID, title) {
        var checked = bChecked ? 'checked' : '';
        var row = '';
        row += '<div class="ihunter-menu-switch-row">';
        row += '<div class="middle-div">';
            row += '<div class="ihunter-menu-text large">'+ title + '</div>';
            // row += abstract ? '<div class="ihunter-menu-text small">' + abstract + '</div>' : '';
        row += '</div>';

        row += '<label class="ihunter-menu-switch float-right">';
            row += '<input id="'+ inputID + '" type="checkbox" ' + checked + '>';
            row += '<span class="slider-switch round"></span>';
        row += '</label>';
        row += '</div>';
        return row;
    }


    checkSaveButton() {
        var titleRequirementsOK = this.checkTitleRequirements(); //want to make sure both functions run.
        var urlRequirementsOK = this.checkUrlRequirements();
        if(titleRequirementsOK && urlRequirementsOK) { 
            this.enableSaveButton();
        } else {
            this.disableSaveButton();
        }
    }

    checkTitleRequirements() {
        var titleOK = $("#titleInput").val().trim().length > 0;
        $('#titleRequirement').text(titleOK ? '' : '*(You must include a title)');
        return titleOK;
    }

    checkUrlRequirements() {
        var urlText = $("#urlInput").val().trim().toLowerCase();

        if(urlText.toLowerCase().includes("ihunterapp")) {
            bootbox.alert({
                title: "iHunter layers detected",
                message: "You cannot add iHunter layers. Please contact iHunter at info@ihunterapp.com if you have any questions."
            });
            return false;
        }
        
        if(this.selectedServiceType == TileServiceType.XYZ || this.selectedServiceType == TileServiceType.TMS) {
            var containsXYZ = urlText.toLowerCase().includes('{x}') && urlText.includes('{y}') && urlText.includes('{z}'); 
            $('#mapUrlRequirement').text(containsXYZ ? '' : '*(map url does not contain {x}, {y}, or {z})');
            return containsXYZ;
        } else if(this.selectedServiceType == TileServiceType.WMS) {
            var versionExists = urlText.toLowerCase().includes('version');
            $('#mapUrlRequirement').text(versionExists ? '' : '*(version parameter not detected)');
            return versionExists;
        } 

        $('#mapUrlRequirement').text('*');
        return false;
    }

    removeBadParamsFromUrlString(url) {
        if( !(url.includes("bbox") || url.includes("width") || url.includes("height")) ) {
            return url;
        }
        
        var indexOfParams = url.indexOf("?");
        var hostUrl = url.substring(0, indexOfParams);
        var originalParams = url.substring(indexOfParams+1, url.length);

        var newParams = '';
        var arrayOfParams = originalParams.split("&");
        for(var i = 0; i < arrayOfParams.length; i++) {
            var param = arrayOfParams[i];
            if(!(param.includes("bbox") || param.includes("height") || param.includes("width"))) {
                newParams += param === '' ? param : "&"+param;
            }
        }
        return newParams.length > 0 ? hostUrl+"?"+newParams : hostUrl;
    }

    getGetCapabilitiesURL(originalUrl) {
        //needs to have a service=wms, request=getcapabilities, and version=###
        //take the original url and get the version, then append.
        var indexOfParams = originalUrl.indexOf("?");
        var originalParams = originalUrl.substring(indexOfParams+1, originalUrl.length);
        var newUrl = originalUrl.substring(0, indexOfParams);
        var newParams = '';

        var arrayOfParams = originalParams.split("&");
        for(var i = 0; i < arrayOfParams.length; i++) {
            var paramString = arrayOfParams[i];
            var paramParts = paramString.split("=");
            if(paramParts.length == 2) {
                var param = paramParts[0].toLowerCase();
                var paramVal = paramParts[1].replace("%2f", "/").replace("%3A", ":");
                if(param == "version") {
                    newParams += newParams.length == 0 ? 'version=' + paramVal : '&version=' + paramVal;
                }
                if(param == "transparent") {
                    newParams += newParams.length == 0 ? 'transparent=' + paramVal : '&transparent=' + paramVal;
                }
            }    
        }
        var transparencyParam = '';
        if(this.selectedServiceType === TileServiceType.WMS && !newParams.toLowerCase().includes("transparent")) {
            transparencyParam += "&transparent=" + this.transparentIfPossibleChecked;
        }

        newUrl += '?service=wms&request=GetCapabilities&styles=&format=image/png' + transparencyParam + (newParams.length > 0 ? '&' + newParams : '');
        return newUrl;
    }

    enableSaveButton() {
        this.modal.find('#modalPrimaryButton').removeAttr("disabled");
    }

    disableSaveButton() {
        this.modal.find('#modalPrimaryButton').attr("disabled", true);
    }

    getWMSURLForZoom(map, zoom) {
        var tileSize = map.leafletTileLayer.getTileSize();
        var tilePoint = this.map.leafletMap.project(new L.LatLng(this.map.province.getProperty("CENTER_LATITUDE"), this.map.province.getProperty("CENTER_LONGITUDE")), zoom).floor().unscaleBy(tileSize).floor();
        return map.leafletTileLayer.getTileUrl(tilePoint, zoom);
    }

    getTMSURLForZoom(map, zoom) {
        var tileSize = map.leafletTileLayer.getTileSize();
        var coords = this.getCoordsFromLatLng(this.map.province.getProperty("CENTER_LATITUDE"), this.map.province.getProperty("CENTER_LONGITUDE"), 
        zoom, tileSize);
        return map.leafletTileLayer.getTestTileUrl(coords);
    }

    saveAndClose() {
        var urlWithoutBadParams = this.removeBadParamsFromUrlString($("#urlInput").val().trim());
        if(this.selectedServiceType == TileServiceType.WMS && !urlWithoutBadParams.toLowerCase().includes("&transparent") && this.transparentIfPossibleChecked) {
            urlWithoutBadParams += "&transparent=true";
        }

        var tileServiceType = this.selectedServiceType;
        var title = $("#titleInput").val().trim();
        var subtitle = $("#subtitleInput").val().trim();
        var googleMapType = 0; //this is for actual base maps...this will always be zero and can likely be removed...
        var map = new UserMapLayer(this.map).initWithParams(urlWithoutBadParams, title, subtitle, googleMapType, tileServiceType, null, $("#maxZoomInput").val(), 1, true, this.selectedOverlayType, null);
        map.setTileLayer();


        if(tileServiceType === TileServiceType.WMS) {
            map.leafletTileLayer.addTo(this.map.leafletMap);
            this.checkTileUrlAttempt(map, this.getWMSURLForZoom(map, 6), this.getWMSURLForZoom(map, 11), this.getWMSURLForZoom(map, 15));     
            map.leafletTileLayer.remove();       
        } 
        else {
            map.leafletTileLayer.addTo(this.map.leafletMap);
            this.checkTileUrlAttempt(map, this.getTMSURLForZoom(map, 6), this.getTMSURLForZoom(map, 11), this.getTMSURLForZoom(map, 15));     
            map.leafletTileLayer.remove();
        }
    }

    getCoordsFromLatLng(lat, lon, zoom, tileSize) {
        // var tileSize = {x:256, y:256};
        var pixelPoint = this.map.leafletMap.project(new L.LatLng(lat, lon), zoom).floor();
        var coords = pixelPoint.unscaleBy(tileSize).floor();
        coords.z = zoom;
        return coords;
    }

    //Try to load the image at url1
    //if it succeeds show the confirmation to the user
    //if it fails, try the second attempt, passing in url2, url3, null
    //if it fails, try the final attempt
    checkTileUrlAttempt(userMapLayer, url1, url2, url3) {
        var self = this;
        var image = new Image();

        image.onload = function() {
            if ('naturalHeight' in this) {
                if (this.naturalHeight + this.naturalWidth === 0) {
                    this.onerror();
                    return;
                }
            } else if (this.width + this.height == 0) {
                this.onerror();
                return;
            }
            // At this point, there's no error.
            var html = '<img src="' + url1 + '">';
            html += '<div>Does this image look like part of the map you were trying to add?</div>';
            openCustomModal("Validating Map Layer", html, 'Yes','No', 30, 90, true);

            $('#secondaryModal').find('#modalPrimaryButton').off(); //removes previous onclick
            $('#secondaryModal').find('#modalPrimaryButton').click(function() {
                self.saveUserMapLayer(userMapLayer);
            });
            $('#secondaryModal').find('#modalPrimaryButton').removeAttr("disabled");
            
        };

        image.onerror = function() {
            if(url3 != null) {
                self.checkTileUrlAttempt(userMapLayer, url2, url3, null);
            } else {
                self.checkTileUrlAttemptFinal(userMapLayer, url2);
            }
        };
        
        image.src = url1;
    }

    saveUserMapLayer(userMapLayer) {
        if(this.originalMapLayer) {
            this.service.db.replaceUserMapLayer(this.originalMapLayer, userMapLayer);
        } else {
            this.service.db.setUserMapLayer(userMapLayer);
        }
        $("#fullScreenModal .close").click();//close the main modal
    }

    //Try to load the image at zoom level 11
    //if it succeeds show the confirmation to the user
    //if it fails, show the wizard if it's WMS, otherwise tell the user that it failed
    checkTileUrlAttemptFinal(userMapLayer, url) {
        var self = this;
        var image = new Image();

        if(this.selectedServiceType === TileServiceType.WMS && !url.toLowerCase().includes("transparent") && this.transparentIfPossibleChecked) {
            url += "&transparent=" + this.transparentIfPossibleChecked;
        }
        
        image.onload = function() {
            if ('naturalHeight' in this) {
                if (this.naturalHeight + this.naturalWidth === 0) {
                    this.onerror();
                    return;
                }
            } else if (this.width + this.height == 0) {
                this.onerror();
                return;
            }
            // At this point, there's no error.
            var html = '<img src="' + url + '">';
            html += '<div>Does this image look like part of the map you were trying to add?</div>';
            openCustomModal("Validating Map Layer", html, 'Yes','No', 30, 90, true);
            
            $('#secondaryModal').find('#modalPrimaryButton').off(); //removes previous onclick
            $('#secondaryModal').find('#modalPrimaryButton').click(function() {
                self.saveUserMapLayer(userMapLayer);
            });
        };
        image.onerror = function() {
            if(userMapLayer.isWMS() && !self.wizardAttempted) {
                //START THE WIZARD
                self.wizardAttempted = true;
                window.onWMSWizard(self, userMapLayer, self.getGetCapabilitiesURL($("#urlInput").val().trim()));
            } 
            else {
                self.wizardAttempted = false;
                self.alertMapNotValidated(userMapLayer);
            }

        };
        
        image.src = url;
    }

    setUrl(url) {
        $("#urlInput").val(url);
    }

    alertMapNotValidated(userMapLayer) {
        var self = this;
        bootbox.confirm({
            title: "Map Layer Not Added",
            message: "We were not able to validate the map layer you were trying to add. Perhaps one of parameters you supplied was incorrect, or perhaps we did something wrong. If you need help, email us with the URL you were trying to add, and we will do our best to figure out the problem.<br><br>If you are confident that this url is correct you can attempt to add it, however it likely will not show up on the map.",
            buttons: {
                cancel: {
                    label: 'Cancel',
                    className: 'btn-secondary'
                },
                confirm: {
                    label: 'Add Map Anyway',
                    className: 'btn-warning'
                }
            },
            callback: function (result) {
                if(result) { //confirm
                    self.saveUserMapLayer(userMapLayer);
                } 
            }
        });
    }

    populateWithUserMapLayer(userMapLayer) {
        if(userMapLayer) {
            this.originalMapLayer = userMapLayer;
            $("#titleInput").val(this.originalMapLayer.displayTitle);
            $("#subtitleInput").val(this.originalMapLayer.displaySubtitle);
            if(this.originalMapLayer.isBaseMap()) { 
                $('#mapTypeButtonGroup #buttonBasemap').click();
            } else {
                $('#mapTypeButtonGroup #buttonOverlay').click();
            }

            if(this.originalMapLayer.tileServiceType == TileServiceType.XYZ) {
                $('#serviceTypeButtonGroup #buttonXYZ').click();
            } else if(this.originalMapLayer.tileServiceType == TileServiceType.WMS) {
                $('#serviceTypeButtonGroup #buttonWMS').click()
            } else {
                $('#serviceTypeButtonGroup #buttonTMS').click()
            }

            $("#urlInput").val(this.originalMapLayer.tileURL);
            this.checkSaveButton();
        }
    }

}

export default AddUserMapLayerView;
