import L from 'leaflet';

export class TrackLocation {
    constructor() {
        this.latitude = 0;
        this.longitude = 0;
        this.time = 0;
        this.course = 0;
        this.altitude = 0;
        this.speed = 0;
        this.verticalAccuracy = 0;
        this.horizontalAccuracy = 0;
        this.pause = false;
    }

    getLocation() {
        return new L.LatLng(this.latitude, this.longitude, this.altitude);
    }

    setLatitude(lat) {
        this.latitude = lat;
    }
    
    setLongitude(lon) {
        this.longitude = lon;
    }
    
    setTime(time) {
        this.time = time;
    }
    
    setCourse(course) {
        this.course = course;
    }

    setAltitude(alt) {
        this.altitude = alt;
    }
    
    setSpeed(speed) {
        this.speed = speed;
    }
    
    setVerticalAccuracy(acc) {
        this.verticalAccuracy = acc;
    }
    
    setHorizontalAccuracy(acc) {
        this.horizontalAccuracy = acc;
    }

    setPause(state) {
        this.pause = state;
    }
    
}

export default TrackLocation;
