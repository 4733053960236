import bootbox from 'bootbox';

import Observable from './Observable';

import { gSettings } from './Settings';
import { getAjaxRequest } from './AjaxHelper';

export default class AccountManager extends Observable {

    constructor(dataService) {
        super();
        
        this.service = dataService;

    }

    getUserAsContact() { 
        return this.service.userContact;
    }

    async sendPasswordResetEmail() { 

        try {
            await this.service.sendPasswordResetEmail();

            bootbox.alert({
                message: "A password reset email has been sent to " + this.getEmail() + ". Please check your spam/junk folder if you don't see an email within a couple of minutes.",
                centerVertical: true
            });

        }catch(error) {
            bootbox.alert({
                message: "Password reset email failed: " + error.message,
                centerVertical: true
            });
        }
    }

    async changeAccountEmail() {
        bootbox.prompt({
            title: "Please enter your current iHunter Account password", 
            centerVertical: true,
            inputType: 'password',
            callback: async (result) => { 
                if(result != null && result != "") {
                    try {
                        let test = await this.service.reauthenticateWithCredential(result);
                        console.log(test);
                        this.promptForNewEmail();

                    }catch(error) {
                        bootbox.alert({
                            message: "Error validating credentails: " + error.message,
                            centerVertical: true
                        });
                    }
                }
            }
        });
    }

    promptForNewEmail() {
        bootbox.prompt({
            title: "Please enter your new email", 
            centerVertical: true,
            inputType: 'email',
            callback: async (result) => { 
                if(result != null && result != "") {

                    await this.service.updateEmail(result);

                    bootbox.alert({
                        message: "You have successfully changed your iHunter email. Please use your new email to login going forward.",
                        centerVertical: true
                    });
                }
            }
        });
    }

    findWebSubscription() {
        let purchases = gSettings.purchasesWeb;
        if(purchases.length > 0) {
            for(let i in purchases) {
                let purchase = purchases[i];

                if(!purchase || !purchase.purchase_id){
                    continue;
                }

                let pid = purchase.purchase_id;

                // Only flag purchases with "sub_" as a prefix
                if(typeof(pid) === 'string' && 
                    pid.startsWith('sub_')) {
                    return purchase;
                }
            }
        }
        return null;
    }    

    getEmail() { 
        var email = this.service.user.email;
        if(email == null || email == "") {
            return "UnknownEmail@iHunter.com";
        }
        return email;
    }

    deleteAccount() { 
        this.service.db.deleteAccount();
    }

    updateUserProfile(name) { 
        this.service.db.updateUserProfile(name);
    }

    pushProfileImageToStorage(pendingImage) { 
        this.service.storage.pushProfileImageToStorage(pendingImage, this.service.user.uid, false);
    }

    deleteProfileImageInStorage() { 
        this.service.storage.deleteProfileImageInStorage(this.service.user.uid, false);
    }

    async requestBillingPortal(subscription) {
        return await this.service.dal.portal(subscription);
        // return new Promise((resolve, reject) => {
        //     if(!subscription) {
        //         return reject("No subscription found");
        //     }

            

        //     var ajaxRequest = getAjaxRequest();
        //     if(ajaxRequest != null) {
        //         ajaxRequest.onreadystatechange = () => {
        //             if(ajaxRequest.readyState === 4) {
        //                 if(ajaxRequest.status === 200) {
        //                     if(ajaxRequest.responseText){
        //                         let portalUrl = ajaxRequest.responseText;
        //                         if(portalUrl) {
        //                             return resolve(portalUrl);//window.open(portalUrl);
        //                         }
        //                     }                           
        //                 }
        //                 return reject("Billing portal request failed");
        //             }
        //         }
        //         let query = this.service.config.functionURL + '/billing-api/portal';
        //         ajaxRequest.open("POST", `${query}?province=${gSettings.provinceCode}&purchase=${subscription.purchase_id}&sku=${subscription.sku}`, true);
        //         ajaxRequest.setRequestHeader('authorization', 'Bearer ' + this.service.accessToken);
        //         // ajaxRequest.setRequestHeader('province', gSettings.provinceCode);
        //         // ajaxRequest.setRequestHeader('sku', subscription.sku);
        //         // ajaxRequest.setRequestHeader('purchase', subscription.purchase_id);
        //         ajaxRequest.send(null);
        //     }else {

        //         return reject("Ajax request error");
        //     }   
        // });
    }

    requestPurchaseInfo(purchaseId) {
        return new Promise((resolve, reject) => {            
            var ajaxRequest = getAjaxRequest();
            if(ajaxRequest != null) {
                // Create a function that will receive data sent from the server
                ajaxRequest.onreadystatechange = () => {
                    if (ajaxRequest.readyState == 4) {
                        if(ajaxRequest.status == 200) {
                            // console.log(ajaxRequest.responseText);
                            resolve(ajaxRequest.responseText);
                        }
                        else {
                            reject(ajaxRequest.responseText);
                        }
                    }
                }
      
                let query = this.service.config.functionURL + '/billing/purchase/info';
                ajaxRequest.open("GET", `${query}?purchase=${purchaseId}`, true);
                ajaxRequest.setRequestHeader('Authorization', 'Bearer ' + this.service.accessToken);
                // ajaxRequest.setRequestHeader('purchase', purchaseId);
                ajaxRequest.send(null);
            }else {

                return reject("Ajax request error");
            }   
        });
    }
}
