import $ from 'jquery';
import bootbox from 'bootbox';

import { gSettings } from './Settings';
import { gSubscriptionSettings } from './SubscriptionSettings';
import { isProduction, openURLInNewTab } from './Environment';
import { openModalURL, openCustomModal } from './ModalHelper';
import ToolsMenuView from './ToolsMenuView';

import { createSwitchRowHTML } from './SettingsMenuView';

export class SubscriptionMenuView extends ToolsMenuView {
    constructor(mapProvider, waypointManager, regulationService) {
        super(mapProvider, waypointManager);


        this.map.onUpdateSubscriptionLayer((options) => {
            if(options) {
                this.updateOptions();
            }else {
                this.updateUI();
            }
        });

        this.regulationService = regulationService;

        window.onPurchaseSubscription = () => {
            this.onPurchaseSubscription();
        };

        window.whatIsClicked = (div, layerID) => {
            this.whatIsClicked(div, layerID);
        };

        window.onSubscriptionLayerInfoClicked = onSubscriptionLayerInfoClicked;
        window.onManageSubscriptionClicked = onManageSubscriptionClicked;
    }

    get header() {
        var subscription = gSubscriptionSettings.getSubscription();
      return subscription.name;
    }

    populatePage() {
        $('#purchaseView').empty();
        $('#subscriptionMenuCollapsableList').empty();

        if(!gSubscriptionSettings.isSubscriptionPurchased()) {
            $('#accordian').hide();
            $('#purchaseView').show();
            this.addPurchaseView();
        }
        else {
            $('#accordian').show();
            $('#purchaseView').hide();
            this.addOptions();
            this.addSearchableMapLayers();   
            this.addUserGuide();
            this.addSubscriptionLayerInfo();
            this.addManageSubscription();
        }
    }

    addPurchaseView() {
        var subscription = gSubscriptionSettings.getSubscription();

        // <h3 style="text-align: center;">'+subscription.name+'</h3>
        var content = '<p style="text-align: center;">'+ subscription.name +'</p>';
        
        for(var feature in subscription.features) {
            content += '<p style="margin: 20px; margin-left: 30px;"><img src="images/listicon_bulletpoint.png">'+ subscription.features[feature] +'</p>';
        }

        content += '<p style="margin-left: 30px; margin-right: 20px;"><img src="images/listicon_bulletpoint.png">Auto-renews until cancelled</p>';

        content += '<button id="subscription_buyButton" class="btn btn-primary" style="width:300px; margin: 20px; text-align: center;" type="button" onclick="onPurchaseSubscription()">Subscribe</button>';

        $('#purchaseView').append(content);

        this.updateBuyButton();
    }

    updateBuyButton() {
        var subscription = gSubscriptionSettings.getSubscription();

        const sku = subscription.sku_web;

        this.map.service.database.getProductPrice(sku).then((price) => {
            var priceText = `Subscribe $${price} / Year`;
            $('#subscription_buyButton').text(priceText);
        }, (error) => {
            console.error(`Failed to get subscription price: '${sku}'`,error);
        });
        
        // this.regulationService.getSubscriptionPlan(subscription.sku_web).then(function(jsonContent) {
        //     var jsonObj = JSON.parse(jsonContent);
        //     // console.log(jsonObj);
        //     if(Object.prototype.hasOwnProperty.call(jsonObj, "amount")) {
        //         var price = parseFloat(jsonObj.amount) / 100;
        //         var priceText = "Subscribe $"+price.toFixed(2)+" / Year";
        //         $('#subscription_buyButton').text(priceText);
        //     }
        // }, function(err) {
        //     console.log(err);
        // });
        
    }

    addSearchableMapLayers() {
        for(var i = 0; i < this.map.mapLayersArray.length; i++) {
            var mapLayer = this.map.mapLayersArray[i];
            if(mapLayer.layerRoot == 'subscription') {
                if(mapLayer.remoteSearchURL) {
                    this.addRemoteSearchableMapLayer(mapLayer, '#subscriptionMenuCollapsableList');
                }
                else if(mapLayer.searchable) {
                    this.addSearchableMapLayer(mapLayer, '#subscriptionMenuCollapsableList');
                } 
            }
        } 
    }

    updateOptions() {
        $('#subscription-optionsContent').html('<div class="panel-body">' + this.getOptionsContent() + '</div>');
    }

    addOptions() {
        this.createHeadingWithContentAndAppend('subscription-optionsHeader', 'subscription-optionsContent', 'Layers / Legend', this.getOptionsContent(), '#subscriptionMenuCollapsableList');
    }

    getOptionsContent() {
        var content = '<div class="collapsable-menu-content">';
        content += '<div class="ihunter-menu-row-nohover standard-text-row">' + 'With all layers enabled, performance may begin to degrade. Show only the layers that you are most interested in if things slow down.</div>';

        for(var i = 0; i < this.map.mapLayersArray.length; i++) {
            var mapLayer = this.map.mapLayersArray[i];
            if(mapLayer.layerRoot == 'subscription') {
                content += this.getOptionsRowForMapLayer(mapLayer);
                content += this.getWhatIsRowForMapLayer(mapLayer);
            }
        } 
        content += '</div>';
        return content;
    }

    getOptionsRowForMapLayer(mapLayer) {
        var onclick = 'boundarySwitchClicked(this,\'' + mapLayer.layerID + '\')';
        //this will create the color style for the switch
        $("<style type='text/css'> input#"+mapLayer.layerID+"-switch:checked + .slider-switch { background-color:" + mapLayer.getColor() + "; } </style>").appendTo("head");
        return createSwitchRowHTML('Show '+mapLayer.name+':', mapLayer.shouldShow(), mapLayer.layerID + '-switch', onclick);
    }

    getWhatIsRowForMapLayer(mapLayer) {
        var onclick = 'whatIsClicked(this,\'' + mapLayer.layerID + '\')';
        return this.createWhatIsRowHTML('What is '+mapLayer.name+'?', mapLayer.layerID + '-whatis', onclick);
    }

    whatIsClicked(div, layerID) {
        for(var i = 0; i < this.map.mapLayersArray.length; i++) {
            if(layerID == this.map.mapLayersArray[i].layerID) {
                var mapLayer = this.map.mapLayersArray[i];
                openCustomModal("", mapLayer.layerDescription, 'OK', null, 50, 80, false);
            }
        }  
    }

    addUserGuide() {        
        this.createHeadingWithContentAndAppend('subscriptionUserGuide', 'subscriptionUserGuideContent', 'User Guide', this.getUserGuideContent(), '#subscriptionMenuCollapsableList');
    }

    getUserGuideContent() {
        var html = '<div style="background:white; padding-top:10px; padding-bottom:10px">';
        var subscription = gSubscriptionSettings.getSubscription();
        var userGuideCount = subscription.getUserGuideCount();

        for(var i = 0; i < userGuideCount; i++) {
            var imgSource = subscription.getUserGuideImagePath(i);
            var text = subscription.getUserGuideText(i);
            html += '<div class="ihunter-menu-row-nohover userguide-text-row">' + text + '</div>';
            html += '<div class="userguide-img-div">' + '<img src="'+imgSource+'" class="userguide-img"></img>' + '</div>'; //width is 350 
            if(i != userGuideCount - 1) {
                html += '<hr class="left-hr-black-line">';
            }
        }
        html += '</div>';
        return html;
    }

    addSubscriptionLayerInfo() {
        var html = '<div class="ihunter-menu-row subscription-bottom-row" id="subscriptionLayerInfo" onclick="onSubscriptionLayerInfoClicked()"><div class="ihunter-menu-text-black">Subscription Layer Info</div><img src="images/arrow_orange_right.png"/></div>';
        $('#subscriptionMenuCollapsableList').append(html);
    }

    addManageSubscription() {
        var html = '<div class="ihunter-menu-row subscription-bottom-row" id="manageSubscription" onclick="onManageSubscriptionClicked()"><div class="ihunter-menu-text-black">Manage Subscription</div><img src="images/arrow_orange_right.png"/></div>';
        $('#subscriptionMenuCollapsableList').append(html);
    }    

    createWhatIsRowHTML(text, inputID, onclick) {
        var row = '';
        row += '<div id="'+inputID+'" class="ihunter-menu-row-nohover standard-text-row" onclick="'+onclick+'" style="color:#057bff" >' + text;
        row += '</div>';
        return row;
    }

    updateUI() {
        this.populatePage();
    }

    async onPurchaseSubscription() {
        var subscription = gSubscriptionSettings.getSubscription();

        try {
            let response = await this.waypointManager.requestSubscriptionCheckout(subscription.sku_web, subscription.name);
            
            openURLInNewTab(response.url);

        }catch(error) {
            console.error("Error:", error);
            alert("There was an error with the purchase of: "+subscription.name+".\n\nPlease try again or contact info@ihunterapp.com if this error continues.");
        }
    }

        

        
    
        // var urlParams = new URLSearchParams({
        //     SKU:subscription.sku_web,
        //     EMAIL:this.map.user.email,
        //     USER:this.map.user.uid,
        //     PROV:this.map.provinceCode,
        //     TITLE:subscription.name,
        //     IS_SUB:'TRUE'
        // });
        // urlParams.append('TESTING', isProduction() ? 'FALSE' : 'TRUE');
    
        // fetch("create-checkout-session.php?" + urlParams, 
        //     { method: "POST",}
        // )
        // .then(function (response) {
        //     // console.log(response.json());
        //     return response.json();
        // })
        // .then(function (session) {
        //     var testString = !isProduction() ? "&TESTING=TRUE" : "";
        //     if(session.id != undefined) {
        //         openURLInNewTab("redirect-to-checkout.php?sid='"+session.id+"'"+testString);
        //     } else {
        //         alert("There was an issue finding the product: "+subscription.name+".\n\nPlease try again or contact info@ihunterapp.com if this error continues.");
        //     }
        //     //we need to use a php script to redirect to, otherwise redirectToCheckout redirects our page.
        // })
        // .catch(function (error) {
        //     console.error("Error:", error);
        //     alert("There was an error with the purchase of: "+subscription.name+".\n\nPlease try again or contact info@ihunterapp.com if this error continues.");
        // });
   //}
    

}

export default SubscriptionMenuView;

function onSubscriptionLayerInfoClicked() {
    openModalURL('', gSubscriptionSettings.getSubscription().moreInfoURL);
}

function onManageSubscriptionClicked() {
    
    var subscription = gSubscriptionSettings.getSubscription();

    if (gSettings.isProductPurchasedWeb(subscription.sku_web) ) {
        window.onManageSubscription();
    }
    else if(gSettings.isProductPurchasedAndroid(subscription.sku_android)) {
        bootbox.alert({title:"Manage Android "+ subscription.name+" Subscription", message:"The "+ subscription.name+" subscription is enabled here because it was shared to this account by an Android device logged into this account. Please use your Android device to manage your subscription, or use the link below to follow Google\'s instructions: <br /><br /><a href='https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DDesktop&hl=en' target='_blank'>Cancel, pause, or change a subscription on Google Play</a>", onEscape:true, backdrop:true});
    }
    else if(gSettings.isProductPurchasedIOS(subscription.sku_ios)) {
        bootbox.alert({title:"Manage iOS "+ subscription.name+" Subscription", message:"The "+ subscription.name+" subscription is enabled here because it was shared to this account by an iOS device logged into this account. Please use your iOS device to manage your subscription, or use the link below to follow Apple's instruction: <br /><br /><a href='https://support.apple.com/en-ca/HT202039' target='_blank'>View, change, or cancel your iOS subscriptions</a>", onEscape:true, backdrop:true});
    }
    //close menu ?
}

