import $ from 'jquery';

import Sidebar from './SidebarBase';

const LEFT_MENU_IHUNTER_SIZE = '160px';
const LEFT_MENU_TOOLS_SIZE = '350px';
const LEFT_MENU_PUBLIC_LAND_SIZE = '350px';
const LEFT_MENU_COUNTY_MAPS_SIZE = '350px';

const TYPE_MENU_OPEN_IHUNTER = 1;
const TYPE_MENU_OPEN_TOOLS = 2;
const TYPE_MENU_OPEN_PUBLIC_LAND = 3;
const TYPE_MENU_OPEN_COUNTY_MAPS = 4;
const TYPE_MENU_OPEN_HUNTLOG = 5;


const MENU_TYPE = {
    IHUNTER: TYPE_MENU_OPEN_IHUNTER,
    TOOLS: TYPE_MENU_OPEN_TOOLS,
    PUBLIC_LAND: TYPE_MENU_OPEN_PUBLIC_LAND, 
    COUNTY_MAPS: TYPE_MENU_OPEN_COUNTY_MAPS,
    HUNTLOG: TYPE_MENU_OPEN_HUNTLOG
}

export class LeftSidebar extends Sidebar {
    constructor() {
        super($('#leftSidebar'), $("#leftSidebarContent"), $('#leftSidebarHeading'), $('#leftSidebarFooterDiv'));

        window.closeLeftSidebar = () => { this.close() }; // Expose for UI binding
    }

    static get MENU_TYPE() { return MENU_TYPE; }

    toggleWithTypeAndContent(type, content) {
        if(type == this.sidebarViewType || type == Sidebar.MENU_TYPE_CLOSED) {
            this.close(); 
        } else {
            if(this.sidebarViewType != Sidebar.MENU_TYPE_CLOSED) {
                this.close(); 
                this.sidebarViewType = type;

                setTimeout(() => {
                            this.setHeader('');
                            this.setFooter('');
                            this.setContent(content); //sets the content when the sidebar is closed so that we don't see the content switch
                            this.open();
                }, Sidebar.TRANSITION_SPEED);
             } 
            else {
                this.sidebarViewType = type;
                this.setHeader('');
                this.setFooter('');
                this.setContent(content);
                this.open();
            }
        }
    }

    //Override
    shouldClose(type) {
        return (type == this.sidebarViewType || type == Sidebar.MENU_TYPE_CLOSED);
    }

    //Override
    open() {
        this.setActiveButton(this.sidebarViewType);
        this.setSidebarSizeForType(this.sidebarViewType);
        this.adjustLeafletControlsForType(this.sidebarViewType);
    }

    //Override
    close() {
        this.setActiveButton(Sidebar.MENU_TYPE_CLOSED); 
        this.setSidebarSizeForType(Sidebar.MENU_TYPE_CLOSED);
        this.adjustLeafletControlsForType(Sidebar.MENU_TYPE_CLOSED);
    }

    removeViewsExcept(type) {
        // if(type != TYPE_MENU_OPEN_TOOLS) {
        //     gToolsMenuView = null;
        // }
        // if(type != TYPE_MENU_OPEN_COUNTY_MAPS) {
        //     gCountyMapsView = null;
        // }
        // if(type != TYPE_MENU_OPEN_PUBLIC_LAND) {
        //     gSubscriptionView = null;
        // }
    }

    setActiveButton(type) {
        $('#ihunterButton').toggleClass("active", type == TYPE_MENU_OPEN_IHUNTER);
        $('#toolsButton').toggleClass("active", type == TYPE_MENU_OPEN_TOOLS);
        $('#publicLandsButton').toggleClass("active", type == TYPE_MENU_OPEN_PUBLIC_LAND);
        $('#countyMapsButton').toggleClass("active", type == TYPE_MENU_OPEN_COUNTY_MAPS);
    }
    
    adjustLeafletControlsForType(type) {
        if(type == Sidebar.MENU_TYPE_CLOSED) {
            $('.leaflet-control-zoom').toggleClass('adjusted-300', false);
            $('.leaflet-control-scale').toggleClass('adjusted-300', false);
            $('.leaflet-control-layers').toggleClass('adjusted-300', false);
            $('#leftDivContainer').toggleClass('adjusted-300', false);
            $('.leaflet-control-zoom').toggleClass('adjusted-160', false);
            $('.leaflet-control-scale').toggleClass('adjusted-160', false);
            $('.leaflet-control-layers').toggleClass('adjusted-160', false);
            $('#leftDivContainer').toggleClass('adjusted-160', false);
            $('.leaflet-control-zoom').toggleClass('adjusted-350', false);
            $('.leaflet-control-scale').toggleClass('adjusted-350', false);
            $('.leaflet-control-layers').toggleClass('adjusted-350', false);
            $('#leftDivContainer').toggleClass('adjusted-350', false);
        } else if(type == TYPE_MENU_OPEN_IHUNTER) {
            $('.leaflet-control-zoom').toggleClass('adjusted-160', true);
            $('.leaflet-control-scale').toggleClass('adjusted-160', true);
            $('.leaflet-control-layers').toggleClass('adjusted-160', true);
            $('#leftDivContainer').toggleClass('adjusted-160', true);
        } else if(type == TYPE_MENU_OPEN_TOOLS || type == TYPE_MENU_OPEN_PUBLIC_LAND || type == TYPE_MENU_OPEN_COUNTY_MAPS || type === TYPE_MENU_OPEN_HUNTLOG) { 
            $('.leaflet-control-zoom').toggleClass('adjusted-350', true);
            $('.leaflet-control-scale').toggleClass('adjusted-350', true);
            $('.leaflet-control-layers').toggleClass('adjusted-350', true);
            $('#leftDivContainer').toggleClass('adjusted-350', true);
        }   
    }

    setSidebarSizeForType(type) {
        this.sidebarViewType = type;

        if(type == Sidebar.MENU_TYPE_CLOSED) {
            this.sidebarDiv.css('width', Sidebar.SIZE_CLOSED);
            this.sidebarContent.css('width', Sidebar.SIZE_CLOSED);
        }
        else if(type == TYPE_MENU_OPEN_IHUNTER) {
            this.sidebarDiv.css('width', LEFT_MENU_IHUNTER_SIZE);
            this.sidebarContent.css('width', LEFT_MENU_IHUNTER_SIZE);
        }
        else if(type == TYPE_MENU_OPEN_TOOLS || type === TYPE_MENU_OPEN_HUNTLOG) {
            this.sidebarDiv.css('width', LEFT_MENU_TOOLS_SIZE);
            this.sidebarContent.css('width', LEFT_MENU_TOOLS_SIZE);
        }
        else if(type == TYPE_MENU_OPEN_PUBLIC_LAND) {
            this.sidebarDiv.css('width', LEFT_MENU_PUBLIC_LAND_SIZE);
            this.sidebarContent.css('width', LEFT_MENU_PUBLIC_LAND_SIZE);
        }
        else if(type == TYPE_MENU_OPEN_COUNTY_MAPS) {
            this.sidebarDiv.css('width', LEFT_MENU_COUNTY_MAPS_SIZE);
            this.sidebarContent.css('width', LEFT_MENU_COUNTY_MAPS_SIZE);
        }
    }

}

export default LeftSidebar;
