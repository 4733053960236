import { cleanupTitle } from './ViewHelper';

export class BoundaryData {
    constructor(json) {
        this.title = cleanupTitle(json["Title"]);
        this.code = json["Code"];
        this.comment = json["Comment"];
        this.subtitle = json["Subtitle"];
        this.boundary = json["Boundary"];
        this.layerID = json["Layer"]; //not always populated
        this.color = json["Color"] != null ? '#'+json["Color"] : null; //not always populated
        this.north = json["North"];
        this.east = json["East"];
        this.south = json["South"];
        this.west = json["West"];
        this.bounds = this.west + ',' + this.north + ',' + this.east + ',' + this.south;//left, top, right, bottom
    }
}

export default BoundaryData;
