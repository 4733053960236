import $ from 'jquery';
import _ from 'underscore';
import L from 'leaflet';
import bootbox from 'bootbox';

import { MGRSToLatLon, UTMToLatLon } from './CoordinateConverter';

import View from './View';

export class AddLatLonWaypointView extends View {
    constructor(modalView, mapProvider) {
        super();
        var self = this;
        this.modal = modalView;
        this.map = mapProvider;
        this.modal.find('#modalPrimaryButton').attr('data-dismiss','');
        this.modal.find('#modalPrimaryButton').off(); //removes previous onclick
        this.modal.find('#modalPrimaryButton').on('click', (event) => {
            if($('#addLatLon').css('display') == 'block') {
                if( ($("#AddLatLonWaypointView_lat").val().trim().length > 0) && ($("#AddLatLonWaypointView_lon").val().trim().length > 0)) {
                    let lat = self.getValue($("#AddLatLonWaypointView_lat").val().trim());
                    let lon = self.getValue($("#AddLatLonWaypointView_lon").val().trim());
                    lon = lon >= 0 ? -1.0 * lon : lon;
                    self.modal.find(".close").click();
                    self.modal.find('#modalPrimaryButton').attr('data-dismiss','modal');
                    _.delay(() => {
                        window.onAddWaypoint(lat, lon);
                        this.map.zoomToLocation(L.latLng(lat, lon));
                    },1000);
                } else {
                    bootbox.alert("You have not entered a valid Latitude and Longitude");
                }
            } 
            else if($('#addGPS').css('display') == 'block') {
                if( ($("#northingDegs").val().trim().length > 0) && ($("#eastingDegs").val().trim().length > 0)) {
                    let lat = self.getLatitude();
                    let lon = self.getLongitude();
                    self.modal.find(".close").click();
                    self.modal.find('#modalPrimaryButton').attr('data-dismiss','modal');
                    _.delay(() => {
                        window.onAddWaypoint(lat, lon);
                        this.map.zoomToLocation(L.latLng(lat, lon));
                    },1000);
                } else {
                    bootbox.alert("You have not entered a valid Northing and Westing");
                }
            } 
            else if($('#addMGRS').css('display') == 'block') {
                if( ($("#AddMGRSWaypointView_coord").val().trim().length > 0)) {
                    let coord = self.sanitizeString($("#AddMGRSWaypointView_coord").val().trim());
                    let mgrsConversionResult = MGRSToLatLon(coord);
                    if(mgrsConversionResult != null && mgrsConversionResult.length > 2 && mgrsConversionResult[0] == true) {
                        self.modal.find(".close").click();
                        self.modal.find('#modalPrimaryButton').attr('data-dismiss','modal');
                        _.delay(() => {
                            let lat = mgrsConversionResult[1];
                            let lon = mgrsConversionResult[2];
                            window.onAddWaypoint(lat, lon);
                            this.map.zoomToLocation(L.latLng(lat, lon));
                        },1000);
                    } else {
                        //error!
                        bootbox.alert("The MGRS coordinate was invalid or formatted incorrectly. Please see the example format.");
                    }
                } else {
                    bootbox.alert("You have not entered a valid MGRS coord");
                }
            } 
            else if($('#addUTM').css('display') == 'block') {
                if( ($("#AddUTMWaypointView_coord").val().trim().length > 0)) {
                    let coord = self.sanitizeString($("#AddUTMWaypointView_coord").val().trim());
                    let utmConversionResult = UTMToLatLon(coord);
                    if(utmConversionResult != null && utmConversionResult.length > 2 && utmConversionResult[0] == true) {
                        self.modal.find(".close").click();
                        self.modal.find('#modalPrimaryButton').attr('data-dismiss','modal');
                        _.delay(() => {
                            let lat = utmConversionResult[1];
                            let lon = utmConversionResult[2];
                            window.onAddWaypoint(lat, lon);
                            this.map.zoomToLocation(L.latLng(lat, lon));
                        },1000);
                    } else {
                        //error!
                        bootbox.alert("The MGRS coordinate was invalid or formatted incorrectly. Please see the example format.");
                    }
                } else {
                    bootbox.alert("You have not entered a valid MGRS coord");
                }
            }
        });
    }

    saveAndClose(name, desc, pinImage) {
        // var uuid = uuidv4();
        // if(this.pendingImages && this.pendingImages.length > 0) {
        //     gFirebaseHelper.saveImagesToFirebase(this.pendingImages, uuid);
        // }

        // var imageNames = ""; 
        // if(this.pendingImages) {
        //     for(var i = 0; i < this.pendingImages.length; i++) {
        //         imageNames += (imageNames == "" ? this.pendingImages[i].name : "," + this.pendingImages[i].name);
        //     }
        // }

        // gFirebaseHelper.addWaypoint(uuid, name, desc, this.lat, this.lon, pinImage, imageNames);
    }

    sanitizeString(value) {
        if(!value || value.trim() === "") {
            return "0";
        }
        return value.trim();
    }

    getValue(value) {
        if(!value || value.trim() === "") {
            return 0.0;
        }
        return parseFloat(value.trim());
    }

    getLatitude() {
        var fDegsN = this.getValue($("#northingDegs").val());
        var fMinsN = this.getValue($("#northingMins").val());
        var fSecsN = this.getValue($("#northingSecs").val());
        return fDegsN + ((fMinsN / 60.0) + (fSecsN / 3600.0));
    }

    getLongitude() {
        var fDegsW = this.getValue($("#eastingDegs").val());
        var fMinsW = this.getValue($("#eastingMins").val());
        var fSecsW = this.getValue($("#eastingSecs").val());
        var val = fDegsW + ((fMinsW / 60.0) + (fSecsW / 3600.0));
        return val >= 0 ? (-1.0 * val) : val;
    }
}

export default AddLatLonWaypointView;
