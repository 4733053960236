import Contact from './Contact';

export default class UnknownContact extends Contact {
    constructor(uid) {
        super(uid);
    }

    isUnknownContact() {
        return true;
    }    
}
