import $ from 'jquery';
import L from 'leaflet';

import Annotation from './Annotation';
import LineAnnotation from './LineAnnotation';
import RectAnnotation from './RectAnnotation';
import CircleAnnotation from './CircleAnnotation';
import AddDrawnWaypointView from './AddDrawnWaypointView';

import { DrawnWaypoint, getAnnotationsFromXML, getAnnotationXMLForAnnotations } from './DrawnWaypoint';
import { uuidv4 } from './Encryption';
import { openHTMLFileInCustomModal } from './ModalHelper';

const TYPE_DRAWING_TOOL_PEN = 0;
const TYPE_DRAWING_TOOL_RECT = 1;
const TYPE_DRAWING_TOOL_LINE = 2;
const TYPE_DRAWING_TOOL_CIRCLE = 3;
const TYPE_DRAWING_TOOL_ERASE = 4;
const TYPE_DRAWING_TOOL_PAN = 5;
const TYPE_BUTTON_DRAWING_COLOR = 6;
const TYPE_BUTTON_DRAWING_TOOLS = 7;

const COLOR_RED = '#f01f28';
const COLOR_GREEN = '#00a550';
const COLOR_BLUE = '#0fb4f3';
const COLOR_ORANGE = '#ff9908';

export class DrawingView {
    constructor(mapProvider, waypointManager) {
        this.waypointManager = waypointManager;
        this.map = mapProvider;

        this.waypoint = null;

        this.normalFeatureGroup = null;
        this.elevationFeatureGroup = null;
        this.annotations = [];
        this.currentAnnotation = null;
        this.setActiveTool(TYPE_DRAWING_TOOL_PEN);
        this.lastActiveDrawingTool = TYPE_DRAWING_TOOL_PEN;
        this.activeColor = COLOR_RED;
        this.updateIcons();
        this.setActiveButton(TYPE_BUTTON_DRAWING_TOOLS);

        this.removeOnClick = null;
        this.eraserMarker = null;
        this.erasing = false;
        this.bShowArea = true;
        this.bShowDist = true;
        this.bShowElevation = false;
        this.disableSaveButton();

        this.setupClickListners();
        this.setupWindowListners();

        this.map.onInitLeaflet((leaflet) => {
            this.normalFeatureGroup = L.featureGroup([]).addTo(leaflet); // Same as this.map.leaflet
            this.elevationFeatureGroup = L.featureGroup([]);//.addTo(this.map);
        });

        this.waypointManager.onUpdateWaypoint((uuid) => {
            if(this.waypoint && this.waypoint.uuid == uuid) {
                this.updateExistingWaypointInfo(this.waypoint);
            }
        });

        var self = this;
        this.mouseDown = (e) => {
            this.removeColorPicker();
            this.removeToolPicker();
            if(this.activeTool === TYPE_DRAWING_TOOL_ERASE) {
                this.showNormalPolylines();
                this.showEraserMarkerAtPoint(e.latlng);
                this.erasing = true;
                this.eraseAtPoint(e.latlng);
            } 
            else {
                if(this.activeTool == TYPE_DRAWING_TOOL_PEN) {
                    this.currentAnnotation = new Annotation(self);
                    this.currentAnnotation.addPoint(e.latlng);
                    this.currentAnnotation.setColor(this.activeColor);
                }
                else if(this.activeTool == TYPE_DRAWING_TOOL_LINE) {
                    this.currentAnnotation = new LineAnnotation(self);
                    this.currentAnnotation.addPoint(e.latlng);
                    this.currentAnnotation.updateMeasurementLabels(); 
                    this.currentAnnotation.setColor(this.activeColor);
                }
                else if(this.activeTool == TYPE_DRAWING_TOOL_RECT) {
                    this.currentAnnotation = new RectAnnotation(self);
                    this.currentAnnotation.addPoint(e.latlng);
                    this.currentAnnotation.setColor(this.activeColor);
                }
                else if(this.activeTool == TYPE_DRAWING_TOOL_CIRCLE) {
                    this.currentAnnotation = new CircleAnnotation(self);
                    this.currentAnnotation.addPoint(e.latlng);
                    this.currentAnnotation.setColor(this.activeColor);
                }
                if(this.currentAnnotation != null) {
                    this.showNormalPolylines();
                    this.normalFeatureGroup.addLayer(this.currentAnnotation.polyline);
                    this.annotations.push(this.currentAnnotation);
                }
            }
        };

        this.mouseUp = (e) => {
            if(this.activeTool == TYPE_DRAWING_TOOL_ERASE) {
                this.removeEraserMarker();
                this.erasing = false;
                this.eraseAtPoint(e.latlng);
            } 
            else if(this.activeTool == TYPE_DRAWING_TOOL_LINE) {
                this.currentAnnotation.addPoint(e.latlng);
                this.currentAnnotation.updateMeasurementLabels(); 
            }

            this.validateAnnotation(this.currentAnnotation);

            // if(this.bShowElevation && this.currentAnnotation != null && !this.currentAnnotation.isCircle() && !this.currentAnnotation.isRectangle()) {
            //     this.currentAnnotation.queryElevations(this.elevationFeatureGroup);
            //     // this.elevationFeatureGroup.addLayer(this.currentAnnotation.multiColorPolyline);
            //     this.showElevationPolylines();
            // }

            this.currentAnnotation = null;

            if(this.annotations != null && this.annotations.length > 0) {
                this.enableSaveButton();
            } else {
                this.disableSaveButton();
            }
            
            this.map.closePopup();
        };

        this.mouseMove = (e) => {
            if(this.activeTool == TYPE_DRAWING_TOOL_ERASE) {
                if(this.erasing) {
                    this.showEraserMarkerAtPoint(e.latlng);
                    this.eraseAtPoint(e.latlng);
                }
            } 
            else if(this.currentAnnotation) {
                this.currentAnnotation.addPoint(e.latlng);
                this.currentAnnotation.updateMeasurementLabels(); 
            }
        };
    }


    setupWindowListners() {
        window.drawingDistSwitchClicked = (cb) => {
            this.drawingDistSwitchClicked(cb);
        };
        window.drawingAreaSwitchClicked = (cb) => {
            this.drawingAreaSwitchClicked(cb);
        };
        window.drawingElevationSwitchClicked = (cb) => {
            this.drawingElevationSwitchClicked(cb);
        };
    }

    setupClickListners() {

        $("#drawingCancelButton").on('click', () => {
            this.onDrawCancelClicked();
        });
        $("#drawingToolButton").on('click', () => {
            this.onDrawToolClicked();
        });
        $("#drawingColorButton").on('click', () => {
            this.onDrawColorClicked();
        });
        $("#drawingEraserButton").on('click', () => {
            this.onDrawEraserClicked();
        });
        $("#drawingPanButton").on('click', () => {
            this.onDrawPanClicked();
        });
        $("#drawingSaveButton").on('click', () => {
            this.onDrawSaveClicked();
        });

        window.onColorPicked = (color) => {
            this.onColorPicked(color);
        };
        window.onToolPicked = (tool) => {
            this.onToolPicked(tool);
        };
    }

    latLngToLayerPoint(point) {
        return this.map.leafletMap.latLngToLayerPoint(point);
    }
    
    enableSaveButton() {
        $('#drawingSaveButton').prop("disabled", false);
    }
    
    disableSaveButton() {
        $('#drawingSaveButton').prop("disabled", true);
    }

    validateAnnotation(a) {
        if(a != null && a.polyline.getLatLngs() != null) {
            var numPoints = a.polyline.getLatLngs().length;

            if(numPoints < a.getMinimumPoints()) {
                this.normalFeatureGroup.removeLayer(a.polyline);
                a.remove();
                this.annotations.pop();
            }
        }
    }

    populateExistingAnnotations() {
        if(this.waypoint != null) {
            var annotations = getAnnotationsFromXML(this, this.waypoint.annotationXML);
            //adding the lines
            for (var i = 0; i < annotations.length; i++) {
                var a = annotations[i];
                this.normalFeatureGroup.addLayer(a.polyline);
                a.setMultiColorPolyline(this.elevationFeatureGroup);
                this.annotations.push(a);
                a.updateMeasurementLabels();
            }
            if(this.bShowElevation) {
                this.showElevationPolylines();
            } else {
                this.showNormalPolylines();
            }
        }
    }

    showEraserMarkerAtPoint(latlng) {
        if(!this.eraserMarker) {
            this.eraserMarker = L.circleMarker(latlng, {radius: 20, color:'#262626', fillColor:'#e1e1e1', fillOpacity: 0.5, weight:1});
            this.eraserMarker.addTo(this.map.leafletMap);
        } else {
            this.eraserMarker.setLatLng(latlng);
        }
    }

    removeEraserMarker() {
        if(this.eraserMarker) {
            this.map.leafletMap.removeLayer(this.eraserMarker);
            this.eraserMarker = null;
        }
    }

    eraseAtPoint(point) {
        this.erasePoint = point;

        for(var i = 0; i < this.annotations.length; i++) {
            var annotation = this.annotations[i];
            var newAnnotations = annotation.eraseAtPoint(point);
            if(newAnnotations != null) {
                for(var k = 0; k < newAnnotations.length; k++) {
                    let a = newAnnotations[k];
                    this.normalFeatureGroup.addLayer(a.polyline);
                    this.annotations.push(a);
                    a.setMultiColorPolyline(this.elevationFeatureGroup);
                    a.updateMeasurementLabels();
                }
                
            }

            // Remove annotation if too much is erased
            if(annotation.getPoints().length < annotation.getMinimumPoints()) {
                
                this.normalFeatureGroup.removeLayer(annotation.polyline);
                this.annotations.splice(i, 1);
                annotation.remove();
                i--;
            }

            annotation.resetElevations();

            this.elevationFeatureGroup.clearLayers();
        }
    }

    showToolbars() {
        $('#leftDiv').fadeOut(() => {
            $('#drawingDiv').fadeIn();
            $('#showLabelsDiv').fadeIn();
            this.setupSwitches();
        });
        $('#drawingHeaderDiv').fadeIn();
    }

    hideToolbars() {
        $('#showLabelsDiv').fadeOut();
        $('#drawingDiv').fadeOut(() => {
            $('#leftDiv').fadeIn();
        });
        $('#drawingHeaderDiv').fadeOut();
    }

    setupSwitches() {
        $('#drawingDistSwitch').prop('checked', this.bShowDist);
        $('#drawingAreaSwitch').prop('checked', this.bShowArea); 
        $('#drawingElevationSwitch').prop('checked', this.bShowElevation); 
    }

    startDrawing(waypoint) {
        if(waypoint) {
            this.waypoint = waypoint;

            this.bShowArea = this.waypoint.showAreaLabel;
            this.bShowDist = this.waypoint.showDistLabel;
            this.bShowElevation =this.waypoint.showElevation;
        } else {
            this.waypoint = null;

            // Reset tool status to default (todo: refactor into method)
            this.bShowArea = true;
            this.bShowDist = true;
            this.bShowElevation = false;

            $('#editedWaypointDiv').hide();
        }

        this.addDrawingListeners();
        this.showToolbars();

        this.map.disableMapClicks();

        this.addTapRestrictions();

        if(this.waypoint) {
            this.updateExistingWaypointInfo(this.waypoint);
            this.waypoint.hide();
            this.populateExistingAnnotations();

            this.map.zoomToLatLngBounds(this.waypoint.getBounds());
        }
    }

    updateExistingWaypointInfo(waypoint) {
        if(waypoint != null) {
            var self = this;

            $('#editedWaypointName').html(waypoint.name);
            $('#editedWaypointDesc').html(waypoint.desc);
            waypoint.setPinImageUrl().then(() => {
                $('#editedWaypointPin').attr("src",waypoint.pinImageUrl);
            });

            $('#editedWaypointEdit').prop("onclick", null).off("click");
            $('#editedWaypointEdit').click(() => {
                this.waypointManager.showEditDrawnWaypoint(waypoint);
            });
            $('#editedWaypointDiv').show();
        } 
    }

    stopDrawing() {
        this.removeDrawingListeners();
        this.map.leafletMap.removeLayer(this.normalFeatureGroup); //removes the drawing annotations
        this.map.leafletMap.removeLayer(this.elevationFeatureGroup);
        for(var i = 0; i < this.annotations.length; i++) {
            let a = this.annotations[i];
            this.normalFeatureGroup.removeLayer(a.polyline);
            a.remove();
        }
        this.annotations = [];
        this.hideToolbars();
        this.removeTapRestrictions();

        this.map.enableMapClicks();  
    }

    addTapRestrictions() {
        this.disablePanning();
        this.markerPane = this.map.leafletMap.getPane('markerPane');
        this.noClickPane = L.DomUtil.create('div', 'no-click-pane');
        this.markerPane.insertBefore(this.noClickPane, this.markerPane.children[0]);
        $('.leaflet-control-container').toggleClass('noselect', true); //makes it so the legend and +/- signs aren't selectable/highlightable
    }

    removeTapRestrictions() {
        this.enablePanning();
        this.markerPane.removeChild(this.noClickPane);
        $('.leaflet-control-container').toggleClass('noselect', false); //makes it so the legend and +/- signs are back to normal
    }

    disablePanning() {
        this.map.leafletMap.dragging.disable();
    }

    enablePanning() {
        this.map.leafletMap.dragging.enable();
    }

    addDrawingListeners() {
        this.map.leafletMap.on('mousedown', this.mouseDown);
        this.map.leafletMap.on('mouseup', this.mouseUp);
        this.map.leafletMap.on('mousemove', this.mouseMove);
    }

    removeDrawingListeners() {
        this.map.leafletMap.off('mousedown', this.mouseDown);
        this.map.leafletMap.off('mouseup', this.mouseUp);
        this.map.leafletMap.off('mousemove', this.mouseMove);
    }

    setActiveButton(type) {
        $('#drawingToolButton').toggleClass("active", type == TYPE_BUTTON_DRAWING_TOOLS || type == TYPE_DRAWING_TOOL_PEN || type == TYPE_DRAWING_TOOL_RECT || type == TYPE_DRAWING_TOOL_LINE || type == TYPE_DRAWING_TOOL_CIRCLE); 
        $('#drawingColorButton').toggleClass("active", type == TYPE_BUTTON_DRAWING_COLOR); 
        $('#drawingEraserButton').toggleClass("active", type == TYPE_DRAWING_TOOL_ERASE);
        $('#drawingPanButton').toggleClass("active", type == TYPE_DRAWING_TOOL_PAN);
    }

    createColorPicker(aboveContainer) {
        this.removeColorPicker();
        this.removeToolPicker();
        var redDiv = '<div class="color-picker-btn red ' + (this.isColorActive(COLOR_RED) ? 'active' : '') + '" onclick="onColorPicked(\''+COLOR_RED+'\')"><div class="color-picker-fill red"></div></div>';
        var greenDiv = '<div class="color-picker-btn green ' + (this.isColorActive(COLOR_GREEN) ? 'active' : '') + '" onclick="onColorPicked(\''+COLOR_GREEN+'\')"><div class="color-picker-fill green"></div></div>';
        var blueDiv = '<div class="color-picker-btn blue ' + (this.isColorActive(COLOR_BLUE) ? 'active' : '') + '" onclick="onColorPicked(\''+COLOR_BLUE+'\')"><div class="color-picker-fill blue"></div></div>';
        var orangeDiv = '<div class="color-picker-btn orange ' + (this.isColorActive(COLOR_ORANGE) ? 'active' : '') + '" onclick="onColorPicked(\''+COLOR_ORANGE+'\')"><div class="color-picker-fill orange"></div></div>';
        
        var colourPickerDiv = '<div class="color-picker-container">'+redDiv + greenDiv + blueDiv+ orangeDiv +'</div>';
        $(aboveContainer).append(colourPickerDiv);
    }

    createToolPicker(aboveContainer) {
        this.removeToolPicker();
        this.removeColorPicker();
        var color = this.activeColor == COLOR_BLUE ? 'blue' : this.activeColor == COLOR_GREEN ? 'green' : this.activeColor == COLOR_ORANGE ? 'orange' : 'red';
        
        var penDiv = '<div class="ihunter-icon-draw draw draw-' + color + ' ' + (this.isToolActive(TYPE_DRAWING_TOOL_PEN) ? 'active' : '') + '" style="margin-left:8px; margin-top:8px;" onclick="onToolPicked('+TYPE_DRAWING_TOOL_PEN+')" ></div>';
        var lineDiv = '<div class="ihunter-icon-draw line line-' + color + ' ' + (this.isToolActive(TYPE_DRAWING_TOOL_LINE) ? 'active' : '') + '" style="margin-left:8px; margin-top:8px;" onclick="onToolPicked('+TYPE_DRAWING_TOOL_LINE+')"></div>';
        var rectDiv = '<div class="ihunter-icon-draw rect rect-' + color + ' ' + (this.isToolActive(TYPE_DRAWING_TOOL_RECT) ? 'active' : '') + '" style="margin-left:8px; margin-top:8px;" onclick="onToolPicked('+TYPE_DRAWING_TOOL_RECT+')"></div>';
        var circleDiv = '<div class="ihunter-icon-draw circle circle-' + color + ' ' + (this.isToolActive(TYPE_DRAWING_TOOL_CIRCLE) ? 'active' : '') + '" style="margin-left:8px; margin-top:8px;" onclick="onToolPicked('+TYPE_DRAWING_TOOL_CIRCLE+')"></div>';
        var toolPickerDiv = '<div class="tool-picker-container">'+penDiv + lineDiv + rectDiv + circleDiv + '</div>';
        $(aboveContainer).append(toolPickerDiv);
    }

    removeColorPicker() {
        $('.color-picker-container').remove();
    }

    removeToolPicker() {
        $('.tool-picker-container').remove();
    }

    isColorActive(color) {
        if(this.activeColor == color) {
            return true;
        }
        return false;
    }

    isToolActive(tool) {
        if(this.activeTool == tool) {
            return true;
        }
        return false;
    }

    updateIcons() {        
        $('#drawingToolButton i').toggleClass('draw-red', this.activeColor == COLOR_RED && this.activeTool == TYPE_DRAWING_TOOL_PEN);
        $('#drawingToolButton i').toggleClass('draw-blue', this.activeColor == COLOR_BLUE && this.activeTool == TYPE_DRAWING_TOOL_PEN);
        $('#drawingToolButton i').toggleClass('draw-green', this.activeColor == COLOR_GREEN && this.activeTool == TYPE_DRAWING_TOOL_PEN);
        $('#drawingToolButton i').toggleClass('draw-orange', this.activeColor == COLOR_ORANGE && this.activeTool == TYPE_DRAWING_TOOL_PEN);
        $('#drawingToolButton i').toggleClass('rect-red', this.activeColor == COLOR_RED && this.activeTool == TYPE_DRAWING_TOOL_RECT);
        $('#drawingToolButton i').toggleClass('rect-blue', this.activeColor == COLOR_BLUE && this.activeTool == TYPE_DRAWING_TOOL_RECT);
        $('#drawingToolButton i').toggleClass('rect-green', this.activeColor == COLOR_GREEN && this.activeTool == TYPE_DRAWING_TOOL_RECT);
        $('#drawingToolButton i').toggleClass('rect-orange', this.activeColor == COLOR_ORANGE && this.activeTool == TYPE_DRAWING_TOOL_RECT);
        $('#drawingToolButton i').toggleClass('line-red', this.activeColor == COLOR_RED && this.activeTool == TYPE_DRAWING_TOOL_LINE);
        $('#drawingToolButton i').toggleClass('line-blue', this.activeColor == COLOR_BLUE && this.activeTool == TYPE_DRAWING_TOOL_LINE);
        $('#drawingToolButton i').toggleClass('line-green', this.activeColor == COLOR_GREEN && this.activeTool == TYPE_DRAWING_TOOL_LINE);
        $('#drawingToolButton i').toggleClass('line-orange', this.activeColor == COLOR_ORANGE && this.activeTool == TYPE_DRAWING_TOOL_LINE);
        $('#drawingToolButton i').toggleClass('circle-red', this.activeColor == COLOR_RED && this.activeTool == TYPE_DRAWING_TOOL_CIRCLE);
        $('#drawingToolButton i').toggleClass('circle-blue', this.activeColor == COLOR_BLUE && this.activeTool == TYPE_DRAWING_TOOL_CIRCLE);
        $('#drawingToolButton i').toggleClass('circle-green', this.activeColor == COLOR_GREEN && this.activeTool == TYPE_DRAWING_TOOL_CIRCLE);
        $('#drawingToolButton i').toggleClass('circle-orange', this.activeColor == COLOR_ORANGE && this.activeTool == TYPE_DRAWING_TOOL_CIRCLE);
    }

    getAnnotationBounds() {
        return this.normalFeatureGroup.getBounds();
    }

    createDrawnWaypoint() {
        var bounds = this.getAnnotationBounds();

        var json = {
            AnnotationXML: this.getAnnotationXML(),
            CenterLatitude: bounds.getCenter().lat.toString(), //bounds center y (encrypted)
            CenterLongitude: bounds.getCenter().lng.toString(), //bounds center x    
            SpanLatitude: Math.abs(bounds.getNorth() - bounds.getSouth()).toString(), //bounds height
            SpanLongitude: Math.abs(bounds.getEast() - bounds.getWest()).toString(), //bounds width
            UUID: uuidv4(),
            creationUsername: "",
            date: "",
            dateUpdated: "",
            desc: "",
            drawnwaypointShowArea: this.bShowArea,
            drawnwaypointShowDist: this.bShowDist,
            drawnwaypointShowElevation: this.bShowElevation,
            image: "",
            latitude: bounds.getCenter().lat.toString(), //bounds center y
            longitude: bounds.getCenter().lng.toString(), //bounds center x
            name: "",
            needsImageUpload: false,
            pinResource: "ihunter_pin_drawing_red.png"
        }

        let waypoint = new DrawnWaypoint(this.map.service, this.map, this);
        waypoint.initialize(json);
        return waypoint;
    }

    getAnnotationXML() {
        return getAnnotationXMLForAnnotations(this.annotations);
    }

    setActiveTool(tool) {
        if((this.activeTool == TYPE_DRAWING_TOOL_PEN || this.activeTool == TYPE_DRAWING_TOOL_LINE || this.activeTool == TYPE_DRAWING_TOOL_RECT || this.activeTool == TYPE_DRAWING_TOOL_CIRCLE) && (tool == TYPE_DRAWING_TOOL_PAN || tool == TYPE_DRAWING_TOOL_ERASE)) {
            this.lastActiveDrawingTool = this.activeTool;
        }
        this.activeTool = tool;
    }

    showNormalPolylines() {
        this.hideElevationPolylines();
        if(!this.map.leafletMap.hasLayer(this.normalFeatureGroup)) {
            this.map.leafletMap.addLayer(this.normalFeatureGroup);
        }
    }

    hideNormalPolylines() {
        if(this.map.leafletMap.hasLayer(this.normalFeatureGroup)) {
            this.map.leafletMap.removeLayer(this.normalFeatureGroup);
        }
    }

    showElevationPolylines() {
        this.hideNormalPolylines();

        for (var i = 0; i < this.annotations.length; i++) {
            var a = this.annotations[i];
            if(a.isLine() || a.isFreehand()) {
                if(a.elevationsString == null || a.elevationsString.length < 1 || a.elevationPointsString == null || a.elevationPointsString.length < 1) {
                    a.queryElevations(this.elevationFeatureGroup);
                }
            } else {
                this.elevationFeatureGroup.addLayer(a.polyline); //just add a normal polyline if it's a rect or circle.
            }
        }

        if(!this.map.leafletMap.hasLayer(this.elevationFeatureGroup)) {
            console.log("ADDING ELEVATION POLYLINES")
            this.map.leafletMap.addLayer(this.elevationFeatureGroup);
        }
    }

    hideElevationPolylines() {
        if(this.map.leafletMap.hasLayer(this.elevationFeatureGroup)) {
            console.log("HIDE THAT ELEVATION FeatureGroup!!");
            this.map.leafletMap.removeLayer(this.elevationFeatureGroup);
        }
    }

    endDrawingView() {
        this.removeColorPicker();
        this.removeToolPicker();
        this.setActiveTool(TYPE_DRAWING_TOOL_PEN);
        this.setActiveButton(-1);
        this.stopDrawing();

        if(this.waypoint) {
            this.waypoint.show();
        }
    }
    
    onToolPicked(tool) {
        $('.tool-picker-container .ihunter-icon-draw.draw').toggleClass("active", tool == TYPE_DRAWING_TOOL_PEN);
        $('.tool-picker-container .ihunter-icon-draw.line').toggleClass("active", tool == TYPE_DRAWING_TOOL_LINE);
        $('.tool-picker-container .ihunter-icon-draw.rect').toggleClass("active", tool == TYPE_DRAWING_TOOL_RECT);
        $('.tool-picker-container .ihunter-icon-draw.circle').toggleClass("active", tool == TYPE_DRAWING_TOOL_CIRCLE);
        this.setActiveTool(tool); 
        this.updateIcons();
    }
    
    onColorPicked(color) {
        $('.color-picker-btn.red').toggleClass("active", color == COLOR_RED);
        $('.color-picker-btn.green').toggleClass("active", color == COLOR_GREEN);
        $('.color-picker-btn.blue').toggleClass("active", color == COLOR_BLUE);
        $('.color-picker-btn.orange').toggleClass("active", color == COLOR_ORANGE);
        this.activeColor = color;
        if(this.activeTool == TYPE_DRAWING_TOOL_PAN || this.activeTool == TYPE_DRAWING_TOOL_ERASE) {
            this.setActiveTool(this.lastActiveDrawingTool);
        }
        this.updateIcons();
        this.setActiveButton(TYPE_BUTTON_DRAWING_TOOLS);
    }
    
    onDrawCancelClicked() {
        this.endDrawingView();
    }
    
    onDrawToolClicked() {
        this.setActiveButton(TYPE_BUTTON_DRAWING_TOOLS);
        this.disablePanning();
        if($('.tool-picker-container').length) {
            this.removeToolPicker();
        } else {
            if(this.activeTool == TYPE_DRAWING_TOOL_PAN || this.activeTool == TYPE_DRAWING_TOOL_ERASE) {
                this.setActiveTool(this.lastActiveDrawingTool);
            }
            this.createToolPicker('#drawingToolButtonContainer');
        }
    }
    
    onDrawColorClicked() {
        this.setActiveButton(TYPE_BUTTON_DRAWING_COLOR);
        if($('.color-picker-container').length) {
            this.removeColorPicker();
        } else {
            this.createColorPicker('#drawingColorButtonContainer');
        }
    }
    
    onDrawEraserClicked() {
        this.removeColorPicker();
        this.removeToolPicker();

        this.setActiveTool(TYPE_DRAWING_TOOL_ERASE);

        this.setActiveButton(TYPE_DRAWING_TOOL_ERASE);
        this.disablePanning();
        if(this.bShowElevation) {
            $('#drawingElevationSwitch').click(); 
        }
    }
    
    onDrawPanClicked() {
        this.removeColorPicker();
        this.removeToolPicker();
        this.setActiveTool(TYPE_DRAWING_TOOL_PAN);
        this.setActiveButton(TYPE_DRAWING_TOOL_PAN);
        this.enablePanning();
    }
    
    onDrawSaveClicked() {
        
        if(this.waypoint != null) {
//            this.waypointManager.updateDrawnWaypoint(this.waypoint, showPrefs, this.getAnnotationXML());
            this.waypointManager.updateAnnotation(this.waypoint, this.getAnnotationXML());
           // })
           // gFirebaseHelper.updateDrawnWaypoint(this.waypoint.uuid, this.waypoint.name, this.waypoint.desc, this.waypoint.location.lat, this.waypoint.location.lng, this.waypoint.spanLat, this.waypoint.spanLon, this.waypoint.pinImage, null, this.waypoint.creationUsername, DateHelpers.stringFromDate(this.waypoint.creationDate), DateHelpers.stringFromDate(moment()), false, this.bShowArea, this.bShowDist, this.bShowElevation, this.getAnnotationXML());
        } 
        else {
            var waypoint = this.createDrawnWaypoint();
            var addDrawnWaypointView;
            openHTMLFileInCustomModal('Add Drawn Waypoint', 'add_drawn_waypoint_view.html', 'Save', 'Cancel', '70', '80', false).then((result) => {
                addDrawnWaypointView = new AddDrawnWaypointView(this.waypointManager, this, result, waypoint);
            }, function(err) {
                console.log(err);
            });
        }
    
        this.endDrawingView();
    }


    drawingDistSwitchClicked(cb) {
        this.bShowDist = cb.checked;
        for(var i = 0; i < this.annotations.length; i++) {
            this.annotations[i].updateMeasurementLabels();
        }
        
        if(this.waypoint) {
            this.waypointManager.setShowDistForWaypoint(this.waypoint, this.bShowDist);
        }
    }
    
    drawingAreaSwitchClicked(cb) {
        this.bShowArea = cb.checked;
        for(var i = 0; i < this.annotations.length; i++) {
            this.annotations[i].updateMeasurementLabels();
        }
    
        if(this.waypoint) {
            this.waypointManager.setShowAreaForWaypoint(this.waypoint, this.bShowArea);
        }
    }
    
    drawingElevationSwitchClicked(cb) {
        this.bShowElevation = cb.checked;
        if(this.bShowElevation) {
            this.showElevationPolylines();
        } else {
            this.showNormalPolylines();
        }
    
        // TODO: Should we save elevation visibility pref to DB?   
        // this.waypointManager.setShowElevationForWaypoint(this.waypoint, this.bShowElevation);
        console.log("drawingElevationSwitchClicked()");
    }

}


export default DrawingView;
//export { onToolPicked, onColorPicked, onDrawAndMeasureClicked, onDrawCancelClicked, onDrawToolClicked, onDrawColorClicked, onDrawEraserClicked, onDrawPanClicked, onDrawSaveClicked, drawingDistSwitchClicked, drawingAreaSwitchClicked, drawingElevationSwitchClicked }

