import $ from 'jquery';
import moment from 'moment';

import View from './View';
import WaypointFilter from './WaypointFilter';

import { gSettings } from './Settings';
import { gWaypointImageCache } from './WaypointImageCache';

import { getDrawnWaypointPins, getTrackedWaypointPins, getWaypointPins, getWaypointBackgrounds } from './WaypointFilter';
import { FB_WAYPOINT_FILTER_PREFS } from './FirebaseAttributes';

export const DateFilterType = Object.freeze({"NONE":0, "DATE_RANGE":1, "PREVIOUS_DAYS":2});

export class WaypointFilterView extends View {
    constructor(modalView) {
        super();
        var self = this;
        this.modal = modalView;
        this.inactiveIcons = new Array();
        this.currentFilter = gSettings.getPreference(FB_WAYPOINT_FILTER_PREFS);

        this.dateFilterType = DateFilterType.NONE;
        this.startDate = null;
        this.endDate = null;

        this.setupPinPicker();
        this.setupBackgroundPicker();

        $('#modalPrimaryButton').removeAttr("disabled"); //enable save button.

        this.modal.find('#modalPrimaryButton').off(); //removes previous onclick
        this.modal.find('#modalPrimaryButton').on('click', 
            (event) => {
                var newFilter = new WaypointFilter();
                if(self.dateFilterType == DateFilterType.DATE_RANGE) {
                    newFilter.startDate = self.startDate;
                    newFilter.endDate = self.endDate;
                } else if(self.dateFilterType == DateFilterType.PREVIOUS_DAYS) {
                    newFilter.numberOfDays = parseInt($('#previousDaysInput').val(), 10);
                } 

                $('#pin-picker .img-frame.filter:not(.selected)').each(function(index) {                
                    var id = $(this).attr("id");
                    newFilter.inactivePinImages.push(id);
                });

                $('#pin-background-picker .img-frame.filter:not(.selected)').each(function(index) {                
                    var id = $(this).attr("id");
                    newFilter.inactiveBackgroundImages.push(id);
                });

                gSettings.updatePreference(FB_WAYPOINT_FILTER_PREFS, newFilter.getJsonForFirebase());
            }
        );

        $('#dateFilterButtonGroup button').click(function() {
            // $(this).addClass('selected').siblings().removeClass('selected');
            if($(this).attr('id') == 'dateRangeButton') {
                self.selectDateFilterType(DateFilterType.DATE_RANGE);
            }
            else if($(this).attr('id') == 'previousDaysButton') {
                self.selectDateFilterType(DateFilterType.PREVIOUS_DAYS);
            } else {
                self.selectDateFilterType(DateFilterType.NONE);
            }
        });

        $('#selectAllButton').click(function() {
            self.selectAllIcons();
        });

        $('#deselectAllButton').click(function() {
            self.deselectAllIcons();
        });

        this.setInitialFilter();
    }

    setupPinPicker() {
        var arrayOfPinNames = getWaypointPins().concat(getDrawnWaypointPins()).concat(getTrackedWaypointPins());
        
        for(var i = 0; i < arrayOfPinNames.length; i++) {
            var pinName = arrayOfPinNames[i];
            $("#pin-picker").append(this.getDivForPin(pinName)); 
            this.setPinImage(pinName, this.shouldHaveBackground(pinName) ? "ihunter_pin_background_grey" : "");
        }

        //add class .selected which will highlight it and remove class .selected from all others
        var self = this;
        $("#pin-picker .img-frame").click( function() {
            self.selectPinImageFromPinPicker($(this));
            return false;
        });
    }

    setupBackgroundPicker() {
        var arrayOfBackgrounds = getWaypointBackgrounds();
     
        for(var i = 0; i < arrayOfBackgrounds.length; i++) {
            var backgroundName = arrayOfBackgrounds[i];
            $("#pin-background-picker .centered-div").append(this.getDivForBackground(backgroundName)); 
            this.setPinImage(backgroundName, "");
        }

        var self = this;
        $("#pin-background-picker .img-frame").click( function() {
            self.selectPinImageFromPinPicker($(this));
            return false;
        });
    }

    shouldHaveBackground(pinName) {
        if(pinName.includes("ihunter_pin_drawing") || pinName.includes("listitem_signpost")) {
            return false;
        }
        return true;
    }

    getDivForPin(pinImageName) {
        return '<div id="'+pinImageName+'" class="img-frame filter grid-item" style="display:inline-block"><img id="'+pinImageName+'_pin_picker" class="pin-image" style="max-width:40px;height: auto;width: auto;"><img class="green-checkmark" src="images/checkbadge@2x.png"></div>';
    }

    getDivForBackground(backgroundName) {
        return '<div id="'+backgroundName+'" class="img-frame filter" style="display:inline-block"><img id="'+backgroundName+'_pin_picker" class="pin-image" style="max-width:40px;height: auto;width: auto;"><img class="green-checkmark" src="images/checkbadge@2x.png"></div>';
    }

    setPinImage(pinName, backgroundImage) {
        gWaypointImageCache.getWaypointImage(pinName,backgroundImage).then(function(pinUrl) {
            $("#"+pinName+"_pin_picker").attr('src', pinUrl);
        });
    }

    isNoDateFilterSelected() {
        return $('#noneButton').hasClass('selected');
    }

    isDateRangeSelected() {
        return $('#dateRangeButton').hasClass('selected');
    }

    isPreviousDaysSelected() {
        return $('#previousDaysButton').hasClass('selected');
    }

    selectDateFilterType(type) {
        var self = this;
        this.dateFilterType = type;
        if(type == DateFilterType.NONE) {
            $('#noneButton').toggleClass('selected', true).siblings().toggleClass('selected', false);
            $('#dateFilterSelectionDiv').html('No date filter.');
        }
        else if(type == DateFilterType.DATE_RANGE) {
            $('#dateRangeButton').toggleClass('selected', true).siblings().toggleClass('selected', false);

            $('#dateFilterSelectionDiv').html('<input id="dateRangePicker" type="text" name="daterange" value="" />');
            //http://www.daterangepicker.com/#config
            $('#dateRangePicker').daterangepicker({
                'opens': 'center',
                "showCustomRangeLabel": false,
                "alwaysShowCalendars": true,
                ranges: {
                    'Today': [moment(), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    'This Year' : [moment().startOf('year'), moment().endOf('year')],
                    'Last Year' : [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
                 }
            }, function(start, end, label) {
                console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
                self.startDate = start;
                self.endDate = end;
                self.setSaveButtonEnabledOrDisabled();
            });

            if(this.currentFilter && this.currentFilter.startDate != null && this.currentFilter.startDate.isValid() && this.currentFilter.endDate != null && this.currentFilter.endDate.isValid()) {
                this.startDate = this.currentFilter.startDate;
                this.endDate = this.currentFilter.endDate;
            } else if(this.startDate && this.endDate) {//start and end date were previously set before clicking away
            
            } else {
                this.startDate = moment();
                this.endDate = moment().add(1,'days');
            }
            $('#dateRangePicker').data('daterangepicker').setStartDate(this.startDate);
            $('#dateRangePicker').data('daterangepicker').setEndDate(this.endDate);
        }
        else if(type == DateFilterType.PREVIOUS_DAYS) {
            $('#previousDaysButton').toggleClass('selected', true).siblings().toggleClass('selected', false);
            var initialValue = this.currentFilter != null && this.currentFilter.numberOfDays > 0 ? this.currentFilter.numberOfDays : 7;
            $('#dateFilterSelectionDiv').html('<input id="previousDaysInput" type="number" name="previousdays" value="'+initialValue+'" />');
            $('#previousDaysInput').on('input',function() {
                self.setSaveButtonEnabledOrDisabled();
            });
        }
        this.setSaveButtonEnabledOrDisabled();
    }

    selectPinImageFromPinPicker(imgFrame) {
        $(imgFrame).toggleClass("selected");
    }
    
    setInitialFilter() {
        var self = this;
        this.selectAllIcons();

        if(!this.currentFilter) {
            this.selectDateFilterType(DateFilterType.NONE);
            return;
        }

        if(this.currentFilter.numberOfDays > 0) {
            this.selectDateFilterType(DateFilterType.PREVIOUS_DAYS);
        } else if(this.currentFilter.startDate && this.currentFilter.endDate) {
            self.startDate = this.currentFilter.startDate;
            self.endDate = this.currentFilter.endDate;
            this.selectDateFilterType(DateFilterType.DATE_RANGE);
        } else {
            this.selectDateFilterType(DateFilterType.NONE);
        }

        if(this.currentFilter.inactivePinImages != null) {
            $('#pin-picker .img-frame.filter').each(function(index) {                
                var id = $(this).attr("id");
                $(this).toggleClass("selected", !self.currentFilter.inactivePinImages.includes(id));
            });
        }

        console.log(this.currentFilter);
        if(this.currentFilter.inactiveBackgroundImages != null) {
            $('#pin-background-picker .img-frame.filter').each(function(index) {                
                var id = $(this).attr("id");
                $(this).toggleClass("selected", !self.currentFilter.inactiveBackgroundImages.includes(id));
            });
        }
    }

    selectAllIcons() {
        $('#pin-picker .img-frame.filter').each(function(index) {
            $(this).toggleClass("selected",true);
        });
    }

    deselectAllIcons() {
        $('#pin-picker .img-frame.filter').each(function(index) {
            $(this).toggleClass("selected",false);
        });
    }

    dateFilterIsValid() {
        if(this.isNoDateFilterSelected()) {
            return true;
        }
        else if(this.isDateRangeSelected()) {
            console.log(this.startDate);
            console.log(this.endDate);
            console.log(this.startDate.isSameOrBefore(this.endDate));
            if(this.startDate != null  && this.endDate != null) {
                if(this.startDate.isSameOrBefore(this.endDate)) {
                    $('#dateRangePicker').css('border','');
                    return true;
                }
            }
            $('#dateRangePicker').css('border', '1px solid red');
        }
        else if(this.isPreviousDaysSelected()) {
            var numberOfDays = parseInt($('#previousDaysInput').val());
            if(numberOfDays != null && Number.isInteger(numberOfDays) && numberOfDays >= 0) {
                $('#dateRangePicker').css('border', '');
                return true;
            }
            $('#previousDaysInput').css('border', '1px solid red');
        }
        return false;
    }

    setSaveButtonEnabledOrDisabled() {
        if(this.dateFilterIsValid()) {
            console.log("Enabling Save Button");
            $('#modalPrimaryButton').removeAttr("disabled"); //enable save button.
        } else {
            console.log("Disabling Save Button");
            $('#modalPrimaryButton').attr("disabled",true); //disable save button.
        }
    }

}

export default WaypointFilterView;
