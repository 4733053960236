import L from 'leaflet'

import { uuidv4 } from './Encryption';
import { TileServiceType } from './Settings';
import { encodeAsFirebaseKey } from './Encryption'

//used for both BaseMaps and User added MapLayers that sit above the base maps. This is the BaseMap class in iOS and Android
//rename to UserMapLayer or something?
export const OverlayType = Object.freeze({"BASEMAP":1, "OVERLAY":2});

// WE CAN LIKELY SCRAP THIS ONCE LEAFLET SORTS IT OUT. THEY HAD INCLUDED coords.z IN v1.3.0 BUT THEN REVERTED IT AT SOME POINT.
// IF WE'RE USING RETINA OR Z-OFFSET, WE LIKELY NEED TO MODIFY THIS
L.TileLayer.Ihunter = L.TileLayer.extend({
    getTestTileUrl: function(coords) {
        let data = {
            r: L.Browser.retina ? '@2x' : '',
            s: this._getSubdomain(coords),
            x: coords.x,
            y: coords.y,
            z: coords.z ? coords.z : this._getZoomForUrl()
        }

        if (this._map && !this._map.options.crs.infinite) {
			var invertedY = this._globalTileRange.max.y - coords.y;
			if (this.options.tms) {
				data['y'] = invertedY;
			}
			data['-y'] = invertedY;
		}

		return L.Util.template(this._url, L.extend(data, this.options));
    }
});

L.tileLayer.ihunter = function(urlTemplate, options) {
    return new L.TileLayer.Ihunter(urlTemplate, options);
}

export class UserMapLayer { 
    constructor(mapProvider) {
      this.map = mapProvider;
        //defaults - these usually get overwritten...
        this.opacity = 1;
        this.overlayType = OverlayType.BASEMAP;
        this.minZoom = 4;
        this.bIsVisible = false;
        this.editable = true;
    }

    initWithSnapshot(snapshot) {
        var snapJson = snapshot.val();
        this.uuid = snapshot.key;
        this.overlayType = snapJson["maptype"]; //const OverlayType.BASEMAP = 1; const OverlayType.OVERLAY = 2;
        var mapJson = JSON.parse(snapJson["json"]);    
        if(Object.prototype.hasOwnProperty.call(snapJson, "opacity")) { //not sure yet if opacity will be in the json node or in the root...
            this.opacity = snapJson["opacity"];
        }
        if(Object.prototype.hasOwnProperty.call(snapJson, "visible")) { //not sure yet if opacity will be in the json node or in the root...
            this.bIsVisible = snapJson["visible"];
        }
        this.initWithJSON(mapJson);
        return this;
    }

    getUUID() {
        if(!this.uuid) {
            this.uuid = encodeAsFirebaseKey(this.displayTitle + "_" + this.cacheFolder);
        }
        return this.uuid;
    }

    initWithParams(url, title, subtitle, type, tileService, cache, maxZoom, opacity, bIsVisible, overlayType, uuid) {
        this.tileURL = url;
        this.displayTitle = title;
        this.displaySubtitle = subtitle;
        this.mapType = type; //this is an enum for ArcGIS, Google - Hybrid, Apple - Satellite, etc I think
        this.tileServiceType = tileService;
        this.cacheFolder = cache ? cache : uuidv4();
        this.maxZoom = maxZoom;
        this.minZoom = 4;
        this.latLngBounds = null;
        this.opacity = opacity > 1 ? opacity / 100 : opacity; //only used for map layers. In case somehow opacity 0-100 is entered, it should be 0-1.
        this.bIsVisible = bIsVisible;
        //we need these to keep track of layers in leaflet for add/remove
        this.leafletTileLayer = null;
        this.overlayType = overlayType != null ? overlayType : OverlayType.BASEMAP;
        this.uuid = uuid == null ? this.getUUID() : uuid;
        return this;
    }

    initWithJSON(json) {
        this.tileURL = json.url;
        this.displayTitle = json.title;
        this.displaySubtitle = json.subtitle;
        this.mapType = json.type; //this is an enum for ArcGIS, Google - Hybrid, Apple - Satellite, etc I think
        this.tileServiceType = json.tileservicetype;
        this.cacheFolder = json.cachefolder ? json.cachefolder : uuidv4(); //this is essentially just a uuid. We don't care about this in the web app, but need to keep track of it still..
        if(Object.prototype.hasOwnProperty.call(json, "boundingrect")) {
            var rectString = json.boundingrect;
            var rect = this.map.getBoundingRectFromString(rectString);
            this.latLngBounds = this.map.latLngBoundsFromRect(rect); 
        }
        this.maxZoom = json.maxzoom;
        this.getUUID()
        return this;
    }

    toJSON() {
        var json = {};
        json["url"] = this.tileURL;
        json["title"] = this.displayTitle;
        json["subtitle"] = this.displaySubtitle;
        json["type"] = this.mapType;
        json["tileservicetype"] = this.tileServiceType;
        json["cachefolder"] = this.cacheFolder;
        if(this.latLngBounds) {
            json["boundingrect"] = this.latLngBounds.getWest()+","+this.latLngBounds.getNorth()+","+this.latLngBounds.getEast()+","+this.latLngBounds.getSouth();
        }
        json["maxzoom"] = this.maxZoom;
        return JSON.stringify(json);
    }

    setTileLayer() {
        if(this.tileURL) {
            var zIndex = this.isBaseMap() ? 199 : 299;
            if(!this.leafletTileLayer) { 
                if(this.tileServiceType == TileServiceType.WMS) {
                    this.leafletTileLayer = L.tileLayer.wms(this.getTileURLWithoutParameters(), this.getWMSOptions()).setZIndex(zIndex).setOpacity(this.opacity);
                } 
                else if(this.tileServiceType == TileServiceType.TMS) {
                    this.leafletTileLayer = L.tileLayer.ihunter(this.tileURL, this.getTMSOptions()).setZIndex(zIndex).setOpacity(this.opacity);
                    // this.leafletTileLayer = L.tileLayer.ihunter(this.tileURL, this.getTMSOptions()).setZIndex(zIndex).setOpacity(this.opacity);
                } 
                else { 
                    this.leafletTileLayer = L.tileLayer.ihunter(this.tileURL, this.getXYZOptions()).setZIndex(zIndex).setOpacity(this.opacity);
                    // this.leafletTileLayer = L.tileLayer.ihunter(this.tileURL, this.getXYZOptions()).setZIndex(zIndex).setOpacity(this.opacity);
                }
            } 
        }
    }

    setEditable(value) {
        this.editable = value;
    }

    setOpacity(opacity) {
        this.opacity = opacity;
        if(this.leafletTileLayer != null) {
            this.leafletTileLayer.setOpacity(opacity);
        }
    }

    setVisibility(visible) {
      this.bIsVisible = visible;
      if(visible == false) {
        this.map.hideUserMapLayer(this);
      } else {
        this.map.showUserMapLayer(this);
      }
    }

    getTileURLWithoutParameters() {
        return this.tileURL.substring(0, this.tileURL.indexOf("?"));
    }

    equals(other) {
        // return ((this.tileURL.length > 0 && other.tileURL.length> 0 && this.tileURL == other.tileURL) || (this.tileURL.length == 0 && other.tileURL.length == 0 && this.mapType == other.mapType));
        return (((this.tileURL.length > 0 && other.tileURL.length> 0 && this.tileURL == other.tileURL) || (this.tileURL.length == 0 && other.tileURL.length == 0 && this.mapType == other.mapType)) && this.overlayType === other.overlayType && this.displayTitle === other.displayTitle && this.displaySubtitle === other.displaySubtitle);
    }

    isBaseMap() {
        return this.overlayType === OverlayType.BASEMAP;
    }

    isWMS() {
        return this.tileServiceType === TileServiceType.WMS;
    }

    isTMS() {
        return this.tileServiceType === TileServiceType.TMS;
    }
    
    isEditable() {
        return this.editable;
    }

    getShortenedTitle() {
        return this.displayTitle.substring(0,10); // I also want to make sure these are unique!
    }

    getWMSOptions() {
        var indexOfParams = this.tileURL.indexOf("?");
        var originalParams = this.tileURL.substring(indexOfParams+1, this.tileURL.length);

        var options = this.getSharedOptions();

        var arrayOfParams = originalParams.split("&");
        for(var i = 0; i < arrayOfParams.length; i++) {
            var paramString = arrayOfParams[i];
            var paramParts = paramString.split("=");
            if(paramParts.length == 2) {
                var param = paramParts[0].toLowerCase();
                var paramVal = paramParts[1].replace("%2f", "/");
                if(param == "version") {
                    options["version"] = paramVal;
                }
                else if(param == "styles") {
                    options["styles"] = paramVal; 
                }
                else if(param == "format") {
                    options["format"] = paramVal;
                }
                else if(param == "layers") {
                    options["layers"] = paramVal;
                }
                else if(param == "transparent") {
                    options["transparent"] = paramVal;
                }
                //if we use these projections it fucks things up if it doesn't match the leaflet projection.
                // else if(param == "srs" || param == "crs") { 
                //     if(paramVal.includes("4326")) {
                //         options["crs"] = L.CRS.EPSG4326;
                //     } else if(paramVal.includes("3857")) {
                //         options["crs"] = L.CRS.EPSG3857;
                //     } else if(paramVal.includes("3395")) {
                //         options["crs"] = L.CRS.EPSG3395;
                //     }
                // }
            }    
        }
        return options;
    }

    getTMSOptions() {
        var options = this.getSharedOptions();
        options["tms"] = true;
        return options;
    }

    getXYZOptions() {
        return this.getSharedOptions();
    }

    getSharedOptions() {
        var options = {};
        options["minZoom"] = this.minZoom;
        options["maxZoom"] = this.maxZoom;
        if(this.latLngBounds) {
            options["bounds"] = this.latLngBounds;
        }
        if(this.isBaseMap()) {
            let uuid = this.getUUID()
            options["attribution"] = '<a href="https://www.ihunterapp.com" title="iHunter Website" target="_blank">iHunter</a><div style="display:none;">id=**'+uuid+'**</div>';
            options['uuid'] = uuid;
        }
        return options;
    }

    getRowHTML() {
        var checked = this.bIsVisible ? 'checked' : '';
        var inputID = this.cacheFolder;
        var row = '';
        row += this.isBaseMap() ? '<div class="ihunter-menu-switch-row">' : '<div class="ihunter-menu-switch-row no-bottom-border">';

            row += '<div class="middle-div">';
                row += '<div class="ihunter-menu-text large">'+ this.displayTitle + '</div>';
                row += '<div class="ihunter-menu-text small">' + this.displaySubtitle + '</div>';
            row += '</div>';

            if(!this.isBaseMap()) {
                row += '<label class="ihunter-menu-switch float-right">'
                    row += '<input id="'+ inputID+'_switch'+ '" type="checkbox" ' + checked + ' onclick="changeVisibilityOfUserMapLayer(\''+this.getUUID()+'\')">'
                    row += '<span class="slider-switch round"></span>'
                row += '</label>';
            }

            if(this.isEditable()) {
                row += '<button id="'+inputID+'_editButton" class="btn btn-primary btn-sm user-map-layer-btn" type="button" onclick="editUserMapLayer(\''+this.getUUID()+'\')"><span class="fa fa-edit fa-lg" aria-hidden="true"></span></button>';
                row += '<button id="'+inputID+'_deleteButton" class="btn btn-danger btn-sm user-map-layer-btn" type="button" onclick="deleteUserMapLayer(\''+this.getUUID()+'\')"><span class="fa fa-trash fa-lg" aria-hidden="true"></span></button>';
            }
   
        row += '</div>';

        if(!this.isBaseMap()) {
            row += '<div class="ihunter-menu-row-nohover no-top-border" id="'+inputID+'_opacityrow">';
                row += '<div class="ihunter-menu-text" style="display:inline-block;">Map Opacity:</div>';
                row += '<div class="opacity-slider"> <input  id="'+inputID+'_input" data-slider-id="'+inputID+'_slider" type="text" data-slider-min="0" data-slider-max="1" data-slider-step="0.01" data-slider-value="'+this.opacity+'" data-slider-tooltip="hide"/>' + '</div>';
            row += '</div>';
        }

        return row;
    }
    
}

export default UserMapLayer;
