import $ from 'jquery';
import _ from 'underscore';
import toastr from 'toastr';
import bootbox from 'bootbox';

import Vue from 'vue';
import AppInfo from './AppInfo.vue';

import RightSidebar from './RightSidebar';
import LeftSidebar from './LeftSidebar';

import ChatView from './ChatView';
import SubscriptionMenuView from './SubscriptionMenuView';
import CountyMapsMenuView from './CountyMapsMenuView';
import SettingsMenuView from './SettingsMenuView';
import BlogMessagesView from './BlogMessagesView';
import EmailUsView from './EmailUsView';
import StoreView from './StoreView';
import AddUserMapLayerView from './AddUserMapLayerView';
import AddRegionView from './AddRegionView';
import AddWaypointView from './AddWaypointView';
import AddContactsView from './AddContactsView';
import AddContactsToGroupView from './AddContactsToGroupView';
import CreateGroupView from './CreateGroupView';
import ShareWaypointsView from './ShareWaypointsView';
import WaypointView from './WaypointView';
import TrackedWaypointView from './TrackedWaypointView';
import DrawnWaypointView from './DrawnWaypointView';
import EditWaypointView from './EditWaypointView';
import EditDrawnWaypointView from './EditDrawnWaypointView';
import EditTrackedWaypointView from './EditTrackedWaypointView';
import ListOfPropertiesView from './ListOfPropertiesView';
import ToolsMenuView from './ToolsMenuView';
import EditGroupView from './EditGroupView';
import ManageAccountView from './ManageAccountView';
import ManageSubscriptionView from './ManageSubscriptionView';
import ChangeBoundaryColorsView from './ChangeBoundaryColorsView';
import WeatherView from './WeatherView';
import AddLatLonWaypointView from './AddLatLonWaypointView';
import WMSWizardView from './WMSWizardView';
import DrawingView from './DrawingView';
import HuntLogView from './HuntLogView';

import { openHTMLFileInCustomModal, openTextInModalWithMoreInfo, openHTMLFileInModal,
    openModal, showPDF, showImage, openModalURL, showPDFWithName
 } from './ModalHelper';
import { openURLInNewTab } from './Environment';
import { isAndroid, isIOS, browserCanRunWebapp, shouldShowAnimations } from './minimumrequirements';
import { centerElementHorizontally} from './UIHelper';
import { gSettings } from './Settings';
import { gSubscriptionSettings } from './SubscriptionSettings';
import { getProvinceSelectionContent } from './Province';


window.bootbox = bootbox; // Expose globally to ensure php scripts can reference bootbox

const FADE_OUT_SPLASH = 2000;
const FADE_IN_SPLASH = 500;

export default class AppView {

    constructor(app) {

        this.app = app;

        // Lazy load via getter
        this._rightSidebar = null;
        this._leftSidebar = null;

        this._chatView = null;
        this._toolsMenuView = null;
        this._subscriptionMenuView = null;
        this._countyMapsMenuView = null;
        this._settingsMenuView = null;
        this._drawingView = null;
        this._blogMessagesView = null
        this._regionView = null;
        this._huntlogView = null;

        this._infoView = null;

        this.setupMainMenuListeners();
        this.setupIHunterMenuListeners();
        this.setupWindowListeners();
        this.setupToolListeners();
        this.setupChatListeners();
        this.showFirebaseLoading("Sign in...");
    }

    get regulations() {
        return this.app.regulations;
    }

    get rightSidebar() {
        if(!this._rightSidebar) {
            this._rightSidebar = new RightSidebar();
        }
        return this._rightSidebar;
    }

    get leftSidebar() {
        if(!this._leftSidebar) {
            this._leftSidebar = new LeftSidebar();
        }
        return this._leftSidebar;
    }
        

    get chatView() {
        if(!this._chatView) {
            this._chatView = new ChatView(this.app.chatManager);
        }
        return this._chatView;
    }

    get toolsMenuView() {
        if(!this._toolsMenuView) {
            this._toolsMenuView = new ToolsMenuView(this.app.map, this.app.waypointManager);
        }
        return this._toolsMenuView;
    }

    get subscriptionMenuView() {
        if(!this._subscriptionMenuView) {
            this._subscriptionMenuView = new SubscriptionMenuView(this.app.map, this.app.waypointManager, this.app.regulationService);
        }
        return this._subscriptionMenuView;
    }

    get countyMapsMenuView() {
        if(!this._countyMapsMenuView) {
            this._countyMapsMenuView = new CountyMapsMenuView(this.app.map, this.app.regulationService);
        }
        return this._countyMapsMenuView;
    }

    get settingsMenuView() {
        if(!this._settingsMenuView) {
            this._settingsMenuView = new SettingsMenuView(this.app.settingsManager);
        }
        return this._settingsMenuView;
    }

    get drawingView() {
        if(!this._drawingView) {
            this._drawingView = new DrawingView(this.app.map, this.app.waypointManager);
        }
        return this._drawingView;
    }

    get blogMessagesView() {
        if(!this._blogMessagesView) {
            this._blogMessagesView = new BlogMessagesView(this.app.regulationService);
        }
        return this._blogMessagesView;
    }

    get storeView() {
        if(!this._storeView) {
            this._storeView = new StoreView();
        }
        return this._storeView;
    }

    get regionView() {
        if(!this._regionView) {
            this._regionView = new AddRegionView(this.app.regionManager);
        }
        return this._regionView;
    }

    get huntlogView() {
        if(!this._huntlogView) {
            this._huntlogView = new HuntLogView(this.app.service);
        }
        return this._huntlogView;
    }    

    get infoView() {
        if(!this._infoView) {
            
            let div = document.createElement('div');
            div.id = 'app';
            document.body.prepend(div);
            this.vm = new Vue({
                el: '#app',
                render: h => h(AppInfo)
            });

            this._infoView = this.vm.$children[0];
        }
        return this._infoView;
    }    

    setupMainMenuListeners() {

        // LeftSidebar
        $("#ihunterButton").on('click', () => {
            this.leftSidebar.toggleWithTypeAndContent(LeftSidebar.MENU_TYPE.IHUNTER, getProvinceSelectionContent(this.app.map.provinces));
        });
        $("#toolsButton").on('click', () => {
            this.leftSidebar.openFromHtmlWithType(LeftSidebar.MENU_TYPE.TOOLS, "tools_menu_view.html #toolsMenu", this.toolsMenuView);
        });
        $("#publicLandsButton").on('click', () => {
            this.leftSidebar.openFromHtmlWithType(LeftSidebar.MENU_TYPE.PUBLIC_LAND, "subscription_menu_view.html #subscriptionMenu", this.subscriptionMenuView);
        });
        $("#countyMapsButton").on('click', () => {
            this.leftSidebar.openFromHtmlWithType(LeftSidebar.MENU_TYPE.COUNTY_MAPS, "countymaps_menu_view.html #countyMapsMenu", this.countyMapsMenuView);
        });


        // RightSidebar
        $("#settingsButton").on('click', () => {
            this.rightSidebar.openFromHtmlWithType(RightSidebar.MENU_TYPE.SETTINGS, "settings_menu_view.html #settingsMenu", this.settingsMenuView);
        });
        $("#blogMessagesButton").on('click', () => {
            this.rightSidebar.openFromHtmlWithType(RightSidebar.MENU_TYPE.BLOG_MESSAGES, "blog_messages_view.html #blogMessages", this.blogMessagesView);
        });
        $("#chatButton").on('click', () => {
            this.rightSidebar.openFromHtmlWithType(RightSidebar.MENU_TYPE.CHAT, "chat_view.html #chats-div", this.chatView);
        });
        $("#storeButton").on('click', () => {
            this.rightSidebar.openFromHtmlWithType(RightSidebar.MENU_TYPE.STORE, "store_view.html #store", this.storeView);
        });
    }


    setupIHunterMenuListeners() {
        // Register handlers in view, dispatch to controller
        window.updateActiveProvince = (provinceCode) => { 
            this.app.setActiveProvince(provinceCode);
            _.delay(function () {
                window.location.reload();   // Reload to new province
                //window.location.href = IHUNTER_URL; 
            }, 1000);
        };
        

        window.onHuntLog = () => {
            this.leftSidebar.openFromHtmlWithType(LeftSidebar.MENU_TYPE.HUNTLOG, "huntlog_view.html", this.huntlogView);
        };
        
        window.onFAQs = () => {
            window.open("https://www.ihunterapp.com/faq/", "_blank");
        };
        
        window.onTutorials = () => {
            window.open("https://www.youtube.com/playlist?list=PLqBh7ZG8WbCx-IIkjuiAd70J5P1B5JJFR", "_blank");
        };
        
        window.onEmailUs = () => {
            openHTMLFileInCustomModal('Email info@ihunterapp.com', 'email_us_view.html', 'Send', 'Cancel', '60', '80', false).then((result) => {
                new EmailUsView(result, this.app.service);
            }, function(err) {
                console.log(err);
            });
        };

        window.onManageAccount = () => {
            openHTMLFileInCustomModal('Manage Account', 'manage_account_view.html', 'Save', 'Cancel', '30', '80', false).then((result) => {
                new ManageAccountView(result, this.app.accountManager);
            }, function(err) {
                console.log(err);
            });
        };

        window.logoutOfFirebase = async () => {
            this.app.service.signOut();
            this.hideSeatsExceeded();
            location.reload();
            // Sign-out successful.
        };

        window.openURLInNewTab = (url) => {
            openURLInNewTab(url);
        };

        // New VUE popup approach
        window.showAppInfo = () => {
            this.infoView.show();
        };
    }

    setupWindowListeners() {

        window.openTab = (evt, tabName, isModal) => {
            this.openTab(evt, tabName, isModal); 
        };
        
        window.boundarySwitchClicked = (checkbox, layerID) => { 
            this.app.map.boundarySwitchClicked(checkbox, layerID);
        };

        window.onCountyMapPopupClick = (countyMapName) => {

            if(this.leftSidebar.sidebarViewType != LeftSidebar.MENU_TYPE.COUNTY_MAPS) {
                this.leftSidebar.openFromHtmlWithType(LeftSidebar.MENU_TYPE.COUNTY_MAPS, "countymaps_menu_view.html #countyMapsMenu", this.countyMapsMenuView);
                //openLeftSidebarWithType(TYPE_MENU_OPEN_COUNTY_MAPS);
            }
            _.delay(() => {
                //handles if it's a purchasable map
                this.countyMapsMenuView.scrollToAndOpenMap(countyMapName);
            }, 600);

            this.closeMapPopup();
        };

        window.openWeatherView = (lat,lon) => {
            this.rightSidebar.openFromHtmlWithType(RightSidebar.MENU_TYPE.OTHER, "weather_view.html #weatherView", new WeatherView(lat,lon));
            this.closeMapPopup();
        };


        window.onWMU = async (code, title) => {
            let text = await this.regulations.getWMUInfo(code, title, false);
            this.rightSidebar.toggleWithTypeAndContent(RightSidebar.MENU_TYPE.OTHER, text, title);
            this.rightSidebar.wmu = code;

            $('#rightSidebarSpinner').hide(); // TODO: Move to RightSidebar
        };
        
        window.getWMUInfo = async (code, title) => { // in GetWMUInfo.php
            let text = await this.regulations.getWMUInfo(code, title, true);
            openModal(title, text);
        };

        window.onLEH = async (code, title) => {
            if(this.app.provinceCode == "bc") { // This condition sucks...
                var pdfURL = "res/provinces/"+this.app.provinceCode+"/pdfs/leh.pdf";
                showPDF(pdfURL, title);
            }else {                
                let text = await this.regulations.getLEHInfo(code, title, false);
                this.rightSidebar.toggleWithTypeAndContent(RightSidebar.MENU_TYPE.OTHER, text, title);
                this.rightSidebar.wmu = code;

                $('#rightSidebarSpinner').hide(); // TODO: Move to RightSidebar
            }
            this.closeMapPopup();
        };

        window.getLEHInfo = async (code, title) => { // in GetWMUInfo.php
            let text = await this.regulations.getLEHInfo(code, title, true);
            openModal(title, text);
        };

        window.onAgLease = async (disposition, title) => {

            var mapLayer  = this.app.map.getMapLayerForLayerID("agleases");

            let text = await this.regulations.getAgLeaseInfo(mapLayer, disposition, title, false);
            this.rightSidebar.toggleWithTypeAndContent(RightSidebar.MENU_TYPE.OTHER, text, title);
            $('#rightSidebarSpinner').hide();

            this.closeMapPopup();
        };

        window.onWMSWizard = async (addUserMapLayerViewReference, userMapLayer, getCapabilitiesURL) => {

            var arrayOfJsons = await this.app.regulationService.getCapabilities(getCapabilitiesURL);
            if(arrayOfJsons == null || arrayOfJsons.length == 0 || arrayOfJsons[0].name == null || arrayOfJsons[0].name == "") {
                addUserMapLayerViewReference.alertMapNotValidated(userMapLayer);
            } else {
                openHTMLFileInCustomModal('WMS URL Wizard', 'wms_wizard_view.html', 'Next', 'Cancel', '30', '80', true).then(function(result) {
                    new WMSWizardView(result, addUserMapLayerViewReference, userMapLayer, arrayOfJsons, getCapabilitiesURL);
                }, function(err) {
                    console.log(err);
                });
            }
        };

        window.getAgLeaseInfo = async (disposition, title) => { // Not used?
            let text = await this.regulations.getAgLeaseInfo(disposition, title, true);
            openModal(title, text);
        };

        window.onBoundary = (data, data2) => {
            console.log(data, data2);
            this.getLayerInfo(data, data2);
        };

        window.showPDFWithName = (pdfName, title) => {
            showPDFWithName(pdfName, title);
        };

        window.onAddWaypoint = (lat, lon, name) => {
            openHTMLFileInCustomModal('Add Waypoint', 'add_waypoint_view.html', 'Save', 'Cancel', '70', '80', false).then((result) => {
                var view = new AddWaypointView(this.app.waypointManager, result, lat, lon, null);
                if(name) {
                    view.setWaypointName(name);
                }
            }, function(err) {
                console.log(err);
            });

            this.closeMapPopup();
        };
    
        window.onEditWaypoint = (waypoint) => {
            openHTMLFileInCustomModal('Edit Waypoint', 'add_waypoint_view.html', 'Save', 'Cancel', '70', '80', false).then((result) => {
                new EditWaypointView(this.app.waypointManager, result, waypoint);
    
            }, function(err) {
                console.log(err);
            });

            this.closeMapPopup();
        };
    
        window.onEditDrawnWaypoint = (waypoint) => {
            openHTMLFileInCustomModal('Edit Drawn Waypoint', 'add_drawn_waypoint_view.html', 'Save', 'Cancel', '70', '80', false).then((result) => {
                new EditDrawnWaypointView(this.app.waypointManager, this.drawingView, result, waypoint);
    
            }, function(err) {
                console.log(err);
            });
        };
    
        window.onEditTrackedWaypoint = (waypoint) => {
            openHTMLFileInCustomModal('Edit Tracked Waypoint', 'add_tracked_waypoint_view.html', 'Save', 'Cancel', '70', '80', false).then((result) => {
                new EditTrackedWaypointView(this.app.waypointManager, result, waypoint);
    
            }, function(err) {
                console.log(err);
            });
        };

        window.requestDeleteWaypoint = (uuid) => {
            var self = this;
            bootbox.dialog({
                message: "Are you sure that you want to delete this waypoint?",
                onEscape: false,
                closeButton: false,
                buttons: {  
                    delete: {
                        label: 'Delete',
                        className: 'btn-danger',
                        callback: function(){
                            self.app.waypointManager.deleteWaypoint(uuid);
                        }
                    },
                    confirm: {
                        label: 'Keep',
                        className: 'btn-primary',
                        callback: function(){
                                
                        }
                    }
                }
            });
        };
        
        window.requestDeleteDrawnWaypoint = (uuid) => {
            var self = this;
            bootbox.dialog({
                message: "Are you sure that you want to delete this drawing?",
                onEscape: false,
                closeButton: false,
                buttons: {
                    delete: {
                        label: 'Delete',
                        className: 'btn-danger',
                        callback: function(){
                            self.app.waypointManager.deleteDrawnWaypoint(uuid);
                        }
                    },
                    confirm: {
                        label: 'Keep',
                        className: 'btn-primary',
                        callback: function(){
                                
                        }
                    }
                }
            });
        };
        
        window.requestDeleteTrackedWaypoint = (uuid) => {
            var self = this;
            bootbox.dialog({
                message: "Are you sure that you want to delete this track?",
                onEscape: false,
                closeButton: false,
                buttons: {
                    delete: {
                        label: 'Delete',
                        className: 'btn-danger',
                        callback: function(){
                            self.app.waypointManager.deleteTrackedWaypoint(uuid);
                        }
                    },
                    confirm: {
                        label: 'Keep',
                        className: 'btn-primary',
                        callback: function(){
                                
                        }
                    }
                }
            });
        };

        window.showExtraInfo = (extraInfoJson) => {
            if(extraInfoJson != null) {
                window.openListOfPropertiesView(extraInfoJson);
            }
        };

        window.openListOfPropertiesView = (json) => {
            this.rightSidebar.openFromHtmlWithType(RightSidebar.MENU_TYPE.OTHER, "list_of_properties_view.html #listOfPropertiesView", new ListOfPropertiesView(json));
            this.closeMapPopup();
        };
        
        window.openWaypointView = (uuid) => {
            this.rightSidebar.openFromHtmlWithType(RightSidebar.MENU_TYPE.OTHER, "waypoint_view.html #waypointView", new WaypointView(this.app.waypointManager, uuid));
            this.closeMapPopup();
        };
        
        window.openWaypointViewAndZoomToLocation = (uuid) => {
            this.rightSidebar.openFromHtmlWithType(RightSidebar.MENU_TYPE.OTHER, "waypoint_view.html #waypointView", new WaypointView(this.app.waypointManager, uuid));
            this.app.map.zoomToLocation(this.app.waypointManager.waypoints[uuid].location);
        };
        
        window.openTrackedWaypointView = (uuid) => {
            this.rightSidebar.openFromHtmlWithType(RightSidebar.MENU_TYPE.OTHER, "tracked_waypoint_view.html #trackedWaypointView", new TrackedWaypointView(this.app.waypointManager, uuid));
            this.closeMapPopup();
        };
        
        window.openTrackedWaypointViewAndZoomToLocation = (uuid) => {
            this.rightSidebar.openFromHtmlWithType(RightSidebar.MENU_TYPE.OTHER, "tracked_waypoint_view.html #trackedWaypointView", new TrackedWaypointView(this.app.waypointManager, uuid));
            this.app.map.zoomToLocation(this.app.waypointManager.trackedWaypoints[uuid].location);
        };
        
        window.openDrawnWaypointView = (uuid) => {
            this.rightSidebar.openFromHtmlWithType(RightSidebar.MENU_TYPE.OTHER, "drawn_waypoint_view.html #drawnWaypointView", new DrawnWaypointView(this.app.waypointManager, uuid));
            this.closeMapPopup();
        };
        
        window.openDrawnWaypointViewAndZoomToLocation = (uuid) => {
            this.rightSidebar.openFromHtmlWithType(RightSidebar.MENU_TYPE.OTHER, "drawn_waypoint_view.html #drawnWaypointView", new DrawnWaypointView(this.app.waypointManager, uuid));
            this.app.map.zoomToLocation(this.app.waypointManager.drawnWaypoints[uuid].location);
        };

        window.onDrivingDirections = (lat, lon) => {
            this.rightSidebar.close();
            openURLInNewTab("https://www.google.com/maps?saddr=My+Location&daddr="+lat+","+lon);
        };
        
        window.filterSwitchClicked = (cb) => {
            var preference = $(cb).attr('id');
            var checked = cb.checked;    
            gSettings.updatePreference(preference,checked);    
        };

        window.onAddUserMapLayer = () => {
            openHTMLFileInCustomModal('Add Map Layer', 'add_map_layer_view.html', 'Save', 'Cancel', '50', '90', false).then((result) => {
                new AddUserMapLayerView(result, this.app.map, this.app.service);
            }, function(err) {
                console.log(err);
            });
        };
        
        window.onEditUserMapLayer = (userMapLayer) => {
            openHTMLFileInCustomModal('Edit Map Layer', 'add_map_layer_view.html', 'Save', 'Cancel', '50', '90', false).then((result) => {
                var view = new AddUserMapLayerView(result, this.app.map, this.app.service);
                view.populateWithUserMapLayer(userMapLayer)
            }, function(err) {
                console.log(err);
            });
        }
        
        window.onEditGroupChat = (chat) => {
            openHTMLFileInCustomModal('Edit Group', 'edit_group_view.html', 'Save', 'Cancel', '30', '80', false).then((result) => {
                new EditGroupView(result, this.app.accountManager, this.app.chatManager, chat);
            }, function(err) {
                console.log(err);
            });
        }
        
        window.onManageSubscription = () => {
            openHTMLFileInCustomModal('Manage Subscription', 'manage_subscription_view.html', 'Save', 'Cancel', '30', '80', false).then((result) => {
                new ManageSubscriptionView(result, this.app.accountManager);
            }, function(err) {
                console.log(err);
            });
        }

        window.onAddStartupRegion = () => {
            this.rightSidebar.close();
            this.leftSidebar.close();

            this.regionView.start((mapRegion) => {// This should be moved to a VM
                this.app.service.db.setStartupMapRegion(mapRegion);
                this.toast("Future launches of iHunter will now start at this map position. The start-up location can be changed in the settings.", "Default Start-up Region Changed");

            });
        }
        
        window.onModifyBoundaryColors = () => {
            openHTMLFileInCustomModal('Modify Boundary Colors', 'change_boundary_colors_view.html', 'OK', null, '50', '90', false).then((result) => {
                new ChangeBoundaryColorsView(result, this.app.map);
            }, function(err) {
                console.log(err);
            });
        }
    }

    setupToolListeners() {
        $("#addWaypointButton").on('click', () => {
            //create the content for the modal
            //2 tabs, simple lat/lon content to fill in
            //save/cancel with content checks

            console.log("addWaypointButton clicked");
            openHTMLFileInCustomModal('Add Waypoint', 'add_lat_lon_waypoint_view.html', 'Save', 'Cancel', '50', '50', false).then((result) => {
                new AddLatLonWaypointView(result, this.app.map);
            }, function(err) {
                console.log(err);
            });
        });

        window.onDrawAndMeasureClicked = (waypoint) => {
            console.log(`onDrawAndMeasureClicked with waypoint: ` + waypoint);

            this.rightSidebar.close();
            this.leftSidebar.close();
            this.drawingView.startDrawing(waypoint);
        };

        $("#drawAndMeasureButton").on('click', () => {
            console.log("drawAndMeasureButton clicked");

            this.rightSidebar.close();
            this.leftSidebar.close();
            this.drawingView.startDrawing(null);
        });
    }

    setupChatListeners() {
        window.onAddContactClick = () => {
            openHTMLFileInCustomModal('Add Contacts', 'add_contacts_view.html', 'Save', 'Cancel', '40', '80', false).then((result) => {
                new AddContactsView(result, this.app.contactManager);
            }, function(err) {
                console.log(err);
            });
        };

        window.onAddGroupClick = () => {
            openHTMLFileInCustomModal('Create Group', 'create_group_view.html', 'Save', 'Cancel', '40', '80', false).then((result) => {
                new CreateGroupView(result, this.app.chatManager);
            }, function(err) {
                console.log(err);
            });
        };

        window.onAddContactOrGroupClick = () => {
            var popup = document.getElementById("addContactsPopup");
            popup.classList.toggle("show");
        };

        window.onAddContactToGroupClick = (chat) => {
            openHTMLFileInCustomModal('Select Contact(s)', 'create_group_view.html', 'Save', 'Cancel', '40', '80', false).then((result) => {
                new AddContactsToGroupView(result, this.app.chatManager, chat);
            }, function(err) {
                console.log(err);
            });
        };

        window.onShareWaypointClick = (chatKey) => {
            if(this.app.waypointManager.hasWaypoints()) {
                openHTMLFileInCustomModal('Select Waypoint(s)', 'share_waypoints_view.html', 'Save', 'Cancel', '40', '80', false).then((result) => {
                    var chat = this.app.chatManager.getActiveChat();
                    new ShareWaypointsView(result, chat, this.app.waypointManager);
                }, function(err) {
                    console.log(err);
                });
            } else {
                bootbox.alert("You do not have any waypoints to send.");
            }
        };
    }

    confirmPlatformSupport() {
        var android = isAndroid();
        var ios = isIOS();
    
        if(!(android || ios)) {
            if(browserCanRunWebapp()) {
                return true;
            }
            else {
                console.log("On old or unsupported browser");
                this.hideFirebaseLoading();
                this.showSupportedBrowsers();
            }
        }else {
            console.log("on android or ios");
            this.hideFirebaseLoading();
            if(android) {
                this.showGooglePlayStoreLink();
            } else if(ios) {
                this.showIOSAppStoreLink();
            }
        }
        return false;
    }

    closeMapPopup() {
        this.app.map.closePopup();
    }

    zoomToLocation(latLng) {
        this.app.map.zoomToLocation(latLng);
    }
    
    zoomToLatLngBounds(bounds) {
        this.app.map.zoomToLatLngBounds(bounds);
    }

    zoomToLatLngBoundsRect(boundsRect) {
        this.app.map.zoomToLatLngBoundsRect(boundsRect);
    }

    showBody() {
        document.body.style.visibility='visible';
    }

    showMainUIComponents(province) {
        this.showFirebaseLoading("Loading map data...");

        setTimeout(() => {

            // show splash page before hiding the other features
            $("#splash_screen").fadeOut(FADE_OUT_SPLASH);


            document.getElementById('headerDiv').style.visibility = "visible";
            document.getElementById('mapid').style.visibility = "visible";
            document.getElementById('leftDivContainer').style.visibility = "visible";
            document.getElementById('bottomDivContainer').style.visibility = "visible";
            document.getElementById('leftSidebar').style.visibility = "visible";
            document.getElementById('rightSidebar').style.visibility = "visible";

            
            if(gSubscriptionSettings.getSubscription()) {
                $('#publicLandsButtonText').text(gSubscriptionSettings.getSubscription().name);
                $('#publicLandsButton').show();    
            } else {
                $('#publicLandsButton').hide();    
            }

            if(province.getProperty("HAS_COUNTY_MAPS_IAP")) {
                $('#countyMapsButton').show();  
            } else {
                $('#countyMapsButton').hide(); 
            }

            document.getElementById('firebase-login-box').style.visibility = "hidden";

            this.hideFirebaseLoading();

        }, FADE_OUT_SPLASH);
    }


  hideMainUIComponents() {
    $("#splash_screen").fadeIn(FADE_IN_SPLASH, function () {
        //console.log("faded in splash screen");
        document.getElementById('headerDiv').style.visibility = "hidden";
        document.getElementById('mapid').style.visibility = "hidden";
        document.getElementById('leftDivContainer').style.visibility = "hidden";
        document.getElementById('bottomDivContainer').style.visibility = "hidden";
        document.getElementById('leftSidebar').style.visibility = "hidden";
        document.getElementById('rightSidebar').style.visibility = "hidden";
    });
  }

  showFirebaseLogin() {
    if(!(isAndroid() || isIOS())) {
        $("#firebase-login-box").fadeIn(FADE_OUT_SPLASH);
    }
  }

  hideProvinceSelection() {
    $("#province-selection-box").fadeOut(FADE_OUT_SPLASH);
  }

  showProvinceSelection() {
    this.hideFirebaseLogin();
    
    //hide the iHunter logo if the window is too small.
    if($("#province-selection-box").height() < 620) {
        $("#province-selection-box-ihunter-logo").height($("#province-selection-box").height()- 415 - 20);
    }
    $(window).resize(function() {
        if($("#province-selection-box").is(":visible")) {
            if($("#province-selection-box").height() < 620) {
                $("#province-selection-box-ihunter-logo").height($("#province-selection-box").height() - 415 - 20);
            } else {
                $("#province-selection-box-ihunter-logo").height(187.5)
            }
        }
    });

    _.delay(function () {
        $("#province-selection-box").fadeIn(FADE_OUT_SPLASH, function () {
        });
    }, FADE_OUT_SPLASH);
  }

    showLoadingDivWithText(str) {
        $("#mapLoadingText").html(str);
        $("#mapLoadingDiv").show();
    }

    hideLoadingDiv() {
        $("#mapLoadingDiv").hide();
    }

    showFirebaseLoading(loadingtext) {
        document.getElementById('loadingview').style.visibility = "visible";
        document.getElementById('loadingtext').textContent = loadingtext;

        this.showBody();
    }

    hideFirebaseLoading() {
        document.getElementById('loadingview').style.visibility = "hidden";
        document.getElementById('loadingtext').textContent = "";
    }

    hideFirebaseLogin() {
        _.delay(function () {
            $("#firebase-login-box").fadeOut(FADE_OUT_SPLASH, function () {
                // document.getElementById('firebase-login-box').style.visibility = "hidden";
            });
        }, FADE_OUT_SPLASH);
    }

    showIOSAppStoreLink() {
        centerElementHorizontally(document.getElementById('firebase-login-box'));
        document.getElementById('ios-link').style.visibility = "visible";
        document.getElementById('mobile-link').style.visibility = "hidden";
    }

    showGooglePlayStoreLink() {
        centerElementHorizontally(document.getElementById('firebase-login-box'));
        document.getElementById('android-link').style.visibility = "visible";
        document.getElementById('mobile-link').style.visibility = "hidden";
    }

    showSupportedBrowsers() {
        document.getElementById('browser-support').style.visibility = "visible";
    }

    showSeatsExceeded() {
        this.hideFirebaseLoading();
        document.getElementById('seat-limit-exceeded').style.visibility = "visible";
    }

    hideSeatsExceeded() {
        document.getElementById('seat-limit-exceeded').style.visibility = "hidden";
    }

    centerElementHorizontally(elem) {
        elem.style.left = '50%';
        elem.style.right = 'auto';
        elem.style.transform = 'translate(-50%, -50%)';
        elem.style["-webkit-transform"] = "translate(-50%, -50%)";
        elem.style["-ms-transform"] = "translate(-50%, -50%)";
        elem.style["transform"] = "translate(-50%, -50%)";
    }

    setProvinceIcon(code) {
        $('.icon-ihunter').css('background-image', 'url(images/ihunter_logo_' + code + '.png)');
        $('.icon-ihunter').css('background-size', 'cover');
        $('.icon-ihunter').css('height', '65px');
        $('.icon-ihunter').css('width', '70px');
    }

    setupAnimations() {
        if(!shouldShowAnimations()) {        
            $(".sidebar").addClass('no-transition');
            $(".leaflet-control-zoom").addClass('no-transition');
            $(".leaflet-control-scale").addClass('no-transition');
            $(".leaflet-control-layers").addClass('no-transition');
            $("#leftDivContainer").addClass('no-transition');
        }
    }



    getLayerInfo(comment, comment2) {
        comment = comment == "null" ? null : comment;
        comment2 = (comment2 == "null" || comment == comment2) ? null : comment2;

        if (comment != null && comment == "http://mywildalberta.com/hunting/regulations/road-corridor-wildlife-sanctuaries.aspx") {
            bootbox.confirm({
                closeButton: false,
                title: "Road Corridor Wildlife Sanctuary",
                message: "This road corridor was mapped independantly by iHunter. It is an interpretation of the corridor description only and should not be relied on as a legal definition.<br><br>View the road corridor details online? ",
                buttons: {
                    cancel: {
                        label: 'No'
                    },
                    confirm: {
                        label: 'Yes'
                    }
                },
                callback: function (result) {
                    if (result) {
                        openURLInNewTab(comment);
                    } 
                }
            });
            this.closeMapPopup();
        }
        else if (comment != null && comment.length > 0) {
            if (comment.startsWith("http")) {
                openModalURL("", comment);
                this.closeMapPopup();
            }
            else if (comment.endsWith(".pdf")) {
                var pdfURL = "res/provinces/"+this.app.provinceCode+"/pdfs/"+comment;
                showPDF(pdfURL, comment);
                this.closeMapPopup();
            }
            else if (comment.endsWith(".jpg") || comment.endsWith(".jpeg") || comment.endsWith(".png")) {
                var imgageURL = "res/provinces/"+this.app.provinceCode+"/pdfs/"+comment;
                showImage(imgageURL, comment);
                this.closeMapPopup();
            }
            else if (comment.includes("<html>") && comment.includes("</html>")) {
                openTextInModalWithMoreInfo(comment, comment2);
                this.closeMapPopup();

            }
            else if (comment.includes("html/") && comment.endsWith(".html")) {
                openHTMLFileInModal("", comment);
                this.closeMapPopup();
            }
            else {
                openTextInModalWithMoreInfo(comment,comment2);
                this.closeMapPopup();
            }
        }
    }

    openTab(evt, tabName, isModal) {
        // Declare all variables
        var i, tabcontent, tablinks, modal;
          modal = isModal ? "-modal" : "";
      //   console.log(modal);
      
        // Get all elements with class="tabcontent" and hide them
        tabcontent = document.getElementsByClassName("tab-content" + modal);
          for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
        }
      
        // Get all elements with class="tablinks" and remove the class "active"
        tablinks = document.getElementsByClassName("tab-links" + modal);
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
      
        // Show the current tab, and add an "active" class to the button that opened the tab
        document.getElementById(tabName).style.display = "block";
        evt.currentTarget.className += " active";
    }

    toast(text,title) {
        var options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-bottom-center",
            "preventDuplicates": true,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "5000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
    
        if(title) {
            toastr.info(text,title, options);
        } else {
            toastr.info(text, '', options)
        }
    }
    
}

//this will clear the modal title and body on close
$('#fullScreenModal').on('hidden.bs.modal', function () {
    $('#fullScreenModal').find('.modal-title').html("");
    $('#fullScreenModal').find('.modal-body').html("");
    $('#fullScreenModal').find('#modalPrimaryButton').attr("data-dismiss","modal"); //resetting this in case it changes elsewhere
});
//this will clear the modal title and body on close
$('#secondaryModal').on('hidden.bs.modal', function () {
    $('#secondaryModal').find('.modal-title').html("");
    $('#secondaryModal').find('.modal-body').html("");
    $('#secondaryModal').find('#modalPrimaryButton').attr("data-dismiss","modal");
});
