import $ from 'jquery';

import View from './View';

export default class StoreView extends View {
    constructor() {
        super();
        // console.log(gSettings);
    }

    get header() {
        return "Store";
    }

    //lets make each view have this function, and call it when the sidebar opens
    populatePage() {

        (function () {
            var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
            if (window.ShopifyBuy) {
                if (window.ShopifyBuy.UI) {
                    ShopifyBuyInit();
                } else {
                    loadScript();
                }
            } else {
                loadScript();
            }

            function loadScript() {
                var script = document.createElement('script');
                script.async = true;
                script.src = scriptURL;
                (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
                script.onload = ShopifyBuyInit;
            }

            function ShopifyBuyInit() {
                var client = window.ShopifyBuy.buildClient({
                    domain: 'ihunter-apps.myshopify.com',
                    storefrontAccessToken: '2d8a9222d3377f255010e8f1a7a1a636',
                });

                window.ShopifyBuy.UI.onReady(client).then(function (ui) {
                    ui.createComponent('collection', {
                      id: 95625019454,
                      node: document.getElementById('collection-component-2b07444154e'),
                      moneyFormat: '%24%7B%7Bamount%7D%7D',
                      options: {
            "product": {
              "buttonDestination": "modal",
              "variantId": "all",
              "contents": {
                "imgWithCarousel": false,
                "variantTitle": false,
                "options": false,
                "description": false,
                "buttonWithQuantity": false,
                "quantity": false
              },
              "text": {
                "button": "VIEW PRODUCT"
              },
              "styles": {
                "product": {
                  "@media (min-width: 601px)": {
                    "max-width": "calc(25% - 20px)",
                    "margin-left": "20px",
                    "margin-bottom": "50px",
                    "width": "calc(25% - 20px)"
                  },
                  "imgWrapper": {
                    "position": "relative",
                    "height": "0",
                    "padding-top": "calc(75% + 15px)"
                  },
                  "img": {
                    "height": "calc(100% - 15px)",
                    "position": "absolute",
                    "left": "0",
                    "right": "0",
                    "top": "0"
                  }
                },
                "button": {
                  "background-color": "#007bff",
                  ":hover": {
                    "background-color": "#006fe6"
                  },
                  ":focus": {
                    "background-color": "#006fe6"
                  }
                }
              }
            },
            "cart": {
              "contents": {
                "button": true
              },
              "styles": {
                "button": {
                  "background-color": "#007bff",
                  ":hover": {
                    "background-color": "#006fe6"
                  },
                  ":focus": {
                    "background-color": "#006fe6"
                  }
                },
                "footer": {
                  "background-color": "#ffffff"
                }
              }
            },
            "modalProduct": {
              "contents": {
                "img": false,
                "imgWithCarousel": true,
                "variantTitle": false,
                "buttonWithQuantity": true,
                "button": false,
                "quantity": false
              },
              "styles": {
                "product": {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0px",
                    "margin-bottom": "0px"
                  }
                },
                "button": {
                  "background-color": "#007bff",
                  ":hover": {
                    "background-color": "#006fe6"
                  },
                  ":focus": {
                    "background-color": "#006fe6"
                  }
                }
              }
            },
            "toggle": {
              "styles": {
                "toggle": {
                  "background-color": "#007bff",
                  ":hover": {
                    "background-color": "#006fe6"
                  },
                  ":focus": {
                    "background-color": "#006fe6"
                  }
                }
              }
            },
            "productSet": {
              "styles": {
                "products": {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px"
                  }
                }
              }
            }
          }
                    });

                    $('#rightSidebarSpinner').hide();
                });
            }
        })();

    }
}
