import $ from 'jquery';

import { gSettings } from './Settings';
import { gSubscriptionSettings } from './SubscriptionSettings';
import { getAjaxRequest } from './AjaxHelper';
import { isProduction, openURLInNewTab } from './Environment';

import View from './View';

export class ManageSubscriptionView extends View {
    constructor(modalView, accountManager) {
        super();
        this.accountManager = accountManager;
        this.modal = modalView;
        this.user = this.accountManager.getUserAsContact(); // this.service.userContact
        $('#modalPrimaryButton').attr("disabled", true); //disable save button.

        this.purchaseID = null;
        this.cancelAtPeriodEnd = false;

        this.subscription = this.accountManager.findWebSubscription();

        this.populatePage();
    }
    
    populatePage() {
        var self = this;

        this.setButtons();

        if(!this.subscription) {
            $('#manageSubscriptionButton').hide();
        }

        var subscription = gSubscriptionSettings.getSubscription();
        if(gSettings.isProductPurchasedWeb(subscription.sku_web))
        {
            var product = gSettings.getProductWeb(subscription.sku_web);
            if(Object.prototype.hasOwnProperty.call(product, "purchase_id")) {
                this.purchaseID = product["purchase_id"];
                var sku = product["sku"];
                $("#subscriptionname").html("<b>Name:</b> "+ subscription.name);
                this.getSubscriptionInfo(sku, this.purchaseID);

                $('#renewalcheckbox').click(function() {
                    self.checkSaveButton();
                });
            }
        }

        
    }

    getSubscriptionInfo(sku, purchaseID) {
        var self = this;
        getSubscriptionInfo(sku, purchaseID, this.accountManager.service.accessToken).then(function(subscriptionInfo) {

            try {
                var jsonObj = JSON.parse(subscriptionInfo);
                // console.log(jsonObj);
                if(Object.prototype.hasOwnProperty.call(jsonObj, "created")) {

                    var purchaseDate = new Date(0);
                    purchaseDate.setUTCSeconds(parseInt(jsonObj["created"])) ; 
                    var renewDate = new Date(0);
                    renewDate.setUTCSeconds(parseInt(jsonObj["current_period_end"])) ; 
                    var isCancelledAtEnd = jsonObj["cancel_at_period_end"];

                    self.cancelAtPeriodEnd = isCancelledAtEnd;

                    $("#subscriptiondate").html("<b>Purchase Date:</b> "+ purchaseDate.toLocaleDateString());
                    var renewalDateText = isCancelledAtEnd ? "Expiry Date:" : "Renewal Date:"; 
                    $("#renewaldate").html("<b>"+renewalDateText+"</b> "+ renewDate.toLocaleDateString());
                    $("#renewalcheckbox").prop("checked", !isCancelledAtEnd);
                }
            }
            catch (e) {
                console.log("getSubscriptionInfo exception: "+e);
            }    
            
        }, function(err) {
            console.log(err);
        });
    }

    setButtons() {
        var self = this;
        this.modal.find('#modalPrimaryButton').off(); //removes previous onclick
        this.modal.find('#modalPrimaryButton').on('click', 
            function(event) {
                self.saveAndClose();
            }
        );

        $('#manageSubscriptionButton').click(async () => {

            let response = await this.accountManager.requestBillingPortal(this.subscription.purchase_id);

            openURLInNewTab(response.url);
        });
    }

    checkSaveButton() {
        var unsavedCancelAtEnd = ! $("#renewalcheckbox").prop("checked");
        if(unsavedCancelAtEnd != this.cancelAtPeriodEnd) { //|| $("#emailInput").val() !== this.user.email) {
            this.enableSaveButton();
        } else {
            this.disableSaveButton();
        }
    }

    enableSaveButton() {
        $('#modalPrimaryButton').removeAttr("disabled");
    }

    disableSaveButton() {
        $('#modalPrimaryButton').attr("disabled", true);
    }

    saveAndClose() {
        updateSubscriptionInfo(this.purchaseID, ! $("#renewalcheckbox").prop("checked")); 
    }
}



function updateSubscriptionInfo(purchaseId, cancelAtPeriodEnd) {
  return new Promise(function(resolve, reject) {
      var script = "updateSubscriptionInfo.php"
      
      var ajaxRequest = getAjaxRequest();
      if(ajaxRequest != null) {
          // Create a function that will receive data sent from the server
          ajaxRequest.onreadystatechange = function () {
              if (ajaxRequest.readyState == 4) {
                  if(ajaxRequest.status == 200) {
                      resolve(ajaxRequest.responseText);
                  }
                  else {
                      reject("");
                  }
              }
          }

          //var queryString = "?wmu=" + wmu + "&province=" + gProvince.provinceCode  + "&database=regulations_" + gProvince.getProperty("regulations_version") + ".db";
          var testString = !isProduction() ? "&TESTING=TRUE" : "";
          var cancelString = "&cancel_at_period_end=" + (cancelAtPeriodEnd ? "true" : "false");
          var queryString = "?purchase_id=" + purchaseId + cancelString + testString;
          ajaxRequest.open("GET", script + queryString, true);
          ajaxRequest.send(null);
      }
  });
}

export default ManageSubscriptionView;

function getSubscriptionInfo(sku, purchaseId, token) {

    return new Promise((resolve, reject) => {
        var script = "getSubscriptionInfo.php"
        
        var ajaxRequest = getAjaxRequest();
        if(ajaxRequest != null) {
            // Create a function that will receive data sent from the server
            ajaxRequest.onreadystatechange = function () {
                if (ajaxRequest.readyState == 4) {
                    if(ajaxRequest.status == 200) {
                        // console.log(ajaxRequest.responseText);
                        resolve(ajaxRequest.responseText);
                    }
                    else {
                        reject(ajaxRequest.responseText);
                    }
                }
            }

            //var queryString = "?wmu=" + wmu + "&province=" + gProvince.provinceCode  + "&database=regulations_" + gProvince.getProperty("regulations_version") + ".db";
            var testString = !isProduction() ? "&TESTING=TRUE" : "";
            var queryString = "?purchase_id=" + purchaseId + testString;
            ajaxRequest.open("GET", script + queryString, true);
            ajaxRequest.setRequestHeader('authorization', 'Bearer ' + token);
            ajaxRequest.send(null);
        }
    });
}
