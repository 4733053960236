// Took them all from FirebaseHelper - probably unessesary

export const FB_PATH_IMAGES_WAYPOINT = '/sync/{0}/images/{1}';

export const FB_HIGHLIGHT_SELECTED_WMU = 'HighlightSelectedWMU';
export const FB_WAYPOINT_SORT_TYPE = 'WaypointSortType';
export const FB_STARTUP_TYPE = 'DefaultMapStartupSelection';
export const FB_SHOW_CWD_ZONES = 'ShowCWDZones';
export const FB_SHOW_CWD_DROPOFFS = 'ShowCWDWaypoints';

export const FB_SHOW_DIRECTIONS_FOR_WAYPOINTS = 'ShowDirectionsForWaypoints';
export const FB_IS_METRIC = 'IsMetric';
export const FB_SELECTED_LEH_REGION = 'SelectedLEHRegion';
export const FB_DEFAULT_MAP_FAVORITE_ID = 'DefaultMapFavoriteID';
export const FB_CURRENT_BASEMAP_WEB = 'CurrentBaseMap_Web';
export const FB_SHOW_BOUNDARY_ON_ZOOM = 'ShowBoundaryOnZoom';
export const FB_HUNTLOG_OPT_IN = 'UofaOptedIn';

export const FB_COUNTY_MAP_OPACITY = 'opacity';
export const FB_COUNTY_MAP_SHOW_BOUNDARY = 'showBoundary';
export const FB_WAYPOINT_FILTER_PREFS = 'WpFilter';
export const FB_WAYPOINT_FILTER_STARTDATE = "WpFilterStartDate";
export const FB_WAYPOINT_FILTER_ENDDATE = "WpFilterEndDate";
export const FB_WAYPOINT_FILTER_NUMDAYS = "WpFilterNumberOfDays";
export const FB_WAYPOINT_FILTER_OUT_ICONS = "WpFilterOutIcons";
export const FB_WAYPOINT_FILTER_OUT_BACKGROUNDS = "WpFilterOutBackgrounds";

export const MESSAGE_TYPE_WAYPOINT = "waypoint";
export const MESSAGE_TYPE_NOTIFICATION = "notification";
export const MESSAGE_TYPE_TEXT = "text";
export const MESSAGE_TYPE_CURRENT_LOCATION = "currentlocation";
export const MESSAGE_TYPE_BROADCAST_LOCATION = "broadcastlocation";

export const FB_FREQUENCY_IN_SECONDS = "frequencyinseconds";
export const FB_LOCATION_DEVICE = "device";
export const FB_LOCATION_ACCURACY = "accuracy";

export const FB_SEASON_VISIBILITY_ARCHERY = 'SeasonVisibility_Arch';
export const FB_SEASON_VISIBILITY_MUZZLELOADER = 'SeasonVisibility_Muzz';
export const FB_SEASON_VISIBILITY_TRAPPING = 'SeasonVisibility_Trap';
export const FB_SEASON_VISIBILITY_DRAW = 'SeasonVisibility_Draw';
export const FB_SEASON_VISIBILITY_CLOSED = 'SeasonVisibility_Closed';
export const FB_SEASON_VISIBILITY_YOUTH = 'SeasonVisibility_Youth';

export const FB_LAST_WAYPOINT_IMAGE = 'LastWaypointImage';
export const FB_LAST_WAYPOINT_BACKGROUND = 'LastWaypointBackground';

export const FB_TYPE_WAYPOINT = 0;
export const FB_TYPE_TRACKEDWAYPOINT = 1;
export const FB_TYPE_DRAWNWAYPOINT = 2;

export const COORDINATE_FORMAT = "CoordinateFormat";
