import $ from 'jquery';

$(function() {
  var bgArray = ['splashscreen-02.jpg', 'splashscreen-03.jpg', 'splashscreen-05.jpg', 'splashscreen-06.jpg', 'splashscreen-09.jpg','splashscreen-10.jpg', 'splashscreen-11.jpg', 'splashscreen-12.jpg', 'splashscreen-13.jpg'/*, 'splashscreen-14.jpg'*/];
  var index = Math.floor(Math.random() * bgArray.length);
  var bg = bgArray[index];

  //console.log(bg);

  let splash = $('#splash_screen');
  splash.css('background-image', 'url(./images/' + bg + ')');
});