export function isIOS() {
    var ios = navigator.userAgent.match(/iPhone|iPad|iPod/i);
    return ios != null && ios.length > 0;
}

export function isAndroid() {
    var android = navigator.userAgent.match(/Android/);
    return android != null && android.length > 0;
}

export function browserCanRunWebapp() {
    navigator.browserSpecs = getBrowserSpecs();

    console.log(`Browser ${navigator.browserSpecs.name} ${navigator.browserSpecs.version}`); //Object { name: "Firefox", version: "47" }
    var name = navigator.browserSpecs.name;
    var version = navigator.browserSpecs.version;
    if ( (name == 'Firefox' && version >= 45) || (name == 'Chrome' && version >= 50) || (name == 'Edge' && version >= 14) || (name == 'Safari' && version >= 9) || (name == 'Opera' && version >= 36)) {
        
        return true;
    } else {
        return false;
    }
}

export function getBrowserInfo() {
    navigator.browserSpecs = getBrowserSpecs();
    var name = navigator.browserSpecs.name;
    var version = navigator.browserSpecs.version;
    return "Browser: " + name + ", version " + version;
}

export function getBrowserSpecs() {
    var ua = navigator.userAgent, tem, 
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return {name:'IE',version:(tem[1] || '')};
    }
    if(M[1]=== 'Chrome'){
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem != null) return {name:tem[1].replace('OPR', 'Opera'),version:tem[2]};
    }
    M = M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem = ua.match(/version\/(\d+)/i))!= null)
        M.splice(1, 1, tem[1]);
    return {name:M[0], version:M[1]};
}

export function shouldShowAnimations() {
    navigator.browserSpecs = getBrowserSpecs();
    if ( navigator.browserSpecs.name == 'Safari' ) {
        return true;
    } else {
        return false;
    }
}