import $ from 'jquery';
import EditDrawnWaypointView from './EditDrawnWaypointView';

export class AddDrawnWaypointView extends EditDrawnWaypointView {
    constructor(waypointManager, drawingView, modalView, waypoint) {
        super(waypointManager, drawingView, modalView, waypoint);
        $('#pin-picker').height(55);
        $('#pin-background-picker').hide();

        this.modal.find('#modalDeleteButton').hide();
    }

    saveAndClose(name, desc, pinImage) {
        this.waypointManager.addDrawnWaypoint(this.waypoint, name, desc, pinImage, null);
    }
}

export default AddDrawnWaypointView;
