import { gSettings } from './Settings';

import { IHUNTER_URL } from './Environment';
import { toTitleCase } from './ViewHelper';

export class MapLayer {
    constructor(jsonObj, layerName, path, root) {
        this.layerID = layerName;
        this.layerRoot = root;
        this.colorPreference = this.layerRoot.includes('leh') ? 'leh_colorpreference' : this.layerID + '_colorpreference';
        this.layerRootPath = path + layerName;
        this.url = IHUNTER_URL + "/" + path + layerName;
        this.name = jsonObj.name;
        this.pluralName = Object.prototype.hasOwnProperty.call(jsonObj, 'name_plural') ? jsonObj.name_plural : jsonObj.name;
        this.version = jsonObj.version;
        this.date = jsonObj.date; //will often be null, but not for AGLeases
        this.layerDescription = jsonObj.what_is_description;
        this.localTileMinZoom = parseInt(jsonObj.localTileMinZoom);
        this.localTileMaxZoom = parseInt(jsonObj.localTileMaxZoom);
        this.vectorsMinZoom = Object.prototype.hasOwnProperty.call(jsonObj, 'vectorsMinZoom') ? parseInt(jsonObj.vectorsMinZoom) : null;
        this.vectorsMaxZoom = Object.prototype.hasOwnProperty.call(jsonObj, 'vectorsMaxZoom') ? parseInt(jsonObj.vectorsMaxZoom) : null; 
        this.remoteTileMinZoom = parseInt(jsonObj.remoteTileMinZoom);
        this.remoteTileMaxZoom = parseInt(jsonObj.remoteTileMaxZoom);
        this.urlTemplate = jsonObj.url;
        this.orderBy = Object.prototype.hasOwnProperty.call(jsonObj, 'orderBy') ? jsonObj.orderBy : 'BOUNDARY_NAME';
        this.visibilityPreference = jsonObj.visibilityPreference;
        this.visibilityDefault = Object.prototype.hasOwnProperty.call(jsonObj, 'visibilityDefault') ? jsonObj.visibilityDefault === "true" : false;
        this.highlightSelected = Object.prototype.hasOwnProperty.call(jsonObj, 'highlight_when_selected') ? jsonObj.highlight_when_selected === "true" : false;
        this.showLabels = Object.prototype.hasOwnProperty.call(jsonObj, 'show_labels') ? jsonObj.show_labels === "true" : false;
        this.oneLabelPerCode = Object.prototype.hasOwnProperty.call(jsonObj, 'one_label_per_code') ? jsonObj.one_label_per_code === "true" : false;
        this.typeDescriptions = Object.prototype.hasOwnProperty.call(jsonObj, 'type_descriptions') ? jsonObj.type_descriptions : {};
        this.highlightMatchingCodesWhenSelected = Object.prototype.hasOwnProperty.call(jsonObj, 'highlight_when_selected') ? jsonObj.highlight_when_selected === "true" : false;
        this.defaultColor = Object.prototype.hasOwnProperty.call(jsonObj, 'color') ? jsonObj.color : "#F75858"; //default for WMUs
        this.fill = Object.prototype.hasOwnProperty.call(jsonObj, 'show_labels') ? jsonObj.show_labels === "false" : true; //if there's no label, we fill the boundaries
        this.fillOpacity = Object.prototype.hasOwnProperty.call(jsonObj, 'fill_opacity') ? parseFloat(jsonObj.fill_opacity) : (this.fill ? 0.2 : 0);
        this.strokeWidth = Object.prototype.hasOwnProperty.call(jsonObj, 'thickness') ? jsonObj.thickness : 3;
        this.popupDisplayOrder = Object.prototype.hasOwnProperty.call(jsonObj, 'popupDisplayOrder') ? parseInt(jsonObj.popupDisplayOrder, 10) : 99;
        this.zLevel = Object.prototype.hasOwnProperty.call(jsonObj, 'z_level') ? parseInt(jsonObj.z_level, 10) : 1; //1 == normal, 2 == high (wmus);
        this.zIndex = 300+this.zLevel; //301 for normal, 302 for high

        this.remoteDatabaseURL = Object.prototype.hasOwnProperty.call(jsonObj, 'remote_database_url') ? jsonObj.remote_database_url.replace("{WEBSERVER}/", "") : null;
        this.remoteSearchURL = Object.prototype.hasOwnProperty.call(jsonObj, 'remote_search_url') ? jsonObj.remote_search_url.replace("{WEBSERVER}/", "") : null;
        this.remoteSearchTerm = Object.prototype.hasOwnProperty.call(jsonObj, 'remote_search_term') ? jsonObj.remote_search_term.replace("{WEBSERVER}/", "") : null;

        this.searchable = false;
        if(this.hasLocalResources() || this.remoteSearchURL) {
            if(Object.prototype.hasOwnProperty.call(jsonObj, 'searchable')) {
                this.searchable = jsonObj.searchable === "true";
            } else {
                this.searchable = true;
            }
        }

        this.parcels = null; //parcels will now just be null or true for mapLayers (except County Maps for now)
        this.layerData = null;
        //we need these to keep track of layers in leaflet for add/remove
        this.leafletLayer = null;
        this.leafletTileLayer = null;
        this.leafletLabelLayers = new Array();
    }

    getTypeDescriptionFromType(type) {
        var titleCase = toTitleCase(type);
        if(titleCase in this.typeDescriptions) {
            return this.typeDescriptions[titleCase];
        }

        if(type in this.typeDescriptions) {
            return this.typeDescriptions[type];
        }
        return "";
    }

    shouldShow() {
        var showLayer = gSettings.getShowMapLayer(this);
        //console.log(this.name + ' has vis pref ' + showLayer);
        if(showLayer === null || showLayer === undefined) {
            return this.visibilityDefault;
        } else {
            return showLayer;
        }
    }

    getStrokeWidth() {
        return this.strokeWidth;
    }

    getIndex() {
        return parseInt(this.layerID.substring(this.layerID.lastIndexOf("_") + 1) - 1);
    }

    getColor() {
        return gSettings.getMapLayerColor(this).toUpperCase();
    }

    usesCustomColor() {
        return this.getColor() != this.defaultColor.toUpperCase();
    }

    hasLocalResources() {
        return (this.vectorsMinZoom > 0 && this.vectorsMaxZoom > 0) || (this.localTileMinZoom > 0 && this.localTileMaxZoom > 0);
    }
}

export default MapLayer;
