import moment from 'moment';

import DrawnWaypoint from './DrawnWaypoint';
import TrackedWaypoint from './TrackedWaypoint';
import Waypoint from './Waypoint';
import WaypointFilter from './WaypointFilter';
import Observable from './Observable';
import DateHelpers from './DateHelpers';

import { getAnnotationXMLForAnnotations } from './DrawnWaypoint';
import { gSettings } from './Settings';
import { WaypointImageCache } from './WaypointImageCache';
import { uuidv4 } from './Encryption';

const EVENT = {
    UPDATE_WAYPOINT: 'update-waypoint',
  };

export default class WaypointManager extends Observable {

    constructor(dataService, mapProvider) {
        super();
        
        this.service = dataService;
        this.map = mapProvider;
        this.drawingView = null;

        this.active = null; // replaces: gActiveUUID

        this.waypoints = {};
        this.drawnWaypoints = {};
        this.trackedWaypoints = {};

        this.onUpdateWaypoint = this.createHandlerMethod(EVENT.UPDATE_WAYPOINT);

        // Constructor will set global gWaypointImageCache as a side-effects
        this.cache = new WaypointImageCache();
    }


    set activeWaypoint(waypoint) {
        this.active = waypoint;
    }

    get activeWaypoint() {
        return this.active;
    }

    initialize(drawingView) {

        this.drawingView = drawingView;

        // Setting preferences
        gSettings.manager.onUpdateWaypointFilter((key, val) => {
            var filter = new WaypointFilter();
            filter.setFilterFromJson(val);
            gSettings.setPreference(key, filter);
            //we'll need to update the waypoint visibility!
            //go through the waypoint, drawnwaypoint, and trackedwaypoint dicts. Add/remove marker based on filter
            this.refreshAllWaypoints(false);
        });

        gSettings.manager.onUpdateWaypointSort((key, val) => {
            
            this.refreshAllWaypoints(false);
        });

        gSettings.manager.onUpdateCoordFormat((key, val) => {
            this.refreshAllWaypoints(true);
        });


        // Waypoints
        this.service.db.onCreateWaypoint((waypointJson) => {
            this.createWaypointHandler(Waypoint, waypointJson);
        });
        this.service.db.onUpdateWaypoint((waypointJson) => {
            this.updateWaypointHandler(Waypoint, waypointJson);
        });
        this.service.db.onDeleteWaypoint((waypointJson) => {
            this.removeWaypointHandler(Waypoint, waypointJson);
        });

        // Drawn Waypoints
        this.service.db.onCreateDrawnWaypoint((waypointJson) => {
            this.createWaypointHandler(DrawnWaypoint, waypointJson);
        });
        this.service.db.onUpdateDrawnWaypoint((waypointJson) => {
            this.updateWaypointHandler(DrawnWaypoint, waypointJson);
        });
        this.service.db.onDeleteDrawnWaypoint((waypointJson) => {
            this.removeWaypointHandler(DrawnWaypoint, waypointJson);
        });

        // Tracked Waypoints
        this.service.db.onCreateTrackedWaypoint((waypointJson) => {
            this.createWaypointHandler(TrackedWaypoint, waypointJson);
        });
        this.service.db.onUpdateTrackedWaypoint((waypointJson) => {
            this.updateWaypointHandler(TrackedWaypoint, waypointJson);
        });
        this.service.db.onDeleteTrackedWaypoint((waypointJson) => {
            this.removeWaypointHandler(TrackedWaypoint, waypointJson);
        });

    }

    createWaypointHandler(Type, waypointJson) {

        let list = this.getListByType(Type);
        let waypoint = null;
        if(Type === DrawnWaypoint){
            waypoint = new Type(this.service, this.map, this.drawingView);

        } else {
            waypoint = new Type(this.service, this.map);
        }

        waypoint.initialize(waypointJson);

        //check visibility...if true add like normal. If not, just add to dict, but don't create the .marker.
        waypoint.setVisibility();
        list[waypoint.uuid] = waypoint;

        this.emit(EVENT.UPDATE_WAYPOINT, waypoint.uuid, waypoint);
    }

    updateWaypointHandler(Type, waypointJson) {
        let list = this.getListByType(Type);
        let waypoint = list[waypointJson.UUID]; // Capital UUID in serialized json

        waypoint.updateWaypoint(waypointJson);
        waypoint.setVisibility();

        this.emit(EVENT.UPDATE_WAYPOINT, waypoint.uuid, waypoint);
    }

    removeWaypointHandler(Type, waypointJson) {
        let list = this.getListByType(Type);
        let waypoint = list[waypointJson.UUID]; // Capital UUID in serialized json

        this.map.leafletMap.removeLayer(waypoint.marker);

        if(Type === DrawnWaypoint){
            this.map.removePolylinesForDrawnWaypoint(waypoint);
        }

        delete list[waypoint.uuid];

        this.emit(EVENT.UPDATE_WAYPOINT, waypoint.uuid);
    }

    getListByType(Type) {
        if(Type === DrawnWaypoint) {
            //console.log("Type: DrawnWaypoint");
            return this.drawnWaypoints;
        }else if(Type === TrackedWaypoint) {
            //console.log("Type: TrackedWaypoint");
            return this.trackedWaypoints;
        }else if(Type === Waypoint) {
            //console.log("Type: Waypoint");
            return this.waypoints;
        }else {
            throw TypeError(`Unable to get waypoint list, unrecognized type: ${Type}`);
        }
    }

    getAllWaypoints() { //getWaypointsDictsAsArray
        var array = [];
        for(let uuid in this.waypoints) {
            array.push(this.waypoints[uuid]);
        }
        for(let uuid in this.drawnWaypoints) {
            array.push(this.drawnWaypoints[uuid]);
        }
        for(let uuid in this.trackedWaypoints) {
            array.push(this.trackedWaypoints[uuid]);
        }
        return array;
    }

    refreshAllWaypoints(forceRefresh) {
        var allWaypoints = this.getAllWaypoints();
        var length = allWaypoints.length;
        for(var i = 0; i < length; i++) {
            var wp = allWaypoints[i];
            wp.setVisibility(forceRefresh);
        }
    }

    sortWaypointsArrayByName(array) {
        array.sort(function(a, b){
            var aName = a.name.toLowerCase();
            var bName = b.name.toLowerCase();
            if(aName < bName) {return -1;}
            if(aName > bName) {return 1;}
            return 0;
        });
    }

    sortWaypointsArrayByDate(array) {
        array.sort(function(a, b){
            return a.creationDate - b.creationDate;
        });
    }
    
    sortWaypointsArrayByLocation(array) {
        if(this.map.currentLocation) {
            array.sort((a, b) => {
                var aDistance  = a.location.distanceTo(this.map.currentLocation);
                var bDistance = b.location.distanceTo(this.map.currentLocation);
                return aDistance - bDistance;
            });
        } 
        else {
            this.sortWaypointsArrayByDate(array);
        }
    }

    async requestSubscriptionCheckout(subscription, name) {
        return await this.service.dal.checkout(subscription, name, true);
    }

    updateAnnotation(waypoint, annotationXML) {

        this.service.db.updateDrawnWaypointAnnotation(waypoint.uuid, annotationXML);
    }

    setShowDistForWaypoint(waypoint, visibility) {
        this.service.db.setShowDistForWaypoint(waypoint.uuid, visibility);
    }

    setShowAreaForWaypoint(waypoint, visibility) {
        this.service.db.setShowAreaForWaypoint(waypoint.uuid, visibility);
    }


    setShowElevationForWaypoint(waypoint, visibility) {

        var annotations = waypoint.getAnnotations(waypoint.annotationXML);
        if(annotations != null && annotations.length > 0 && annotations[0].elevationsString != null && annotations[0].elevationsString.length > 0) {
            //we only have to change the visibility since the data already exists
        } else {
            var length = annotations.length;
            var editedAnnotations = Array(length);
            if(annotations != null && length > 0) {
                for(var i = 0; i < length; i++) {
                    editedAnnotations[i] = annotations[i].queryElevations();
                }
                Promise.all(editedAnnotations).then((annotations) => { 
                    if(annotations != null) {
                        var xml = getAnnotationXMLForAnnotations(annotations);
                        if(xml != null && xml.length > 0) {
                            this.waypointManager.updateDrawnWaypointAnnotation(waypoint.uuid, xml);
                        }
                    }
                });
            }
        }


        this.service.db.setShowElevationForWaypoint(waypoint.uuid, visibility);
    }

    addWaypoint(name, desc, lat, lon, pinImage, backgroundImage, pendingImages) {
        var uuid = uuidv4();

        if(pendingImages && pendingImages.length > 0) {
            this.service.storage.saveImagesToFirebase(pendingImages, uuid);
        }

        var imageNames = ""; 
        if(pendingImages) {
            for(let i = 0; i < pendingImages.length; i++) {
                imageNames += (imageNames == "" ? pendingImages[i].name : "," + pendingImages[i].name);
            }
        }

        this.service.db.setLastWaypointImage(pinImage.replace(".png", ""), backgroundImage.replace(".png", ""));
        this.service.db.addWaypoint(uuid, name, desc, lat, lon, pinImage, backgroundImage, imageNames);
    }

    updateWaypointImages(waypoint, existingImages, pendingImages, oldImages) {
        if(pendingImages && pendingImages.length > 0) {
            this.service.storage.saveImagesToFirebase(pendingImages, waypoint.uuid);
        }
        if(oldImages && oldImages.length > 0) {
            for(let i = 0; i < oldImages.length; i++) {
                var imageName = oldImages[i];
                this.service.storage.removeImageFromFirebase(waypoint.uuid, imageName);
            }
        }

        var imageNames = ""; 
        if(existingImages) {
            for(let i = 0; i < existingImages.length; i++) {
                imageNames += (imageNames == "" ? existingImages[i] : "," + existingImages[i]);
            }
        }
        if(pendingImages) {
            for(let i = 0; i < pendingImages.length; i++) {
                imageNames += (imageNames == "" ? pendingImages[i].name : "," + pendingImages[i].name);
            }
        }

        return imageNames;
    }
 
    updateWaypoint(waypoint, name, desc, pinImage, pinBackground, existingImages, pendingImages, oldImages) {
        
        var imageNames = this.updateWaypointImages(waypoint, existingImages, pendingImages, oldImages);
        this.service.db.updateWaypoint(waypoint.uuid, name, desc, waypoint.location.lat, waypoint.location.lng, pinImage, pinBackground, imageNames, waypoint.altitude, waypoint.accuracy, waypoint.creationUsername, DateHelpers.stringFromDate(waypoint.creationDate), DateHelpers.stringFromDate(moment()), waypoint.moonphase, waypoint.needsImageUpload, waypoint.weatherImage);
    }

    updateTrackedWaypoint(waypoint, name, desc, pinImage, existingImages, pendingImages, oldImages) {
        
        var imageNames = this.updateWaypointImages(waypoint, existingImages, pendingImages, oldImages);
        this.service.db.updateTrackedWaypoint(waypoint.uuid, name, desc, waypoint.location.lat, waypoint.location.lng, waypoint.spanLat, waypoint.spanLon, pinImage, imageNames, waypoint.creationUsername, DateHelpers.stringFromDate(waypoint.creationDate), DateHelpers.stringFromDate(moment()), waypoint.needsImageUpload, waypoint.annotationXML);
    }

    updateDrawnWaypoint(waypoint, name, desc, pinImage, existingImages, pendingImages, oldImages) {
       
        var imageNames = this.updateWaypointImages(waypoint, existingImages, pendingImages, oldImages);
        this.service.db.updateDrawnWaypoint(waypoint.uuid, name, desc, waypoint.location.lat, waypoint.location.lng, waypoint.spanLat, waypoint.spanLon, 
            pinImage, imageNames, waypoint.creationUsername, DateHelpers.stringFromDate(waypoint.creationDate), DateHelpers.stringFromDate(moment()), 
            waypoint.needsImageUpload, waypoint.showAreaLabel, waypoint.showDistLabel, waypoint.showElevation, waypoint.annotationXML);
    }
    
    addDrawnWaypoint(waypoint, name, desc, pinImage, imageNames) {
        this.service.db.addDrawnWaypoint(waypoint.uuid, name, desc, waypoint.location.lat, waypoint.location.lng, waypoint.spanLat, waypoint.spanLon, pinImage, imageNames, waypoint.creationUsername, waypoint.showAreaLabel, waypoint.showDistLabel, waypoint.showElevation, waypoint.annotationXML) 
    }

    shareWaypoints(uuids, chat, pinImage) {
        let waypoints = [];
        for(let i in uuids) {
            let waypoint = this.waypointExists(uuids[i]);
            waypoints.push(waypoint);
        }
        this.service.db.shareWaypoints(waypoints, chat, pinImage);
    }
 
    deleteWaypoint(waypoint) {
        // TODO: refactor view callback hooks to pass waypoint instead of uuid,
        // leaving this "unpacking" step until UI rework.
        if(waypoint.uuid) {
            this.service.db.deleteWaypoint(waypoint.uuid);
        }else {
            this.service.db.deleteWaypoint(waypoint);
        }
    }

    deleteDrawnWaypoint(waypoint) {
        if(waypoint.uuid) {
            this.service.db.deleteDrawnWaypoint(waypoint.uuid);
        }else {
            this.service.db.deleteDrawnWaypoint(waypoint);
        }
    }

    deleteTrackedWaypoint(waypoint) {
        if(waypoint.uuid) {
            this.service.db.deleteTrackedWaypoint(waypoint.uuid);
        }else {
            this.service.db.deleteTrackedWaypoint(waypoint);
        }
    }


    showEditWaypoint(waypoint) {
        // TODO: Avoid routing via window global to reach AppView
        window.onEditWaypoint(waypoint);
    }

    showEditTrackedWaypoint(waypoint) {
        // TODO: Avoid routing via window global to reach AppView
        window.onEditTrackedWaypoint(waypoint);
    }

    showEditDrawnWaypoint(waypoint) {
        // TODO: Avoid routing via window global to reach AppView
        window.onEditDrawnWaypoint(waypoint);
        //this.map.zoomToLatLngBounds(waypoint.getBounds());
    }

    showNavigateToWaypoint(waypoint) {
        // TODO: Avoid routing via window global
        window.onDrivingDirections(waypoint.location.lat, waypoint.location.lng);
    }

    async getImageUrl(waypoint, uuid) {

        return await this.service.storage.getImageUrlForWaypoint(waypoint, uuid);
    }


    waypointExists(uuid) {
        return this.waypoints[uuid] || this.drawnWaypoints[uuid] || this.trackedWaypoints[uuid];    
    }

    hasWaypoints() {
        return Object.keys(this.waypoints).length > 0 || Object.keys(this.drawnWaypoints).length > 0 || Object.keys(this.trackedWaypoints).length > 0;
    }
}


