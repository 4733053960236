import $ from 'jquery';

import View from './View';

export class WMSWizardView extends View {
    constructor(modalView, addUserMapLayerViewReference, userMapLayer, arrayOfJsons, url) {
        super();
        this.modal = modalView;
        this.userMapLayer = userMapLayer;
        this.getCapabilitiesUrl = url;
        this.arrayOfJsons = arrayOfJsons;
        this.selectedLayers = new Array();
        this.addUserMapLayerViewReference = addUserMapLayerViewReference;
        this.disableSaveButton();
        this.populatePage();
    }

    populatePage() {
        this.setButtons();
        // this.parseGetCapabilitiesXML();
        this.showLayers();
    }

    setButtons() {
        var self = this;
        this.modal.find('#modalPrimaryButton').off(); //removes previous onclick
        this.modal.find('#modalPrimaryButton').on('click', 
            function(event) {
                self.saveAndClose();
            }
        );
    }

    showLayers() {
        var self = this;
        if(!(this.arrayOfJsons == null || this.arrayOfJsons.length == 0 || this.arrayOfJsons[0].name == null || this.arrayOfJsons[0].name == "")) {
            $.each(this.arrayOfJsons, function(i, item){
                $("#availableLayers").append(self.getHTMLForLayerRowWithSwitch(false, item.name+"layer-switch",item.title, item.abstract)); 
                var switchID = '[id="'+item.name+"layer-switch"+'"]';
                $(switchID).click(function() {
                    var id = $(this).attr('id');
                    var name = id.substring(0, id.length - 12);
                    self.onLayerClicked(this, name);
                    self.checkSaveEnabled();
                });
            });
        }
    }

    onLayerClicked(checkbox, layerName) {
        let checked = checkbox.checked;
        if(checked) {
            for(let i = 0; i < this.selectedLayers.length; i++) {
                let selectedLayer = this.selectedLayers[i];
                if(selectedLayer === layerName) {
                    return; 
                }
            }
            this.selectedLayers.push(layerName);
        } else {
            for(let i = 0; i < this.selectedLayers.length; i++) {
                let selectedLayer = this.selectedLayers[i];
                if(selectedLayer === layerName) {
                    this.selectedLayers.splice(i, 1);
                    return; 
                }
            }
        }
    }

    enableSaveButton() {
        this.modal.find('#modalPrimaryButton').removeAttr("disabled");
    }

    disableSaveButton() {
        this.modal.find('#modalPrimaryButton').attr("disabled", true);
    }

    checkSaveEnabled() {
        if(this.selectedLayers && this.selectedLayers.length > 0) {
            this.enableSaveButton();
        } else {
            this.disableSaveButton();
        }
    }

    saveAndClose() {
        var layersParam = '&layers=';
        for(var i = 0; i < this.selectedLayers.length; i++) {
            var selectedLayer = this.selectedLayers[i];
            layersParam += i==0 ? selectedLayer : ','+selectedLayer;
        }
        var newUrl = this.getCapabilitiesUrl.replace("request=GetCapabilities", "request=GetMap") + layersParam; 
        this.addUserMapLayerViewReference.setUrl(newUrl);
        this.addUserMapLayerViewReference.saveAndClose();
    }

    getHTMLForLayerRowWithSwitch(bChecked, inputID, title, abstract) {
        var checked = bChecked ? 'checked' : '';
        var row = '';
        row += '<div class="ihunter-menu-switch-row">';

        
        row += '<div class="middle-div">';
            row += '<div class="ihunter-menu-text large">'+ title + '</div>';
            row += abstract ? '<div class="ihunter-menu-text small">' + abstract + '</div>' : '';
        row += '</div>';

        row += '<label class="ihunter-menu-switch float-right">';
            row += '<input id="'+ inputID + '" type="checkbox" ' + checked + '>';
            row += '<span class="slider-switch round"></span>';
        row += '</label>';
        row += '</div>';
        return row;
    }
}

export default WMSWizardView;
