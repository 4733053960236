import _ from 'underscore';
import EventEmitter from 'eventemitter3';

import { isLocal } from './Environment';

export default class Observable extends EventEmitter {

    constructor() {
        super();
    }

    createHandlerMethod(state) {
        return (handler) => {
            if(!_.isFunction(handler)) {
                throw TypeError('Invalid callback handler');
            }

            return this.on(state, (...args) => {
                handler(...args);
            }); 
        };
    }

    emit(event, ...args){
        if(!event) {
            throw new Error(`Unable to emit undefined event`);
        }

        if(isLocal()) { // Only log verbose when local
            if(args.length > 0) {
                console.info(`Event ${event} %o`, [...args]);
            }else {
                console.info(`Event ${event}`);
            }
        }

        super.emit(event, ...args);
    }
}
