if(window.ActiveXObject === undefined) {
  window.ActiveXObject = ()=>{}; // Prevent lint error about undefined objevt
}

export function getAjaxRequest() {
  var ajaxRequest = null;  // The variable that makes Ajax possible!

  try {
      // Opera 8.0+, Firefox, Safari
      ajaxRequest = new XMLHttpRequest();
  } catch (e) {
      // Internet Explorer Browsers
      try {
          ajaxRequest = new window.ActiveXObject("Msxml2.XMLHTTP");
      } catch (e) {
          try {
              ajaxRequest = new window.ActiveXObject("Microsoft.XMLHTTP");
          } catch (e) {
              // Something went wrong
              console.log("Your browser couldn't handle this request!");
          }
      }
  }

  return ajaxRequest;
}

export default getAjaxRequest;