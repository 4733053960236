import $ from 'jquery';
import _ from 'underscore';
import L from 'leaflet';

import { getAjaxRequest } from './AjaxHelper';
import { openHTMLFileInCustomModal } from './ModalHelper';

import BoundaryData from './BoundaryData';
import WaypointFilterView from './WaypointFilterView';
import { SearchResult } from './SearchResult';


// Create search class to provide access to mapDataProvider (this.map)
// Currently a mix of view, model and manager functionality
export default class SearchManager {

    constructor(mapProvider) {
        this.map = mapProvider;
        this.updateSearchTimer = null;
        this.searchTimestamp = null;
        this.pendingSearches = null;

        this.remoteSearchTimer = null;
        this.delayedGoogleSearchTimer = null;


        this.setupListeners();
        this.setupSearchHeader();
    }

    setupListeners() {
        window.hideSearchThenZoom = (bounds, layerID, code) => {
            this.hideSearchThenZoom(bounds, layerID, code);
        };
        window.delayedRemoteSearch = (remoteSearchURL, defaultColor, searchText, divToPopulate, bMainSearch, bPrepend) => {
            this.delayedRemoteSearch(remoteSearchURL, defaultColor, searchText, divToPopulate, bMainSearch, bPrepend);
        };
        window.requestGoogleSearchDetails = (ref) => {
            this.requestGoogleSearchDetails(ref);
        };
        window.gotoCoordinateSearchResult = (latitudeString, longitudeString) => {
            this.gotoCoordinateSearchResult(latitudeString, longitudeString);
        };

        window.onFilterWaypointsClicked = () => {
            openHTMLFileInCustomModal('Filter Waypoints', 'waypoint_filter_view.html', 'Save', 'Cancel', '80', '80', false).then((result) => {
                new WaypointFilterView(result);
            }, function(err) {
                console.log(err);
            });
        };

        window.filterFunction = (inputID, contentID) => {
            this.filterFunction(inputID, contentID);
        };
    }



    doRemoteSearch(remoteSearchURL, defaultColor, searchText, divToPopulate, bMainSearch, bPrepend, timestamp) {
        if(!searchText || searchText.length == 0) {
            $('#'+divToPopulate).html("");
        } else {
            var ajaxRequest = getAjaxRequest(); 
            if(ajaxRequest != null) {
                ajaxRequest.onreadystatechange = () => {
                    if (ajaxRequest.readyState == 4) {
      
                        if(ajaxRequest.responseText && timestamp === this.searchTimestamp) {
      
                            this.pendingSearches--;
                            var jsonData = JSON.parse(ajaxRequest.responseText);
                            var rowsHTML = "";
                            Object.keys(jsonData).forEach((key) => {
                                var json = jsonData[key];
                                if (!_.isEmpty(json)) {                           
                                    rowsHTML += this.getBoundaryRowHTMLWithBoundary(json, defaultColor, bMainSearch, searchText);
                                }
                            });
                            if(rowsHTML == "" && this.pendingSearches == 0 && $('#'+divToPopulate).html == "") {
                                rowsHTML += bMainSearch ? '<div class="ihunter-menu-boundary-row">No iHunter Specific Data Found</div>' : '<div class="ihunter-menu-boundary-row">No Results Found</div>';
                            }
      
                            if(rowsHTML.length > 0) {
                                if(bPrepend) {
                                    $('#'+divToPopulate).prepend(rowsHTML);
                                } else {
                                    $('#'+divToPopulate).append(rowsHTML);
                                }
                            }   
                        }
                    }
                }
                ajaxRequest.open("GET", remoteSearchURL.replace("{search}", searchText), true);
                ajaxRequest.send(null);
            }
        }
    }
      
    getBoundaryRowHTMLWithBoundary(json, defaultColor, bMainSearch, searchText) {
        var bd = new BoundaryData(json);
        var row = '';
        if(bMainSearch) {
            row = '<div class="ihunter-menu-boundary-row" onclick="hideSearchThenZoom(\''+bd.bounds+'\',\''+bd.layerID+'\',\''+bd.code+'\')">';
            // var row = '<div class="ihunter-menu-boundary-row" onclick="hideSearchThenZoom(\'' + bd.bounds + '\',\''+bd.boundary+'\',\''+bd.color+'\')">';  
        } else {
            row = '<div class="ihunter-menu-boundary-row" onclick="zoomToBoundsStringAndSelectWithBoundary(\'' + bd.bounds + '\',\''+bd.boundary+'\',\''+defaultColor+'\')">';  
        }
         
        row += '<div class="left-div"> <img class="ihunter-left-img" src="images/wmu_icon_black.png"';
        if(bd.color != null) {
            row += ' style="background-color: ' + bd.color + '"';
        }
        row += '/></div>';
        row += '<div class="middle-div">';
        
        var pattern = new RegExp(searchText, 'gi');
      
        // search for the search string, and highlight if its found in the title
        var loc = bd.title.search(pattern);
        if(loc == -1) {
            row += '<div class="ihunter-menu-text large" >' + bd.title + '</div>';
        } else {
            row += '<div class="ihunter-menu-text large" >' + bd.title.substring(0, loc)+'<span style="background-color: #ff9908">'+ bd.title.substring(loc, loc+searchText.length) +'</span>'+ bd.title.substring(loc+searchText.length) + '</div>';
        }
      
        // search for the search string, and highlight if its found in the title
        let subtitleloc = bd.subtitle.search(pattern);
        if(subtitleloc == -1) {
            row += '<div class="ihunter-menu-text small" >' + bd.subtitle + '</div>';
        } else {
            row += '<div class="ihunter-menu-text small" >' + bd.subtitle.substring(0, subtitleloc)+'<span style="background-color: #ff9908">'+ bd.subtitle.substring(subtitleloc, subtitleloc+searchText.length) +'</span>'+ bd.subtitle.substring(subtitleloc+searchText.length) + '</div>';
        }
        
        row += '</div>';
        row += '<div class="right-div"><i class="fa fa-chevron-right" style="color:#727272"></i></div>';
        row += '</div>';
        return row;
      }

    hideSearchThenZoom(bounds, layerID, code) {
        $('#titleheader-searchInput').val('');
        $('#main-search-results-div').html('');
        if(layerID.includes('leh')) {
            this.map.zoomToBoundsStringAndSelectLEH(bounds, layerID, code);
        } else {
            this.map.zoomToBoundsStringAndSelectWithMapLayer(bounds, layerID, code);
        }
      }
      

      
      updateSearchResults(searchTerm) {
        
        if(this.updateSearchTimer) {
            clearTimeout(this.updateSearchTimer);
        }
    
        this.updateSearchTimer = setTimeout(() => {
            $('#main-search-results-div').html('');
            this.searchTimestamp = Date.now();
            
            var databasePaths = "";
            var colors = "";
            var mapLayerIDs = "";
            this.pendingSearches = 0;
            this.map.mapLayersArray.forEach(mapLayer => {
    
                if(mapLayer.remoteSearchURL) {
                    this.pendingSearches++;
                    this.doRemoteSearch(mapLayer.remoteSearchURL, mapLayer.defaultColor, searchTerm, 'main-search-results-div', true, false, this.searchTimestamp);
                }
                else if(mapLayer.searchable) {
                    var databasePath = mapLayer.layerRootPath + "/boundarydata.db";
                    databasePaths += databasePaths == "" ? databasePath : ","+databasePath;
                    colors += colors == "" ? mapLayer.defaultColor : "," + mapLayer.defaultColor;
                    mapLayerIDs += mapLayerIDs == "" ? mapLayer.layerID : "," + mapLayer.layerID;
                }   
            });
    
            this.pendingSearches++;

            this.doCoordinateSearch('main-search-results-div', searchTerm.replace("%2C", ",").replace("%20"," "), true);

            var script = 'getBoundariesFromDatabasesMatchingSearch.php';
            var queryString = '?prov='+this.map.provinceCode + '&database='+databasePaths + '&search=' + searchTerm + '&colors=' + colors.replace(/#/g, "") + '&layers=' + mapLayerIDs;
            this.doRemoteSearch(script + queryString,'#cccccc',searchTerm,'main-search-results-div', true, false, this.searchTimestamp);
    
            var mapCenter = this.map.leafletMap.getCenter();
            var countryCode = this.map.province.getProperty("COUNTRY_CODE");
            var googleSearchUrl = "https://maps.googleapis.com/maps/api/place/autocomplete/json?input="+searchTerm+"&location="+mapCenter.lat+","+mapCenter.lng+"&components=country:"+countryCode;
            this.doGoogleSearch("getGoogleSearch.php?query="+googleSearchUrl.replace(/&/g, "%26"), 'main-search-results-div', searchTerm, true, this.searchTimestamp); 
            
        }, 1000);
    }
    
    sanitizeSearchTerm(value) {
        let term = value.trim();
        term = encodeURIComponent(term);
        return term.replace(/\s/g, "+");
    }


    setupSearchHeader() {
        
        $("#titleheader-searchInput").keyup((e) => {
    
            $('#main-search-results-div').show();
            this.updateSearchResults(this.sanitizeSearchTerm(e.currentTarget.value));
        });
    }
    

    delayedGoogleSearch(googleSearchURL, divToPopulate, searchText) {
        if(this.delayedGoogleSearchTimer) {
            clearTimeout(this.delayedGoogleSearchTimer);
        }
        this.delayedGoogleSearchTimer = setTimeout(() => {
            this.searchTimestamp = Date.now();
            this.doGoogleSearch(googleSearchURL, divToPopulate, searchText, true, this.searchTimestamp);
        }, 1000);
    }
    
    doCoordinateSearch(divToPopulate, searchText, bPrepend) {
        if(!searchText || searchText.length == 0) {
            $('#'+divToPopulate).html("");
        } else {
            var latLngRow = '';
            if(searchText.includes(",")) {
                var splitText = searchText.replace(" ", "").split(",");
                if(splitText.length == 2 && this.validateLatitude(splitText[0]) && this.validateLongitude(splitText[1])) {
                    latLngRow = '<div class="ihunter-menu-boundary-row" onclick="gotoCoordinateSearchResult(\''+splitText[0]+'\', \''+splitText[1]+'\')">';
                    latLngRow += '<div class="left-div"> <img class="ihunter-left-img" src="images/globe_search.png" width="40px" height="40px"';
                    latLngRow += '/></div>';
                    latLngRow += '<div class="middle-div">';
                
                    latLngRow += '<div class="ihunter-menu-text large" >' + searchText + '</div>';
                    latLngRow += '<div class="ihunter-menu-text small" >' + "Coordinate search result" + '</div>';
                    latLngRow += '</div>';
                    latLngRow += '<div class="right-div"><i class="fa fa-chevron-right"></i></div>';
                    latLngRow += '</div>';
                    latLngRow += '<div style="height:3px; background:#e1e1e1"></div>';
                    if(bPrepend) {
                        $('#'+divToPopulate).prepend(latLngRow);
                    }
                    else {
                        $('#'+divToPopulate).append(latLngRow);
                    }
                }
            }
        }
    }

    gotoCoordinateSearchResult(latitudeString, longitudeString) {
        var sr = new SearchResult("coordinate", "Coordinate search");
        sr.location = new L.LatLng(parseFloat(latitudeString),parseFloat(longitudeString));
        this.map.addMarkerForSearchResult(sr);
        this.map.zoomToLocation(sr.location);
        $("#main-search-results-div").hide();
    }
    
    //similar to doRemoteSearch, but what we do with the data after the ajax request is different. Could be handled cleaner with promises..
    doGoogleSearch(googleSearchURL, divToPopulate, searchText, bPrepend, timestamp) {
        if(!searchText || searchText.length == 0) {
            $('#'+divToPopulate).html("");
        } else {
            var ajaxRequest = getAjaxRequest(); 
            if(ajaxRequest != null) {
                ajaxRequest.onreadystatechange = () => {
                    if (ajaxRequest.readyState == 4) {
    
                        if(ajaxRequest.responseText && timestamp === this.searchTimestamp) {
                            var jsonData = JSON.parse(ajaxRequest.responseText);
                            var jsonArray = jsonData["predictions"];
                            var rowsHTML = "";
                            for(var i = 0; i < jsonArray.length; i++) {
                                var result = jsonArray[i];
                                //at this point we just populate the rows and it doesn't have a location yet. You need to use the reference and do another google search!
                                rowsHTML += this.getSearchRow(result.reference, result.description, searchText);
                            }
    
                            if(rowsHTML.length > 0) {
                                rowsHTML += '<div style="height:3px; background:#e1e1e1"></div>';
                                if(bPrepend) {
                                    $('#'+divToPopulate).prepend(rowsHTML);
                                }
                                else {
                                    $('#'+divToPopulate).append(rowsHTML);
                                }
                            }
                        }
                    }
                }
                ajaxRequest.open("GET", googleSearchURL, true);
                ajaxRequest.send(null);
            }
        }
    }
    

    delayedRemoteSearch(remoteSearchURL, defaultColor, searchText, divToPopulate, bMainSearch, bPrepend) {
        if(this.remoteSearchTimer) {
            clearTimeout(this.remoteSearchTimer);
        }
        this.remoteSearchTimer = setTimeout(() => {
            $('#'+divToPopulate).html('');
            this.searchTimestamp = Date.now();
            this.doRemoteSearch(remoteSearchURL, defaultColor, searchText, divToPopulate, bMainSearch, bPrepend, this.searchTimestamp);
        }, 1000);
    }

    filterFunction(input, contentID) {
        input = document.getElementById(input);
        var filter = input.value.toUpperCase();
        var div = document.getElementById(contentID);
        var row = div.getElementsByClassName("ihunter-menu-boundary-row");
        for (var i = 0; i < row.length; i++) {
            var txt = row[i].getElementsByClassName("ihunter-menu-text");
            for(var k = 0; k < txt.length; k++) {
                var txtValue = txt[k].textContent || txt[k].innerText;
                if(txtValue.toUpperCase().indexOf(filter) > -1) {
                    row[i].style.display = "";
                    break;
                }
                else {
                    row[i].style.display = "none";
                }
            }
        }
    }
    
    getSearchRow(reference, description, searchText) {
        var row = '';
        row = '<div class="ihunter-menu-boundary-row" onclick="requestGoogleSearchDetails(\''+reference+'\')">';
        row += '<div class="left-div"> <img class="ihunter-left-img" src="images/globe_search.png" width="40px" height="40px"';
        row += '/></div>';
        row += '<div class="middle-div">';
    
        var pattern = new RegExp(searchText, 'gi');
        var loc = description.search(pattern);
        if(loc == -1) {
            row += '<div class="ihunter-menu-text large" >' + description + '</div>';
        } else {
            row += '<div class="ihunter-menu-text large" >' + description.substring(0, loc)+'<span style="background-color: #ff9908">'+ description.substring(loc, loc+searchText.length) +'</span>'+ description.substring(loc+searchText.length) + '</div>';
        }
    
        // row += '<div class="ihunter-menu-text small">' + bd.subtitle + '</div>';
        row += '</div>';
        row += '<div class="right-div"><i class="fa fa-chevron-right"></i></div>';
        row += '</div>';
        return row;
    }
    
    requestGoogleSearchDetails(reference) {
        var googleSearchUrl = "https://maps.googleapis.com/maps/api/place/details/json?reference="+reference+"&sensor=true";
    
        var ajaxRequest = getAjaxRequest(); 
        if(ajaxRequest != null) {
            ajaxRequest.onreadystatechange = () => {
                if (ajaxRequest.readyState == 4) {
                    
                    var jData = JSON.parse(ajaxRequest.responseText);
                    var jResult = jData.result;
    
                    if(jResult != null) {
                        var jGeometry = jResult.geometry;
                        if(jGeometry != null) {
                            var jLocation = jGeometry.location;
                            if(jLocation != null) {
                                var location = L.latLng(jLocation.lat, jLocation.lng);
                                var name = jResult.name;
                                var sr = new SearchResult(reference, name);
                                sr.location = new L.LatLng(jLocation.lat,jLocation.lng);
                                this.map.addMarkerForSearchResult(sr);
                                this.map.zoomToLocation(location);
                                $("#main-search-results-div").hide();
                            }
                        }
                    }                
                }
            }
            ajaxRequest.open("GET", "getGoogleSearch.php?query="+googleSearchUrl, true);
            ajaxRequest.send(null);
        }    
    }

    validateLatitude(latitude) {
        var lat = parseFloat(latitude);
        if(lat && lat >= -90.0 && lat <= 90.0) {
            return true;
        }
        return false;
    }

    validateLongitude(latitude) {
        var lon = parseFloat(latitude);
        if(lon && lon >= -180.0 && lon <= 180.0) {
            return true;
        }
        return false;
    }

}
