import $ from 'jquery';

import View from './View';
import BlogMessage from './BlogMessage';

export default class BlogMessagesView extends View {
    constructor(regService) {
        super();
        
        this.regService = regService;
    }

    get header() {
        return "Messages";
    }

    //lets make each view have this function, and call it when the sidebar opens
    populatePage() {
        this.getBlogMessages();
    }

    async getBlogMessages() {
        //https://www.ihunterapp.com/messages/getMessages.php?platform=android&province="+this.context.getString(R.string.province_name_short)+"&timestamp="+this.settings.getMessagesTimestamp()

        let response = await this.regService.getBlogMessages();
        var jsonObj = JSON.parse(response);
    
        let messages = jsonObj.sort((a, b) => {
            // [HUNT-945] Sort messages latest first
            if(a.timestamp > b.timestamp){
                return -1;
            }
            if(a.timestamp < b.timestamp){
                return 1;
            }
            return 0;
        });
        var html = "";  

        for(let i=0; i < messages.length; i++) {
            var m = new BlogMessage(messages[i]);
            html += m.getHTML();
        }
        $("#blogMessages").append(html);
        $('#rightSidebarSpinner').hide();
    }
}

