export function getPurchasesString(purchases) {
    var stringDisplay = "";
    if(purchases && purchases.length > 0) {
        for(var i in purchases) {
            var sku = purchases[i];
            if(Object.prototype.hasOwnProperty.call(sku, "sku")) {
                sku = sku["sku"];
            }
            stringDisplay += stringDisplay.length > 0 ? ", "+getPurchaseNameFromSKU(sku) : getPurchaseNameFromSKU(sku);
        }
    }   
    else {
        stringDisplay = 'None';
    }
    return stringDisplay;
}

export function getPurchaseNameFromSKU(sku) {
    if(sku.includes("_")) {
        var countyParts = sku.split("_");
        for (var i = 0; i < countyParts.length; i++) {
            countyParts[i] = countyParts[i].charAt(0).toUpperCase() + countyParts[i].substr(1);
        }

        return countyParts.join(" ");
    }
    else {
        return sku.replace("com.kindevelopment.", "")
    }
}
