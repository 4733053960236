var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "app-info" }, [
        _c(
          "div",
          { staticClass: "modal-dialog full", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header full" }, [
                _c("div", { staticClass: "app-header" }, [
                  _c("img", {
                    staticClass: "ihunter-icon",
                    attrs: { src: _vm.icon },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "app-props" }, [
                    _c("span", { staticClass: "app-name" }, [
                      _vm._v("iHunter " + _vm._s(this.provinceName)),
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("App Version: " + _vm._s(this.appVersion)),
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("Regs Version: " + _vm._s(this.regVersion)),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "app-copy" }, [
                      _vm._v("Ⓒ " + _vm._s(this.appAuthor)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button", "data-dismiss": "modal" },
                        on: {
                          click: function ($event) {
                            return _vm.email()
                          },
                        },
                      },
                      [_vm._v("Email Us")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "closebtn rightSidebar",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.close()
                      },
                    },
                  },
                  [_vm._v("×")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body full" }, [
                _c("div"),
                _vm._v(" "),
                _c("div", { domProps: { innerHTML: _vm._s(this.notice) } }),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm._m(0),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: {
                      click: function ($event) {
                        return _vm.close()
                      },
                    },
                  },
                  [_vm._v("Close")]
                ),
              ]),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("iHunter Web App utilizes "),
      _c("a", { attrs: { href: "licenses.txt", target: "_blank" } }, [
        _vm._v("3rd party software"),
      ]),
      _vm._v(
        " licensed under MIT, BSD, and other terms.\n                    "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }