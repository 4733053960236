import { gSettings } from './Settings';

import CountyMap from './CountyMap';
import Rect from './Rect';

export class CountyMaps {
    constructor(province) {
      this.province = province;
        this.countyMapsJSONArray = [];
        this.inactiveCountyMapsJSONArray = [];
        this.allCountyMapsArray = []; //let's create the county maps and store them in an array, rather than creating them as we added them to the dicts. This way we can add/remove easier.
        this.purchasedMaps = {};
        this.purchasableMaps = {};
        this.updatableMaps = {};
        this.unavailableMaps = {};
        this.boundaryJsonData = {};
    }

    setupMaps(counties, boundaryJsonData, inactive_counties) {

        this.boundaryJsonData = boundaryJsonData;

        this.countyMapsJSONArray = counties;
        this.inactiveCountyMapsJSONArray = inactive_counties == null ? [] : inactive_counties;
        for (var i = 0; i < this.countyMapsJSONArray.length; i++) {
            var mapName = this.countyMapsJSONArray[i];
            var countyMap = new CountyMap(mapName);
            this.allCountyMapsArray.push(countyMap);  
        }

        this.updatePurchasedMaps();
    }

    isMapInactive(mapName) {
        for (var k = 0; k < this.inactiveCountyMapsJSONArray.length; k++) {
            var inactiveMapName = this.inactiveCountyMapsJSONArray[k];
            if(mapName == inactiveMapName) {
                return true;
            }
        }
        return false;
    }


    updatePurchasedMaps() {
        this.purchasedMaps = {};
        this.purchasableMaps = {};
        this.updatableMaps = {};
        this.unavailableMaps = {};
        
        for (let i = 0; i < this.allCountyMapsArray.length; i++) {
            let map = this.allCountyMapsArray[i];
            if(gSettings.isProductPurchased(map.mapName)) {
                this.addToPurchasedMaps(map);
            }
            else if(this.isUpdateToPurchasedMap(map.mapName)) {
                this.addToUpdatableMaps(map)
            }
            else {
                if(!this.isMapInactive(map.mapName)) {
                    this.addToPurchasableMaps(map);
                } 
            }
        }

        for(let i = 0; i < this.boundaryJsonData.length; i++) {
            var json = this.boundaryJsonData[i];
            var mapRoot = json.Subtitle;    
            var boundingRect = new Rect(parseFloat(json.West), parseFloat(json.North), parseFloat(json.East), parseFloat(json.South)); 
            var map = this.getCountyMapFromRoot(mapRoot);
            
            if(map == null) {
                map = new CountyMap(mapRoot);
                map.fullName = json.Title;
                map.code = json.Code;
                map.bounds = boundingRect;
                map.email = json.Comment;
                this.unavailableMaps[mapRoot] = map;
            } else {
                map.fullName = json.Title;
                map.code = json.Code;
                map.bounds = boundingRect;
                map.email = json.Comment;
            }   
        }
    }

    isCountyMap(mapName) {
        return this.countyMapsJSONArray.indexOf(mapName) >= 0;
    }

    getPurchasedMap(mapName) {
        if(Object.prototype.hasOwnProperty.call(this.purchasedMaps, mapName)) {
            return this.purchasedMaps[mapName];
        }

        return null;
    }

    getPurchasableMap(mapName) {
        if(Object.prototype.hasOwnProperty.call(this.purchasableMaps, mapName)) {
            return this.purchasableMaps[mapName];
        }

        return null;
    }

    addToPurchasedMaps(map) {
        this.removeOlderMapsFromArray(map.mapName, this.purchasedMaps);
        this.removeMapsFromArray(map.mapName, this.purchasableMaps);
        
        if(!Object.prototype.hasOwnProperty.call(this.purchasedMaps, map.mapName) || this.purchasedMaps[map.mapName] == null) {
            this.purchasedMaps[map.mapName] = map;
        }
    }

    addToUpdatableMaps(map) {
        this.removeOlderMapsFromArray(map.mapName, this.updatableMaps);
        if(!Object.prototype.hasOwnProperty.call(this.updatableMaps, map.mapName) || this.updatableMaps[map.mapName] == null) {
            this.updatableMaps[map.mapName] = map;
        }
    }

    getYearFromCountyMap(mapName) {
        var countyParts = mapName.split("_");
        var year = countyParts.pop();
        if(parseInt(year)) {
            return year;
        }
        return null;
    }

    getRootFromCountyMap(mapName) {
        if(!mapName) {
            console.log("Invalid county map name");
            return;
        }
        mapName = mapName.replace("__","_");
        var countyParts = mapName.split("_");
        var year = countyParts.pop();
        var rootCountyName = countyParts.join("_");
        return rootCountyName;
    }

    addToPurchasableMaps(map) {
        this.removeOlderMapsFromArray(map.mapName, this.purchasableMaps);
        if(!Object.prototype.hasOwnProperty.call(this.purchasableMaps, map.mapName) || this.purchasableMaps[map.mapName] == null) {
            this.purchasableMaps[map.mapName] = map;
        }
    }

    isUpdateToPurchasedMap(mapName) {
        var mapRoot = this.getRootFromCountyMap(mapName);
        var mapYear = this.getYearFromCountyMap(mapName);

        for (var tempMap in this.purchasedMaps) {
            if (Object.prototype.hasOwnProperty.call(this.purchasedMaps, tempMap)) {
                var tempMapRoot = this.getRootFromCountyMap(tempMap);
                if(mapRoot === tempMapRoot) {
                    var tempMapYear = this.getYearFromCountyMap(tempMap);
                    if(parseInt(mapYear) > parseInt(tempMapYear)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    getUpdatableMapForPurchasedMap(mapName) {
        for (var updatableMapName in this.updatableMaps) {
            var mapRoot = this.getRootFromCountyMap(mapName);
            var tempMapRoot = this.getRootFromCountyMap(updatableMapName);

            if(mapRoot === tempMapRoot) {
                var mapYear = this.getYearFromCountyMap(mapName);
                var tempMapYear = this.getYearFromCountyMap(updatableMapName);
                if(parseInt(tempMapYear) > parseInt(mapYear)) {
                    return this.updatableMaps[updatableMapName];
                }
            }
        }
        return null;
    }

    removeMapsFromArray(mapName, arr) {
        var mapRoot = this.getRootFromCountyMap(mapName);
        for (var tempMap in arr) {
            var tempMapRoot = this.getRootFromCountyMap(tempMap);
            if(mapRoot === tempMapRoot) {
                delete arr[tempMap];
            }
        }
    }

    removeOlderMapsFromArray(mapName, arr) {
        var mapRoot = this.getRootFromCountyMap(mapName);
        for (var tempMapName in arr) {
            var tempMapRoot = this.getRootFromCountyMap(tempMapName);
            if(mapRoot === tempMapRoot) {
                var mapYear = this.getYearFromCountyMap(mapName);
                var tempMapYear = this.getYearFromCountyMap(tempMapName);
                if(parseInt(mapYear) > parseInt(tempMapYear)) {
                    delete arr[tempMapName];
                }
            }
        }
    }

    getCopyrightURLForCountyMap(countyName) {
        return this.getBaseURLForCountyMap(countyName)+"copyright.png";
    }

    getLegendURLForCountyMap(countyName) {
        return this.getBaseURLForCountyMap(countyName)+"legend.png";
    }

    getMetaDataURLForCountyMap(countyName) {
        return this.getBaseURLForCountyMap(countyName)+"metadata.json";
    }

    getBoundaryURLForCountyMap(countyName) {
        return this.getBaseURLForCountyMap(countyName)+"boundary.geojson";
    }

    getTopoJsonURLForCountyMap(countyName) {
        return this.getBaseURLForCountyMap(countyName)+"boundary.topojson";
    }

    getTemplatedPathForCountyMap(countyName) {
        return this.getBaseURLForCountyMap(countyName)+"{z}/{x}/{y}.png";
    }

    getBaseURLForCountyMap(countyName) {
        return this.getBaseURLForCountyMaps()+countyName+"/";
    }

    getBaseURLForCountyMaps() {
        return "https://tiles.ihunterapp.com/tiles/"+ this.province.getProperty("SERVER_FOLDER").toLowerCase()+"/";
    }

    getCountyMapFromRoot(mapRoot) {
        for (let mapName in this.purchasedMaps) {
            if(this.getRootFromCountyMap(mapName) == mapRoot) {
                return this.purchasedMaps[mapName];
            }
        }
        for (let mapName in this.purchasableMaps) {
            if(this.getRootFromCountyMap(mapName) == mapRoot) {
                return this.purchasableMaps[mapName];
            }
        }
        for (let mapName in this.unavailableMaps) {
            if(mapName == mapRoot) {
                return this.unavailableMaps[mapName];
            }
        }
        return null;
    }

    getCountyMapFromMapName(mapName) {
        for (let m in this.purchasedMaps) {
            if(m == mapName) {
                return this.purchasedMaps[m];
            }
        }
        for (let m in this.purchasableMaps) {
            if(m == mapName) {
                return this.purchasableMaps[m];
            }
        }
        for (let m in this.unavailableMaps) {
            if(m == mapName) {
                return this.unavailableMaps[m];
            }
        }
        return null;
    }

    // mapIsPurchased(mapName) {
    //     return gSettings.isProductPurchased(mapName);
    // }

    getPopupSubtitle() {
      var mapNameWithYear = this.mapName;//FIX ME - we shouldn't always have a mapNameWithYear or need some way to flag unavailable
      if(this.mapIsAvailable()) {
          if(this.mapIsPurchased(mapNameWithYear)) {
              if(this.getUpdatableMapForPurchasedMap(mapNameWithYear)) {
                  return "View Settings (Update Available)";
              } else {
                  return "View Settings";
              }
          } 
          else {
              return "** "+this.getMapYear()+" Map for sale **";
          }
      } 

      return "Map Unavailable";
  }
}

export default CountyMaps;
