import $ from 'jquery';
import _ from 'underscore';
import bootbox from 'bootbox';
import firebase from 'firebase';

import { gSettings } from './Settings';
import { uuidv4 } from './Encryption';

import { getPurchaseNameFromSKU } from './PurchaseHelper';
import View from './View';


export class ManageAccountView extends View {
    constructor(modalView, accountManager) {
        super();
        this.modal = modalView;
        this.accountManager = accountManager;
        this.user = this.accountManager.getUserAsContact();
        this.bImageChanged = false;
        this.profileImageSource = "";
        this.pendingImage = null;
        $('#modalPrimaryButton').attr("disabled", true); //disable save button.

        this.subscription = this.accountManager.findWebSubscription();
        this.populatePage();
    }
    
    populatePage() {
        $("#profileImage").attr("src", this.user.photoUrl);
        
        $("#usernameInput").val(this.user.username);
        $("#usernameInput").keyup(() => {
            this.checkSaveButton();
        }); 

        // $("#emailInput").val(this.user.email);
        // $("#emailInput").keyup(function() {
        //     self.checkSaveButton();
        // }); 

        this.addPurchasesToDiv(gSettings.purchasesWeb, "#webpurchases");
        this.addPurchasesToDiv(gSettings.purchasesAndroid, "#androidpurchases");
        this.addPurchasesToDiv(gSettings.purchasesIOS, "#iospurchases");

        this.setButtons();

        if(!this.subscription) {
            $('#manageSubscriptionButton').hide();
        }
    }

    addPurchasesToDiv(purchases, divID) {
        var content = "";
        if(purchases.length > 0) {
            for(var i in purchases) {
                var sku = purchases[i];
                if(Object.prototype.hasOwnProperty.call(sku, "sku")) {
                    sku = sku["sku"];
                }
                content += '<div class="ihunter-menu-row-nohover"><div class="ihunter-menu-text">'+getPurchaseNameFromSKU(sku)+'</div></div>';
            }
        }   
        else {
            content += '<div class="ihunter-menu-row-nohover"><div class="ihunter-menu-text">No Purchases</div></div>';
        }

        $(divID).append(content);
    }

    setButtons() {
        var self = this;
        this.modal.find('#modalPrimaryButton').off(); //removes previous onclick
        this.modal.find('#modalPrimaryButton').on('click', 
            function(event) {
                self.saveAndClose();
            }
        );

        //this button will click the profilePictureFile Input to select an image to use as your profile picture
        $('#editPictureButton').click(function() {
            $('#profilePictureFile').click();
        });

        $('#profilePictureFile').on('change', function() { 
            self.getImageURL(this.files);
        });

        $('#deletePictureButton').click(function() {
            self.changeImage("images/noProfilePic.jpg");
        });

        $('#deleteAccountButton').click(() => {
            bootbox.confirm({
                title: "WARNING",
                message: "Are you sure that you want to delete your iHunter account? This action is irreversible<br><br>You will no longer be able to use the web app with this account.<br><br>All purchases made from the web app are linked to this account, so deleting this account will delete those purchases and they will not be recoverable or transferable to another account.<br><br>Local waypoints and tracks that are stored on your devices will remain.<br><br>Please contact iHunter at info@ihunterapp.com if you have any questions about deleting your account.",
                buttons: {
                    confirm: {
                        label: 'Delete Account',
                        className: 'btn-danger'
                    },
                    cancel: {
                        label: 'Cancel',
                        className: 'btn-secondary'
                    }
                },
                callback: (result) => {
                    if(result) {
                        this.accountManager.deleteAccount();
                        $("#fullScreenModal .close").click();
                    } 
                }
            }); 
            
        });

        $('#manageSubscriptionButton').click(async () => {

            window.onManageSubscription();
        });

        firebase.auth().currentUser.getIdTokenResult().then((idTokenResult) => {
            console.log(idTokenResult.signInProvider);
            if(idTokenResult.signInProvider == firebase.auth.EmailAuthProvider.PROVIDER_ID) {
                $('#changeEmailButton').show();
            } else {
                $('#changeEmailButton').hide();
            }

            if(idTokenResult.signInProvider == firebase.auth.EmailAuthProvider.PROVIDER_ID) {
                $('#changePasswordButton').show();
            } else {
                $('#changePasswordButton').hide();
            }
            
        });


        $('#changePasswordButton').click(() => {
            bootbox.confirm({
                message: "Would you like iHunter to send a password reset email to " + this.accountManager.getEmail(),
                centerVertical: true,
                buttons: {
                    confirm: {
                        label: 'Send Email'
                    },
                    cancel: {
                        label: 'Cancel'
                    }
                },
                callback: (result) => {
                    if(result == true) {
                        this.accountManager.sendPasswordResetEmail();
                    }
                }
            });
        });

        $('#changeEmailButton').click(() => {
            this.accountManager.changeAccountEmail();
        });
    }

    getImageURL(files) {
               
        if (files && files[0] && files.length >= 1) {
           
            var newName = 'Image_' + uuidv4() + ".jpg"; //create a name with a uuid. This way we don't have to worry about bad characters, and unique names
            var initialFile = files[0];
            var blob = initialFile.slice(0,initialFile.size, 'image/jpg');
            var newFile = new File([blob], newName, {type: 'image/jpg'})
            this.pendingImage = newFile;
            var reader = new FileReader();
            reader.onload = (_.bind(function(file) {
                return _.bind(function (e) {
                    this.changeImage(e.target.result);
                }, this);
            }, this))(newFile);

            reader.readAsDataURL(newFile);
        } 
    }

    changeImage(source) {
        $("#profileImage").attr("src", source);
        this.profileImageSource = source;
        this.bImageChanged = true;
        this.checkSaveButton();
    }

    checkSaveButton() {
        if($("#usernameInput").val() !== this.user.username || this.bImageChanged) { //|| $("#emailInput").val() !== this.user.email) {
            this.enableSaveButton();
        } else {
            this.disableSaveButton();
        }
    }

    enableSaveButton() {
        $('#modalPrimaryButton').removeAttr("disabled");
    }

    disableSaveButton() {
        $('#modalPrimaryButton').attr("disabled", true);
    }

    saveAndClose() {
        if($("#usernameInput").val() !== this.user.username && $("#usernameInput").val().trim() !== "") {
            console.log("Changing username");
            this.accountManager.updateUserProfile($("#usernameInput").val().trim());
        }
        if(this.bImageChanged && this.pendingImage) {
            console.log("Changing profile picture");
            this.accountManager.pushProfileImageToStorage(this.pendingImage);
        }
        if(this.bImageChanged && this.profileImageSource == "images/noProfilePic.jpg") {
            this.accountManager.deleteProfileImageInStorage();
        }
    }
}

export default ManageAccountView;
