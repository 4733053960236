import Settings from './Settings';
import SubscriptionSettings from './SubscriptionSettings';
import Observable from './Observable';

const EVENT = {

    UPDATE_SEASON_SETTINGS: 'update-season-settings',
    UPDATE_INFO_SETTINGS: 'update-additional-info-settings',
    UPDATE_LAYER_PREFS: 'update-layer-preferences',
    UPDATE_BOUNDARY_PREFS: 'update-boundary-preferences',
    UPDATE_COLOR_PREFS: 'update-color-preferences',
    UPDATE_WAYPOINT_FILTER: 'update-waypoints-filter',
    UPDATE_WAYPOINT_SORT: 'update-waypoints-sort',
    UPDATE_COORD_FORMAT: 'update-coordinate-format',
    UPDATE_SELECTED_LEH_REGION: 'update-selected-region',
    UPDATE_CURRENT_BASEMAP: 'update-basemap',
    ADD_STARTUP_REGION: 'add-startup-region',
    REMOVE_STARTUP_REGION: 'remove-startup-region',
}


export default class SettingsManager extends Observable {

    constructor(dataService, mapProvider) {
        super();
        
        this.service = dataService;
        this.map = mapProvider;

        this.settings = null;
        this.subscription = null;

        this.onUpdateSeasonSettings = this.createHandlerMethod(EVENT.UPDATE_SEASON_SETTINGS);
        this.onUpdateInfoSettings = this.createHandlerMethod(EVENT.UPDATE_INFO_SETTINGS);
        this.onUpdateLayerPrefs = this.createHandlerMethod(EVENT.UPDATE_LAYER_PREFS);
        this.onUpdateBoundaryPrefs = this.createHandlerMethod(EVENT.UPDATE_BOUNDARY_PREFS);
        this.onUpdateWaypointFilter = this.createHandlerMethod(EVENT.UPDATE_WAYPOINT_FILTER);
        this.onUpdateWaypointSort = this.createHandlerMethod(EVENT.UPDATE_WAYPOINT_SORT);
        this.onUpdateColorPrefs = this.createHandlerMethod(EVENT.UPDATE_COLOR_PREFS);
        this.onUpdateCoordFormat = this.createHandlerMethod(EVENT.UPDATE_COORD_FORMAT);
        this.onUpdateSelectedRegion = this.createHandlerMethod(EVENT.UPDATE_SELECTED_LEH_REGION);
        this.onUpdateCurrentBasemap = this.createHandlerMethod(EVENT.UPDATE_CURRENT_BASEMAP);
        this.onAddMapStartupRegion = this.createHandlerMethod(EVENT.ADD_STARTUP_REGION);
        this.onRemoveMapStartupRegion = this.createHandlerMethod(EVENT.REMOVE_STARTUP_REGION);
    }

    initialize(provinceCode) {

        this.settings = new Settings(this, provinceCode);                     // gSettings
        this.subscription = new SubscriptionSettings(this.settings);    // gSubscriptionSettings
    }

    setPreference(name, val) {
        this.settings.setPreference(name, val);
    }

    updatePreference(name, val) {
        this.service.db.updatePreference(name, val);
    }

    setOpacityForUserMapLayer(uuid, opacity) {
        this.service.db.setOpacityForUserMapLayer(uuid, opacity);
    }

    setVisibilityForUserMapLayer(uuid, visibility) {
        this.service.db.setVisibilityForUserMapLayer(uuid, visibility);
    }


    deleteUserMapLayer(uuid) {
        var existingLayer = this.map.getUserMapLayerForUUID(uuid);
        if(existingLayer && existingLayer.isBaseMap()) {
            this.map.removeBaseMapFromControl(existingLayer);
        }

        this.service.db.deleteUserMapLayer(uuid);
    }

    deleteStartupMapRegion(uuid) {
        this.service.db.deleteStartupMapRegion(uuid);
    }

    updateSeasonSettings(pref, val) {
        this.settings.setPreference(pref, val);
        this.emit(EVENT.UPDATE_SEASON_SETTINGS, pref, val);
    }

    updateInfoSettings(pref, val) {
        this.settings.setPreference(pref, val);
        this.emit(EVENT.UPDATE_INFO_SETTINGS, pref, val);
    }

    updateLayerPrefs(pref, val) {
        this.settings.setPreference(pref,val);
        this.emit(EVENT.UPDATE_LAYER_PREFS, pref, val);
    }

    updateBoundaryPrefs(pref, val) {
        this.settings.setPreference(pref, val);
        this.emit(EVENT.UPDATE_BOUNDARY_PREFS, pref, val);
    }

    updateSelectedRegion(pref, val) {
        this.settings.setPreference(pref, val);
        this.emit(EVENT.UPDATE_SELECTED_LEH_REGION, pref, val);
    }

    updateWaypointFilter(pref, val) {
        // Let handler setPreferences after parsing filter
        // this.settings.setPreference(pref, val); <-- purposely commented
        this.emit(EVENT.UPDATE_WAYPOINT_FILTER, pref, val);
    }

    updateWaypointSort(pref, val) {
        this.settings.setPreference(pref, val);
        this.emit(EVENT.UPDATE_WAYPOINT_SORT, pref, val);
    }

    updateCurrentBasemap(pref, val) {
        this.settings.setPreference(pref, val);
        this.emit(EVENT.UPDATE_CURRENT_BASEMAP, pref, val);
    }

    addMapStartupRegion(pref, val) {
        this.emit(EVENT.ADD_STARTUP_REGION, val);
    }

    removeMapStartupRegion(pref, val) {
        this.emit(EVENT.REMOVE_STARTUP_REGION, pref);
    }

    updateColorPrefs(pref, val) {
        this.settings.setPreference(pref,val);
        this.emit(EVENT.UPDATE_COLOR_PREFS, pref, val);
    }


    updateCoordFormat(pref, val) {
        this.settings.setPreference(pref,val);
        this.emit(EVENT.UPDATE_COORD_FORMAT, pref, val);
    }

}