import mergeImages from 'merge-images';

export var gWaypointImageCache = null; // Created in index.js:completeSetup()

export class WaypointImageCache {
    constructor() {
        this.cacheDict = new Object();

        if(gWaypointImageCache === null) {
          gWaypointImageCache = this;
        }
    }
    
    getWaypointImage(pinName, backgroundName) { //use moments for Dates
        var self = this;
        return new Promise(function(resolve, reject) {
            var pinKey = WaypointImageCache.getKeyForPin(pinName, backgroundName);
            var imgUrl = self.cacheDict[pinKey];
            if(imgUrl != null) {
                resolve(imgUrl);
            }
            if(backgroundName != null && backgroundName.length > 0) {
                mergeImages(["images/"+backgroundName+"@2x.png","images/"+pinName+"@2x.png"]).then(function(b64) { //may need to add .png and @2x
                    self.cacheDict[pinKey] = b64;
                    resolve(b64);
                });
            } else {
                imgUrl = "images/"+pinName+"@2x.png"
                self.cacheDict[pinKey] = imgUrl;
                resolve(imgUrl);
            }
        });
    }

    static getKeyForPin(pinName, backgroundName) {
        if(backgroundName != null && backgroundName.length > 0) {
            return pinName + "_" + backgroundName;
        }
        return pinName;
    }
}

export default WaypointImageCache;
