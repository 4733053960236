/*global XDomainRequest*/

import $ from 'jquery';

import View from './View';
import { Configuration } from './FirebaseService';

export class AddContactsView extends View {
    constructor(modalView, contactManager) {
        super();
        this.contactManager = contactManager;

        var self = this;
        this.selectedContacts = new Array();
        this.searchedContacts = new Array();
        this.modal = modalView;
        
        this.modal.find('#modalPrimaryButton').attr("disabled", true); //disable save button to begin with.
        
        this.modal.find('#modalPrimaryButton').off(); //removes previous onclick
        this.modal.find('#modalPrimaryButton').on('click', 
            function(event) {
                self.saveAndClose();
            }
        );

        // Get the input field
       // var input = document.getElementById("myInput");

        $("#addContactSearch").keypress(function(event) {
            self.onAddContactSearchKeyPress(event);
        });

        $("#addContactSearchButton").click(function() {
            self.doAddContactSearch();
        });

    }

    

    saveAndClose() {
        for(var i = 0; i < this.selectedContacts.length; i++) {
            var contact = this.selectedContacts[i];
            this.contactManager.requestContact(contact);
        }
    }

    
    onAddContactSearchKeyPress(e) {
        var code = (e.keyCode ? e.keyCode : e.which);
        if (code == 13) { //Enter keycode
            e.preventDefault();
            this.doAddContactSearch();
        }
    }

    doAddContactSearch() {
        this.searchedContacts = new Array();
        var numberOfSearches = 0;
        
        var searchText = $("#addContactSearch").val().trim().toLowerCase();
        if(searchText != null && searchText != "") {

            var queryPortion = "orderBy=\"email\"&startAt=\""+searchText+"\"&endAt=\""+searchText+"\uf8ff"+"\"";
            var queryPortion_name = "orderBy=\"lowername\"&startAt=\""+searchText+"\"&endAt=\""+searchText+"\uf8ff"+"\"";
            var self = this;

            //https://ihunter-d5eab
            
            getURLContent(Configuration().databaseURL + "/users.json?" + queryPortion, (jsonContent) => {
                var jsonObj = JSON.parse(jsonContent);

                for(var key in jsonObj) { 
                    var contact = this.contactManager.createContact(jsonObj[key]);
                    
                    if(!this.contactManager.contactWithUidExists(contact.uid, self.searchedContacts) && !this.contactManager.contactWithUidExists(contact.uid, this.contactManager.getContactsArray()) && this.contactManager.user.uid !==contact.uid) {
                        var selectedContact = self.selectedContactForUID(contact.uid);
                        if(selectedContact) {
                            self.searchedContacts.push(selectedContact);
                        } else {
                            self.searchedContacts.push(contact);
                        }
                    }
                }
                numberOfSearches += 1;
                if(numberOfSearches == 2) {
                    self.appendResultsToHTML();
                }
            });

            //https://ihunter-d5eab
            getURLContent(Configuration().databaseURL + "/users.json?" + queryPortion_name, (jsonContent) => {
                var jsonObj = JSON.parse(jsonContent);

                for(var key in jsonObj) {
                    var contact = this.contactManager.createContact(jsonObj[key]);

                    if(!this.contactManager.contactWithUidExists(contact.uid, self.searchedContacts) && !this.contactManager.contactWithUidExists(contact.uid, this.contactManager.getContactsArray())) {
                        self.searchedContacts.push(contact);
                    }
                }
                numberOfSearches += 1;
                if(numberOfSearches == 2) {
                    self.appendResultsToHTML();
                }
            });
        }
    }

    selectedContactForUID(uid) {
        var arrayLength = this.selectedContacts.length;
        for(var i = 0; i < arrayLength; i++) {
            var contact = this.selectedContacts[i];
            if(contact.uid === uid) {
                return contact;
            }
        }
        return null;
    }

    appendResultsToHTML() {
        var self = this;
        $("#availableContacts").html("");
        if(this.selectedContacts && this.selectedContacts.length > 0) {
            this.sortContactsArray(this.selectedContacts);
            $("#availableContacts").append(this.getHTMLForChatHeader("Selected Contacts"));
            for (const key in this.selectedContacts) {
                let contact = this.selectedContacts[key];
                let html = this.getHTMLForAddContactRow(contact, true, true);
                $("#availableContacts").append(html);
                this.addOnClickListenerForUID(contact.uid, "_selected");
            }
        }

        if(this.searchedContacts && this.searchedContacts.length > 0) {
            this.sortContactsArray(this.searchedContacts);
            $("#availableContacts").append(this.getHTMLForChatHeader("Available Contacts"));
            for (const key in this.searchedContacts) {
                let contact = this.searchedContacts[key];
                let html = this.getHTMLForAddContactRow(contact, this.selectedContactForUID(contact.uid) != null, false);
                $("#availableContacts").append(html);
                this.addOnClickListenerForUID(contact.uid, "_available");
            }
        }
    }

    getHTMLForChatHeader(title) {
        var htmlContent = '<div class="contacts-header-row">';
        htmlContent += '<div>' + title + '</div>';
        htmlContent += '</div>';
        return htmlContent;
    }

    addOnClickListenerForUID(uid, suffix) {
        var self = this;
        $("#"+uid+suffix).click(function() {
            var id = $(this).attr('id');
            var uid = id.substring(0, id.length - suffix.length);
            self.selectContactClicked(uid);
        });
    }


    sortContactsArray(array) {
        array.sort(function(a, b){
            var aName = a.username;
            var bName = b.username;
            if(aName < bName) {return 1;}
            if(aName > bName) {return -1;}
            return 0;
        });
    }

    selectContactClicked(uid) {
        for(let i = 0; i < this.selectedContacts.length; i++) {
            let contact = this.selectedContacts[i];
            if(contact.uid === uid) {
                this.selectedContacts.splice(i, 1);
                this.appendResultsToHTML();
                this.checkSaveEnabled();
                return; 
            }
        }
        for(let i = 0; i < this.searchedContacts.length; i++) {
            let contact = this.searchedContacts[i];
            if(contact.uid === uid) {
                this.selectedContacts.push(contact);
                this.appendResultsToHTML();
                this.checkSaveEnabled();
                return; 
            }
        }
    }

    checkSaveEnabled() {
        if(this.selectedContacts && this.selectedContacts.length > 0) {
            $('#modalPrimaryButton').removeAttr("disabled"); //enable save button.
        } else {
            $('#modalPrimaryButton').attr("disabled", true); //disable save button.
        }
    }

    getHTMLForAddContactRow(contact, bShowCheck, bIsSelectionRow) {
        var leftImageSrc = 'images/noProfilePic.jpg';
        var title = contact.username;
        var subTitle = contact.getMaskedEmail();

        var suffix = bIsSelectionRow ? "_selected" : "_available";
        var htmlContent = '<div class="contact-row" id="'+contact.uid+suffix+'">';
        
            htmlContent += '<div class="left-div"> <img class="pin-image" src="' + leftImageSrc + '" /> </div>';
            htmlContent += bShowCheck ? '<div class="middle-div includes-right-div">' : '<div class="middle-div">';
                htmlContent += '<div class="ihunter-menu-text medium top">'+ title + '</div>';
                htmlContent += '<div class="bottom-div">';
                htmlContent += '<div class="ihunter-menu-text small left">' + subTitle + '</div>';
                htmlContent += '</div>';
            htmlContent += '</div>';
            if(bShowCheck) {
                htmlContent += '<div class="right"> <img class="pin-image" src="images/checkmark.png" /> </div>';
            }

        htmlContent += '</div>';
        
        return htmlContent;
    }

}

function createCORSRequest(method, url) {
    var xhr = new XMLHttpRequest();
    if ("withCredentials" in xhr) {
  
      // Check if the XMLHttpRequest object has a "withCredentials" property.
      // "withCredentials" only exists on XMLHTTPRequest2 objects.
      xhr.open(method, url, true);
  
    } else if (typeof XDomainRequest != "undefined") {
  
      // Otherwise, check if XDomainRequest.
      // XDomainRequest only exists in IE, and is IE's way of making CORS requests.
      xhr = new XDomainRequest();
      xhr.open(method, url);
  
    } else {
  
      // Otherwise, CORS is not supported by the browser.
      xhr = null;
  
    }
    return xhr;
  }

function getURLContent( url, onReadyFunction ) {
    
    var corsRequest = createCORSRequest('GET', url);  // The variable that makes Ajax possible!
  
    // Create a function that will receive data sent from the server
    corsRequest.onload = function () {
        // console.log("cors request onload "+corsRequest.responseText);
        onReadyFunction(corsRequest.responseText);
    };
  
    corsRequest.onerror = function() {
        console.log('There was an error with the CORS request');
    };

    corsRequest.send();
}

export default AddContactsView;
