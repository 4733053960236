import $ from 'jquery';

import View from './View';

export class ListOfPropertiesView extends View {
    constructor(json) {
        super();
        this.json = JSON.parse(json);
    }

    get header() {
        return "Properties";
    }

    //lets make each view have this function, and call it when the sidebar opens
    populatePage() {
        this.populateContent('#listOfProperties');
        $('#rightSidebarSpinner').hide();
    }

    populateContent(appendTo) {
        if(this.json != null) {
            console.log(this.json);
            for (var obj in this.json) {
                var property = this.json[obj];
                this.createHeadingAndAppendTo(appendTo, property.name);
                this.createContentAndAppendTo(appendTo, property.value);                
            }
        }
    }

    createHeadingAndAppendTo(appendTo, heading) {
        console.log("test");
        $(appendTo).append('<div class="ihunter-menu-heading" id="'+heading+'_Heading">'+heading+'</div>');
    }

    createContentAndAppendTo(appendTo, content) {
        if(content != null && content.startsWith("http")) {
            // $(appendTo).append('<div class="ihunter-menu-row-nohover" onclick="openModalURL(\'\', \''+content+'\');">'+content+'</div>'); //can't open in iFrame because of CORS
            $(appendTo).append('<div class="ihunter-menu-row-nohover" onclick="openURLInNewTab(\''+content+'\');">'+content+'</div>');
        }
        else {
            $(appendTo).append('<div class="ihunter-menu-row-nohover">'+content+'</div>');
        }
    }
}

export default ListOfPropertiesView;
