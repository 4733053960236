import $ from 'jquery';
import _ from 'underscore';
import bootbox from 'bootbox';

import { gSettings } from './Settings';

import Sidebar from './SidebarBase';

const RIGHT_MENU_SETTINGS_SIZE = '400px';
const RIGHT_MENU_BLOG_MESSAGES_SIZE = '400px';
const RIGHT_MENU_CHAT_SIZE = '800px';
const RIGHT_MENU_STORE_SIZE = '400px';
const RIGHT_MENU_LEASEINFO_SIZE = '400px';
const RIGHT_MENU_OTHER_SIZE = '500px';

const TYPE_MENU_OPEN_OTHER = 1;
const TYPE_MENU_OPEN_SETTINGS = 2;
const TYPE_MENU_OPEN_LEASEINFO = 3;
const TYPE_MENU_OPEN_BLOG_MESSAGES = 4;
const TYPE_MENU_OPEN_CHAT = 5;
const TYPE_MENU_OPEN_STORE = 6;

const MENU_TYPE = {
    OTHER: TYPE_MENU_OPEN_OTHER,
    SETTINGS: TYPE_MENU_OPEN_SETTINGS,
    LEASEINFO: TYPE_MENU_OPEN_LEASEINFO, 
    BLOG_MESSAGES: TYPE_MENU_OPEN_BLOG_MESSAGES,
    CHAT: TYPE_MENU_OPEN_CHAT,
    STORE: TYPE_MENU_OPEN_STORE,
};

//export var gRightSidebar = null; // Created in index.js:finalizeSetup()

export class RightSidebar extends Sidebar {

    static get MENU_TYPE() {
        return MENU_TYPE;
    }

    constructor() {
        super($('#rightSidebar'), $("#rightSidebarContent"), $('#rightSidebarHeading'), $('#rightSidebarFooterDiv'));


        this.wmu = '';

        window.closeRightSidebar = () => {
            this.close(); 
        };
        window.toggleFilterView = () => {
            this.toggleFilterView();
        };
        window.removeFilterView = () => {
            this.removeFilterView();
        };


        // TODO: find a new home for WMU view - probably needs its own view
        gSettings.manager.onUpdateSeasonSettings((key, val) => {
            if(this.wmu != '') {
                //if WMUInfoScreen open:  refresh it
                window.onWMU (this.wmu, this.getHeader());
                //updateWMUInfo(gRightSidebar.wmu, gRightSidebar.getHeader());               
            }
        });
    }

    //currently this includes a title and hides the footer - this may need to change in the future.
    toggleWithTypeAndContent(type, content, title) {
        if((type == this.sidebarViewType && type != TYPE_MENU_OPEN_OTHER && type != TYPE_MENU_OPEN_LEASEINFO) || type == Sidebar.MENU_TYPE_CLOSED) { //other will just toggle, not close
            this.close(); 
        } else {
            if(this.sidebarViewType != Sidebar.MENU_TYPE_CLOSED) {
                this.close(); 
                this.sidebarViewType = type;
                setTimeout(() => {
                    this.setContent(content); //sets the content when the sidebar is closed so that we don't see the content switch
                    this.setHeader(title);
                    this.hideFooter();
                    this.open();
                }, Sidebar.TRANSITION_SPEED); //delay in ms...this will allow it to close first. Make sure this matches the delay in .sidebar in sidebar.css
            } 
            else {
                this.sidebarViewType = type;
                this.setContent(content);
                this.setHeader(title);
                this.hideFooter();
                this.open();
            }
        }
    }

    //Override
    shouldClose(type) {
        return ((type == this.sidebarViewType && type != TYPE_MENU_OPEN_OTHER) || type == Sidebar.MENU_TYPE_CLOSED);
    }

    //Override
    open() {
        //console.log(this.sidebarViewType);
        this.setActiveButton(this.sidebarViewType);
        this.setSidebarSizeForType(this.sidebarViewType);
    }

    //Override
    close() {
        this.removeFilterView();
        this.wmu = ""; //keep track of the active wmu, because we are potentially filtering wmu if it's open
        this.setActiveButton(Sidebar.MENU_TYPE_CLOSED); 
        this.setSidebarSizeForType(Sidebar.MENU_TYPE_CLOSED);
    }

    removeGlobalViewVariables() {
        //gChatView = null; //this will help keep track if the ChatView is open or not
        //gSettingsView = null; //this will help keep track if the gSettingsView is open or not
    }
    removeViewsExcept(type) {
        // if(type != TYPE_MENU_OPEN_CHAT) {
        //     gChatView = null;
        // }
        // if(type != TYPE_MENU_OPEN_SETTINGS) {
        //     gSettingsView = null;
        // }
    }

    //Override
    setActiveButton(type) {
        $('#settingsButton').toggleClass("active", type == TYPE_MENU_OPEN_SETTINGS);
        $('#blogMessagesButton').toggleClass("active", type == TYPE_MENU_OPEN_BLOG_MESSAGES);
        $('#chatButton').toggleClass("active", type == TYPE_MENU_OPEN_CHAT);
        $('#storeButton').toggleClass("active", type == TYPE_MENU_OPEN_STORE);
    }
    
    //Override
    setSidebarSizeForType(type) {
        this.sidebarViewType = type;

        if(type == Sidebar.MENU_TYPE_CLOSED) {
            this.sidebarDiv.css('width', Sidebar.SIZE_CLOSED);
            this.sidebarContent.css('width', Sidebar.SIZE_CLOSED);
        }
        else if(type == TYPE_MENU_OPEN_SETTINGS) {
            this.sidebarDiv.css('width', RIGHT_MENU_SETTINGS_SIZE);
            this.sidebarContent.css('width', RIGHT_MENU_SETTINGS_SIZE);
        }
        else if(type == TYPE_MENU_OPEN_BLOG_MESSAGES) {
            this.sidebarDiv.css('width', RIGHT_MENU_BLOG_MESSAGES_SIZE);
            this.sidebarContent.css('width', RIGHT_MENU_BLOG_MESSAGES_SIZE);
        }
        else if(type == TYPE_MENU_OPEN_STORE) {            
            this.sidebarDiv.css('width', RIGHT_MENU_STORE_SIZE);
            this.sidebarContent.css('width', RIGHT_MENU_STORE_SIZE);
        }
        else if(type == TYPE_MENU_OPEN_CHAT) {            
            this.sidebarDiv.css('width', RIGHT_MENU_CHAT_SIZE);
            this.sidebarContent.css('width', RIGHT_MENU_CHAT_SIZE);
        }
        else if(type == TYPE_MENU_OPEN_OTHER) {
            this.sidebarDiv.css('width', RIGHT_MENU_OTHER_SIZE);
            this.sidebarContent.css('width', RIGHT_MENU_OTHER_SIZE);
        }
        else if(type == TYPE_MENU_OPEN_LEASEINFO) {
            this.sidebarDiv.css('width', RIGHT_MENU_LEASEINFO_SIZE);
            this.sidebarContent.css('width', RIGHT_MENU_LEASEINFO_SIZE);
        }
    }

    
    openFromHtml(htmlString, view) { //Override
        $('#rightSidebarSpinner').show();
        super.openFromHtml(htmlString, view);
    }

    toggleFilterView() {
        //slide up or fade in a menu on the bottom right (500px wide)
        $('#rightSidebarFilter').toggleClass('showing');
    }

    removeFilterView() { 
        $('#rightSidebarFilter').removeClass('showing');
    }
}

export default RightSidebar;
