export default class View {
    constructor() {
    }

    get header() {
      return null;
    }

    get footer() {
      return null;
    }

    get isVisible() {
        return true; // Stub for later optimization
    }

    //lets make each view have this function, and call it when the sidebar opens
    populatePage() {
      throw new Error('Method not implemented.');
    }
}
