import moment from 'moment';

export class DateHelpers {
    constructor() {
    }
    
    static dateFromString(strDate) { //use moments for Dates
        if(strDate == null) {
            return moment();
        }
        return moment(strDate);       
    }
    
    
    
    static timeIntervalFromString(timeString) {
        var date;
        if(timeString == null) {
            return new Date().now();
        }
        // if the string has a dot, then the date timestamp is in seconds since 1970.
        // on Android, we need it in milliseconds since 1970, so need to convert it.
        else if(timeString.includes("Z")) {
            if(timeString.length > 23) {
                date = moment(timeString, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"); //iHunter will use this format
            } else {
                date = moment(timeString, "yyyy-MM-dd'T'HH:mm:ss'Z'"); //iHunter will use this format
            }
        }
        else if(timeString.includes(".")) {
            date = moment(parseFloat(timeString) * 1000);
        }
        else {
            date = moment(parseFloat(timeString));
        }

        return date.valueOf();
    }

    static stringFromDate(moment) {
        return moment.format("YYYY-MM-DD HH:mm:ss");
    }

    static getTimeAsString(date) {
        return moment(date).format('h:mm a');
    }

    static relativeStringFromDate(lastMessageDate) {
        var currentDate = moment();
        var currentYear = currentDate.year();
        var lastMessageYear = lastMessageDate.year();
        var currentMonth = currentDate.month() + 1; //0-11 originally.
        var lastMessageMonth = lastMessageDate.month() + 1; //0-11 originally.
        var currentDay = currentDate.date();
        var lastMessageDay = lastMessageDate.date();

        if(currentYear != lastMessageYear) {
            return lastMessageYear;
        } else if(currentDay != lastMessageDay || currentMonth != lastMessageMonth) {
            return lastMessageDate.format('MMM D');
        }
        return lastMessageDate.format('h:mm a');
    }

    static dateFromTimeInSeconds(seconds) {
        return moment.unix(seconds);
    }

    static chatMessageStringFromDate(time) {
        return moment(time).format('lll') ;
    }
}

export default DateHelpers;
