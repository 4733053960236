
import _ from 'underscore';
import firebase from 'firebase';

import BlobWithFilename from './BlobwithFilename';

const PHOTO_URL = 'UserProfilePic.jpg';
//const UNKNOWN_PHOTO_URL = 'images/noProfilePic.jpg';

export default class FirebaseStorage {

    constructor(service) {
        this.service = service;
        this.storage = firebase.storage();
    }

    get user() {
        return this.service.user;
    }

    getPhotoUrl(uid) {
        return new Promise((resolve, reject) => {
            var storageRef = this.storage.ref();
            var photoUrl = PHOTO_URL;
            storageRef.child(`/users/${uid}/${photoUrl}`).getDownloadURL().then((url) => {
                console.log(`Profile picture found: ${photoUrl}`);
                return resolve(url);
            }).catch((error) => {
                console.log(`No profile picture set for ${uid}. You can safely ignore this error.`);
                return reject(error);  
            });
        });
    }
    
    // TODO: Combine getImageUrlForWaypoint and getWeatherImageUrlForWaypoint
    getImageUrlForWaypoint(waypoint, imageName) {
        return new Promise((resolve, reject) => {
            if(!waypoint || !imageName) {
                reject(new TypeError("Invalid arguments"));
            }

            let image = imageName.replace('.jpg', '').replace('.png', '');

            var storageRef = this.storage.ref(`/sync/${this.user.uid}/images/${waypoint.uuid}`); 
            storageRef.child(image).getDownloadURL().then((url) => {
                resolve([url, imageName]);
            }).catch((error) => {
                reject(error);
            });  

        });
    }
    
    getWeatherImageUrlForWaypoint(uid, imageName) {
        return new Promise((resolve, reject) => {
            if(!uid || !imageName) {
                reject(new TypeError("Invalid arguments"));
            }

            let image = imageName.replace('.jpg', '').replace('.png', '');

            var storageRef = this.storage.ref(`/sync/${this.user.uid}/images/${uid}`);
            storageRef.child(image).getDownloadURL().then((url) => {
                resolve(url);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    pushProfileImageToStorage(source, uid, bIsForGroupChat) { //uid can also be a chatkey
        if(source) {
            var file = source;
            var resizedImage = resizeImage({
                file: file,
                maxSize: 750 //adjust this if the size is too big/small. This seems to be about 100-150kb, which is OK quality
            }).then((resizedImage) => {
                this.uploadResizedProfilePic(resizedImage, uid, bIsForGroupChat);
            }).catch((err) => {
                console.error(err);
            });
        }
    }

    deleteProfileImageInStorage(uid, bIsForGroupChat) {
        var ref = this.storage.ref().child('/users/'+uid+'/UserProfilePic.jpg');
        ref.delete().then(() => {
            // File deleted successfully
            this.updateProfilePicDate(uid, bIsForGroupChat);
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });
    }

    uploadResizedProfilePic(blobWithFilename, uid, bIsForGroupChat) {
        var metadata = {'contentType': 'image/jpeg'};
        var filename = blobWithFilename.filename.substring(0,blobWithFilename.filename.lastIndexOf('.')); //remove the extension
        var uploadTask = this.storage.ref().child('/users/'+uid+'/UserProfilePic.jpg').put(blobWithFilename.blob, metadata);
        
        uploadTask.on('state_changed', null, (error) => {
            console.error('Upload failed:', error);
        }, () => {
            //populate the images node (We use this to download images to devices).
            this.service.db.updateProfilePicDate(uid, bIsForGroupChat);
        });
    }

    saveImagesToFirebase(imagesToSave, waypointUUID) {
        for(var i = 0; i < imagesToSave.length; i++) {
            var file = imagesToSave[i];
            var resizedImage = resizeImage({
                file: file,
                maxSize: 750 //adjust this if the size is too big/small. This seems to be about 100-150kb, which is OK quality
            }).then((resizedImage) => {
                this.uploadWaypointImage(resizedImage, waypointUUID);
            }).catch((err) => {
                console.error(err);
            });
        }
    }

    uploadWaypointImage(blobWithFilename, waypointUUID) {
        return new Promise((resolve, reject) => {
            var metadata = {'contentType': 'image/jpeg'};
            var filename = blobWithFilename.filename.substring(0,blobWithFilename.filename.lastIndexOf('.')); //remove the extension
            var uploadTask = this.storage.ref().child('sync/' + this.user.uid + '/images/' + waypointUUID + '/' + filename).put(blobWithFilename.blob, metadata);
            
            uploadTask.on('state_changed', null, (error) => {
                console.error('Upload failed:', error);
                reject(error)
            }, () => {
                resolve(filename);
                //populate the images node (We use this to download images to devices).
                this.service.db.setWaypointImage(filename, waypointUUID);
            });
        });
    }

    removeImageFromFirebase(waypointUUID, imageName) {
        imageName = imageName.replace('.jpg', '').replace('.png','');
        this.storage.ref().child('sync/' + this.user.uid + '/images/' + waypointUUID + '/' + imageName).delete().then(() => {
            // File deleted successfully
            this.service.db.removeWaypointImage(imageName, waypointUUID);
        }).catch(function(error) {
        // Uh-oh, an error occurred!
        });
    }

    deleteImage(filename, waypointUUID) {
        return new Promise((resolve, reject) => {
            this.storage.ref().child('sync/' + this.user.uid + '/images/' + waypointUUID + '/' + filename).delete().then(function() {
                //file deleted successfully
                resolve();
            }).catch(function(error) {
                console.error('Delete failed:', error);
                reject(error);
            });
        });
    }

    copyFileFromStorage(filename, uuid, originalContactUID) {
        var originalStorageRef = this.storage.ref().child('/sync/'+originalContactUID+'/images/'+uuid+'/'+filename);
        originalStorageRef.getDownloadURL().then((url) => {
            // `url` is the download URL for 'images/stars.jpg'
            // This can be downloaded directly:
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
              var blob = xhr.response;
              var metadata = {'contentType': 'image/jpeg'};
              var uploadTask = this.storage.ref().child('/sync/'+this.user.uid+'/images/'+uuid+'/'+filename).put(blob, metadata);
            };
            xhr.open('GET', url);
            xhr.send();
          
          }).catch(function(error) {
            // Handle any errors
          });
    }

    copyImagesAtPath(oldPath, uuid, originalContactUID) {
        const self = this;
        var oldRef = this.service.db.db.ref(oldPath);
        oldRef.once("value", (snapshot) => {
            if(snapshot.exists()) {
                var json = snapshot.val();
                var images = json["image"];
                if(images && images != "") {
                    var imagesArray = images.split(",");
                    if(imagesArray) {
                        imagesArray.forEach(function (imageName, index) {
                            self.copyFileFromStorage(imageName.replace('.jpg', '').replace('.png', ''), uuid, originalContactUID);
                        });
                    }
                }
                var weatherImage = json["weather_image"];
                if(weatherImage != null && weatherImage != "") {
                    self.copyFileFromStorage(weatherImage.replace('.jpg', '').replace('.png', ''), uuid, originalContactUID);
                }
            }
        }); 
    }

}

var resizeImage = function (settings) {
    var file = settings.file;
    var maxSize = settings.maxSize;
    var reader = new FileReader();
    var image = new Image();
    var canvas = document.createElement('canvas');
    var dataURItoBlob = function (dataURI) {
        var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
            atob(dataURI.split(',')[1]) :
            unescape(dataURI.split(',')[1]);
        var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var max = bytes.length;
        var ia = new Uint8Array(max);
        for (var i = 0; i < max; i++)
            ia[i] = bytes.charCodeAt(i);
        // return new Blob([ia], { type: mime });
        return new BlobWithFilename(new Blob([ia], { type: mime }), file.name);
    };
    var resize = function () {
        var width = image.width;
        var height = image.height;
        if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
            }
        } else {
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
            }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        var dataUrl = canvas.toDataURL('image/jpeg');
        return dataURItoBlob(dataUrl);
    };
    return new Promise(function (ok, no) {
        if (!file.type.match(/image.*/)) {
            no(new Error("Not an image"));
            return;
        }
        reader.onload = function (readerEvent) {
            image.onload = function () { return ok(resize()); };
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
};

