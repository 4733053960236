import { gSettings } from './Settings';

var K0 = 0.9996;

var E = 0.00669438;
var E2 = Math.pow(E, 2);
var E3 = Math.pow(E, 3);
var E_P2 = E / (1 - E);

var SQRT_E = Math.sqrt(1 - E);
var _E = (1 - SQRT_E) / (1 + SQRT_E);
var _E2 = Math.pow(_E, 2);
var _E3 = Math.pow(_E, 3);
var _E4 = Math.pow(_E, 4);
var _E5 = Math.pow(_E, 5);

var M1 = 1 - E / 4 - 3 * E2 / 64 - 5 * E3 / 256;
var M2 = 3 * E / 8 + 3 * E2 / 32 + 45 * E3 / 1024;
var M3 = 15 * E2 / 256 + 45 * E3 / 1024;
var M4 = 35 * E3 / 3072;

var P2 = 3 / 2 * _E - 27 / 32 * _E3 + 269 / 512 * _E5;
var P3 = 21 / 16 * _E2 - 55 / 32 * _E4;
var P4 = 151 / 96 * _E3 - 417 / 128 * _E5;
var P5 = 1097 / 512 * _E4;

var R = 6378137;

var ZONE_LETTERS = 'CDEFGHJKLMNPQRSTUVWXX';

//this function is meant for the format: 30 N 699319 5710158, but I'm just going to adjust it to use 30N 699319 5710158 to match ios/android
export function UTMToLatLon(utm) {
    console.log(utm);
    var utmCoord = utm.trim().match(/\S+/g);
    // if (utmCoord==null || utmCoord.length!=4) throw new Error(`invalid UTM coordinate ‘${utmCoord}’`);
    if(utmCoord != null && utmCoord.length == 3) {
        var lastChar = utmCoord[0].substring(utmCoord[0].length-1, utmCoord[0].length); 
        var firstChars = utmCoord[0].substring(0, utmCoord[0].length-1); 
        utmCoord.splice(1, 0, lastChar);
        utmCoord[0] = firstChars;
    }
    var zoneNum, zoneLetter, easting, northing, northern;
    if(utmCoord != null && utmCoord.length == 4) {
        zoneNum = utmCoord[0];
        zoneLetter = utmCoord[1];
        easting = utmCoord[2];
        northing = utmCoord[3];
        northern = zoneLetter >= 'N';
    } 

    return UTMToLatLonWithMultipleParams(easting, northing, zoneNum, null, northern, true)
}

function UTMToLatLonWithMultipleParams(easting, northing, zoneNum, zoneLetter, northern, strict) {
  strict = strict !== undefined ? strict : true;

  if (!zoneLetter && northern === undefined) {
    console.log('either zoneLetter or northern needs to be set');
    return [false,null,null];
  } else if (zoneLetter && northern !== undefined) {
    console.log('set either zoneLetter or northern, but not both');
    return [false,null,null];
  }

  if (strict) {
    if (easting < 100000 || 1000000 <= easting) {
        console.log('easting out of range (must be between 100 000 m and 999 999 m)');
        return [false,null,null];
    }
    if (northing < 0 || northing > 10000000) {
        console.log('must be between 0 m and 10 000 000 m');
        return [false,null,null];
    }
  }
  if (zoneNum < 1 || zoneNum > 60) {
    console.log('zone number out of range (must be between 1 and 60)');
    return [false,null,null];
  }
  if (zoneLetter) {
    zoneLetter = zoneLetter.toUpperCase();
    if (zoneLetter.length !== 1 || ZONE_LETTERS.indexOf(zoneLetter) === -1) {
        console.log('zone letter out of range (must be between C and X)');
        return [false,null,null];
    }
    northern = zoneLetter >= 'N';
  }

  var x = easting - 500000;
  var y = northing;

  if (!northern) y -= 1e7;

  var m = y / K0;
  var mu = m / (R * M1);

  var pRad = mu +
             P2 * Math.sin(2 * mu) +
             P3 * Math.sin(4 * mu) +
             P4 * Math.sin(6 * mu) +
             P5 * Math.sin(8 * mu);

  var pSin = Math.sin(pRad);
  var pSin2 = Math.pow(pSin, 2);

  var pCos = Math.cos(pRad);

  var pTan = Math.tan(pRad);
  var pTan2 = Math.pow(pTan, 2);
  var pTan4 = Math.pow(pTan, 4);

  var epSin = 1 - E * pSin2;
  var epSinSqrt = Math.sqrt(epSin);

  var n = R / epSinSqrt;
  var r = (1 - E) / epSin;

  var c = _E * pCos * pCos;
  var c2 = c * c;

  var d = x / (n * K0);
  var d2 = Math.pow(d, 2);
  var d3 = Math.pow(d, 3);
  var d4 = Math.pow(d, 4);
  var d5 = Math.pow(d, 5);
  var d6 = Math.pow(d, 6);

  var latitude = pRad - (pTan / r) *
                 (d2 / 2 -
                  d4 / 24 * (5 + 3 * pTan2 + 10 * c - 4 * c2 - 9 * E_P2)) +
                  d6 / 720 * (61 + 90 * pTan2 + 298 * c + 45 * pTan4 - 252 * E_P2 - 3 * c2);
  var longitude = (d -
                   d3 / 6 * (1 + 2 * pTan2 + c) +
                   d5 / 120 * (5 - 2 * c + 28 * pTan2 - 3 * c2 + 8 * E_P2 + 24 * pTan4)) / pCos;

  return [true, toDegrees(latitude), toDegrees(longitude) + zoneNumberToCentralLongitude(zoneNum)];
}

export function UTMFromLatLon(latitude, longitude, forceZoneNum) {
  if (latitude > 84 || latitude < -80) {
      console.log('latitude out of range (must be between 80 deg S and 84 deg N)');
      return [false,null];
  }
  if (longitude > 180 || longitude < -180) {
      console.log('longitude out of range (must be between 180 deg W and 180 deg E)');
      return [false,null];
  }

  var latRad = toRadians(latitude);
  var latSin = Math.sin(latRad);
  var latCos = Math.cos(latRad);

  var latTan = Math.tan(latRad);
  var latTan2 = Math.pow(latTan, 2);
  var latTan4 = Math.pow(latTan, 4);

  var zoneNum;

  if (forceZoneNum === undefined) {
    zoneNum = latLonToZoneNumber(latitude, longitude);
  } else {
    zoneNum = forceZoneNum;
  }

  var zoneLetter = latitudeToZoneLetter(latitude);

  var lonRad = toRadians(longitude);
  var centralLon = zoneNumberToCentralLongitude(zoneNum);
  var centralLonRad = toRadians(centralLon);

  var n = R / Math.sqrt(1 - E * latSin * latSin);
  var c = E_P2 * latCos * latCos;

  var a = latCos * (lonRad - centralLonRad);
  var a2 = Math.pow(a, 2);
  var a3 = Math.pow(a, 3);
  var a4 = Math.pow(a, 4);
  var a5 = Math.pow(a, 5);
  var a6 = Math.pow(a, 6);

  var m = R * (M1 * latRad -
               M2 * Math.sin(2 * latRad) +
               M3 * Math.sin(4 * latRad) -
               M4 * Math.sin(6 * latRad));
  var easting = K0 * n * (a +
                          a3 / 6 * (1 - latTan2 + c) +
                          a5 / 120 * (5 - 18 * latTan2 + latTan4 + 72 * c - 58 * E_P2)) + 500000;
  var northing = K0 * (m + n * latTan * (a2 / 2 +
                                         a4 / 24 * (5 - latTan2 + 9 * c + 4 * c * c) +
                                         a6 / 720 * (61 - 58 * latTan2 + latTan4 + 600 * c - 330 * E_P2)));
  if (latitude < 0) northing += 1e7;

  console.log(northing);
  return [true, ''+zoneNum+zoneLetter+' '+Math.round(easting)+' '+Math.round(northing)];
}

export function latitudeToZoneLetter(latitude) {
  if (-80 <= latitude && latitude <= 84) {
    return ZONE_LETTERS[Math.floor((latitude + 80) / 8)];
  } else {
    return null;
  }
}

export function latLonToZoneNumber(latitude, longitude) {
  if (56 <= latitude && latitude < 64 && 3 <= longitude && longitude < 12) return 32;

  if (72 <= latitude && latitude <= 84 && longitude >= 0) {
    if (longitude <  9) return 31;
    if (longitude < 21) return 33;
    if (longitude < 33) return 35;
    if (longitude < 42) return 37;
  }

  return Math.floor((longitude + 180) / 6) + 1;
}

function zoneNumberToCentralLongitude(zoneNum) {
  return (zoneNum - 1) * 6 - 180 + 3;
}

function toDegrees(rad) {
  return rad / Math.PI * 180;
}

function toRadians(deg) {
  return deg * Math.PI / 180;
}

//https://stackoverflow.com/questions/46728319/how-to-convert-between-lat-long-and-mgrs-using-javascript-without-dependence-on
export function MGRSFromLatLng(Lat, Long) {
    if (Lat < -80) return [false,'N/A - Too far South']; 
    if (Lat > 84) return [false,'N/A - Too far North'];
    var c = 1 + Math.floor ((Long+180)/6);
    var e = c*6 - 183 ;
    var k = Lat*Math.PI/180;
    var l = Long*Math.PI/180;
    var m = e*Math.PI/180;
    var n = Math.cos (k);
    var o = 0.006739496819936062*Math.pow (n,2);
    var p = 40680631590769/(6356752.314*Math.sqrt(1 + o));
    var q = Math.tan (k);
    var r = q*q;
    var s = (r*r*r) - Math.pow (q,6);
    var t = l - m;
    var u = 1.0 - r + o;
    var v = 5.0 - r + 9*o + 4.0*(o*o);
    var w = 5.0 - 18.0*r + (r*r) + 14.0*o - 58.0*r*o;
    var x = 61.0 - 58.0*r + (r*r) + 270.0*o - 330.0*r*o;
    var y = 61.0 - 479.0*r + 179.0*(r*r) - (r*r*r);
    var z = 1385.0 - 3111.0*r + 543.0*(r*r) - (r*r*r);
    var aa = p*n*t + (p/6.0*Math.pow (n,3)*u*Math.pow (t,3)) + (p/120.0*Math.pow (n,5)*w*Math.pow (t,5)) + (p/5040.0*Math.pow (n,7)*y*Math.pow (t,7));
    var ab = 6367449.14570093*(k - (0.00251882794504*Math.sin (2*k)) + (0.00000264354112*Math.sin (4*k)) - (0.00000000345262*Math.sin (6*k)) + (0.000000000004892*Math.sin (8*k))) + (q/2.0*p*Math.pow (n,2)*Math.pow (t,2)) + (q/24.0*p*Math.pow (n,4)*v*Math.pow (t,4)) + (q/720.0*p*Math.pow (n,6)*x*Math.pow (t,6)) + (q/40320.0*p*Math.pow (n,8)*z*Math.pow (t,8));
    aa = aa*0.9996 + 500000.0;
    ab = ab*0.9996; if (ab < 0.0) ab += 10000000.0;
    var ad = 'CDEFGHJKLMNPQRSTUVWXX'.charAt (Math.floor (Lat/8 + 10));
    var ae = Math.floor (aa/100000);
    var af = ['ABCDEFGH','JKLMNPQR','STUVWXYZ'][(c-1)%3].charAt (ae-1);
    var ag = Math.floor (ab/100000)%20;
    var ah = ['ABCDEFGHJKLMNPQRSTUV','FGHJKLMNPQRSTUVABCDE'][(c-1)%2].charAt (ag);
    function pad (val) {if (val < 10) {val = '0000' + val} else if (val < 100) {val = '000' + val} else if (val < 1000) {val = '00' + val} else if (val < 10000) {val = '0' + val}return val}
    // aa = Math.floor (aa%100000); aa = pad (aa);
    // ab = Math.floor (ab%100000); ab = pad (ab);
    aa = Math.round (aa%100000); aa = pad (aa);
    ab = Math.round (ab%100000); ab = pad (ab);
    return [true,c + ad + '' + af + ah + ' ' + aa + ' ' + ab];
}

//this function is meant for the format: 48P UV 77298 83034, but I'm just going to adjust it to use 48PUV 77298 83034 to match ios/android
export function MGRSToLatLon(a) {
    var b = a.trim();
    b = b.match(/\S+/g);
    if(b != null && b.length == 3) {
        var lastTwoChars = b[0].substring(b[0].length-2, b[0].length); 
        var firstChars = b[0].substring(0, b[0].length-2); 
        b.splice(1, 0, lastTwoChars);
        b[0] = firstChars;
    }
    console.log(b);
    if (b == null || b.length != 4) return [false,null,null];
    var c = (b[0].length < 3) ? b[0][0] : b[0].slice(0,2);
    var d = (b[0].length < 3) ? b[0][1] : b[0][2];
    var e = (c*6-183)*Math.PI / 180;
    var f = ["ABCDEFGH","JKLMNPQR","STUVWXYZ"][(c-1) % 3].indexOf(b[1][0]) + 1;
    var g = "CDEFGHJKLMNPQRSTUVWXX".indexOf(d);
    var h = ["ABCDEFGHJKLMNPQRSTUV","FGHJKLMNPQRSTUVABCDE"][(c-1) % 2].indexOf(b[1][1]);
    var i = [1.1,2.0,2.8,3.7,4.6,5.5,6.4,7.3,8.2,9.1,0,0.8,1.7,2.6,3.5,4.4,5.3,6.2,7.0,7.9];
    var j = [0,2,2,2,4,4,6,6,8,8,0,0,0,2,2,4,4,6,6,6];
    var k = i[g];
    var l = Number(j[g]) + h / 10;
    if (l < k) l += 2;
    var m = f*100000.0 + Number(b[2]);
    var n = l*1000000 + Number(b[3]);
    m -= 500000.0;
    if (d < 'N') n -= 10000000.0;
    m /= 0.9996; n /= 0.9996;
    var o = n / 6367449.14570093;
    var p = o + (0.0025188266133249035*Math.sin(2.0*o)) + (0.0000037009491206268*Math.sin(4.0*o)) + (0.0000000074477705265*Math.sin(6.0*o)) + (0.0000000000170359940*Math.sin(8.0*o));
    var q = Math.tan(p);
    var r = q*q;
    var s = r*r;
    var t = Math.cos(p);
    var u = 0.006739496819936062*Math.pow(t,2);
    var v = 40680631590769 / (6356752.314*Math.sqrt(1 + u));
    var w = v;
    var x = 1.0 / (w*t); w *= v;
    var y = q / (2.0*w); w *= v;
    var z = 1.0 / (6.0*w*t); w *= v;
    var aa = q / (24.0*w); w *= v;
    var ab = 1.0 / (120.0*w*t); w *= v;
    var ac = q / (720.0*w); w *= v;
    var ad = 1.0 / (5040.0*w*t); w *= v;
    var ae = q / (40320.0*w);
    var af = -1.0-u;
    var ag = -1.0-2*r-u;
    var ah = 5.0 + 3.0*r + 6.0*u-6.0*r*u-3.0*(u*u)-9.0*r*(u*u);
    var ai = 5.0 + 28.0*r + 24.0*s + 6.0*u + 8.0*r*u;
    var aj = -61.0-90.0*r-45.0*s-107.0*u + 162.0*r*u;
    var ak = -61.0-662.0*r-1320.0*s-720.0*(s*r);
    var al = 1385.0 + 3633.0*r + 4095.0*s + 1575*(s*r);
    var lat = p + y*af*(m*m) + aa*ah*Math.pow(m,4) + ac*aj*Math.pow(m,6) + ae*al*Math.pow(m,8);
    var lng = e + x*m + z*ag*Math.pow(m,3) + ab*ai*Math.pow(m,5) + ad*ak*Math.pow(m,7);
    lat = lat*180 / Math.PI;
    lng = lng*180 / Math.PI;
    return [true,lat,lng];
}

export function getCoordinateString(lat,lon) {
    var coordinateType = gSettings.getCoordinateFormat();
    if(coordinateType == 1) {//DMS
        var latSeconds = Math.abs(lat*3600.0);
        var latDegrees = latSeconds / 3600;
        latSeconds = latSeconds % 3600.0;
        var latMinutes = latSeconds / 60.0;
        latSeconds %= 60.0;

        var longSeconds = Math.abs(lon * 3600.0);
        var longDegrees = longSeconds / 3600;
        longSeconds = longSeconds % 3600.0;
        var longMinutes = longSeconds / 60;
        longSeconds %= 60.0;

        var latDirection = (lat >= 0) ? 'N' : 'S';
        var longDirection = (lon >= 0) ? 'E' : 'W';
        
        return Math.floor(latDegrees) + '° ' + Math.floor(latMinutes) + '\' ' + Number(latSeconds).toFixed(3) + '\" ' + latDirection + ', ' + Math.floor(longDegrees) + '° ' + Math.floor(longMinutes) + '\' ' + Number(longSeconds).toFixed(3) + '\" ' + longDirection;

    } else if(coordinateType == 2) {//MGRS
        var mgrsResult = MGRSFromLatLng(lat,lon);
        if(mgrsResult[0] == true && mgrsResult.length > 1) {
            return mgrsResult[1];
        }
    } else if(coordinateType == 3) {//UTM
        var utmResult = UTMFromLatLon(lat,lon);
        if(utmResult[0] == true && utmResult.length > 1) {
            return utmResult[1];
        }
    }
    return Number(lat).toFixed(6) + ", " + Number(lon).toFixed(6);
}
