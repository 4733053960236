import { gSettings, TileServiceType } from './Settings';

export class CountyMap {
    constructor(mapName) {
        this.mapName = mapName;
        this.fullName = "";
        this.code = "";
        this.email = "";
        this.tileURL = ""
        this.mapType = 0;
        this.tileServiceType = TileServiceType.None; 
        this.minZoom = 4;  
        this.maxZoom = 16; 
        this.bounds = null;
        this.layerData = null;
        this.leafletTileLayer = null;
        this.leafletBoundsLayer = null;
        this.parcels = null;
        this.opacity = 1;
        this.showBoundary = false;
        this.popupDisplayOrder = 99;
        this.zLevel = 0;
        this.zIndex = 299;
        this.topojson = null;
    }

    setTileData(url, type, tileService, maxZoom, minZoom) {
        this.tileURL = url;
        this.mapType = type; //0;
        this.tileServiceType = tileService; 
        this.minZoom = minZoom; 
        this.maxZoom = maxZoom; 
        this.leafletTileLayer = null;
    }

    mapIsPurchased(mapName) {
      return gSettings.isProductPurchased(mapName);
  }

    setStripeSku(sku) {
        // console.log("setting sku: "+sku+" for map: "+this.mapName);
    }

    getMapRoot() {
        var name = this.mapName.replace("__","_");
        var countyParts = name.split("_");
        var year = countyParts.pop();
        var rootCountyName = countyParts.join("_");
        return rootCountyName;
    }

    getMapYear() {
        var countyParts = this.mapName.split("_");
        var year = countyParts.pop();
        if(parseInt(year)) {
            return year;
        }
        return null;
    }

    mapIsAvailable() {
        return this.getMapYear() != null;
    }

    getPopupTitle() {
        var mapNameWithYear = this.mapName;//FIX ME - we shouldn't always have a mapNameWithYear or need some way to flag unavailable
        var name = this.fullName.replace("RM of ","").replace("County of ","").replace(" County","").replace("MD of ","");
        var provinceCode = gSettings.provinceCode;
        if(provinceCode == "sk") {
            name += " No. " + parseInt(this.code);
            if(this.mapIsAvailable()) {
                name += " ("+this.getMapYear()+")";
            }
        } 
        else {
            if(this.mapIsAvailable() && this.mapIsPurchased(mapNameWithYear)) {
                name += " " + this.getMapYear();
            }
        }
        return name;
    }

    getMapName() {
      return this.name;
    }


    getPopupSubtitle(updateAvailable) {
        var mapNameWithYear = this.mapName;//FIX ME - we shouldn't always have a mapNameWithYear or need some way to flag unavailable
        if(this.mapIsAvailable()) {
            if(this.mapIsPurchased(mapNameWithYear)) {
                if(updateAvailable) {
                    return "View Settings (Update Available)";
                } else {
                    return "View Settings";
                }
            } 
            else {
                return "** "+this.getMapYear()+" Map for sale **";
            }
        } 

        return "Map Unavailable";
    }

    // getPopupSubtitle() {
    //     var mapNameWithYear = this.mapName;//FIX ME - we shouldn't always have a mapNameWithYear or need some way to flag unavailable
    //     if(this.mapIsAvailable()) {
    //         if(this.mapIsPurchased(mapNameWithYear)) {
    //             if(gCountyMaps.getUpdatableMapForPurchasedMap(mapNameWithYear)) {
    //                 return "View Settings (Update Available)";
    //             } else {
    //                 return "View Settings";
    //             }
    //         } 
    //         else {
    //             return "** "+this.getMapYear()+" Map for sale **";
    //         }
    //     } 

    //     return "Map Unavailable";
    // }

    getMenuTitle() {
        var name = this.fullName.replace("RM of ","").replace("County of ","").replace(" County","").replace("MD of ","");
        name += this.getMapYear() ? " " + this.getMapYear() : "";
        return name;
    }

    getMenuSubtitle() {
        var description = "";
        if(this.fullName.includes("RM of")) {
            if(gSettings.provinceCode == "sk" && this.code.length > 0) {
                description += "RM " + parseInt(this.code) + " ";
            } else {
                description += "RM ";
            }
        } else if(this.fullName.includes("County")) {
            description += "County ";
        } else if(this.fullName.includes("MD ")) {
            description += "MD ";
        }
        description += "Landowner Map";
        return description;
    }
}

export default CountyMap;
